<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','cart']">Cart</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">Clone</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">{{cartItemDetails?.title}}</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="p-0  flex flex-row relative" style="border-radius:8px;">
        <div class="relative w-full">
            <mat-tab-group class="mt-4 lg:mt-8" (selectedTabChange)="tabChanged($event)" id="designMapTab"
                #designMapTab>
                <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
                    <cart-design-map  (onNext)="onClickNext($event)" [mapData]="mapData" [data]="tab"
                        [onClickNext]="tab.onNext">

                    </cart-design-map>
                </mat-tab>
                <mat-tab label="Contacts" class="flex flex-col justify-end">

                    <ng-container *ngIf="contactList?.length > 0 ; else noData">
                        <div class="flex flex-col items-end w-full">

                            <button mat-flat-button class="block sm:hidden btn_white self-end" (click)="addContact()"
                                style="align-self: flex-start;line-height: normal;">
                                Add Contact
                            </button>
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6 w-full">
                                <div *ngFor="let contact of contactList; let last = last"
                                    class="flex flex-row bg-card border border-[#afafaf] rounded-lg">

                                    <mat-checkbox class="w-full p-4" [(ngModel)]="contact.selected">
                                        <p class="text-[#060267]" style="font-size:17px;font-weight:700;">
                                            {{contact.name}}</p>
                                        <div class="text-black flex flex-row items-start gap-4 mt-1"
                                            style="font-weight:400;font-size:14px;">
                                            {{contact.email}}
                                        </div>
                                    </mat-checkbox>
                                </div>
                            </div>
                            <button class="p-6 pb-2 pt-2 self-end mt-6 btn_normal w-28 sm:hidden"
                                (click)="submitAutofillMappingData()">
                                Clone
                            </button>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="hidden sm:flex absolute gap-2 z-10" style="right:0px;top:20px">
            <button mat-flat-button class="btn_white w-28" (click)="addContact()" *ngIf="showCloneButton">Add Contact
            </button>
            <button mat-flat-button class="btn_normal w-20" (click)="onNext()" *ngIf="!showCloneButton">Next </button>
            <button mat-flat-button class="btn_normal w-20" (click)="submitAutofillMappingData()"
                *ngIf="showCloneButton">Clone </button>
        </div>
    </div>
</section>

<ng-template #noData>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any contacts yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Oops! It seems there are no contacts saved yet. Start by adding your contact here.
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addContact()" mat-flat-button>
            Add Contact
        </button>
    </div>
    <!-- / -->
</ng-template>