import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { AutofillDesignMapComponent } from '../../autofill-manager/autofill-map/design-map/design-map.component';
import { AutofillManagerService } from '../../autofill-manager/autofill-manager.service';
import { AutofillGroup, MappingData } from '../../autofill-manager/autofill-manager.types';
import { ProductService } from 'app/core/product/product.service';


@Component({
  selector: 'lock-fields',
  templateUrl: './lock-fields.component.html',
  styleUrls: ['./lock-fields.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatRadioModule, AutofillDesignMapComponent,
    MatTabsModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule,
    MatProgressSpinnerModule]
})

export class LockFieldsComponent implements OnInit {

  @ViewChild("designMapTab", { static: false }) designMapTab: MatTabGroup;
  /**
   * Constructor
   */
  constructor(private _productService: ProductService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _autofillManager: AutofillManagerService,
    private location: Location,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef, private _imageViewer: ImageViewerService,
    private _toast: ToastrService, private broadcast: BroadcastService,
    private route: ActivatedRoute) {

  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  autofillData: any
  productDetails: any

  mapData: MappingData;
  tabs: any[] = [];
  autofillMappedData = {
    designs: []
  }

  productId
  ngOnInit(): void {
    this.productId = this.route.snapshot.paramMap.get('id');
    this.mapData = this.route.snapshot.data['data'][1]
    this.autofillData = this.route.snapshot.data['data'][0]
    this.productDetails = this.route.snapshot.data['data'][2]

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()

    })
    this.configureTabs()
  }

  configureTabs() {
    this.autofillData[0]?.designs.forEach((element, index) => {
      if (index === 0) {
        element.active = true
      }
      this.tabs.push(element)
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onClickNext(data) {
    let index = this.designMapTab.selectedIndex

    if (this.autofillMappedData.designs.length > index) {
      this.autofillMappedData.designs[index] = data
    }
    else {
      this.autofillMappedData.designs.push(data)
    }

    if ((index + 1) == this.tabs.length) {
      this.submitAutofillMappingData()
    } else
      this.designMapTab.selectedIndex += 1
  }

  tabChanged(e: MatTabChangeEvent) {
    // this.tabs = this.tabs.filter((item, index) => {
    //   if (index == e.index) {
    //     item.active = true
    //   } else {
    //     item.active = false
    //   }
    //   return item
    // })
  }

  submitAutofillMappingData() {

    this._spinner.show();

    let data = { 'autofill_data': this.autofillMappedData.designs }
    this._productService.submitLockFieldsAutofillData(this.productId, data).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        let dialog = this._fuseConfirmationService.open({
          title: "Success",
          message: res?.message ?? "Successfully submitted autofill data",
          dismissible: false,
          icon: { show: false },
          actions: {
            cancel: { show: false },
            confirm: { label: "Ok" }
          },
        })
        dialog.afterClosed().subscribe(result => {
          this.location.back()
        })
      },
      error: err => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : "Something went wrong", "Sorry")
      }
    })
  }
}