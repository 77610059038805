import { inject } from '@angular/core';
import { Observable, forkJoin, of, tap } from 'rxjs';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { SearchService } from '../search/search.service';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';

export const categoryDetailDataResolver = (route: ActivatedRouteSnapshot) => {
    const _searchService = inject(SearchService);
    const _productService = inject(ProductService);
    const _broadcastService = inject(BroadcastService);
    if (route.url[0].path != "category") {
        _broadcastService.broadcast('product_details_resolver_started_running', {})
    }
    // _broadcastService.broadcast('product_details_resolver_started_running', {})
    _productService.clearData()
    _searchService.clearData()
    const requestArray: Observable<any>[] = [
        _searchService.search({
            sub_category_slug: route.params['subCategoryId'], 
            category_slug: route.params['id'], batch_size: 100, get_results: true
        }, false)]
        console.log(route.params['subCategoryId']);
        
    return route.params['subCategoryId'] != null ? forkJoin(requestArray) : of(null)
};

export const productDetailDataResolver = (route: ActivatedRouteSnapshot) => {
    const _productService = inject(ProductService);
    const _searchService = inject(SearchService);
    const _broadcastService = inject(BroadcastService);
    if (route.url[0].path != "product") {
        _broadcastService.broadcast('product_details_resolver_started_running', {})
    }
    const requestArray: Observable<any>[] = [_productService.getProductDetails(route.params['productId']).pipe(
        tap((result) => {
            _searchService.clearData()
            return result;
        }),
    )]
    return forkJoin(requestArray);
}; 