import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "../localstorage/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    constructor(private cookieService: CookieService, private _localStorageService: LocalStorageService) {

    }

    setCookie() {
        let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
        this.cookieService.set('token', this._localStorageService.accessToken, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
        this.cookieService.set('Organization-ID', this._localStorageService.accountType == 'user' ? "0" : this._localStorageService.organizationId, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
    }

    setAutofillCookie() {
        let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
        this.cookieService.set('token', localStorage.getItem('autofill_token'), { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
        this.cookieService.set('Organization-ID', this._localStorageService.accountType == 'user' ? "0" : this._localStorageService.organizationId, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
    }

}