<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content>

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form [formGroup]="organizationForm" #organizationNgForm="ngForm">

                    <div class="grid grid-cols-2 sm:grid-cols-4 gap-3 w-full">

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Organization Name</mat-label>
                                <input [formControlName]="'name'" matInput class="text-black">
                                <mat-error *ngIf="organizationForm.get('name').hasError('required')">
                                    Name is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('name').hasError('minlength')">
                                    A valid name is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Tagline</mat-label>
                                <input [formControlName]="'company_tagline'" matInput class="text-black">
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Website</mat-label>
                                <input [formControlName]="'company_website'" matInput class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_website').hasError('pattern')">
                                    A valid url is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Mobile</mat-label>
                                <input [formControlName]="'phone_number'" matInput maxlength="13" type="number"
                                    class="text-black">
                                <mat-error *ngIf="organizationForm.get('phone_number').hasError('required')">
                                    Mobile number is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('phone_number').hasError('min')">
                                    A valid mobile number is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Fax Number</mat-label>
                                <input [formControlName]="'company_fax'" matInput maxlength="13" type="number"
                                    class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_fax').hasError('required')">
                                    Fax number is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('company_fax').hasError('min')">
                                    A valid fax number is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--  -->
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Email Address</mat-label>
                                <input [formControlName]="'company_email'" matInput type="email" class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_email').hasError('required')">
                                    Email id is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('company_email').hasError('email')">
                                    A valid email id is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Address Line 1</mat-label>
                                <input [formControlName]="'company_address_line_1'" matInput class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_address_line_1').hasError('required')">
                                    Address line 1 is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Address Line 2</mat-label>
                                <input [formControlName]="'company_address_line_2'" matInput class="text-black">
                            </mat-form-field>
                        </div>
                        <!-- / -->
                        <!--  -->
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Zip Code</mat-label>
                                <input [formControlName]="'company_zipcode'" matInput type="number" maxlength="10"
                                    class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_zipcode').hasError('required')">
                                    Zip code is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('company_zipcode').hasError('min')">
                                    A valid zip code is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>City</mat-label>
                                <input [formControlName]="'company_city'" matInput class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_city').hasError('required')">
                                    City is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('company_city').hasError('minlength')">
                                    A valid city code is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>State</mat-label>
                                <input [formControlName]="'company_state'" matInput class="text-black">
                                <mat-error *ngIf="organizationForm.get('company_state').hasError('required')">
                                    State is required
                                </mat-error>
                                <mat-error *ngIf="organizationForm.get('company_state').hasError('minlength')">
                                    A valid state code is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- / -->
                    </div>

                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button class="btn_normal w-50 mt-3" [disabled]="organizationForm.disabled" mat-flat-button
        [color]="'primary'" type="submit" (click)="submit()">
        <span *ngIf="!organizationForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="organizationForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>