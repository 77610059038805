<section class="flex flex-col sm:flex-row gap-6">

    <div class="w-full sm:w-40 lg:w-72 h-50 bg-[#f5f5f5] rounded-lg overflow-hidden shrink-0 cursor-zoom-in"
        (click)="openImage(data.image_url)">
        <img [src]="data.image_url" class="h-full w-full object-contain" alt="Design preview">
    </div>
    <form class="flex flex-col w-full gap-4">
        <div *ngFor="let field of data.fields" class="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-6 items-center">
            <mat-form-field class="w-full hidden md:flex" *ngIf="field.type == 'text'" subscriptSizing="dynamic"
                [floatLabel]="'dynamic'">
                <mat-label class="flex sm:hidden"></mat-label>
                <input matInput disabled="true" [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}" class="text-black">
            </mat-form-field>

            <img [src]="field.value" *ngIf="field.type != 'text'" [alt]="field.type"
                style="background-image: url(assets/images/image_bg_transparant.png); width: 80px;height: 80px;border:1px solid #cad4e6; background-color: #f5f5f5;border-radius: 6px;object-fit: contain;">

            <div class="flex flex-row items-center gap-6">
                <mat-form-field class="w-full h-fit" subscriptSizing="dynamic" [floatLabel]="'dynamic'">
                    <mat-label class="flex md:hidden" *ngIf="field.type == 'text'">{{field.value}}</mat-label>
                    <mat-select [(ngModel)]="field.key_id" [ngModelOptions]="{standalone: true}">
                        <mat-option [value]="null">-Remove Selection-</mat-option>
                        <mat-option *ngFor="let keyMap of (field.type === 'text' ? mapData?.text : mapData?.image)"
                            [value]="keyMap.key">
                            {{keyMap.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-slide-toggle class="shrink-0 w-20" [(ngModel)]="field.locked" [ngModelOptions]="{standalone: true}"
                    [ngClass]="{'mt-6': field.type == 'text', 'mt--0': field.type != 'text'}">
                    Lock
                </mat-slide-toggle>
            </div>

        </div>

        <!-- <button mat-flat-button class="p-4 pb-2 pt-2 self-end btn_normal w-28 mt-6 sm:hidden" (click)="onSubmit()">
            Next
        </button> -->
    </form>
</section>