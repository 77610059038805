<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{data==null ? 'Add Address' : 'Edit Address'}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1 pb-1">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form [formGroup]="addressForm" #addressNgForm="ngForm" class="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <mat-form-field class="w-full col-span-1 md:col-span-2">
                        <mat-label>Name</mat-label>
                        <input id="name" matInput type="text" [formControlName]="'name'" class="text-black">
                        <mat-error *ngIf="addressForm.get('name').hasError('required')">
                            Name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 md:col-span-2 -mt-[10px]">
                        <mat-label>Address Line 1</mat-label>
                        <input id="address_line_1" matInput type="text" [formControlName]="'address_line_1'" class="text-black">
                        <mat-error *ngIf="addressForm.get('address_line_1').hasError('required')">
                            Address line 1 shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 md:col-span-2 -mt-[10px]">
                        <mat-label>Address Line 2</mat-label>
                        <input id="address_line_2" matInput type="text" [formControlName]="'address_line_2'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]">
                        <mat-label>City</mat-label>
                        <input id="city" matInput type="text" [formControlName]="'city'" class="text-black">
                        <mat-error *ngIf="addressForm.get('city').hasError('required')">
                            City shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]">
                        <mat-label>State</mat-label>
                        <input id="state" matInput type="text" [formControlName]="'state'" class="text-black">
                        <mat-error *ngIf="addressForm.get('state').hasError('required')">
                            State shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]">
                        <mat-label>Zipcode</mat-label>
                        <input id="zipcode" matInput type="text" [formControlName]="'zipcode'" class="text-black">
                        <mat-error *ngIf="addressForm.get('zipcode').hasError('required')">
                            Zipcode shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]">
                        <mat-label>Phone Number</mat-label>
                        <input id="phone_number" matInput type="text" [formControlName]="'phone_number'" class="text-black">
                        <mat-error *ngIf="addressForm.get('phone_number').hasError('required')">
                            Phone number shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button mat-flat-button class="w-50 mt-3 btn_normal" [disabled]="addressForm.disabled"
        mat-flat-button [color]="'primary'" type="submit" (click)="submit()">
        <span *ngIf="!addressForm.disabled">
            {{data == null ? 'Submit' : 'Update'}}
        </span>
        <mat-progress-spinner *ngIf="addressForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>