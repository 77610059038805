import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HomeService } from './home.service';

export const homeDataResolver = () => {
    const _homeService = inject(HomeService);
    const requestArray: Observable<any>[] = [_homeService.getBanners(),
    _homeService.getEmailSignatures(),
    // _homeService.getFeaturedTemplates(),
    // _homeService.getFeaturedCategories()
]
    return forkJoin(requestArray);
};
