<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0 min-h-120" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">Help</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">FAQ</a>
    </div>
    <h1 class="text-center sm:text-start mb-3" style="font-weight:700;font-size:23px;">FAQ</h1>
    <div style="margin-left:-24px !important;margin-right:-24px !important;">
        <mat-tab-group class="mt-4 sm:mt-0" style="flex-grow: 0;" (selectedTabChange)="onTabChanged($event);">
            <mat-tab *ngFor="let category of categories" [label]="category.title">
                <div>
                    <ng-container *ngIf="category.faqs?.length > 0; else loading">
                        <mat-accordion class="max-w-full mt-8">
                            <ng-container *ngFor="let faq of category.faqs">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="justify-between" [collapsedHeight]="'56px'">
                                        <mat-panel-title
                                            class="font-medium leading-tigh p-1"><h2>{{faq.question}}</h2></mat-panel-title>
                                    </mat-expansion-panel-header>
                                   <div class="p-3"> {{faq.answer}} </div> 
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-accordion>
                    </ng-container>
                </div>
                <ng-container *ngIf="category.videos?.length > 0;">
                    <h2 class="font-bold text-xl text-center sm:text-start mt-20">
                        Checkout Our Explaimer Videos
                    </h2>
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
                        <div class="flex flex-col" style="overflow: hidden;" *ngFor="let video of category.videos">
                            <iframe width="100%" [src]="getSanitizedURL(video)"
                                style="border-radius:20px;border:none; aspect-ratio: 16/9;"
                                *ngIf="video?.video_url?.includes('youtube')" allow="fullscreen https://www.youtube.com"
                                allowTransparency="true" background="transparent">
                            </iframe>

                            <video width="100%" controls style="aspect-ratio: 16/9;background-color: #000;"
                                *ngIf="!video?.video_url?.includes('youtube')">
                                <source [src]="getSanitizedURL(video)" type="video/mp4">
                                Your browser does not support this video.
                            </video>

                            <p
                                style="font-size:15px;color:#000000;width:100%;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;">
                                {{video?.title}}</p>
                        </div>
                    </div>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>

</section>


<ng-template #loading class="w-full justify-center">
    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
    </mat-progress-spinner>
</ng-template>