<section>
    <div class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
        <!-- Bread crumb -->
        <div class="breadcrumb flex mt-4 mb-2 sm:mb-8">
            <a class="text-black" [routerLink]="['/','home']">Home</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-black" style="font-weight:600" href="/about-us">About Us</a>
        </div>
        <!-- / -->
        <!-- Detail page -->
        <div class="mt-6">
            <img src="./assets/images/about-us_banner.png" style="width:100%;height:100%;min-height:120px;object-position: center;object-fit: cover;border-radius: 10px;;">
        </div>
        <div class="mt-[30px] md:mt-[50px] md:ml-10 md:mr-10">
            <h1 class="text-center m-auto md:ml-0 mb-3 text-[#060266]" style="font-weight:700;font-size:24px;">
                Design, Print & Marketing Solutions</h1>
            <p class="mt-2 mb-2 text-black text-center" style="font-weight:400;font-size:14px;">
                At Printing Warehouses of America, we excel at providing innovative business services designed to
                enhance customer engagement. We are one of the most experienced printing companies in Florida and are
                dedicated to providing our customers with high-quality printing, design, and marketing solutions. By
                leveraging cutting-edge technologies, we empower you to reach your audience more effectively and
                efficiently. Our commitment to embracing the latest advancements and collaborating closely with our
                clients is at the heart of our mission, driving your business growth and success.
                <br><br>Our commitment as an industry leader is to deliver seamless experiences through high-quality
                printing, design, and marketing services. We prioritize offering the best quality at affordable prices,
                ensuring exceptional value and client satisfaction. We continuously strive to innovate and adapt to meet
                the evolving needs of our clients. Our expert team is here to provide personalized solutions that align
                with your goals and help you stand out in a competitive market.
            </p>
        </div>
        <!-- / -->
        <!-- Smart choice for quality prints -->
        <div class="flex flex-col md:flex-row items-center gap-8 md:gap-20 mt-[90px] w-full">
            <div>
                <img src="./assets/images/quality_prints_img.png" class="md:h-[260px] w-full h-auto md:max-w-fit md:w-auto">
            </div>
            <div>
                <h2 class="text-center md:text-start m-auto md:ml-0 mb-3 text-[#060266]"
                    style="font-weight:700;font-size:24px;">Smart Choices for Quality Prints</h2>
                <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">To
                    meet all your
                    printing needs, Printing Warehouses of America offers a diverse selection of high-quality products.
                    Whether you need the best business card printing, brochures, door hangers, car magnets, greeting
                    cards, or other printed materials, our state-of-the-art printing technology ensures crisp, vibrant
                    results every time. Whether you’re running a small business or managing a large company, we assist
                    in creating standout materials that look professional, polished, and impactful. Whatever your
                    project entails, we ensure exceptional results that align with your budget and exceed your
                    expectations.</p>
            </div>
        </div>
        <!-- / -->

        <!-- Perfecting Digital Experiences -->
        <div class="flex flex-col-reverse md:flex-row items-center gap-8 md:gap-20 w-full mt-[60px]">
            <div>
                <h2 class="text-center md:text-start m-auto md:ml-0 mb-3 text-[#060266]"
                    style="font-weight:700;font-size:24px;">
                    Perfecting Digital Experiences</h2>
                <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">
                    Whatever your digital needs, Printing Warehouses of America offers advanced products to enhance your
                    online presence. Our services include creating eye-catching Facebook posters, custom e-flyers,
                    captivating social media visuals, and professional email signatures. We also provide the best
                    designs for Instagram posts to make your content stand out. With our innovative resources, you can
                    effortlessly share your designs across multiple social media platforms, ensuring consistent and
                    impactful communication with your audience.</p>
            </div>
            <div>
                <img src="./assets/images/digital_experience_img.png" class="md:h-[260px] w-full h-auto md:max-w-fit md:w-auto">
            </div>
        </div>
        <!-- / -->
        <!-- Marketing solutions for every business -->
        <div class="flex flex-col md:flex-row items-center gap-8 md:gap-20 w-full mt-[60px]">
            <div>
                <img src="./assets/images/marketing_solutions_img.png" class="md:h-[260px] w-full h-auto md:max-w-fit md:w-auto">
            </div>
            <div>
                <h2 class="text-center md:text-start m-auto md:ml-0 mb-3 text-[#060266]"
                    style="font-weight:700;font-size:24px;">
                    Marketing Solutions for Every Business</h2>
                <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">Every
                    business must improve its brand, and Printing Warehouses of America can help. We provide various
                    digital marketing services, including SEO, PPC, and Social Media Management, to connect with your
                    audience effectively. Our on-trend, intuitive <a href="/social-media" style="color:#060266;font-weight:600">social media scheduler</a> tools simplify your strategy by
                    automating and scheduling posts across multiple platforms, ensuring consistent and impactful
                    communication. Our <a href="https://crmlevel10.com/" style="color:#060266;font-weight:600" target="_blank">CRM services</a> also help you interact better with your customers and work more
                    efficiently, helping you build strong, productive relationships.</p>
            </div>
        </div>
        <!-- / -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-2 mt-20 md:mt-26">
            <div class="p-[37px] pb-[45px] flex flex-col items-start w-full" *ngFor="let item of aboutData;"
                style="border: solid 1px #dedeff;border-radius: 20px;box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);background-color:#ffffff;z-index: 2;">
                <div class="flex sm:flex-row flex-col sm:gap-6 items-center mb-2 w-full">
                    <img fetchpriority="low" ngSrc="{{item.icon}}" class="w-[55px] h-[55px] mb-2 relative" [alt]="item.title"
                        style="width:45px;height:45px;object-fit: contain;" fill>
                    <h3 class="text-[#060267] mb-2 mt-2 text-center sm:text-left"
                        style="font-size:20px;font-weight:700;max-width: 140px;">
                        {{item.title}}
                    </h3>
                </div>
                <div class="text-black text-center sm:text-left mb-4" style="font-size:14px;">
                    {{item.content}}
                </div>
            </div>
            <!-- /column1 -->
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 pt-0 pb-0 w-full mt-10">
        <div class="flex flex-row md:justify-end w-full  pl-0 pb-6 pt-6 filter-listing bg-[#f7f4f0]">
            <div class="flex items-center justify-center">
                <img src="./assets/images/ebook_img@3x.png"
                    style="max-width:100%;height:80%;object-fit: cover;object-position: right;"
                    alt="Printing Warehouses of America Ebook cover and sign-up">
            </div>
            <div
                class="flex flex-col flex-auto ml-1 sm:ml-6 mr-0 sm:mr-2 mt-4 mb-4 max-w-[240px] sm:max-w-[400px] pr-6 justify-center">
                <h4 class="text-left"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-weight:600;font-size:16px;">
                    Ebook {{date | date:'yyyy'}}</h4>
                <h4 class="text-left ebook_title max-w-[320px] sm:max-w-[300px]"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-size:26px;font-weight:bold;line-height: 1.2;">
                    Printing Warehouses
                    of America®</h4>
                <!-- Email field -->
                <form [formGroup]="ebookForm" #ebookNgForm="ngForm">
                    <div class="mb-6 mt-3">
                        <mat-form-field class="col-span-1 md:col-span-2 w-[200px] sm:w-[270px]"
                            [hideRequiredMarker]="hideRequiredMarker">
                            <mat-label class="text-black" style="font-weight:normal;font-size:12px;">Email
                                Address</mat-label>
                            <input id="email" matInput type="email" [formControlName]="'email'" class="text-black">
                            <mat-error *ngIf="ebookForm.get('email').hasError('required')">
                                Email shouldn't be empty
                            </mat-error>
                            <mat-error *ngIf="ebookForm.get('email').hasError('email')">
                                Please provide a valid email id
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
                <button class="block -mt-[20px] mb-2 m-left btn_normal w-full sm:w-[200px] " (click)="downloadEbook()"
                    style="align-self: flex-start;">Download</button>
                <p class="mt-2 text-left ebook_text"
                    style="font-size: 11px;font-family: 'Poppins', sans-serif;font-weight:400;">
                    By clicking “Download” you agree to receive marketing emails
                    from Printing Warehouses of America. You also agree that your personal
                    data will be processed in accordance with our Privacy Policy</p>

            </div>
        </div>
        <div class="flex justify-center flex-row"
            style="background-image: linear-gradient(91deg, #ededed 0%, #f8f8f8 98%);">
            <div class="min-w-50 sm:ml-12 sm:mt-12 sm:mb-12 m-8 flex flex-col justify-center mr-8 sm:-mr-[5rem]">
                <h4 class="text-center sm:text-left ebook_title m-auto sm:ml-0 sm:mb-0 sm:max-w-[250px]"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-size:26px;font-weight:bold;line-height: 1.2;">
                    Start Designing
                    Your Project Now</h4>
                <p class="mt-2 ebook_text text-center sm:text-left"
                    style="font-size: 11px;font-family: 'Poppins', sans-serif;font-weight:400;max-width:350px">
                    And unlock your full potential with our unmatched
                    printing services!

                </p>
                <button class="block w-full sm:w-[200px] mt-6 m-auto sm:ml-0 plan_btn btn_normal"
                    [routerLink]="['/','search']" style="padding:0.5rem 1.9rem;line-height: normal;">Get
                    Started</button>
            </div>
            <div class="hidden sm:flex">
                <img src="./assets/images/getstarted_banner.png" class="max-w-120 object-contain h-full"
                    style="object-position: bottom;" alt="Best printing services">
            </div>

        </div>
    </div>

</section>