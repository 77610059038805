import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { DesignView } from '../design-view/design-view.component';
import { Franchise, Product, ProductVersion } from 'app/core/product/product.types';
import { Pagination } from 'app/core/api/pagination.type';
import { ProductService } from 'app/core/product/product.service';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from 'app/shared/login/signin.component';
import { VersionHistoryComponent } from '../version-history/version-history.component';
import { HttpParams } from '@angular/common/http';
import { AddToCartComponent } from '../add-to-cart/add-to-cart.component';
@Component({
  selector: 'app-product-version',
  templateUrl: './product-version.component.html',
  styleUrls: ['./product-version.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule, PWAPagination, MatProgressSpinnerModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule, DesignView,
    RouterModule]
})
export class ProductVersionPopup implements OnInit {

  @ViewChild('filterNgForm') createChecklistNgForm: NgForm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: Product, options },
    private _formBuilder: FormBuilder, private _productService: ProductService,
    private _localStorage: LocalStorageService, private _spinner: NgxSpinnerService,
    private _toast: ToastrService, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<ProductVersionPopup>
  ) { }

  // @Output() onClose = new EventEmitter<void>();

  // close() {
  //   this.onClose.emit();
  // }

  filterForm: FormGroup;
  ngOnInit(): void {
    this.filterForm = this._formBuilder.group({
      franchise: [0],
      franchise_category: [0],
      color: [0],
      tag: [0],
      keyword: [0]
    });
    this.getVersions(1)
  }

  versions: ProductVersion[]
  showLoading = false
  pagination: Pagination = { current_page: 1, batch_size: 10, total_count: 1, total_pages: 1 }

  getVersions(pageNumber) {
    this.showLoading = true
    let queryParams = new HttpParams();
    queryParams = queryParams.append("batch_size", 12);
    queryParams = queryParams.append("page", pageNumber ?? 1);
    if (this.filterForm.get('franchise').value > 0)
      queryParams = queryParams.append("franchise", this.filterForm.get('franchise').value);
    if (this.filterForm.get('franchise_category').value > 0)
      queryParams = queryParams.append("franchise_category", this.filterForm.get('franchise_category').value);
    if (this.filterForm.get('tag').value > 0)
      queryParams = queryParams.append("tag", this.filterForm.get('tag').value);
    if (this.filterForm.get('color').value > 0)
      queryParams = queryParams.append("color", this.filterForm.get('color').value);
    if (this.filterForm.get('keyword').value > 0)
      queryParams = queryParams.append("keyword", this.filterForm.get('keyword').value);
    this._productService.getProductVersions(this.data?.product?.id, queryParams).subscribe({
      next: (res: any) => {
        this.versions = res.data
        this.pagination = res
        this.showLoading = false
      },
      error: (err) => {
        this.showLoading = false
      }
    })
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = "";
  close() {
    // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInLeft")
    // document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    // setTimeout(() => { this._dialogRef.close(); }, 1000);
    this._dialogRef.close();
  }

  openSignIn() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(LoginComponent, dialogConfig)
  }


  getProductVersionHistory(versionId) {
    if (this.data?.product.is_accessory) {
      this.openPlaceYourOrder(versionId)
      return
    }
    if (this._localStorage.accessToken?.length == 0) {
      this.openSignIn()
      return
    }
    this._spinner.show()
    this._productService.getProductHistory(versionId).subscribe({
      next: versions => {
        this._spinner.hide()
        if (versions?.length > 0)
          this.openVersionHistory(versions, versionId)
        else
          this.createVersionHistory(versionId)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openPlaceYourOrder(versionId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-auto', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    dialogConfig.data = { isAccessory: true, options: this.data?.options, versionId: versionId };
    this._matDialog.open(AddToCartComponent, dialogConfig)
  }

  createVersionHistory(versionId) {
    this._spinner.show()
    this._productService.createVersionHistory(versionId, false).subscribe({
      next: version => {
        this._spinner.hide()
        this.openVersionHistory([version], versionId)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openVersionHistory(histories, versionId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']

    dialogConfig.data = { versionId: versionId, histories: histories, options: this.data?.options, productId: this.data?.product?.id };
    this._matDialog.open(VersionHistoryComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });

  }

  franchises: Franchise[]
  onChangeCategory() {
    this.filterForm.patchValue({ 'franchise': 0 })
    this.franchises = this.data?.product?.franchises?.filter(franchise => franchise.category === this.filterForm.get('franchise_category').value)
  }

  doSearch() {
    this.getVersions(1)
  }
}
