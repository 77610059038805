import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-review-popup',
  templateUrl: './review-popup.component.html',
  styleUrls: ['./review-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule]
})
export class ReviewPopup implements OnInit {

  @ViewChild('filterNgForm') createChecklistNgForm: NgForm;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { checklistId: number },
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<ReviewPopup>) { }

  filterForm: FormGroup;
  ngOnInit(): void {
    this.filterForm = this._formBuilder.group({
      sub_category: [""],
      size_category: [""],
      business_category: [""],
      color_category: [""]
    });
    // this.title = "Update Checklist Item"
  }


  // title = "Create Checklist Item";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }



}
