import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PaymentService } from 'app/core/payment/payment.service';
import { PaymentCard } from 'app/core/payment/payment.types';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CreateAddressComponent } from '../settings/address/create-address/create-address.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CreateCardComponent } from '../settings/saved-cards/create-card/create-card.component';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails, CartItem, ShippingMethod } from 'app/core/cart/cart.types';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
// import {} from '@types/googlemaps';
import PlaceResult = google.maps.places.PlaceResult;
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'pay-for-others',
  templateUrl: './pay-for-others.component.html',
  styleUrls: ['./pay-for-others.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatButtonModule, MatTabsModule, MatCheckboxModule, FormsModule,
    MatRadioModule, MatFormFieldModule, MatInputModule, MatIconModule,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent, MatGoogleMapsAutocompleteModule,
    FuseAlertComponent,
    StripeCardComponent]
})

export class PayForOtherComponent implements OnInit {
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  @ViewChild('createCardNgForm') createCardNgForm: NgForm;
  createCardForm: UntypedFormGroup;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  constructor(
    private clipboard: Clipboard,
    private _fuseConfirmationService: FuseConfirmationService, private _spinner: NgxSpinnerService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    private fb: UntypedFormBuilder, private stripeService: StripeService, private _route: ActivatedRoute,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _cardsService: PaymentService, private _cartService: CartService, private _router: Router) {

    this.matIconRegistry.addSvgIcon(
      "nav_search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav-search.svg")
    );
  }

  selectedTab = 0
  useShippingAddressAsShippingAddress = false

  cartDetails: CartDetails = null
  cartItems: CartItem[] = []
  paymentToken
  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        this.paymentToken = this._route.snapshot.params['token']
      }
    );
    this._cartService.cartDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cartDetails => {
        if (cartDetails.items.length == 0) {
          // this.showCartEmptyOrReviewError(true)
          return
        }

        this.cartDetails = cartDetails
        this.cartItems = cartDetails.items
        this._changeDetectorRef.markForCheck()
      })

    this.createCardForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address_line_1: ['', [Validators.required]],
      address_line_2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      phone_number: ['', [Validators.minLength(8)]],
    });

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onAutocompleteSelected(result: PlaceResult) {
    result.formatted_address
    this.createCardForm.patchValue({
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
    })

    this.createCardForm.patchValue({ "name": result?.name })
    this.createCardForm.patchValue({ "phone_number": result?.formatted_phone_number })

    const findComponent = (type) =>
      result?.address_components?.find(component => component.types.includes(type));

    const streetNumber = findComponent('street_number');
    const route = findComponent('route');
    this.createCardForm.patchValue({ "address_line_1": `${streetNumber ? streetNumber.long_name : ''} ${route ? route.long_name : ''}`.trim() })

    const subpremise = findComponent('subpremise');
    if (subpremise) {
      this.createCardForm.patchValue({ "address_line_2": subpremise.long_name })
    }

    const locality = findComponent('locality');
    const sublocality = findComponent('sublocality');
    this.createCardForm.patchValue({ "city": locality?.long_name || sublocality?.long_name || '' })

    const state = findComponent('administrative_area_level_1');
    if (state) {
      this.createCardForm.patchValue({ "state": state.long_name })
    }

    const postalCode = findComponent('postal_code');
    const postalCodePrefix = findComponent('postal_code_prefix');
    this.createCardForm.patchValue({ "zipcode": postalCode?.long_name || postalCodePrefix?.long_name || '' })
    console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    // this.latitude = location.latitude;
    // this.longitude = location.longitude;
  }

  showNextTab() {
    switch (this.selectedTab) {
      case 0: {
        this.selectedTab = 1
        break;
      }
    }
  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
  }

  addCard() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateCardComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Card added successfully"), "Success");
          // Refresh the keyword list
          //   this.showLoading(true)
          this._cardsService.getCards().subscribe()
        }
      });
  }


  confirmAndPay(cardId) {
    this._spinner.show();
    let data = {
      "card_id": cardId,
      "email": this.createCardForm.get('email').value,
      "billing_address": this.createCardForm.value,
    }

    this._cartService.payForOthers(data, this.paymentToken).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.showCheckoutSuccess(res)
      },
      error: (err) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Sorry",
          message: err ? err.error.message : `Something went wrong. Please try again.`,
          dismissible: false,
          actions: {
            confirm: { label: "Retry" }
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == 'confirmed')
            this.confirmAndPay(cardId)
        });
      }
    })
  }

  showCartEmptyOrReviewError(isCartEmpty) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Sorry",
      message: isCartEmpty ? `Your cart is empty. Please add products to your cart to continue.` : "Please review all item(s) before checkout",
      dismissible: false,
      actions: {
        cancel: { show: false },
        confirm: { label: "Ok" }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnDestroy()
      if (isCartEmpty)
        this._router.navigate(['/'])
      else
        this._router.navigate(['/', 'cart'])
    });
  }

  showCheckoutSuccess(res) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Thank you",
      message: res ? res.message : `Order placed successfully!`,
      dismissible: false,
      icon: { show: false },
      actions: {
        cancel: { show: false },
        confirm: { label: "Ok" }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._router.navigate(['/'])
    });
  }


  //////Card Payment setup
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '1rem',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  get validForm() {
    return this.createCardForm.valid && this.stripeCardValid;
  }

  onChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;

  createToken() {
    this.showAlert = false
    this.createCardForm.markAllAsTouched();
    this.createCardForm.markAsTouched()
    if (this.createCardForm.invalid) {
      return;
    }

    this._spinner.show()

    this.stripeService.createToken(this.card.element)
      .subscribe({
        next: response => {
          this._spinner.hide()
          if (response.error != null) {
            this.alert.type = "error"
            this.alert.message = response.error.message ?? "Something went wrong. Please try again"
            this.showAlert = true
          } else {
            console.log(response);
            this.confirmAndPay(response.token.id)
          }
        },
        error: error => {
          this._spinner.hide()
          this.alert.type = "error"
          this.alert.message = "Something went wrong. Please try again"
          this.showAlert = true
        }
      })
  }

  useShippingAsBilling(event) {
    this.createCardForm.patchValue({
      address_line_1: event?.checked ? this.cartDetails?.billing_address_json?.address_line_1 : "",
      address_line_2: event?.checked ? this.cartDetails?.billing_address_json?.address_line_2 : "",
      city: event?.checked ? this.cartDetails?.billing_address_json?.city : "",
      state: event?.checked ? this.cartDetails?.billing_address_json?.state : "",
      email: event?.checked ? this.cartDetails?.billing_address_json?.email : "",
      zipcode: event?.checked ? this.cartDetails?.billing_address_json?.zipcode : "",
      phone_number: event?.checked ? this.cartDetails?.billing_address_json?.phone_number : "",
      name: event?.checked ? this.cartDetails?.billing_address_json?.name : "",
    })
  }
}
