import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrganizationAdmin } from 'app/core/organization/organization.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { AutofillGroup } from '../autofill-manager.types';
import { AutofillManagerService } from '../autofill-manager.service';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { BehaviorSubject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule, MatDatepickerModule,
    CommonModule, MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent, MatIconModule,
    StripeCardComponent, MatRadioModule
  ]
})
export class AddContactComponent implements OnInit {

  @ViewChild('contactNgForm') contactNgForm: NgForm;
  contactForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AutofillGroup,
    private _autofillService: AutofillManagerService,
    private _toast: ToastrService, private fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<AddContactComponent>) {
  }

  isSingleUpload = true
  ngOnInit(): void {
    this.contactForm = this.fb.group({
      email: ['', this.isSingleUpload ? [Validators.required, Validators.email] : []],
      attachment: ['', this.isSingleUpload ? [] : [Validators.required]],
      method: ['single', [Validators.required]]
    });
  }

  onMetodChange(data: MatRadioChange) {
    this.isSingleUpload = data.value == 'single'
    this.contactForm.controls['email'].setValidators(this.isSingleUpload ? [Validators.required, Validators.email] : []);
    this.contactForm.controls['email'].updateValueAndValidity();
    this.contactForm.controls['attachment'].setValidators(this.isSingleUpload ? [] : [Validators.required]);
    this.contactForm.controls['attachment'].updateValueAndValidity();

  }

  attachment$ = new BehaviorSubject<File>(null);
  onSelectDocument(e) {
    this.showAlert = false;
    let files = (e.target as HTMLInputElement).files
    if (files.length == 0)
      return;
    this.processFile(files[0])
    this.attachment$.next(files[0])
    this.contactForm.patchValue({ attachment: files[0].name })

  }

  usersList = []
  lines = []; //for headings
  linesR = []; // for rows
  processFile(file: File): boolean {
    this.usersList = []
    this.lines = [];
    this.linesR = [];
    if (file.type != "text/csv") {
      this.contactForm.patchValue({ attachment: null })
      return false
    }
    //File reader method
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = [];
      allTextLines = csv.split(/\r|\n|\r/).filter(i => i !== "");
      //Table Headings
      let headers = allTextLines[0].split(',');
      let data = headers;
      let tarr = [];
      for (let j = 0; j < headers.length; j++) {
        tarr.push(data[j]);
      }
      //Pusd headings to array variable
      this.lines.push(tarr);
      // Table Rows
      let tarrR = [];
      let arrl = allTextLines.length;
      let rows = [];
      for (let i = 1; i < arrl; i++) {
        rows.push(allTextLines[i].split(','));
      }

      for (let j = 0; j < arrl; j++) {
        if (rows[j] != null)
          tarrR.push(rows[j]);
      }
      //Push rows to array variable
      this.linesR.push(tarrR);

      let emailIndex = null
      if (this.lines[0] != null)
        this.lines[0].forEach((header, index) => {
          if (header.toLowerCase() == "email")
            emailIndex = index
        })
      if (emailIndex != null)
        this.linesR.forEach(row => {
          row.forEach(element => {
            this.usersList.push({ "email": element[emailIndex] })
          });
        })
      if (this.usersList.length == 0) {
        this.contactForm.patchValue({ attachment: null })
      }
    }
    return this.usersList?.length > 0

  }


  title = "Add Contact";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      return;
    }

    this.contactForm.disable()
    let request = this._autofillService.createContact(this.data.id, !this.isSingleUpload ? this.usersList : { "email": this.contactForm.get('email').value })
    request.subscribe({
      next: (response: any) => {
        this.contactForm.enable()
        this.responseMessage = response.message ? response.message : `Group has been ${this.data == null ? 'created' : 'updated'}`
        this.close()
      },
      error: error => {
        this.contactForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

}
