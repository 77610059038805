import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AutofillManagerService } from '../autofill-manager.service';
import { ActivatedRouteSnapshot } from '@angular/router';

export const autofillContactDataResolver = (route: ActivatedRouteSnapshot) => {
    const _autofillManagerService = inject(AutofillManagerService);
    const requestArray: Observable<any>[] = []

    requestArray.push(_autofillManagerService.getContactDetails(route.params['groupId'], route.params['contactId']))
    requestArray.push(_autofillManagerService.getGroupDetails(route.params['groupId'], true))

    return forkJoin(requestArray);
};
