<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content>

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form [formGroup]="transferHistoryForm" #transferHistoryNgForm="ngForm" class="grid grid-cols-1">

                    <mat-form-field class="w-full">
                        <mat-label>User Data</mat-label>
                        <textarea [formControlName]="'user_data'" matInput></textarea>
                        <mat-error *ngIf="transferHistoryForm.get('user_data').hasError('required')">
                            User data is required
                        </mat-error>
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button class="btn_normal w-50 mt-3" [disabled]="transferHistoryForm.disabled" mat-flat-button [color]="'primary'"
        type="submit" (click)="submit()">
        <span *ngIf="!transferHistoryForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="transferHistoryForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>