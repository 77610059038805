import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatDialogModule, CommonModule]
})
export class ImageViewerComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _dialogRef: MatDialogRef<ImageViewerComponent>) { }

  onClickOutside() {
    this.close()
  }


  close() {
    this._dialogRef.close();
  }

}
