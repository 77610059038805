import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { CartDetails, CartItem, CloneDesignDetails, Promocode, ShippingMethod } from './cart.types';
import { MappingData } from 'app/modules/admin/autofill-manager/autofill-manager.types';
export type PaymentType = "monthly" | "yearly";
@Injectable({ providedIn: 'root' })
export class CartService {

    constructor(private _httpClient: HttpClient) {
    }

    private _cartDetail: BehaviorSubject<CartDetails> = new BehaviorSubject(null);
    get cartDetail$(): Observable<CartDetails> {
        return this._cartDetail.asObservable();
    }

    getCartDetails() {
        return this._httpClient.get<CartDetails>(`${BASE_URL}cart/`).pipe(
            map((cart) => {
                this._cartDetail.next(cart);
                return cart;
            })
        );
    }

    addToCart(data){
        return this._httpClient.post<CartItem>(`${BASE_URL}cart/`, data).pipe(
            map((cartItem) => {
                this._cartDetail.value.items.push(cartItem)
                this._cartDetail.next(this._cartDetail.value)
                return cartItem;
            })
        );
    }

    deleteCartItem(itemId) {
        return this._httpClient.delete(`${BASE_URL}cart/${itemId}/`).pipe(
            tap((res: any) => {
                let cart = this._cartDetail.value
                cart.items = cart.items.filter(item => item.id !== itemId)
                this._cartDetail.next(cart);
            })
        )
    }

    updateCartItem(itemId, data) {
        return this._httpClient.put(`${BASE_URL}cart/${itemId}/`, data)
    }

    getCartItemDetails(itemId) {
        return this._httpClient.get(`${BASE_URL}cart/${itemId}/`)
    }

    getPromocodes() {
        return this._httpClient.get<Promocode[]>(`${BASE_URL}product/promocode/list/`)
    }

    applyPromoCode(promoCode) {
        return this._httpClient.post(`${BASE_URL}cart/promo_code/`, { "promo_code": promoCode })
    }

    removePromoCode() {
        return this._httpClient.delete(`${BASE_URL}cart/promo_code/`)
    }

    transferHistory(data) {
        return this._httpClient.post(`${BASE_URL}cart/user_transfer/`, { user_data: data })
    }

    updateCartAddress(data) {
        return this._httpClient.put(`${BASE_URL}cart/address/`, data)
    }

    getShippingMethods() {
        return this._httpClient.get<ShippingMethod[]>(`${BASE_URL}cart/shipping/`)
    }

    updateShippingMethods(data) {
        return this._httpClient.put(`${BASE_URL}cart/shipping/`, data)
    }

    checkOut(data) {
        return this._httpClient.post(`${BASE_URL}checkout/`, data)
    }

    payForOthers(data, token) {
        return this._httpClient.put(`${BASE_URL}pay/${token}/`, data)
    }

    getPaymentLink(data) {
        return this._httpClient.post(`${BASE_URL}checkout/`, data)
    }

    // applyPromoCode(promoCode) {
    //     return this._httpClient.post(`${BASE_URL}cart/promo_code/`, { "promo_code": promoCode })
    // }

    //////////Clone
    getMappingData(): Observable<MappingData> {
        return this._httpClient.get<MappingData>(`${BASE_URL}cart/autofill/mapping_data/`)
    }

    private _cloneItemDetail: BehaviorSubject<CloneDesignDetails> = new BehaviorSubject(null);
    get cloneItemDetail$(): Observable<CloneDesignDetails> {
        return this._cloneItemDetail.asObservable();
    }

    getCloneDesignDetails(cartItemId): Observable<CloneDesignDetails> {
        return this._httpClient.get<CloneDesignDetails>(`${BASE_URL}cart/autofill/${cartItemId}/`).pipe(
            map((details) => {
                this._cloneItemDetail.next(details);
                return details;
            })
        );
    }

    postAutofilData(id, data) {
        return this._httpClient.post(`${BASE_URL}cart/autofill/${id}/`, data)
    }

    cloneProduct(id, data) {
        return this._httpClient.post(`${BASE_URL}cart/clone/${id}/`, data)
    }

    
    getPaymentLinkDetails(token) {
        return this._httpClient.get<CartDetails>(`${BASE_URL}pay/${token}/`).pipe(
            map((cart) => {
                this._cartDetail.next(cart);
                return cart;
            })
        );
    }
}
