import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { FAQ, FAQCategory, FAQVideo } from 'app/core/faq/faq.types';
import { FAQService } from 'app/core/faq/faq.service';
import { Subject, takeUntil } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTabsModule, MatExpansionModule, RouterModule, CommonModule, NgClass, NgFor, MatProgressSpinnerModule]
})
export class HelpComponent implements OnInit {
  constructor(
    private faqService: FAQService, private sanitizer: DomSanitizer,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  categories: FAQCategory[]
  faqs: FAQ[]
  videos: FAQVideo[]

  faqArray = new Map<string, FAQ[]>()

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.faqService.faqCategories$.pipe(takeUntil(this._unsubscribeAll)).subscribe((categories) => {
      this.categories = categories
      this._changeDetectorRef.markForCheck()
      this.onTabChanged({ index: 0 })
    });

  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
    if (this.categories[clickedIndex].faqs == null)
      this.getFAQs(clickedIndex)
    if (this.categories[clickedIndex].videos == null)
      this.getFAQVideos(clickedIndex)
  }

  getFAQs(clickedIndex) {
    this.faqService.getFAQs(this.categories[clickedIndex].id).subscribe({
      next: (res => {
        this._changeDetectorRef.markForCheck()
        this.categories[clickedIndex].faqs = res.data
      }),
      error: error => {
        this._changeDetectorRef.markForCheck()
        this.categories[clickedIndex].faqs = []
      }
    })
  }

  getFAQVideos(clickedIndex) {
    this.faqService.getVideos(this.categories[clickedIndex].id).subscribe({
      next: (res => {
        this._changeDetectorRef.markForCheck()
        this.categories[clickedIndex].videos = res.data
      }),
      error: error => {
        this._changeDetectorRef.markForCheck()
        this.categories[clickedIndex].videos = []
      }
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  urlMap = new Map();
  getSanitizedURL(video) {
    if (!this.urlMap.has(video.id)) {
      this.urlMap.set(video.id, this.sanitizer?.bypassSecurityTrustResourceUrl(video.video_url))
    }
    return this.urlMap.get(video.id)
  }
}
