import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AutofillContact, AutofillContactsList, AutofillGroup, AutofillGroupList, MappingData } from './autofill-manager.types';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { AutofillContactInfo } from './contact-update/autofill-contact.types';

@Injectable({ providedIn: 'root' })
export class AutofillManagerService {

    constructor(private _httpClient: HttpClient) {
    }

    private _groups: BehaviorSubject<AutofillGroup[]> = new BehaviorSubject(null);
    get groups$(): Observable<AutofillGroup[]> {
        return this._groups.asObservable();
    }

    private _group: BehaviorSubject<AutofillGroup> = new BehaviorSubject(null);
    get group$(): Observable<AutofillGroup> {
        return this._group.asObservable();
    }

    private _contacts: BehaviorSubject<AutofillContact[]> = new BehaviorSubject(null);
    get contacts$(): Observable<AutofillContact[]> {
        return this._contacts.asObservable();
    }

    private _hasMoreGroup: BehaviorSubject<Boolean> = new BehaviorSubject(null);
    get hasMoreGroup$(): Observable<Boolean> {
        return this._hasMoreGroup.asObservable();
    }

    private _hasMoreContact: BehaviorSubject<Boolean> = new BehaviorSubject(null);
    get hasMoreContact$(): Observable<Boolean> {
        return this._hasMoreContact.asObservable();
    }

    ordersPage = 1
    getGroups(nextPage: Boolean = false): Observable<AutofillGroupList> {
        if (nextPage)
            this.ordersPage++
        else
            this.ordersPage = 1
        return this._httpClient.get<AutofillGroupList>(`${BASE_URL}autofill_manager/group/?batch_size=20&page=${this.ordersPage}`).pipe(
            tap((groupList) => {
                if (nextPage) {
                    let tempOrders: AutofillGroup[] = this._groups.value
                    tempOrders = [...tempOrders, ...groupList.data]
                    this._groups.next(tempOrders);
                } else {
                    this._groups.next(groupList.data);
                }
                this._hasMoreGroup.next(groupList.current_page < groupList.total_pages)
                return groupList;
            }),
        );
    }

    getGroupDetails(groupId, needToGetAutofillData) {
        let params = new HttpParams();
        if (needToGetAutofillData)
            params = params.append('get_design_autofill_data', needToGetAutofillData ? 1 : 0);
        return this._httpClient.get<AutofillGroup>(BASE_URL + `autofill_manager/group/${groupId}/`, { params: params }).pipe(
            tap((response) => {
                this._group.next(response);
            })
        )
    }

    createGroup(data) {
        return this._httpClient.post<any>(BASE_URL + 'autofill_manager/group/', data)
    }

    updateGroup(groupId, data) {
        return this._httpClient.put<any>(BASE_URL + `autofill_manager/group/${groupId}/`, data)
    }

    deleteGroup(groupId) {
        return this._httpClient.delete<any>(BASE_URL + `autofill_manager/group/${groupId}/`).pipe(
            tap((res: any) => {
                this._groups.next(this._groups.value.filter(group => group.id !== groupId));
            })
        )
    }

    ////////Contacts
    contactsPage = 1
    getContacts(groupId: number, nextPage: Boolean = false): Observable<AutofillContactsList> {
        if (nextPage)
            this.contactsPage++
        else
            this.contactsPage = 1
        return this._httpClient.get<AutofillContactsList>(`${BASE_URL}autofill_manager/group/${groupId}/item/?batch_size=20&page=${this.contactsPage}`).pipe(
            tap((contactsList) => {
                if (nextPage) {
                    let contacts: AutofillContact[] = this._contacts.value
                    contacts = [...contacts, ...contactsList.data]
                    this._contacts.next(contacts);
                } else {
                    this._contacts.next(contactsList.data);
                }
                this._hasMoreContact.next(contactsList.current_page < contactsList.total_pages)
                if (this._group.value != null) {
                    this._group.value.contacts = contactsList.total_count
                }
                return contactsList;
            }),
        );
    }

    createContact(groupId, data) {
        return this._httpClient.post<any>(BASE_URL + `autofill_manager/group/${groupId}/item/`, data)
    }

    updateContact(groupId, contactId, data) {
        return this._httpClient.put<any>(BASE_URL + `autofill_manager/group/${groupId}/item/${contactId}/`, data).pipe(
            tap((response) => {
                this._contact.next(response);
            })
        )
    }

    deleteContact(groupId, contactId) {
        return this._httpClient.delete<any>(BASE_URL + `autofill_manager/group/${groupId}/item/${contactId}/`).pipe(
            tap((res: any) => {
                this._contacts.next(this._contacts.value.filter(contact => contact.id !== contactId)); if (this._group.value != null) {
                    this._group.value.contacts -= 1
                }
            })
        )
    }

    private _contact: BehaviorSubject<AutofillContactInfo> = new BehaviorSubject(null);
    get contact$(): Observable<AutofillContactInfo> {
        return this._contact.asObservable();
    }

    getContactDetails(groupId, contactId) {
        return this._httpClient.get<AutofillContactInfo>(BASE_URL + `autofill_manager/group/${groupId}/item/${contactId}/`).pipe(
            tap((response) => {
                this._contact.next(response);
            }))
    }

    getRefreshedContactDetails(groupId, contactId) {
        let params = new HttpParams();
        params = params.append('get_review_images', 1);
        params = params.append('create_review_images', 1);
        return this._httpClient.get<any>(BASE_URL + `autofill_manager/group/${groupId}/item/${contactId}/`, { params: params }).pipe(
            tap((response) => {
                this._contact.next(response);
            }))
    }

    /////Mapping
    getMappingData(): Observable<MappingData> {
        return this._httpClient.get<MappingData>(`${BASE_URL}autofill_manager/mapping_data/`)
    }


    proceedToCart(groupId) {
        return this._httpClient.post<any>(BASE_URL + `autofill_manager/group/${groupId}/add_to_cart/`, null)
    }
}
