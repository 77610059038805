<mat-dialog-actions class="flex justify-end pl-4 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <!-- <div class="justify-self-start text-2xl font-extrabold">{{title}}</div> -->
    <button class="w-auto justify-end items-start mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="p-0">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full h-auto p-4 pt-0 pb-4 sm:pb-4">
            <div class="w-full mx-auto ">
                <!-- Content starts -->
                <h1 class="text-black" style="font-weight:700;font-size:20px;">Write a Review</h1>
                <p class="mt-2 mb-0 text-black" style="font-size:12px;font-weight:400;">Overall Rating</p>
                <!-- Create event form -->
                <div class="flex flex-row gap-1">
                    <img alt="Rating" src="./assets/images/rating.svg"
                        style="width: 20px;height:20px;object-fit: contain;">
                    <img alt="Rating" src="./assets/images/rating.svg"
                        style="width: 20px;height:20px;object-fit: contain;">
                    <img alt="Rating" src="./assets/images/rating.svg"
                        style="width: 20px;height:20px;object-fit: contain;">
                    <img alt="Rating" src="./assets/images/rating.svg"
                        style="width: 20px;height:20px;object-fit: contain;">
                </div>
                <!-- Create event form -->

                <form class="max-w-sm mx-auto mt-6">
                    <textarea id="message" rows="4" cols="60"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:placeholder-gray-400"
                        placeholder="Your Comments"
                        style="border: solid 1px #dedeff; background-color: #fcfcfc; border-radius: 8px;"></textarea>
                </form>
                <!-- Content ends -->

            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <!-- <button class="fuse-mat-button-large w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="createChecklistForm.disabled" (click)="submitForm()" form="createChecklistForm">
        <span *ngIf="!createChecklistForm.disabled">
            SUBMIT
        </span>
        <mat-progress-spinner *ngIf="createChecklistForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button> -->
    <button mat-flat-button class=" mt-0 w-full btn_normal">Submit Review</button>
</mat-dialog-actions>