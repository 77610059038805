import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrganizationAdmin } from 'app/core/organization/organization.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ProductService } from 'app/core/product/product.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';

@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.component.html',
  styleUrls: ['./transfer-history.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule, MatDatepickerModule,
    CommonModule, MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class TransferHistoryComponent implements OnInit {

  @ViewChild('transferHistoryNgForm') transferHistoryNgForm: NgForm;
  transferHistoryForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { historyId, versionId },
    private _productService: ProductService,
    private _toast: ToastrService, private _confirmation: FuseConfirmationService,
    private fb: UntypedFormBuilder, private _matDialog: MatDialog,
    private localStorageService: LocalStorageService,
    private _dialogRef: MatDialogRef<TransferHistoryComponent>) {
  }

  ngOnInit(): void {
    this.transferHistoryForm = this.fb.group({
      user_data: [this.localStorageService.transferAccountDetails ?? '', [Validators.required]]
    });
  }

  title = "Transfer History";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.transferHistoryForm.markAllAsTouched();
    if (this.transferHistoryForm.invalid) {
      return;
    }

    this.transferHistoryForm.disable()
    let request = this._productService.transferHistory(this.data.historyId, this.transferHistoryForm.value)
    request.subscribe({
      next: (response: any) => {
        this.transferHistoryForm.enable()
        this._confirmation.open({
          icon: { show: false },
          title: "Success",
          message: response.message ? response.message : "History has been transferred",
          actions: {
            cancel: { show: false },
            confirm: {
              label: "Ok",
            }
          }
        }).afterClosed().subscribe((result) => {
          this.localStorageService.transferAccountDetails = this.transferHistoryForm?.get('user_data').value
          this._matDialog.closeAll()
        })
      },
      error: error => {
        this.transferHistoryForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }



}
