<mat-dialog-actions class="flex justify-end pl-4 mb-1 p-0" style="min-height:fit-content !important">
    <button class="flex mb-2 sm:mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
</mat-dialog-actions>

<mat-dialog-content class="p-0 sm:p-8" style="padding-top: 0px !important;">

    <div class="w-full  mx-auto ">
        <!-- Content starts -->

        <form class="w-full mt-2 gap-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
            <!-- Category -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'">
                <mat-label>Category</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.category" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="onChangeCategory();doSearch()">
                    <mat-option [value]="null">All</mat-option>
                    <ng-container *ngFor="let category of searchConfig.categories">
                        <mat-option [value]="category?.id"
                            *ngIf="category.user_templates_allowed">{{category?.title}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <!-- Sub Category -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'">
                <mat-label>Sub Category</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.sub_category" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="onChangeSubCategory();doSearch()">
                    <mat-option [value]="null">All</mat-option>
                    <ng-container *ngFor="let category of searchConfig.sub_categories">
                        <mat-option [value]="category?.id"
                            *ngIf="category.user_templates_allowed == true">{{category?.title}}</mat-option>
                    </ng-container>

                </mat-select>
            </mat-form-field>
            <!-- Size -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'">
                <mat-label>Size</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.size" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="onChangeSize();doSearch()">
                    <mat-option [value]="size?.id" *ngFor="let size of searchConfig.sizes">{{size?.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Franchise Category -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'"
                *ngIf="searchConfig?.businesses?.length > 0">
                <mat-label>Franchise Category</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.franchise_category"
                    [ngModelOptions]="{standalone: true}" (selectionChange)="onChangeFranchiseCategory();doSearch()">
                    <mat-option [value]="null">All</mat-option>
                    <mat-option [value]="category?.id"
                        *ngFor="let category of searchConfig.businesses">{{category?.franchise_category}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Franchise -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'"
                *ngIf="franchises?.length > 0">
                <mat-label>Franchise</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.franchise" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="doSearch()">
                    <mat-option [value]="null">All</mat-option>
                    <mat-option [value]="franchise?.id"
                        *ngFor="let franchise of franchises">{{franchise?.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Color -->
            <mat-form-field class="w-full" floatLabel="always" [subscriptSizing]="'dynamic'"
                *ngIf="searchConfig?.colors?.length > 0">
                <mat-label>Color</mat-label>
                <mat-select placeholder="Select" [(ngModel)]="filter.colors" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="doSearch()" multiple style="border: none !important;">
                    <mat-option [value]="color.id" *ngFor="let color of searchConfig?.colors">
                        <div class="flex flex-row items-center gap-2">
                            <div class="h-4 w-4 border border-[#f5f5f5] rounded-md"
                                [style.background-color]="color.code"></div>
                            {{color.name}}
                        </div>

                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- / -->
        </form>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-3 mb-4">
            <div *ngFor="let version of versions; let i = index">
                <app-design-view [product]="version" class="cursor-pointer"
                    (click)="getProductVersionHistory(version.id)"></app-design-view>
            </div>
        </div>
        <p *ngIf="versions?.length == 0" style="padding: 50px;text-align: center;">
            There are no products matching your query
        </p>

        <div class="flex justify-center">
            <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>

        <pwa-pagination class="flex justify-center w-full justify-self-center mt-10 mb-8"
            *ngIf="pagination?.total_pages > 1" (onPageChange)="getVersions($event)"
            [pagination]="pagination"></pwa-pagination>

        <!-- Content ends -->

    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isForSocialMedia">
    <button mat-flat-button (click)="submit()" class="mt-0 btn_normal">
        Submit
    </button>
</mat-dialog-actions>