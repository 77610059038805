<mat-dialog-actions class="flex justify-end pl-4 mb-4 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <!-- <div class="justify-self-start text-2xl font-extrabold">{{title}}</div> -->
    <button class="w-auto" (click)="close()">
        <img src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;" alt="Close icon">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="p-0">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full h-auto p-6 pt-0 ">
            <!-- Login Form -->
            <div class="mx-auto  max-w-lg  w-screen" *ngIf="otpIdentifier == null && !showForgotPassword">
                <!-- Content starts -->
                <h1 class="text-lg sm:text-2xl" style="font-weight:700;color:#292362">
                    {{showSignup ? 'Create Your Account' : 'Login to Your Account'}}
                </h1>
                <fuse-alert class="mt-8 -mb-4 max-w-lg " *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                    [type]="alert.type" [@shake]="alert.type === 'error'" [dismissible]="true">
                    {{alert.message}}
                </fuse-alert>
                <form class="max-w-lg mx-auto mb-4 mt-6" *ngIf="!showSignup" [formGroup]="loginForm"
                    #loginNgForm="ngForm">
                    <!-- Email field -->
                    <mat-form-field class="w-full">
                        <mat-label>Email address</mat-label>
                        <input id="loginEmail" matInput [formControlName]="'email'" class="text-black">
                        <mat-error *ngIf="loginForm.get('email').hasError('required')">
                            Email address is required
                        </mat-error>
                        <mat-error *ngIf="loginForm.get('email').hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full">
                        <mat-label>Password</mat-label>
                        <input id="loginPassword" matInput type="password" [formControlName]="'password'"
                            #loginPasswordField class="text-black">
                        <button mat-icon-button type="button"
                            (click)="loginPasswordField.type === 'password' ? loginPasswordField.type = 'text' : loginPasswordField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="loginPasswordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="loginPasswordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="loginForm.get('password').hasError('required')">
                            Password is required
                        </mat-error>
                    </mat-form-field>

                    <div class="flex w-full justify-end gap-4 items-center mt-1">
                        <div class="underline forgotpass_text cursor-pointer" (click)="showForgotPassword = true"
                            style="color:black">Forgot Password?</div>
                        <button class="mt-0 btn_normal" (click)="login('password')">Login</button>
                    </div>
                </form>

                <!-- Sign Up form -->
                <form class="mt-8 max-w-lg" [formGroup]="signUpForm" #signUpNgForm="ngForm" *ngIf="showSignup">

                    <!-- Name field -->
                    <mat-form-field class="w-full">
                        <mat-label>Full name</mat-label>
                        <input id="name" matInput [formControlName]="'name'" class="text-black">
                        <mat-error *ngIf="signUpForm.get('name').hasError('required')">
                            Name is required
                        </mat-error>
                    </mat-form-field>

                    <!-- Email field -->
                    <mat-form-field class="w-full">
                        <mat-label>Email address</mat-label>
                        <input id="email" matInput [formControlName]="'email'" class="text-black">
                        <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                            Email address is required
                        </mat-error>
                        <mat-error *ngIf="signUpForm.get('email').hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>

                    <!-- Password field -->
                    <mat-form-field class="w-full">
                        <mat-label>Password</mat-label>
                        <input id="password" matInput type="password" [formControlName]="'password'" #passwordField
                            class="text-black">
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error>
                            Password is required
                        </mat-error>
                    </mat-form-field>
                    <!-- Referral Code field -->
                    <mat-form-field class="w-full">
                        <mat-label>Referral Code</mat-label>
                        <input id="referral_code" matInput [formControlName]="'referral_code'" class="text-black">
                        <mat-error *ngIf="signUpForm.get('referral_code').hasError('required')">
                            Referral code is required
                        </mat-error>
                    </mat-form-field>
                    <!-- ToS and PP -->
                    <div class="inline-flex items-end w-full ">
                        <mat-checkbox style="margin-left: -10px;" [color]="'primary'" [formControlName]="'agreeTnC'">
                            I have relevant authorizations to use all the media files I’ll be uploading to this platform
                        </mat-checkbox>
                    </div>

                    <!-- Submit button -->
                    <button class="btn_normal w-full mt-3" mat-flat-button [color]="'primary'"
                        [disabled]="signUpForm.disabled" (click)="signUp()">
                        <span *ngIf="!signUpForm.disabled">
                            Create Your Free Account
                        </span>
                        <mat-progress-spinner *ngIf="signUpForm.disabled" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>

                </form>

                <div class="flex items-center mt-8 w-full">
                    <div class="flex-auto mt-px border-t"></div>
                    <div class="mx-2 text-secondary">Or {{showSignup ? 'Signup' :'Login'}} With</div>
                    <div class="flex-auto mt-px border-t"></div>
                </div>

                <div class="w-full justify-center mt-6 hidden">
                    <img style="max-width:250px;" class="cursor-pointer" (click)="signInWithApple()"
                        src="./assets/images/apple_login_button.png" alt="Apple login">
                </div>
                <div>
                    <div class="flex items-center justify-center w-full mt-6 gap-2" #googleContainer
                        id="googleContainer">
                        <!-- <div class="w-auto  sm:w-full">
                            <div class="flex gap-4 items-center cursor-pointer w-full"
                                style="border-radius:6px;border:1px solid #dbdbdb;padding:0.5rem 0.9375rem"><img
                                    src="assets/temp/google.svg" class="w-5 h-5" /><span
                                    class="hidden sm:flex">Google</span></div>
                        </div> -->
                        <!-- <div class="w-auto  sm:w-full">
                            <a class="flex gap-4 items-center cursor-pointer w-full"
                                style="border-radius:6px;border:1px solid #dbdbdb;padding:0.5rem 0.9375rem"><img
                                    src="assets/temp/linedIn.svg" class="w-5 h-5" /><span
                                    class="hidden sm:flex">Linkedin</span></a>
                        </div> -->
                        <div class="w-auto">
                            <a class="flex gap-4 items-center justify-center cursor-pointer h-[40px] w-[40px] pl-0 "
                                (click)="signInWithFB()" style="border-radius:4px;border:1px solid #dbdbdb;"><img
                                    src="assets/images/fb.svg" class="w-5 h-5" alt="Facebook" />
                            </a>
                        </div>

                        <div>
                            <asl-google-signin-button type='icon' size='large' width="50px"></asl-google-signin-button>
                            <!-- <div id="g_id_onload"
                                data-client_id="376257940850-hre70m0erul7ttulu6ajvmd1tgb8du2c.apps.googleusercontent.com"
                                data-context="signin" data-ux_mode="popup" data-callback="onGoogleLoginComplete($event)"
                                data-auto_prompt="false">
                            </div>
                            <div class="g_id_signin " data-type="icon" data-shape="square" data-theme="outline"
                                data-text="signin_with" data-size="large" id="gButtonMobile" #gButtonMobile>
                            </div> -->
                            <!-- <div class="g_id_signin w-[219px] hidden sm:flex" data-type="standard" data-shape="rectangular"
                            data-theme="outline" data-text="signin_with" data-size="large" data-logo_alignment="left"
                            id="gbutton" #gbutton>
                        </div> -->
                        </div>


                        <div class="w-auto">
                            <a class="flex gap-4 items-center overflow-hidden justify-center cursor-pointer h-[40px] w-[40px] pl-0 "
                                (click)="signInWithApple()" style="border-radius:4px;border:1px solid #dbdbdb;"><img
                                    src="assets/images/apple-login.svg" class="w-15 h-15 object-cover" alt="Apple login"/></a>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col sm:flex-row w-full justify-center gap-4 items-center mt-6"
                    *ngIf="!showSignup">
                    <a style="color:black">New here ?</a>
                    <a class="underline cursor-pointer mt-0" style="font-weight:600;font-size:14px;color:#292362"
                        (click)="showSignup = true; showAlert = false">
                        Create Your Account</a>
                </div>

                <div class="flex flex-col sm:flex-row w-full justify-center gap-4 items-center mt-6" *ngIf="showSignup">
                    <a style="color:black">Already have an account ?</a>
                    <a class="underline cursor-pointer mt-0" style="font-weight:600;font-size:14px;color:#292362"
                        (click)="showLogin()">
                        Login</a>
                </div>
                <!-- Content ends -->
            </div>
            <!-- Login form end -->

            <!-- OTP Form -->
            <div class=" mx-auto max-w-lg  w-screen" *ngIf="otpIdentifier">
                <!-- Content starts -->
                <h2 class="text-lg sm:text-2xl" style="font-weight:700;color:#292362">Enter OTP</h2>
                <fuse-alert class="mt-8 -mb-4 max-w-lg " *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                    [type]="alert.type" [@shake]="alert.type === 'error'" [dismissible]="true">
                    {{alert.message}}
                </fuse-alert>
                <form class="mt-8" [formGroup]="otpForm" #otpNgForm="ngForm">

                    <!-- OTP field -->
                    <mat-form-field class="w-full">
                        <mat-label>OTP</mat-label>
                        <input id="otp" matInput [formControlName]="'otp'" type="text" class="text-black">
                        <mat-error *ngIf="otpForm.get('otp').hasError('required')">
                            OTP is required
                        </mat-error>
                        <mat-error *ngIf="otpForm.get('otp').hasError('min')">
                            Please enter a valid OTP
                        </mat-error>
                    </mat-form-field>
                </form>

                <div class="flex w-full justify-end gap-4 items-center mt-4">
                    <div class="underline forgotpass_text cursor-pointer" (click)="resendOTP()" style="color:black">
                        Didn't got OTP? Resend </div>
                    <button class=" mt-0 btn_normal" [disabled]="otpForm.disabled" (click)="submitOTP()">
                        <span *ngIf="!otpForm.disabled">
                            Submit
                        </span>
                        <mat-progress-spinner *ngIf="otpForm.disabled" [diameter]="24" [mode]="'indeterminate'">
                        </mat-progress-spinner></button>
                </div>


                <!-- Actions -->
                <div class="inline-flex items-end justify-center w-full mt-1.5">
                    <a class="text-md font-medium text-primary-500 hover:underline" (click)="showLogin()">
                        Return to log in
                    </a>
                </div>
            </div>
            <!-- OTP Form end -->


            <!-- Forgot password Form -->
            <div class=" mx-auto max-w-lg w-screen" *ngIf="showForgotPassword">
                <!-- Content starts -->
                <h2 class="text-lg sm:text-2xl" style="font-weight:700;color:#292362">Recover Your Account</h2>
                <fuse-alert class="mt-8 -mb-4 max-w-lg " *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                    [type]="alert.type" [@shake]="alert.type === 'error'" [dismissible]="true">
                    {{alert.message}}
                </fuse-alert>
                <form class="mt-8" [formGroup]="forgotPasswordForm" #forgotPasswordNgForm="ngForm">

                    <!-- Email field -->
                    <mat-form-field class="w-full">
                        <mat-label>Email Id</mat-label>
                        <input id="resetEmail" matInput [formControlName]="'email'" type="email" class="text-black">
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                            Email id is required
                        </mat-error>
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                            Please enter a valid email id
                        </mat-error>
                    </mat-form-field>

                    <div class="flex w-full justify-end gap-4 items-center mt-4">
                        <div class="underline forgotpass_text cursor-pointer" (click)="showLogin()" style="color:black">
                            Return to log in</div>
                        <button class=" mt-0 btn_normal" [disabled]="forgotPasswordForm.disabled"
                            (click)="submitForgotPasswordMail()">
                            <span *ngIf="!forgotPasswordForm.disabled">
                                Submit
                            </span>
                            <mat-progress-spinner *ngIf="forgotPasswordForm.disabled" [diameter]="24"
                                [mode]="'indeterminate'">
                            </mat-progress-spinner></button>
                    </div>


                </form>

            </div>
            <!-- Forgot password  end -->
        </div>

    </div>

</mat-dialog-content>