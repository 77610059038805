import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { MatDialogConfig, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SMMAccount, SMMAccountsResponse, SMMConnectionStatus, SMMPost, SMMPostsResponse } from 'app/core/social-media/social-media.types';

import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { Pagination } from 'app/core/api/pagination.type';
import { NewPostPopup } from '../new-post/new-post.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { LINKEDIN_REDIRECT_URL } from 'environments/environment';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-social-media-management',
  templateUrl: './social-media-management.component.html',
  styleUrls: ['./social-media-management.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, MatMenuModule, PWAPagination, MatExpansionModule, MatTabsModule, MatIconModule, NgFor, MatButtonModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, FuseCardComponent, NewPostPopup, MatDialogModule]
})

export class SocialMediaManagementComponent implements OnInit {
  sortbyby = 0
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  // @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private broadcast: BroadcastService,
    private _fuseConfirmationService: FuseConfirmationService, private authServiceOne: SocialAuthService,
    private _spinner: NgxSpinnerService, private _smmService: SocialMediaService, private route: ActivatedRoute,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef, private _router: Router,) {
    this.accounts.set('facebook', null)
    this.accounts.set('twitter', null)
    this.accounts.set('linkedin', null)
    this.accounts.set('instagram', null)
    this.route.queryParams.subscribe(params => {
      let twitterAuthToken = params['oauth_verifier'];
      if (twitterAuthToken != null) {
        this.selectedTab = 'twitter'
        this.connectSMM(twitterAuthToken)
      }

      let linkedinAuthToken = params['code'];
      if (linkedinAuthToken != null) {
        this.selectedTab = 'linkedin'
        if (sessionStorage.getItem('isLinkedinAuthForPages') == '1')
          this.syncLinkedinPages(linkedinAuthToken)
        else
          this.connectSMM(linkedinAuthToken)
      }
    });
  }

  smTabs = ['facebook', 'twitter', 'linkedin', 'instagram']
  selectedTab = 'facebook'
  onChangeTab($event) {
    if (this.selectedTab == this.getSelectedTab($event.index)) {
      return
    }
    this.selectedTab = this.getSelectedTab($event.index)
    this.getAccounts(this.selectedTab)
  }

  accounts = new Map<string, SMMAccountsResponse>();

  ngOnInit(): void {
    this._smmService.facebookAccounts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((posts: SMMAccountsResponse) => {
        this.accounts.set('facebook', posts)
        this._changeDetectorRef.markForCheck()
      })
    this._smmService.twitterAccounts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((posts: SMMAccountsResponse) => {
        this.accounts.set('twitter', posts)
        this._changeDetectorRef.markForCheck()
      })
    this._smmService.instagramAccounts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((posts: SMMAccountsResponse) => {
        this.accounts.set('instagram', posts)
        this._changeDetectorRef.markForCheck()
      })
    this._smmService.linkedinAccounts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((posts: SMMAccountsResponse) => {
        this.accounts.set('linkedin', posts)
        this._changeDetectorRef.markForCheck()
      })
    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      this._smmService.getSMMConnectionStatus(true).subscribe()
      this._smmService.getAccounts('facebook').subscribe()
      this._smmService.getAccounts('twitter').subscribe()
      this._smmService.getAccounts('instagram').subscribe()
      this._smmService.getAccounts('linkedin').subscribe()
    })
    this.initSMMLogin()
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getIcon(accountType) {
    switch (accountType) {
      case 'facebook': {
        return './assets/images/fb_icon.png'
      }
      case 'twitter': {
        return './assets/images/twitter_icon.png'
      }
      case 'linkedin': {
        return './assets/images/linkedin_icon.png'
      }
      case 'instagram': {
        return './assets/images/instagram_icon.png'
      }
    }
  }

  openNewPostPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { checklistId: 0 };
    dialogConfig.panelClass = ['w-full', 'max-w-md']
    this._matDialog.open(NewPostPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 0) {
          this._toast.success(value)
        }
      });
  }

  asIsOrder(a, b) {
    return 1;
  }

  getAccounts(type) {
    this._smmService.getAccounts(type).subscribe()
  }

  deleteAccount(type, account: SMMAccount) {

    const dialogRef = this._fuseConfirmationService.open({
      title: "Delete Account",
      message: `Are you sure you want to delete the account?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteAccount(type, account)
    });
  }

  proceedDeleteAccount(type, account: SMMAccount) {
    this._spinner.show();
    this._smmService.removeAccount(type, account.id ?? account.twitter_id ?? account?.linkedin_id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.getAccounts(type)
        this._toast.success(res.message ? res.message : 'Account has been removed successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  connectSocialMedia() {
    switch (this.selectedTab) {
      // Facebook
      case 'facebook': {
        this.authServiceOne.initState.subscribe(
          {
            next: res => {
              this.authServiceOne.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
            },
            error: error => {
              this._toast.error("Something went wrong. Please try again.", "Sorry")
            }
          }
        );
        break;
      }

      // Twitter
      case 'twitter': {
        this.getTwitterSyncToken()
        break;
      }

      // Linkedin
      case 'linkedin': {
        var loc = window.location.toString();
        sessionStorage.setItem('isLinkedinAuthForPages', '0')
        var path = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81pcdvyec811fs&redirect_uri=" + loc + "&scope=rw_organization_admin%20w_member_social%20r_basicprofile%20r_liteprofile%20r_emailaddress%20w_organization_social%20w_member_social";

        window.location.href = path;
        break;
      }

      // instagram
      case 'instagram': {
        this.authServiceOne.initState.subscribe(
          {
            next: res => {
              this.authServiceOne.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
            },
            error: error => {
              this._toast.error("Something went wrong. Please try again.", "Sorry")
            }
          }
        );
        break;
      }
    }
  }

  fbLoginOptions = {
    scope: 'email,public_profile,pages_show_list,pages_manage_posts,instagram_basic,instagram_content_publish,instagram_manage_insights'
  };

  private getTwitterSyncToken() {
    this._spinner.show()
    this._smmService.getSMMConnectionStatus(true).subscribe({
      next: (data: SMMConnectionStatus) => {
        this._spinner.hide()
        window.open(data.twitter_auth_url)
      },
      error: (err) => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : 'Something went wrong. Please try again.', "Sorry")
      }
    })
  }

  getLinkedinPageSyncUrl() {
    this._spinner.show()
    var loc = window.location.toString();
    this._smmService.getLinkedinPageSyncUrl(LINKEDIN_REDIRECT_URL).subscribe({
      next: (res) => {
        this._spinner.hide()
        sessionStorage.setItem('isLinkedinAuthForPages', '1')
        window.location.href = res.sync_url;
      },
      error: (err) => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : 'Something went wrong. Please try again.', "Sorry")
      }
    })
  }

  syncLinkedinPages(token) {
    this._router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { oauth_token: null, oauth_verifier: null, code: null },
        queryParamsHandling: 'merge'
      });
    this._spinner.show()
    var loc = window.location.toString();
    sessionStorage.setItem('isLinkedinAuthForPages', '0')
    this._smmService.syncLinkedinPages(LINKEDIN_REDIRECT_URL, token).subscribe(
      {
        next: (res) => {
          this._spinner.hide();
          this.getAccounts(this.selectedTab)
          this._fuseConfirmationService.open({
            title: "Success",
            message: res.message ? res.message : 'Linkedin pages synced successfully',
            actions: {
              confirm: { label: "Ok" },
              cancel: { show: false }
            },
            icon: { show: false }
          })
        },
        error: (err) => {
          this._spinner.hide()
          this._toast.error(err ? err.error.message : 'Something went wrong. Please try again.', "Sorry")
        }
      })
  }
  //Facebook Login 
  subscription: Subscription
  initSMMLogin() {
    this.subscription = this.authServiceOne.authState.subscribe((user: any) => {
      if (user) {
        this.connectSMM(user.authToken)
      }
    });
  }


  getSelectedTab(position) {
    switch (position) {
      case 0: return 'facebook'
      case 1: return 'twitter'
      case 2: return 'linkedin'
      case 3: return 'instagram'
    }
  }

  connectSMM(token) {
    this._spinner.show()
    this._smmService.connectSMM(this.selectedTab == 'instagram' ? 'facebook' : this.selectedTab, token).subscribe({
      next: (data: any) => {
        this._spinner.hide()
        this._router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { oauth_token: null, oauth_verifier: null, code: null },
            queryParamsHandling: 'merge'
          });
        this._fuseConfirmationService.open({
          title: "Success",
          message: data.message ? data.message : 'Social media pages synced successfully',
          actions: {
            confirm: { label: "Ok" },
            cancel: { show: false }
          },
          icon: { show: false }
        })

      },
      error: (err) => {
        this._router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { oauth_token: null, oauth_verifier: null, code: null },
            queryParamsHandling: 'merge'
          });
        this._spinner.hide()
        this._toast.error(err ? err.error.message : 'Something went wrong. Please try again.', "Sorry")
      }
    })
  }
}