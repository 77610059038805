import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Product, VersionHistory, Tag, TagList } from './product.types';
import { SearchService } from 'app/modules/admin/search/search.service';

@Injectable({ providedIn: 'root' })
export class ProductService {


    constructor(private _httpClient: HttpClient, private _searchService: SearchService) {
    }

    private _product: BehaviorSubject<Product> = new BehaviorSubject(null);
    get productDetail$(): Observable<Product> {
        return this._product.asObservable();
    }

    getProductDetails(productId): Observable<Product> {
        return this._httpClient.get<Product>(`${BASE_URL}products/${productId}/`).pipe(
            tap((result) => {
                this._product.next(result);
                return result;
            }),
        );
    }

    getProductVersions(productId, queryParams): Observable<Product> {

        return this._httpClient.get<Product>(`${BASE_URL}products/${productId}/versions/`, { params: queryParams })
    }

    //For employee access
    getProductVersionDetails(versionId, saveData: boolean = true): Observable<Product> {
        return this._httpClient.get<Product>(`${BASE_URL}product/details/${versionId}/?default=0`).pipe(
            tap((result) => {
                if (saveData)
                    {
                        this._searchService.clearData()
                        this._product.next(result);
                    }
                return result;
            }),
        );
    }

    getAutofillMapping(id) {
        return this._httpClient.get(`${BASE_URL}product/${id}/autofill/`)
    }

    submitLockFieldsAutofillData(id, data) {
        return this._httpClient.put(`${BASE_URL}product/${id}/autofill/`, data)
    }

    cloneHistoryToCustomProduct(productID, request) {
        return this._httpClient.post(
            `${BASE_URL}product/${productID}/convert-custom-product/`, request)
    }

    getPriceCalculation(data) {
        return this._httpClient.post<Product>(`${BASE_URL}products/price/`, data)
    }

    getPricingTypes(category, subCategory) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("category", category);
        queryParams = queryParams.append("sub_category", subCategory);
        queryParams = queryParams.append("batch_size", 100);
        return this._httpClient.get<Product>(`${BASE_URL}live_pricing/products/`, { params: queryParams })
    }

    getPricingConfigurations(configurationType) {
        return this._httpClient.get<Product>(`${BASE_URL}live_pricing/products/${configurationType}/options/`, {})
    }

    getPrice(configurationType, options) {
        return this._httpClient.post<Product>(`${BASE_URL}live_pricing/products/${configurationType}/price/`, {
            "option_ids": options
        })
    }

    getProductHistory(productId) {
        return this._httpClient.get<VersionHistory[]>(`${BASE_URL}product/history/${productId}/`)
    }

    createVersionHistory(productId, autofillData) {
        let data = {
            autofill_user_details: autofillData,
        };
        return this._httpClient.post(`${BASE_URL}product/history/` + productId + '/', data)
    }

    deleteHistory(versionId) {
        return this._httpClient.delete(`${BASE_URL}product/history/` + versionId + '/')
    }

    deleteProduct(designId) {
        return this._httpClient.delete(`${BASE_URL}product/details/` + designId + '/')
    }

    deleteDesign(designId) {
        return this._httpClient.delete(`${BASE_URL}product/design/` + designId + '/')
    }

    createShareLink(id) {
        return this._httpClient.get(`${BASE_URL}product/history/` + id + '/social_share/');
    }

    getDesignUpdateStatus(historyId, date): any {
        return this._httpClient.get(
            `${BASE_URL}product/history/${historyId}/check-for-updates/?date=${date}`
        );
    }

    transferHistory(historyId, data) {
        return this._httpClient.post(`${BASE_URL}product/history/${historyId}/transfer/`, data)
    }


    private _userTags: BehaviorSubject<Tag[]> = new BehaviorSubject(null);
    get userTags$(): Observable<Tag[]> {
        return this._userTags.asObservable();
    }

    getUserTags(search) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("search", search);
        queryParams = queryParams.append("batch_size", 100);
        return this._httpClient.get<TagList>(`${BASE_URL}product/version-user-tag/`, { params: queryParams }).pipe(
            tap((result) => {
                this._userTags.next(result.data);
                return result;
            }),
        );;
    }

    createUserTag(data) {
        return this._httpClient.post(`${BASE_URL}product/version-user-tag/`, data);
    }

    updateUserTag(data, id) {
        return this._httpClient.put(`${BASE_URL}product/version-user-tag/${id}/`, data);
    }

    deleteUserTag(id) {
        return this._httpClient.delete(`${BASE_URL}product/version-user-tag/${id}/`);
    }

    //Custom product
    createCustomProduct(data) {
        return this._httpClient.post(`${BASE_URL}product/custom/template/`, data);
    }

    createCustomProductDesign(data) {
        return this._httpClient.post(`${BASE_URL}product/design/`, data);
    }

    

    clearData() {
        this._userTags.next(null);
        this._product.next(null);
    }

}
