import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SocialMediaService } from 'app/core/social-media/social-media.service';

export const smmConnectionDataResolver = (route: ActivatedRouteSnapshot) => {
    const _smmService = inject(SocialMediaService);
    const requestArray: Observable<any>[] = [
        _smmService.getSMMConnectionStatus(true),
        _smmService.getAccounts('facebook'),
        _smmService.getAccounts('twitter'),
        _smmService.getAccounts('instagram'),
        _smmService.getAccounts('linkedin'),
    ]
    return forkJoin(requestArray);
};