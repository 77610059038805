import { inject } from '@angular/core';
import { Observable, forkJoin, tap } from 'rxjs';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { SearchService } from '../search/search.service';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';

export const productVersionDetailDataResolver = (route: ActivatedRouteSnapshot) => {
    const _productService = inject(ProductService);
    const _searchService = inject(SearchService);
    const _broadcastService = inject(BroadcastService);
    
    if (route.url[0].path != "product-version") {
        _broadcastService.broadcast('product_details_resolver_started_running', {})
    }

    _productService.clearData()
    const requestArray: Observable<any>[] = [_productService.getProductVersionDetails(route.params['versionId']).pipe(
        tap((result) => {
            _searchService.clearData()
            return result;
        }),
    )]
    return forkJoin(requestArray);
};