<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
      <!-- Bread crumb -->
      <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">Terms and Conditions</a>
    </div>
    <!-- / -->

    <div class="container">

        <br>
        <P ALIGN=JUSTIFY>
            These Terms and Conditions
            (“Agreement”) are agreed to between Printing Warehouses of America Inc. (“Company”)
            and you, or, if you represent an entity or other organization, that
            entity or organization (in either case “You”).
        </P>
        <P ALIGN=JUSTIFY>
            Company
            offers an online platform known as printingwarehouses.com (the
            “Platform”) for the purpose of allowing its customers the ability
            to design and print various marketing materials, along with offering
            various digital marketing products (“Customer Products”). The
            Platform provides You with the ability to access and use graphics,
            photographs, images, document layouts, artwork, text, fonts, software
            tools, and other information (“Content”) on a variety of topics,
            as well as additional services, functionality, and resources made
            available through the Platform (“Services”), all of which aid You
            in the creation of Customer Products.
        </P>
        <P ALIGN=JUSTIFY>
            This
            Platform and all Content are the copyrighted property of Company or
            the copyrighted property of parties from whom Company has licensed
            such property. All rights in the Platform and its Content are
            reserved worldwide. It is strictly prohibited to retain, copy,
            distribute, publish, or use any portion of the Content except as
            expressly allowed in these Terms and Conditions. Company reserves the
            right to add to, delete from, or modify any part of Content at any
            time without prior notice. Any modifications to Content, whether by
            You or Company remain the property of Company and its licensors. The
            design tools provided on the Platform employ a limited number of
            elements, including icons, fonts, color schemes, and design effects.
            Company reserves the right to use all such elements and to make all
            such elements available for use by other parties in the future. You
            do not obtain any right or claim to any of the individual design
            elements through your creation of Customer Products or your
            incorporation of a design into one or more <BR>Customer Products.
            Other Company customers may use the tools to create products that
            have similar or identical combinations of these elements, and Company
            does not guarantee that Your design will not have similarities to
            designs by other parties. Company provides no warranty of any kind
            that designs created using the tools will not infringe, or be subject
            to a claim of infringing, the trademark or other rights of another
            party. It is solely Your responsibility to obtain the advice of an
            attorney regarding whether the logo design is legally available for
            your use and does not infringe the rights of another party.
        </P>
        <P ALIGN=JUSTIFY>
            You
            acknowledge and agree that the Platform and all Services and Content
            are available “as is” and empower You to select which Content You
            deem appropriate for Your needs. Company makes no representations or
            warranties that the Content You select is intended for the purposes
            for which You select it, nor does Company validate any of Your
            designs within the Platform prior to printing Customer Products. You
            bear the sole responsibility for proofreading all materials related
            to Customer Products.
        </P>
        <P ALIGN=JUSTIFY>
            Regardless
            of Your use of the Platform, You maintain sole responsibility for any
            Customer Products or other material you may design, create, or submit
            to the Platform.
        </P>
        <P ALIGN=JUSTIFY>
            YOU
            AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR DETERMINING THE
            APPROPRIATENESS AND SUITABILITY OF THE PLATFORM FOR YOU AND FOR USING
            THE PLATFORM BASED ON YOUR PARTICULAR CIRCUMSTANCES.
        </P>
        <P ALIGN=JUSTIFY>
            This
            Agreement is entered into as of the earliest date You first access or
            use the Platform (the “Effective Date”). This Agreement includes
            all terms and conditions below. This Agreement applies to the
            Platform, including all Content and Services available through the
            Platform. This Agreement is the complete and exclusive agreement
            between You and Company regarding Your access to and use of the
            Platform or any Content or Services.
        </P>
        <P ALIGN=JUSTIFY>
            This
            Agreement supersedes any prior agreement or proposal, oral or
            written, and any other communications between You and Company
            relating to Your use of the Platform or any Content or Services.
        </P>
        <P ALIGN=JUSTIFY>
            PLEASE
            CAREFULLY READ THIS AGREEMENT. BY ACCESSING OR USING THE PLATFORM,
            INCLUDING ANY CONTENT OR SERVICES, OR BY CLICKING A BOX THAT STATES
            THAT YOU ACCEPT OR AGREE TO THESE TERMS, YOU AGREE THAT YOU HAVE READ
            AND AGREE TO BE BOUND BY THIS AGREEMENT.
        </P>
        <OL>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Definitions</B>
                    .
                    Terms used in this Agreement have the definitions given in this
                    Agreement or, if not defined in this Agreement, have their plain
                    English meaning as commonly interpreted in the United States.
                </P>
        </OL>
        <OL START=2>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Term.</B>
                    This Agreement will be effective upon the Effective Date and
                    continue until such time as the Company or You terminate your use of
                    the Platform.
                </P>
        </OL>
        <OL START=3>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Modifications</B>.
                    Company reserves the right, at any time, to modify the Platform and
                    any portion of the Content or Services, with or without notice to
                    You, by making those modifications available on the Platform.
                    Company also reserves the right, at any time, to modify the terms of
                    this Agreement. Company will inform You of the presence of any
                    changes to this Agreement by posting those changes on the Platform
                    or by providing You with notice through the Platform. Any
                    modifications will be effective 30 days following posting on the
                    Platform or delivery of such notice through the Platform, except in
                    the case of a legally required modification, which will be effective
                    upon posting. You may terminate this Agreement as set forth below if
                    you object to any such modifications; however, You will be deemed to
                    have agreed to any and all modifications through Your continued use
                    of the Platform, Content, or Services following such notice period.
                </P>
        </OL>
        <OL START=4>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Eligibility</B>
                    .
                    The Platform and all Content and Services are intended for use by
                    individuals 18 years of age and older. If You are not 18 years of
                    age or older, You agree not to access or use the Platform or any
                    Content or Services. You agree not to allow any individual under 18
                    years of age to access or use the Platform, regardless of whether
                    You are the parent or guardian of that individual
                </P>
        </OL>
        <OL START=5>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Accounts.</B>
                </P>
                <OL>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Accounts.</B>

                            Subject to the terms of this Agreement, You may be permitted to
                            access certain Content and Services through the Platform without
                            establishing an account on the Platform; however, access to the
                            Platform generally requires that You register as a user of the
                            Platform (a “User”) and establish a user account on the
                            Platform (an “Account”). Approval of Your request to establish
                            and maintain any Account will be at the sole discretion of Company.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Account
                                IDs</B>
                            . You will be
                            responsible for establishing and maintaining the user
                            identification and password for Your Account (the “Account ID”).
                            Each Account is for Your personal use and all Account IDs are
                            personal in nature. Each Account ID may be used only by You alone.
                            You may not share or transfer Your Account or Account ID or provide
                            a third party with the right to access your Account or Account ID.
                            You are solely responsible for all use of the Platform and all
                            Content and Services through Your Account. You will ensure the
                            security and confidentiality of Your Account ID and will notify
                            Company immediately if Your Account ID is lost, stolen or otherwise
                            compromised. You are fully responsible for all liabilities and
                            damages incurred through the use of Your Account or under Your
                            Account ID (whether lawful or unlawful) and any actions taken
                            through Your Account or under Your Account ID will be deemed to
                            have been lawfully completed by You.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Account
                                Information.</B>
                            In connection
                            with establishing an Account, You will be asked to submit certain
                            information about Yourself. You are solely responsible for the
                            Account Information for Your Account. You agree that: (a) all
                            Account Information You provide will be accurate, complete and
                            current; and (b) You will maintain and promptly update all Account
                            Information as necessary to keep it accurate, complete, and
                            current. You may not: (i) select or use an Account ID of another
                            person with the intent to impersonate that person; and (ii) use an
                            Account ID that Company, in its sole discretion, deems offensive.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Account
                                Confirmation</B>
                            . You are
                            solely responsible for confirming the set-up and configuration of
                            Your in all respects and for making all changes and updates thereto
                            through this Agreement.
                        </P>
                </OL>
        </OL>
        <OL START=6>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Access.</B>
                </P>
                <OL>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To
                                the Platform.</B>
                            You may
                            access the Platform by using the online application (or mobile
                            application, at such time as it may exist) provided by and on
                            behalf of Company (each, an “Application,” and forming a part
                            of the “Platform” for purposes of this Agreement). Subject to
                            Your compliance with this Agreement, Company will permit You to
                            access and use the Platform solely for lawful purposes and only in
                            accordance with the terms of this Agreement. You are solely
                            responsible for obtaining and maintaining all equipment,
                            facilities, and connectivity required to access or use the Platform
                            or Application, in each case as necessary to meet Your requirements
                            based on Your particular circumstances.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To
                                Applications.</B>
                            Subject to
                            Your compliance with this Agreement and any other terms and
                            conditions accompanying each Application, Company will permit You
                            to access, or download and install (if applicable) Applications and
                            operate those Applications solely for the purpose of using and
                            accessing the Platform. You may use or install each Application
                            only on computers, smart phones, tablets, or other applicable
                            devices owned or controlled by You and used only for Your own
                            personal and non-commercial purposes in accordance with this
                            Agreement and any applicable terms and conditions accompanying the
                            Application or otherwise provided to You by Company. Except as
                            expressly set forth in the previous sentence, You are granted no
                            licenses or other rights in or to any Application. You agree not to
                            use, modify, reproduce, perform, display, create derivative works
                            from, republish, post, transmit, participate in the transfer or
                            sale of, distribute, or in any way exploit or utilize any
                            Application other than as expressly permitted in this Agreement or
                            any other agreement You are required to agree to before being given
                            access to any Application.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To Other Users.</B>
                            The Platform
                            may allow you to link, connect, or otherwise communicate with other
                            users of the Platform. By linking, connecting, or communicating
                            with other Users, You are agreeing to allow those Users to
                            communicate directly with You through the Platform. You agree that
                            You are solely responsible for all communications between You and
                            any other User through the Platform. Your extension or acceptance
                            of a link, connection, or other communication with another User
                            will serve as Your affirmative “opt in” to the disclosure of
                            any of Your Content (as defined below) or other data or information
                            (which may include Your personally identifiable information) that
                            You provide to that other User. You agree that Your links,
                            connections or other communications with other Users through the
                            Platform will not: (a) violate this Agreement, including, without
                            limitation, the Privacy Policy; (b) violate any applicable
                            international, federal, state, and local treaties, laws, rules,
                            regulations, and ordinances (“Laws”), including, without
                            limitation, any rules of professional conduct or of licensing
                            bodies; (c) be libelous, defamatory, obscene, abusive,
                            pornographic, threatening, or an invasion of privacy; (d)
                            constitute an infringement, misappropriation or violation of the
                            IPR (as defined below) or other rights of any third party; (e) be
                            illegal in any way or advocate illegal activity; (f) be false,
                            misleading or inaccurate; (g) be considered junk mail, spam, a part
                            of a pyramid scheme, a disruptive commercial message or disruptive
                            advertisement; or (h) cause the publication or release of any
                            material non-public data or information.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To Content.</B>
                            You will be
                            provided with access to a variety of Content through the Platform
                            posted by Company, including, text, audio, video, photographs,
                            maps, illustrations, graphics, and/or other data, information, and
                            media. Unless otherwise noted on the Platform, all Content
                            available through the Platform (“Platform Content”) is owned by
                            Company and Company’s other third-party providers. All Platform
                            Content is provided for informational purposes only, and You are
                            solely responsible for verifying the accuracy, completeness, and
                            applicability of all Platform Content and for Your use of any
                            Platform Content. Subject to Your compliance with this Agreement,
                            You may access the Platform Content solely for Your own personal
                            purposes in connection with Your use of the Platform. Obtaining
                            Customer Products from Company does not entitle You to use any
                            portion of Platform Content apart from the finished Customer
                            Products as they are supplied by Company. You will not, and will
                            not permit any third party to: (a) alter, modify, reproduce, or
                            create derivative works of any Platform Content; (b) distribute,
                            sell, resell, lend, loan, lease, license, sublicense, or transfer
                            any Platform Content; or (c) alter, obscure, or remove any
                            copyright, trademark, or any other notices that are provided on or
                            in connection with any Platform Content. Company has not verified
                            the accuracy of, and will not be responsible for any errors or
                            omissions in, any Platform Content. Without limiting the foregoing,
                            Company will not be held liable to You or any other third party for
                            any Content, including Your Content (as defined below), under a
                            Federal Law called the Communications Decency Act or CDA, 47 U.S.C.
                            § 230. Except as set forth in this Agreement, You are granted no
                            licenses or other rights in or to any Platform Content, or any IPR
                            (as defined below) therein or related thereto. If You would like to
                            use any Platform Content in a manner not permitted by this
                            Agreement, please contact Company.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To Third-Party Services.</B>
                            The
                            Platform may provide You with the choice to access certain Services
                            developed, provided, or maintained by other third party service
                            providers (each, a “Third Party Service”). In addition to the
                            terms of this Agreement, Your access to and use of any Third Party
                            Service is also subject to any other agreement You may agree to
                            before being given access to the Third Party Service (each, a
                            “Third Party Service Agreement”). The terms of any Third Party
                            Service Agreement (which may include payment of additional fees)
                            will apply to the applicable Third Party Services provided under
                            that Third Party Service Agreement in addition to the terms of this
                            Agreement but will not apply to any other Services You may access
                            through the Platform. COMPANY TAKES NO RESPONSIBILITY FOR ANY THIRD
                            PARTY SERVICE AND YOUR ACCESS TO AND USE OF ANY THIRD PARTY SERVICE
                            IS SOLELY AS SPECIFIED IN EACH APPLICABLE THIRD PARTY AGREEMENT.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To Third Party Information</B>
                            .
                            The Platform may provide You with certain access to data and other
                            information about Users of the Platform and other third parties
                            (“User Information”). The User Information may contain
                            sensitive personal information regarding those Users and other
                            third parties. You agree that You will only collect, use and
                            disclose User Information in strict accordance with this Agreement
                            and the current Privacy Policy relating to the Platform available
                            at <a [routerLink]="['/privacy-policy']">www.printingwarehouses.com/privacy-policy</a>l̥
                            (“Privacy Policy”). Except as expressly provided in this
                            Agreement and the Privacy Policy, You are granted no licenses or
                            rights in or to any User Information or any IPR therein or related
                            thereto.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To
                                Mobile Services.</B>
                            The
                            Services offered through the Platform may include certain messaging
                            and communications services available through Your mobile telephone
                            and other mobile device (“Mobile Services”). By accessing or
                            using any Mobile Services, You consent to receiving messages
                            including Content through any applicable mobile device You specify
                            through the Platform (“Mobile Device”) and to providing Content
                            from Your Mobile Device to other Users through the Platform. As to
                            any Mobile Device You use in connection with any Mobile Services,
                            You acknowledge that You are the authorized account holder for that
                            Mobile Device. All Mobile Services and the messages and Content
                            received, provided, transmitted, exchanged, or otherwise made
                            available through the Mobile Services are subject to each
                            applicable Third Party Service Agreement with the third party
                            service providers used by Company to deliver those Services.
                        </P>
                </OL>
        </OL>
        <BR>
        <OL START=7>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Your
                        Content.</B>
                    You are solely
                    responsible for all Content that You may provide or upload to the
                    Platform or otherwise generate through Your use of or access to the
                    Platform (“Your Content”). As between You and Company, You
                    retain ownership of Your Content. Subject to any limitations
                    included in the Privacy Policy, You grant Company a nonexclusive,
                    royalty-free, perpetual, irrevocable, and fully sublicensable
                    (including, without limitation, to Users and other third parties)
                    right to use, copy, store, reproduce, modify, display, adapt,
                    publish, translate, create derivative works from, distribute, and
                    display (“Use”) Your Content for purposes of providing the
                    Services to You and for use in making enhancements and improvements
                    to the Platform. You represent and warrant that none of Your Content
                    or the use of Your Content by Company: (1) violates this Agreement,
                    the Privacy Policy, or any requirements under applicable Laws; (2)
                    is libelous, defamatory, obscene, abusive, pornographic,
                    threatening, or an invasion of privacy; (3) constitutes an
                    infringement, misappropriation or violation of the IPR or other
                    rights of any third party; (4) is illegal in any way or advocates
                    illegal activity; (5) is an advertisement or solicitation of funds,
                    goods, or services (unless You have entered into a separate
                    agreement with Company); (6) is false, misleading or inaccurate; or
                    (7) is or could be considered junk mail, spam, a part of a pyramid
                    scheme, a disruptive commercial message or disruptive advertisement.
                    Company is not responsible or liable for any deletion, correction,
                    destruction, damage, loss or failure to store, restore or back-up
                    any of Your Content. You agree that You have all right, title,
                    interest and consent in Your Content necessary to allow Company to
                    Use Your Content as set forth in the rights and licenses You grant
                    to Company under this Agreement.
                </P>
        </OL>
        <OL START=8>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Termination and Effect.</B>
                </P>
                <OL>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Termination.</B>

                            This Agreement may be terminated by Company at any time, in
                            Company’s sole discretion: (a) upon any breach or threatened
                            breach by You of this Agreement; or (b) for any reason or no
                            reason, upon notice to You. You may terminate this Agreement at any
                            time upon notice to Company or by deleting Your Account as may be
                            permitted through the Platform.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Effect.</B>

                            Termination of this Agreement will terminate all of Your Accounts
                            on the Platform. Upon termination or expiration of this Agreement
                            by Company except for your uncured breach of this Agreement, each
                            of Your Accounts may be converted into a “read-only” format for
                            a period of 30 days. During this 30-day period, You may be
                            permitted to access Your Accounts solely for purposes of retrieving
                            Your Content from Your Accounts. Following such 30-day period,
                            Company may thereafter terminate all access to Your Accounts and
                            decide whether to keep Your Content active, delete, or archive any
                            of Your Content in Company’s sole and absolute discretion. Upon
                            termination or expiration of this Agreement for any reason: (a) all
                            rights and subscriptions granted to You under this Agreement will
                            terminate; (b) You will immediately cease all use of and access to
                            the Platform, including all Content and Services; (c) You will
                            immediately delete any Applications You have downloaded or
                            installed prior to termination; and (d) You will immediately either
                            return to Company or, at Company’s discretion, destroy any
                            Content of Company and any other information related to this
                            Agreement in Your possession or control.
                        </P>
                </OL>
        </OL>

        <OL START=9>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Suspension.</B>

                    Without limiting Company’s right to terminate this Agreement,
                    Company may also suspend Your access to Your Account and the
                    Platform or any Content or Services (including Your Content), with
                    or without notice to You, upon any actual, threatened, or suspected
                    breach of this Agreement or applicable Law or upon any other conduct
                    deemed by Company, in its sole discretion, to be inappropriate or
                    detrimental to the Platform, Company, or other User or third party.
                </P>
        </OL>
        <OL START=10>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Platform Technology.</B>
                    The Platform,
                    and the data, information, databases, software, hardware and other
                    technology used by or on behalf of Company to operate the Platform,
                    and the structure, organization, and underlying data, information
                    and software code thereof (collectively, the “Technology”),
                    constitute valuable trade secrets of Company. You will not, and will
                    not permit any third party to: (1) access or attempt to access the
                    Technology except as expressly provided in this Agreement; (2) use
                    the Technology in any unlawful manner or in any other manner that
                    could damage, disable, overburden or impair the Technology; (3) use,
                    support, or develop bots, scrapers, software, scripts, crawlers,
                    browser plugins, robots, or other automated methods including
                    human-powered automation through like farming or any other
                    crowd-sourced methods to access, scrape, or copy any portion of the
                    Technology, or to add or download data, or send or redirect
                    information or messages; (4) alter, modify, reproduce, or create
                    derivative works of the Technology; (5) distribute, sell, resell,
                    lend, loan, lease, license, sublicense, or transfer any of Your
                    rights to access or use the Technology or otherwise make the
                    Technology available to any third party; (6) reverse engineer,
                    disassemble, decompile, or otherwise attempt to derive the method of
                    operation of the Technology; (7) attempt to circumvent or overcome
                    any technological protection measures intended to restrict access to
                    any portion of the Technology; (8) monitor the availability,
                    performance or functionality of the Technology; (9) interfere with
                    the operation or hosting of the Technology; (10) capture screenshots
                    or make impressions, reproductions, distributions or publications of
                    any content contained within the Technology, such as images, logos,
                    texts, recommendations, comments, and any other materials; or (11)
                    perform any other action intended to circumvent the controls or
                    access mechanisms within the Technology.
                </P>
        </OL>
        <OL START=11>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Ownership</B>
                    .
                    Company retains all right, title and interest, including, without
                    limitation, all IPR (as defined below), in and to the Technology and
                    any additions, improvements, updates and modifications thereto. You
                    receive no ownership interest in or to the Technology and You are
                    not granted any right or license to use the Technology itself, apart
                    from Your ability to access the Platform, under this Agreement. The
                    Company name, logo, and all product and service names associated
                    with the Platform are trademarks of Company and its providers and
                    You are granted no right or license to use them. For purposes of
                    this Agreement, “IPR” means all intellectual property rights,
                    proprietary rights, rights of publicity, rights of privacy, and any
                    and all other legal rights protecting data, information or
                    intangible property throughout the world, including, without
                    limitation, any and all copyrights, trademarks, service marks, trade
                    secrets, patent rights, moral rights, sui generis rights in
                    databases, and contract rights.
                </P>
        </OL>
        <OL START=12>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Transfer of Title</B>
                    .
                    You agree that the risk of loss and title for any Customer Product
                    passes to You upon Company’s delivery to its carrier. For any
                    Customer Product that is to be provided to You in an electronic
                    format, You agree that delivery of such Customer Product shall be
                    deemed to have occurred either (a) at the time Company transmits the
                    Customer Product via email or other electronic communication
                    addressed to You, or (b) at the time Company transmits a
                    notification to You that the Customer Product is available for
                    downloading from the Platform.
                </P>
        </OL>
        <OL START=13>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Representations
                        and Warranties.</B>
                </P>
                <OL>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">To
                                Company.</B>
                            You represent and
                            warrant to Company that: (a) You have the legal right and authority
                            to enter into this Agreement; (b) this Agreement forms a binding
                            legal obligation on Your behalf; (c) You have the legal right and
                            authority to perform Your obligations under this Agreement and to
                            grant the rights and licenses described in this Agreement; (d) your
                            use of the Platform will be in strict accordance with this
                            Agreement, the Privacy Policy, and all applicable laws and
                            regulations (including without limitation any local laws or
                            regulations in Your country, state, city, or other governmental
                            area, regarding online conduct and acceptable content); (e) Your
                            Content will comply with all applicable state and federal laws,
                            rules, and regulations and not violate any intellectual property
                            rights or any person’s right of privacy or publicity; and (f) You
                            are not located in a country that is subject to a U.S. Government
                            embargo or that has been designated by the U.S. Government as a
                            “terrorist supporting” country, and You are not listed on any
                            U.S. Government list of prohibited or restricted parties.
                        </P>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">Compliance
                                with Laws</B>
                            . You acknowledge
                            that (a) the Platform allows you to design and print various lawful
                            marketing materials, along with offering various digital marketing
                            products; (b) the Platform is a general purpose service and is not
                            specifically designed to facilitate compliance with any specific
                            Law; and (c) You will access and use the Platform in compliance
                            with all Laws applicable to You, Your Content, and any other
                            Platform Content You may access through the Platform. Company is
                            not responsible for notifying You of any such Law, enabling Your
                            compliance with any such Law, or for Your failure to comply. You
                            represent and warrant to Company that Your Content, Your Services,
                            and Your use of and access to the Platform, including any Platform
                            Content, will comply with all applicable Laws and will not cause
                            Company itself or any other User to violate any applicable Laws,
                            including, without limitation, the Health Insurance Portability and
                            Accountability Act of 1996 or the Health Information Technology for
                            Economic and Clinical Health (HITECH) Act (enacted as part of the
                            American Recovery and Reinvestment Act of 2009).
                        </P>
                </OL>
        </OL>
        <OL START=14>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Disclaimers</B>.
                </P>
                <OL>
                    <LI>
                        <P ALIGN=JUSTIFY>
                            <B class="heading">No
                                Warranties</B>
                            . THE PLATFORM,
                            CONTENT, AND SERVICES ARE PROVIDED “AS IS” AND “AS
                            AVAILABLE.” COMPANY AND ITS AFFILIATES DO NOT WARRANT OR
                            GUARANTEE THE ACCURACY, COMPLETENESS, ADEQUACY OR CURRENCY OF THE
                            PLATFORM OR ANY CONTENT OR SERVICES AND DO NOT ENDORSE THE VIEWS OR
                            OPINIONS THAT MAY BE EXPRESSED IN THE PLATFORM CONTENT OR OTHER
                            DATA, INFORMATION, OR CONTENT THAT MAY BE PROVIDED THROUGH THE
                            PLATFORM. COMPANY AND ITS AFFILIATES EXPRESSLY DISCLAIM ANY AND ALL
                            WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THE
                            PLATFORM, CONTENT, SERVICES AND OTHER SUBJECT MATTER OF THIS
                            AGREEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                            WITHOUT LIMITATION, ANY WARRANTIES OF FITNESS FOR A PARTICULAR
                            PURPOSE, MERCHANTABILITY, TITLE OR NON-INFRINGEMENT. NO ORAL OR
                            WRITTEN INFORMATION OR ADVICE GIVEN BY COMPANY, ITS EMPLOYEES,
                            AFFILIATES OR AGENTS WILL INCREASE THE SCOPE OF, OR CREATE ANY NEW
                            WARRANTIES IN ADDITION TO, THE WARRANTIES EXPRESSLY SET FORTH IN
                            THIS SECTION.
                        </P>
                </OL>
        </OL>
        <OL START=15>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Indemnity.</B>

                    You hereby agree to indemnify, defend, and hold harmless Company and
                    its officers, directors, shareholders, affiliates, employees,
                    agents, contractors, assigns, Users, customers, providers,
                    licensees, and successors in interest (“Indemnified Parties”)
                    from any and against all claims, losses, liabilities, damages, fees,
                    expenses and costs (including attorneys’ fees, court costs, damage
                    awards, and settlement amounts) that result from any and against all
                    claim or allegation against any Indemnified Party arising in any
                    manner from: (1) Your access to or use of the Platform, including
                    any Content or Services; (2) Your content or any access to or use
                    thereof; (3) any access to or use of Your Content by any other
                    users; (4) Your collection, use and disclosure of any User
                    Information, and (5) Your breach of any provision of this Agreement,
                    including any representation or warranty. Company will provide You
                    with notice of any such claim or allegation, and Company will have
                    the right to participate in the defense of any such claim at its
                    expense.
                </P>
        </OL>
        <OL START=16>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Limitation on Liability.</B>

                    COMPANY WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER
                    ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY OR
                    TORT (INCLUDING NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH
                    OR OUT OF THE USE OF THE PLATFORM, CONTENT, OR SERVICES, EVEN IF
                    COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                    INCLUDING, WITHOUT LIMITATION, ANY LOSS OF YOUR CONTENT,
                    OPPORTUNITY, REVENUES, PROFITS OR REPUTATION, BUSINESS INTERRUPTION,
                    OR PROCUREMENT OF SUBSTITUTE CONTENT, GOODS OR SERVICES. COMPANY’S
                    TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT AND ALL
                    CONTENT AND SERVICES PROVIDED UNDER THIS AGREEMENT OR THROUGH THE
                    PLATFORM, WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED
                    $10 OR, IN THE CASE OF YOU HAVING PAID FEES TO COMPANY TO ACCESS THE
                    PLATFORM OR ANY CONTENT OR SERVICES, THE AMOUNTS PAID BY YOU TO
                    COMPANY IN THE THREE (3) MONTH PERIOD PRECEDING THE EVENTS GIVING
                    RISE TO ANY LIABILITY. YOU AGREE THAT COMPANY WOULD NOT ENTER INTO
                    THIS AGREEMENT WITHOUT THESE LIMITATIONS ON ITS LIABILITY. IN
                    JURISDICTIONS WHERE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                    INCIDENTAL DAMAGES IS NOT PERMITTED, COMPANY’S LIABILITY IS
                    LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                </P>
        </OL>
        <OL START=17>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Data Privacy.</B>

                    You expressly consent to the use and disclosure of your information
                    and other data and information as described in the Privacy Policy.
                    Notwithstanding anything in the Privacy Policy, Company will have
                    the right to collect, extract, compile, synthesize, and analyze
                    non-personally identifiable data or information (data or information
                    that does not identify an entity or natural person as the source
                    thereof) resulting from Your access to and use of the Platform,
                    Services or Content. To the extent any such non-personally
                    identifiable data or information is collected or generated by
                    Company, the data and information will be solely owned by Company
                    and may be used by Company for any lawful business purpose without a
                    duty of accounting to You, provided that the data and information is
                    used only in an aggregated form, without directly identifying You or
                    any other entity or natural person as a source thereof.
                </P>
        </OL>
        <OL START=18>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Claims
                        of Infringement.</B>
                    Company
                    respects Your copyrights and other intellectual property rights and
                    those of other third parties. If You believe in good faith that Your
                    copyrighted work has been reproduced on the Platform without Your
                    authorization in a way that constitutes copyright infringement, You
                    may notify our designated copyright agent by mail to:
                </P>
                <P>
                    Printing Warehouses of America Inc.<BR>
                    Attn: Copyright Infringement Agent<BR>
                    6200 Metrowest Blvd, Suite 204<BR>
                    Orlando, FL 32835
                </P>
                <P ALIGN=JUSTIFY>
                    Please
                    provide the following information to Company’s Copyright
                    Infringement Agent: (1) the identity of the infringed work, and of
                    the allegedly infringing work; (2) Your name, address, daytime phone
                    number, and email address, if available; (3) a statement that You
                    have a good-faith belief that the use of the copyrighted work is not
                    authorized by the owner, his or her agent, or the law; (4) a
                    statement that the information in the notification is accurate and,
                    under penalty of perjury, that You are authorized to act on behalf of
                    the owner; and (5) Your electronic or physical signature.
                </P>
        </OL>
        <OL START=19>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Disputes.</B>
                    Except as otherwise provided below, the parties will attempt to
                    resolve all disputes, controversies, or claims arising under, out
                    of, or relating to this Agreement, including the formation,
                    validity, binding effect, interpretation, performance, breach or
                    termination, of this Agreement and the arbitrability of the issues
                    submitted to arbitration hereunder and non-contractual claims
                    relating to this Agreement (each, a “Dispute”), in accordance
                    with the procedures set forth in this Section. If any Dispute cannot
                    be resolved through negotiations between the parties within 5 days
                    of notice from one party to the other of the Dispute, such Dispute
                    will be finally settled through binding arbitration under the
                    arbitration rules of the American Arbitration Association (“AAA”)
                    then in effect (the “Rules”). Either party may commence the
                    arbitration by delivering a request for arbitration as specified in
                    the Rules. The arbitration will be conducted before a sole neutral
                    arbitrator selected by agreement of the parties. If the parties
                    cannot agree on the appointment of a single arbitrator within 30
                    days (the “Initial Period”) after either party to this Agreement
                    delivers a request for arbitration, a neutral arbitrator will be
                    selected as provided in the Rules. The arbitration will be conducted
                    exclusively in the English language at a site specified by Company
                    in Orange County, Florida U.S.A. The award of the arbitrator will be
                    the exclusive remedy of the parties for all claims, counterclaims,
                    issues, or accountings presented or pleaded to the arbitrator. The
                    award of the arbitrators will require payment of the costs, fees,
                    and expenses incurred by the prevailing party in any such
                    arbitration by the non-prevailing party. Judgment upon the award may
                    be entered in any court or governmental body having jurisdiction
                    thereof. Any additional costs, fees or expenses incurred in
                    enforcing the award may be charged against the party that resists
                    its enforcement.
                </P>
        </OL>
        <OL START=20>
            <LI>
                <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in;">
                    <B class="heading">NO CLASS ACTIONS: You may only
                        bring individual claims. Under no circumstances are you allowed to
                        bring a claim as a plaintiff or a class member in a class. Class
                        action lawsuits, class-wide arbitrations, private attorney-general
                        actions, and any other proceedings where someone acts in a
                        representative capacity are not allowed. Any combining of individual
                        proceedings must have the consent of all parties.</B>
                </P>
        </OL>
        <OL START=21>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Governing Law and Venue.</B>

                    The interpretation of the rights and obligations of the parties
                    under this Agreement, including, to the extent applicable, any
                    negotiations, arbitrations or other proceedings hereunder, will be
                    governed in all respects exclusively by the laws of the State of
                    Florida U.S.A. as such laws apply to contracts between Florida
                    residents performed entirely within Florida without regard to the
                    conflict of laws provisions thereof. Subject to Section 19
                    (Disputes), each party will bring any action or proceeding arising
                    from or relating to this Agreement exclusively in a federal court in
                    the Middle District of Florida, U.S.A. or in state court in Orange
                    County, Florida U.S.A., and You irrevocably submit to the personal
                    jurisdiction and venue of any such courts in any such action or
                    proceeding brought in such courts by Company.
                </P>
        </OL>
        <OL START=22>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Notices.</B>

                    Unless otherwise specified in this Agreement, any notices required
                    or allowed under this Agreement will be provided to Company by
                    postal mail to the address for Company listed on the Platform.
                    Company may provide You with any notices required or allowed under
                    this Agreement by sending You an email to any email address You
                    provide to Company in connection with Your Account, provided that in
                    the case of any notice applicable both to You and other Users of the
                    Platform, Company may instead provide such notice by posting on the
                    Platform. Notices provided to Company will be deemed given when
                    actually received by Company. Notice provided to You will be deemed
                    given 24 hours after posting to the Platform or sending via e-mail,
                    unless (as to e-mail) the sending party is notified that the e-mail
                    address is invalid.
                </P>
        </OL>
        <OL START=23>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Linked
                        Sites.</B>
                    The Platform may
                    contain links to third-party sites or Content that are not under the
                    control of Company. If you access a third-party site or Content from
                    the Platform, then you do so at your own risk and Company is not
                    responsible for any content on any linked site or content. You may
                    not frame or otherwise incorporate into another site the content or
                    other materials on the Platform without prior written consent.
                </P>
        </OL>
        <OL START=24>
            <LI>
                <P ALIGN=JUSTIFY>
                    <B class="heading">Additional Terms</B>
                    .
                    Unless otherwise amended as provided herein, this Agreement will
                    exclusively govern Your access to and use of the Platform, including
                    all Content and Services, and is the complete and exclusive
                    understanding and agreement between the parties, and supersedes any
                    oral or written proposal, agreement or other communication between
                    the parties, regarding Your access to and use of the Platform,
                    including all Content and Services. Except as expressly set forth in
                    this Agreement, this Agreement may be amended or modified only by a
                    writing signed by both parties. All waivers by Company under this
                    Agreement must be in writing or later acknowledged by Company in
                    writing. Any waiver or failure by Company to enforce any provision
                    of this Agreement on one occasion will not be deemed a waiver by
                    Company of any other provision or of such provision on any other
                    occasion. If any provision of this Agreement is held to be
                    unenforceable, that provision will be removed to the extent
                    necessary to comply with applicable Law, replaced by a provision
                    that most closely approximates the original intent and economic
                    effect of the original to the extent consistent with the applicable
                    Law, and the remaining provisions will remain in full force. The
                    prevailing party in any lawsuit or proceeding arising from or
                    related to this Agreement will be entitled to receive its costs,
                    expert witness fees and reasonable attorneys’ fees, including
                    costs and fees on appeal. Neither this Agreement nor any rights or
                    obligations of You hereunder may be assigned or transferred by You
                    (in whole or in part and including by sale, merger, consolidation,
                    or other operation of law) without the prior written approval of
                    Company. Any assignment in violation of the foregoing will be null
                    and void. Company may assign this Agreement to any party that
                    assumes Company’s obligations hereunder. The parties hereto are
                    independent parties, not agents, employees or employers of the other
                    or joint venturers, and neither acquires hereunder any right or
                    ability to bind or enter into any obligation on behalf of the other.
                    Any reference herein to “including” will mean “including,
                    without limitation.” Upon request from Company, You agree to
                    provide Company with such documentation or records with respect to
                    Your activities under this Agreement as may be reasonably requested
                    for Company to verify Your compliance with the terms of this
                    Agreement and all applicable Laws. The following Sections survive
                    any expiration or termination of this Agreement: 1 (Definitions), 8
                    (Termination and Effect), 11 (Ownership), 12 (Representations and
                    Warranties), 14 (Disclaimers), 15 (Indemnity), 16 (Limitation on
                    Liability), 17 (Data Privacy), 19 (Disputes), 20 (No Class Actions),
                    21 (Governing Law and Venue), 22 (Notices), and 24 (Additional
                    Terms).
                </P>
        </OL>
    </div>
</section>