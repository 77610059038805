import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FilterConfiguration, FilterParams, SearchResult } from 'app/core/product/product.types';
import { SearchService } from '../search.service';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { PWAConfiguration } from 'app/core/configuration/configuration.types';
@Component({
    selector: 'pwa-search-filter-dialog',
    templateUrl: './search-filter-dialog.component.html',
    styleUrls: ['./search-filter-dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule, SearchFilterComponent,
        RouterModule]
})
export class SearchFilterDialog implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { filterParams: FilterParams, filterConfig: FilterConfiguration },
        private _formBuilder: FormBuilder, private _searchService: SearchService,private _configurationService: ConfigurationService,
        private _dialogRef: MatDialogRef<SearchFilterDialog>
    ) { }

    filterParams: FilterParams = null
    filterConfig: FilterConfiguration = null
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    categories 
    ngOnInit(): void {
        this.filterParams = JSON.parse(JSON.stringify(this.data.filterParams))
        this.filterConfig = JSON.parse(JSON.stringify(this.data.filterConfig))
        if (!this.filterParams.custom_templates) {
            this._configurationService.configuration$
              .pipe(takeUntil(this._unsubscribeAll)).subscribe((config: PWAConfiguration) => {
                this.categories = config.digital_categories.concat(config.print_categories)      
              })
          }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }

    title = "Filter";

    onFilterChange(filter: FilterParams) {
        this.filterParams = filter

        if (!this.filterParams.custom_templates) {
            let selectedCategory = this.categories?.find(category => category?.id == this.filterParams.category)
            let selectedSubCategory = selectedCategory?.sub_categories?.find(category => category?.id == this.filterParams.sub_category)
            this.filterConfig = {
              categories: this.categories,
              sub_categories: selectedCategory?.sub_categories,
              sizes: selectedSubCategory?.sizes
            }
            return
        }
        this.filterParams.get_results = false
        this._searchService.search(this.filterParams).subscribe({
            next: (response: SearchResult) => {
                this.filterConfig = response.filter_configuration
            }
        })
    }



    //Close modal with a response message
    responseMessage = null;
    close() {
        this._dialogRef.close();
    }

    submit() {
        this.responseMessage = this.filterParams
        this._dialogRef.close(this.responseMessage)
    }

}
