import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from './core/user/user.service';
import { LocalStorageService } from './core/localstorage/local-storage.service';
import { OrganizationService } from './core/organization/organization.service';
import { CartService } from './core/cart/cart.service';
import { ConfigurationService } from './core/configuration/configuration.service';

export const initialDataResolver = () => {
    // const messagesService = inject(MessagesService);
    const navigationService = inject(NavigationService);
    const _localStorage = inject(LocalStorageService);
    const _configurationService = inject(ConfigurationService)

    const requestArray: Observable<any>[] = [navigationService.get(), _configurationService.getConfigurations()]
    if (_localStorage.accessToken != null && _localStorage.accessToken != '') {

        const notificationsService = inject(NotificationsService);
        // const quickChatService = inject(QuickChatService);
        const userService = inject(UserService);
        const cartService = inject(CartService);
        const organizationService = inject(OrganizationService);

        requestArray.push(userService.get())
        if (_localStorage.accountType == 'organization' ) {
            requestArray.push(organizationService.getOrganizationDetails())
        }
        requestArray.push(notificationsService.getAll())
        requestArray.push(cartService.getCartDetails())
    }

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin(requestArray);
};
