import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrderService } from 'app/core/order/order.service';
import { OrderItem } from 'app/core/order/order.types';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CartItem } from 'app/core/cart/cart.types';
import { CartService } from 'app/core/cart/cart.service';
import { FileService } from 'app/core/file-services/file.service';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';


@Component({
  selector: 'app-review-product',
  templateUrl: './review-product.component.html',
  styleUrls: ['./review-product.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class ReviewProductComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CartItem,
    private _cartService: CartService,
    private fb: UntypedFormBuilder,
    private imageViewer: ImageViewerService,
    private _fileService: FileService,
    private _dialogRef: MatDialogRef<ReviewProductComponent>) {
  }

  ngOnInit(): void {

  }

  title = "Review Product";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  showLoading = false
  proofConfirmed = false
  submit() {
    if (!this.proofConfirmed) {
      this.alert.type = "error"
      this.alert.message = "Please confirm that you have reviewed the designs."
      this.showAlert = true
      return
    }
    this.showLoading = true
    this._cartService.updateCartItem(this.data.id, { "review_completed": true }).subscribe({
      next: (response: any) => {
        this.showLoading = false
        this.responseMessage = response.message ? response.message : "Your review has been submitted"
        this.close()
      },
      error: error => {
        this.showLoading = false
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, modifyDesign: boolean, designId: number }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  modifyDesigns(designId) {
    this.responseMessage = { message: null, modifyDesign: true, designId: designId }
    this.close()
  }

  downloadDesigns() {
    for (var i = 0; i < this.data?.designs.length; i++) {
      setTimeout((design) => {
        this._fileService.downloadFile(design.image, design.title + ".jpeg")
      }, 200 + i * 200, this.data?.designs[i]);
    }
  }

  openImage(url) {
    this.imageViewer.open(url)
  }

}
