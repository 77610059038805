<mat-dialog-content class="p-0 max-w-200">
    <div class="flex justify-between items-center">
        <div class="text-black text-left mb-0 w-full" style="font-weight:700;font-size:20px;">
            Donation Link
        </div>
        <button class="ml-2 sm:ml-8" (click)="close()">
            <img alt="Close icon" class="h-7 w-7 p-1" src="./assets/images/close_icon.svg" style="object-fit: contain;">
        </button>

    </div>
    <div class="flex flex-col items-start justify-start max-w-200">

        <div class="flex gap-3 justify-between w-full mt-4"
            style="background-color:white;border-radius:6px;padding:10px 15px;box-shadow: 0 3px 6px 0 #e9eff8;border: solid 1px #e1e8f4;justify-content: space-between;">
            <p
                style="margin-bottom:0px;font-size:12px;color:#000000;align-self: center;word-wrap:break-word;overflow-wrap: anywhere;">
                {{paymentLink}}
            </p>
        </div>
        <button class="btn_normal p-4 pl-6 pr-6 flex items-center justify-center mt-4 self-end"
            style="border-radius:8px;height:40px;" (click)="onClickCopy(paymentLink)">
            <div class="flex flex-row justify-content-center align-items-center ">
                <!-- <lottie-player *ngIf="showSuccessAnimation" [src]="successAnimationRes" background="transparent"
                    speed="2" style="margin-right: 10px; width: 20px; height: 20px;" autoplay></lottie-player> -->
                <div class="text-md">
                    {{showSuccessAnimation ? 'Copied' : 'Click to Copy'}}
                </div>
            </div>
        </button>
    </div>

</mat-dialog-content>