import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL, LINKEDIN_REDIRECT_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { SMMAccountsResponse, SMMAllAccountsResponse, SMMConnectionStatus, SMMHistoryResponse, SMMPage, SMMPagesResponse, SMMPost, SMMPostsResponse, SocialmediaServices } from './social-media.types';
@Injectable({ providedIn: 'root' })
export class SocialMediaService {

    constructor(private _httpClient: HttpClient) {
    }

    private _socialmediaServices: BehaviorSubject<SocialmediaServices> = new BehaviorSubject(null);
    get sociamMediaServices$(): Observable<SocialmediaServices> {
        return this._socialmediaServices.asObservable();
    }

    getServices(): Observable<SocialmediaServices> {
        return this._httpClient.get<SocialmediaServices>(`${BASE_URL}subscription/plans/`).pipe(
            tap((plans: SocialmediaServices) => {
                this._socialmediaServices.next(plans);
            }),
        );
    }

    subscribe(planId) {
        return this._httpClient.post(`${BASE_URL}subscription/plans/`, { plan: planId })
    }

    getSMMConnectionStatus(resync: Boolean) {
        return this._httpClient.get<SMMConnectionStatus>(BASE_URL + `social-media/connect/status/?resync=${resync ? "1" : "0"}`)
    }

    connectSMM(smmType: string, smmToken: string) {
        let params = { token: smmToken, type: smmType }
        if (smmType === 'linkedin') {
            params['redirect_uri'] = LINKEDIN_REDIRECT_URL
            params['linkedin_web'] = true
        }
        return this._httpClient.put<any>(BASE_URL + 'social-media/connect/', params)
    }

    private _facebookAccounts: BehaviorSubject<SMMAccountsResponse> = new BehaviorSubject(null);
    get facebookAccounts$(): Observable<SMMAccountsResponse> {
        return this._facebookAccounts.asObservable();
    }

    private _instagramAccounts: BehaviorSubject<SMMAccountsResponse> = new BehaviorSubject(null);
    get instagramAccounts$(): Observable<SMMAccountsResponse> {
        return this._instagramAccounts.asObservable();
    }

    private _twitterAccounts: BehaviorSubject<SMMAccountsResponse> = new BehaviorSubject(null);
    get twitterAccounts$(): Observable<SMMAccountsResponse> {
        return this._twitterAccounts.asObservable();
    }

    private _linkedinAccounts: BehaviorSubject<SMMAccountsResponse> = new BehaviorSubject(null);
    get linkedinAccounts$(): Observable<SMMAccountsResponse> {
        return this._linkedinAccounts.asObservable();
    }

    getAccounts(smmType: ('facebook' | 'instagram' | 'twitter' | 'linkedin')) {
        return this._httpClient.get<SMMAccountsResponse>(BASE_URL + 'social-media/accounts/' + smmType + '/').pipe(
            tap((account: SMMAccountsResponse) => {
                switch (smmType) {
                    case 'facebook': {
                        this._facebookAccounts.next(account)
                        break;
                    }
                    case 'instagram': {
                        this._instagramAccounts.next(account)
                        break;
                    }
                    case 'twitter': {
                        this._twitterAccounts.next(account)
                        break;
                    }
                    default: {
                        this._linkedinAccounts.next(account)
                        break;
                    }
                }
            }),
        );
    }

    getAllAccounts(smmType: string[]) {
        let params = new HttpParams();
        params = params.append('accounts', smmType.toString());
        return this._httpClient.get<SMMAllAccountsResponse>(BASE_URL + 'social-media/accounts/', { params: params })
    }

    getPages(smmType: string, accountId: number) {
        return this._httpClient.get<SMMPagesResponse>(`${BASE_URL}social-media/${smmType}/${accountId}/pages/`)
    }

    getLinkedinPageSyncUrl(redirectUri: string) {
        return this._httpClient.get<any>(`${BASE_URL}social-media/linkedin/pages/?redirect_uri=${redirectUri}`)
    }

    syncLinkedinPages(redirectUri: string, token: string) {
        return this._httpClient.post<any>(`${BASE_URL}social-media/linkedin/pages/`, { code: token, redirect_uri: redirectUri })
    }

    removeAccount(smmType: string, accountId: number) {
        return this._httpClient.delete<any>(`${BASE_URL}social-media/accounts/${smmType}/${accountId}/`)
    }

    syncPages(smmType: string, accountId: number) {
        return this._httpClient.post<SMMPagesResponse>(`${BASE_URL}social-media/${smmType}/${accountId}/pages/`, {})
    }

    getPageDetails(smmType: string, accountId: string, pageId: string) {
        return this._httpClient.get<SMMPage>(BASE_URL + 'social-media/' + smmType + '/' + accountId + '/page/' + pageId + '/')
    }


    private _draftPosts: BehaviorSubject<SMMPostsResponse> = new BehaviorSubject(null);
    get draftPosts$(): Observable<SMMPostsResponse> {
        return this._draftPosts.asObservable();
    }
    private _scheduledPosts: BehaviorSubject<SMMPostsResponse> = new BehaviorSubject(null);
    get scheduledPosts$(): Observable<SMMPostsResponse> {
        return this._scheduledPosts.asObservable();
    }
    private _publishedPosts: BehaviorSubject<SMMPostsResponse> = new BehaviorSubject(null);
    get publishedPosts$(): Observable<SMMPostsResponse> {
        return this._publishedPosts.asObservable();
    }

    getPosts(postType: ('draft' | 'scheduled' | 'published'), page: number, isSortASC: boolean) {
        let params = new HttpParams();
        params = params.append('page', page ? page : 1);
        params = params.append('batch_size', 12);
        params = params.append('sort', isSortASC ? 'added_at' : '-added_at');
        params = params.append('publish_status', postType);
        return this._httpClient.get<SMMPostsResponse>(BASE_URL + 'social-media/posts/', { params: params }).pipe(
            tap((response: SMMPostsResponse) => {
                switch (postType) {
                    case 'draft': {
                        this._draftPosts.next(response)
                        break;
                    }
                    case 'scheduled': {
                        this._scheduledPosts.next(response)
                        break;
                    }
                    default: {
                        this._publishedPosts.next(response)
                        break;
                    }
                }
            }),
        );
    }

    private _postDetails: BehaviorSubject<SMMPost> = new BehaviorSubject(null);
    get postDetails$(): Observable<SMMPost> {
        return this._postDetails.asObservable();
    }
    getPostDetails(postId: number) {
        return this._httpClient.get<SMMPost>(`${BASE_URL}social-media/posts/${postId}/`).pipe(
            tap((response: SMMPost) => {
                this._postDetails.next(response)
            }));
    }

    getPostHistory(postId: number, targetId: number, accountType: string, page: number) {
        let params = new HttpParams();
        params = params.append('page', page ? page : 1);
        params = params.append('sort', '-added_at');
        params = params.append('batch_size', 10);
        params = params.append('account_type', accountType);
        return this._httpClient.get<SMMHistoryResponse>(`${BASE_URL}social-media/posts/${postId}/publish-history/`, { params: params })
    }

    deletePost(postId: string) {
        return this._httpClient.delete<any>(`${BASE_URL}social-media/posts/${postId}/`)
    }

    createPost(data: FormData) {
        return this._httpClient.post<any>(`${BASE_URL}social-media/posts/`, data)
    }

    updatePost(data: FormData, postId: number) {
        return this._httpClient.put<any>(`${BASE_URL}social-media/posts/${postId}/`, data)
    }

}
