import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SMMAllAccountsResponse, SMMHistory, SMMPage, SMMPost, SMMPostHistory } from 'app/core/social-media/social-media.types';
import { ToastrService } from 'ngx-toastr';
import { MatInputModule } from '@angular/material/input';
import { Pagination } from 'app/core/api/pagination.type';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfirmationService } from '@fuse/services/confirmation';
@Component({
  selector: 'app-post-history',
  templateUrl: './post-history.component.html',
  styleUrls: ['./post-history.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule,
    FormsModule, MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule, PWAPagination,
    MatButtonModule, MatProgressSpinnerModule,
    RouterModule, MatTooltipModule,
    FuseCardComponent]
})
export class PostHistoryModal implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: { postDetails: SMMPost, target: SMMPage },
    private _smmService: SocialMediaService, private _confirmation: FuseConfirmationService,
    private _dialogRef: MatDialogRef<PostHistoryModal>) {
    this.target = data?.target
    this.postDetails = data?.postDetails
  }
  postDetails: SMMPost
  target: SMMPage
  showLoading = false
  histories: SMMHistory[]
  pagination: Pagination = { current_page: 1, batch_size: 10, total_count: 1, total_pages: 1 }
  ngOnInit(): void {

    this.getPostHistories(1)
  }



  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  showError(history: SMMHistory) {
    if (!history.error)
      return
    this._confirmation.open({
      title: "Post Error",
      message: history.error_message ?? "No error information available.",
      actions: { cancel: { show: false }, confirm: { label: "Ok" } }
    })
  }

  getPostHistories(pageNumber) {
    this.showLoading = true
    this._smmService.getPostHistory(this.postDetails.id, this.target.id, this.target.target_type, pageNumber).subscribe({
      next: (res: any) => {
        this.histories = res.history
        this.pagination = res
        this.showLoading = false
      },
      error: (err) => {
        this.showLoading = false


        // this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }


}
