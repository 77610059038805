<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','autofill-manager']">Autofill Manager</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','autofill-manager',groupDetails?.id]">{{groupDetails?.name}}</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">Autofill</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="p-0  flex flex-row relative mt-4 lg:mt-8" style="border-radius:8px;">
        <mat-tab-group class="relative w-full" (selectedTabChange)="tabChanged($event)" id="designMapTab" #designMapTab>
            <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
                <design-map (onNext)="onClickNext($event)" [mapData]="mapData" [data]="tab" [onClickNext]="tab.onNext">
                </design-map>
            </mat-tab>
        </mat-tab-group>

        <div class="flex absolute gap-2 z-10" style="right:0px;top:0px">
            <button mat-flat-button class="btn_normal w-20" (click)="onNext()">Next</button>
        </div>
    </div>

</section>