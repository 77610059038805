<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600" href="/socialmedia-services">Social Media Posts</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="flex flex-col-reverse md:flex-row items-center gap-8 sm:gap-8 md:gap-16  w-full bg-[#f5f2ff] p-[30px] sm:pr-[50px] sm:pl-[50px] md:pl-[60px] md:pr-[60px] md:pt-[35px] md:pb-[35px]"
        style="border-radius:20px;">
        <div>
            <img class="h-[150px] md:h-[160px] w-full md:w-auto object-contain md:max-w-fit"
                src="./assets/images/smm-banner.png" alt="Social media scheduling tool">
        </div>
        <div>
            <h1 class="text-center md:text-start" style="font-weight:700;font-size:23px;">Social Media Posts</h1>
            <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">Save time
                and grow your brand effortlessly. With our solution, you can easily connect with social media accounts,
                create compelling posts, and schedule them with precision, thereby boosting your social media strategy
                for success.
            </p>
        </div>
    </div>
    <div class="flex flex-col sm:flex-row md:hidden mt-7 gap-4 justify-end" style="right:0px;top:-20px">
        <button mat-flat-button class="btn_white" [routerLink]="['/','social-media']">
            <div class="flex gap-2 items-center justify-center">
                <img src="./assets/images/connect_icon.png" class="w-6 h-auto" alt="Connect icon">
                <p>Connect Social Media</p>
            </div>
        </button>
        <button mat-flat-button (click)="openNewPostPopup(null)" class="mt-0 btn_normal">
            <div class="items-center justify-center flex gap-2">
                <span style="height:24px;">
                    <mat-icon svgIcon="heroicons_outline:plus" class="text-white"></mat-icon>
                </span>
                <p>New Post</p>
            </div>
        </button>
    </div>
    <!-- tab section starts -->
    <div class="p-0 mt-10 h-auto flex flex-row relative ml-0 sm:ml-[-24px] mr-0 sm:mr-[-24px]"
        style="border-radius:8px;">
        <div class="relative w-full">
            <mat-tab-group id="postsTab" #postsTab>
                <mat-tab [label]="postType | titlecase" *ngFor="let postType of postTypes">
                    <div class="flex flex-col sm:flex-row gap-4 justify-between">
                        <div class="flex sm:justify-start justify-between flex-row gap-4 items-center"
                            *ngIf="posts?.get(postType)?.posts?.length > 0">
                            <label style="text-wrap: nowrap;">Sort by</label>
                            <form class="w-auto">
                                <mat-form-field class="w-full" subscriptSizing="dynamic">
                                    <mat-select placeholder="Popular" [ngModel]="sort.get(postType)" [id]="postType"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onSortChanged(postType, $event)">
                                        <mat-option [value]="'-added_at'">Latest</mat-option>
                                        <mat-option [value]="'added_at'">Oldest</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="flex grow items-center gap-4 justify-between sm:justify-end">
                            <p>View By</p>
                            <div class="flex items-center gap-4">
                                <button (click)="listType = 'list'" class="w-6 h-6 cursor-pointer"><img alt="List icon"
                                        [src]="listType == 'list' ? './assets/images/listicon_active.svg' : './assets/images/list_icon.png' "></button>
                                <button (click)="listType = 'grid'" class="w-6 h-6 cursor-pointer"><img alt="Grid icon"
                                        [src]="listType == 'grid' ? './assets/images/grid_active.svg' : './assets/images/grid_icon.png'"></button>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="posts?.get(postType)?.posts?.length > 0 ; else noData">
                        <div class="grid grid-cols-1 gap-6  md:gap-3 mt-6 mb-4 grow"
                            [ngClass]="{'md:grid-cols-2 lg:grid-cols-3': listType == 'grid'}">
                            <div *ngFor="let post of posts.get(postType)?.posts">
                                <ng-container [ngTemplateOutlet]="listType == 'grid' ? gridView : listView"
                                    [ngTemplateOutletContext]="{post:post, key:postType}">
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <pwa-pagination class="flex justify-center w-full justify-self-center mt-10 mb-8"
                        (onPageChange)="getPosts(postType,$event)"
                        [pagination]="pagination.get(postType)"></pwa-pagination>
                </mat-tab>

            </mat-tab-group>
        </div>
        <div class="hidden md:flex absolute gap-4 z-10 mr-[24px]" style="right:0px;top:-20px">
            <button mat-flat-button class="btn_white" [routerLink]="['/','social-media']">
                <div class="flex gap-2 items-center">
                    <img src="./assets/images/connect_icon.png" class="w-6 h-auto" alt="Connect icon">
                    <p style="font-size: 12px;">Connect Social Media</p>
                </div>

            </button>
            <button mat-flat-button class="mt-0 btn_normal" (click)="openNewPostPopup(null)">
                <div class="items-center justify-center flex gap-2">
                    <span style="height:24px;">
                        <mat-icon svgIcon="heroicons_outline:plus" class="text-white"></mat-icon></span>
                    <p style="font-size: 12px;">New Post</p>
                </div>
            </button>

        </div>
    </div>
    <!-- / -->

</section>

<ng-template #noData>
    <div class="flex flex-col items-center justify-center m-auto w-full md:w-4/5 pt-14 pb-6 pl-4 pr-4 md:p-20">
        <p class="text-black mb-1 text-center" style="font-size:18px;font-weight:500;">
            <ng-container [ngSwitch]="postsTab?.selectedIndex">
                <ng-container *ngSwitchCase="2">
                    Oops! It appears you haven't drafted any posts yet
                </ng-container>
                <ng-container *ngSwitchDefault>
                    There are no posts
                </ng-container>
            </ng-container>
        </p>
        <p class="text-black text-center" style="font-size:13px;font-weight:normal;">
            <ng-container [ngSwitch]="postsTab?.selectedIndex">
                <ng-container *ngSwitchCase="0">
                    Sorry! Looks like there's nothing to show on your feed right now. Keep creating and publishing
                    engaging content to keep your audience entertained and informed!
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    Sorry! Looks like there's nothing to show on your feed right now. Keep scheduling your posts to keep
                    your audience engaged and entertained!
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    It seems like you haven't drafted any posts yet on social media. Don't worry, you can start creating
                    captivating content to engage your audience and build your online presence!
                </ng-container>
            </ng-container>
        </p>
    </div>
</ng-template>

<ng-template #gridView let-post='post' let-key="key">
    <fuse-card class="flex flex-col w-full filter-post cursor-pointer"
        [routerLink]="['/','social-media','posts',post.id]">
        <!-- Post header -->
        <div class="flex items-center mx-4 mt-6 mb-4">
            <img class="w-10 h-10 rounded-full" onError="this.src = 'assets/images/profile-icon.webp'"
                alt="Profile image"
                [src]="post.targets.length > 0 ? (post.targets[0].image_url ?? post.targets[0].profile_image_url ?? post.targets[0].profile_picture_url)  :  'assets/images/smm/empty_image_bg.png'">
            <img *ngIf="post.targets.length > 1" class="w-10 h-10 rounded-full" alt="Profile image"
                onError="this.src = 'assets/images/profile-icon.webp'"
                [src]="post.targets.length > 1 ?  (post.targets[1].image_url ?? post.targets[1].profile_image_url ?? post.targets[1].profile_picture_url) :  'assets/images/smm/empty_image_bg.png'"
                style="margin-left:-20px;box-shadow: -3px 0px 5px #00000057;">

            <div class="flex flex-col ml-4 grow">
                <span class="font-semibold leading-none">
                    <span *ngIf="getPageCount(post) != 0">{{getPageCountText(post)}}
                    </span>
                    <span *ngIf="getPageCount(post) == 0">
                        <i>No targets</i>
                    </span>
                </span>
                <span class="text-xs text-secondary leading-none mt-1">
                    <span *ngIf="!post.published && !post.is_draft && !post.error> 0 ">Scheduled
                        for
                        {{post.scheduled_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.published">Published on
                        {{post.published_time ??
                        post?.latest_publish_history[0].published_time
                        | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.is_draft > 0">Updated on
                        {{post.updated_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.error > 0">Tried to publish on
                        {{post.published_time ??
                        post?.latest_publish_history[0].published_time
                        | date:'MMM dd yyyy | hh:mm aa'}}</span>
                </span>
            </div>
            <div *ngIf="post.recurring_frequency != null" (click)="onClickPost(post)" class="flex flex-col items-center"
                style="width: 50px;min-width: 40px; text-align: center;font-size: 8px;color:#000;">
                <img [src]="'assets/images/smm_recurring_list.png'" alt="Recurring icon"
                    style="width: 32px;border-radius: 0px;margin-right: 0px;">
                Recurring
            </div>
        </div>
        <!-- Post details -->
        <div class="flex mx-4 mb-4">
            <div class="flex h-60 pr-0 w-full relative"
                style="border-radius:8px;background-color:#f5f5f5;overflow: hidden;">
                <!-- Post Image  -->
                <img *ngIf="post.image_url" [src]="post.image_url" class="w-full h-full" style="object-fit: contain;" alt="Post preiew">

                <!-- Post text -->
                <p class="truncate h-full w-fll" style="margin-bottom: 0px; white-space: pre-wrap; padding: 5px;"
                    *ngIf="!post.image_url && post.text">{{post.text}}</p>

                <!-- Post Error -->
                <div *ngIf="post.error" class="flex flex-col items-center justify-center p-4 absolute opacity-70"
                    style="bottom: 0px;left: 0px;top: 0px;right: 0px;background-color: #000;">
                    <img src="assets/images/ic_smm_error.svg" style="height: 50px;width: 50px;"
                        alt="Social media error">
                    <p style="padding: 20px;color: white;padding: 0px;margin: 0px;">Error !
                    </p>
                    <p style="padding: 20px;color: white;text-align: center;padding: 0px;margin: 0px;">
                        Something went wrong. Please reschedule this post</p>
                </div>
            </div>
        </div>
    </fuse-card>
    <div class="flex mt-3 gap-4 justify-end" style="right:0px;top:-20px" *ngIf="!post.published">
        <button mat-flat-button class="flex gap-2 items-center btn_white" (click)="deletePost(key, post)">
            Delete
        </button>
        <button mat-flat-button (click)="openNewPostPopup(post)"
            class="mt-0 items-center justify-center flex gap-2  btn_normal">
            Edit
        </button>
    </div>
</ng-template>

<ng-template #listView let-post='post' let-key="key">
    <fuse-card class="flex flex-row w-full filter-post cursor-pointer"
        [routerLink]="['/','social-media','posts',post.id]">

        <!-- Post header -->
        <div class="flex items-center ml-6 mr-0 sm:ml-6  mt-6 mb-6 grow">
            <img class="w-10 h-10  rounded-full hidden sm:flex" onError="this.src = 'assets/images/profile-icon.webp'"
                alt="Profile image"
                [src]="post.targets.length > 0 ? (post.targets[0].image_url ?? post.targets[0].profile_image_url ?? post.targets[0].profile_picture_url)  :  'assets/images/smm/empty_image_bg.png'">
            <img *ngIf="post.targets.length > 1" class="w-10 h-10 rounded-full hidden sm:flex" alt="Profile image"
                onError="this.src = 'assets/images/profile-icon.webp'"
                [src]="post.targets.length > 1 ?  (post.targets[1].image_url ?? post.targets[1].profile_image_url ?? post.targets[1].profile_picture_url) :  'assets/images/smm/empty_image_bg.png'"
                style="margin-left:-20px;box-shadow: -3px 0px 5px #00000057;">

            <div class="flex flex-col ml-0 sm:ml-4 grow">
                <span class="font-semibold leading-none truncate w-full" style="contain: inline-size;">
                    <span *ngIf="getPageCount(post) != 0">{{getPageCountText(post)}}
                    </span>
                    <span *ngIf="getPageCount(post) == 0">
                        <i>No targets</i>
                    </span>
                </span>
                <span class="text-xs text-secondary leading-none mt-1">
                    <span *ngIf="!post.published && !post.is_draft && !post.error> 0 ">Scheduled
                        for
                        {{post.scheduled_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.published">Published on
                        {{post.published_time ??
                        post?.latest_publish_history[0].published_time
                        | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.is_draft > 0">Updated on
                        {{post.updated_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <span *ngIf="post.error > 0">Tried to publish on
                        {{post.published_time ??
                        post?.latest_publish_history[0].published_time
                        | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    <div *ngIf="post.recurring_frequency != null" (click)="onClickPost(post)"
                        class="flex md:hidden  items-center gap-2 mt-1"
                        style="width: 50px;min-width: 40px; text-align: center;font-size: 9px;color:#000;">
                        <img [src]="'assets/images/smm_recurring_list.png'" alt="Recurring icon"
                            style="width: 20px;border-radius: 0px;margin-right: 0px;">
                        Recurring
                    </div>
                </span>
            </div>
            <div *ngIf="post.recurring_frequency != null" (click)="onClickPost(post)"
                class="hidden md:flex flex-col items-center"
                style="width: 50px;min-width: 40px; text-align: center;font-size: 8px;color:#000;">
                <img [src]="'assets/images/smm_recurring_list.png'" alt="Recurring icon"
                    style="width: 32px;border-radius: 0px;margin-right: 0px;">
                Recurring
            </div>
        </div>
        <!-- Post details -->
        <div class="flex items-center m-6">
            <div class="flex sm:h-18 pr-0 sm:w-20 w-14 h-14 relative"
                style="border-radius:8px;background-color:#f5f5f5;overflow: hidden;">
                <!-- Post Image  -->
                <img *ngIf="post.image_url" [src]="post.image_url" class="w-full h-full" style="object-fit: contain;"
                    alt="Post preview">

                <!-- Post text -->
                <p class="truncate h-full w-fll" style="margin-bottom: 0px; white-space: pre-wrap; padding: 5px;"
                    *ngIf="!post.image_url && post.text">{{post.text}}</p>

                <!-- Post Error -->
                <div *ngIf="post.error" class="flex flex-col items-center justify-center p-4 absolute opacity-70"
                    style="bottom: 0px;left: 0px;top: 0px;right: 0px;background-color: #000;">
                    <img src="assets/images/ic_smm_error.svg" style="height: 50px;width: 50px;" alt="Error icon">
                    <p style="padding: 20px;color: white;padding: 0px;margin: 0px;">Error !
                    </p>
                    <p style="padding: 20px;color: white;text-align: center;padding: 0px;margin: 0px;">
                        Something went wrong. Please reschedule this post</p>
                </div>
            </div>
        </div>
        <div class="flex gap-4 justify-end items-center mr-6" style="right:0px;top:-20px" *ngIf="!post.published">
            <button mat-flat-button class="hidden md:flex items-center btn_white h-fit"
                (click)="$event.stopPropagation(); deletePost(key, post)">
                <span class="flex items-center gap-2"><img src="./assets/images/delete_icon.svg" alt="Delete icon"
                        style="width:17px;height:17px;object-fit: contain;">
                    <p>DELETE</p>
                </span>
            </button>
            <button mat-flat-button (click)="$event.stopPropagation();openNewPostPopup(post)"
                class="hidden md:flex mt-0 items-center justify-center gap-2  btn_white h-fit">
                <span class="flex items-center gap-2"><img src="./assets/images/edit_icon.svg" alt="Edit icon"
                        style="width:17px;height:17px;object-fit: contain;">EDIT</span>
            </button>
            <div class="flex md:hidden">
                <button [matMenuTriggerFor]="menu" class="flex" (click)="$event.stopPropagation();">
                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deletePost(key, post)">Delete</button>
                    <button mat-menu-item (click)="openNewPostPopup(post)">Edit</button>
                </mat-menu>

            </div>
        </div>
    </fuse-card>

</ng-template>