import { inject } from '@angular/core';
import { CartService } from 'app/core/cart/cart.service';
import { PaymentService } from 'app/core/payment/payment.service';
import { UserService } from 'app/core/user/user.service';
import { Observable, forkJoin } from 'rxjs';

export const subscriptionPaymentDataResolver = () => {
    const _userService = inject(UserService);
    const _paymentService = inject(PaymentService);
    const requestArray: Observable<any>[] = []
    requestArray.push(_userService.getAddresses())
    requestArray.push(_paymentService.getCards())
    return forkJoin(requestArray);
};
