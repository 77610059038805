<div class="flex flex-col flex-auto min-w-0">
    <!-- Header -->
    <div class="pb-28 px-4 sm:pb-32 sm:px-16 overflow-hidden"
        style="background-image: url(./assets/images/contact_banner.png);background-size: 100% 100%;">
        <!-- Bread crumb -->
        <div class="breadcrumb flex mt-6 mb-10 px-1 sm:px-4 md:px-8 m-auto sm:ml-0" style="max-width:90%;">
            <a class="text-black" [routerLink]="['/','home']">Home</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-black">Help</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-black" style="font-weight:600" [routerLink]="['/','contact-us']">Contact Us</a>
        </div>
        <!-- / -->
        <div class="z-10 relative flex flex-col items-center m-auto" style="max-width:90%;">
            <h1 class="text-[#060266] text-center mb-1" style="font-weight:700;font-size:27px;">Need Support? Contact
                Us!
            </h1>
            <p class="text-black text-center" style="font-size:14px;">Reach out to our team through the
                contact form below, and we'll get back to you as soon as possible.</p>
        </div>
    </div>

    <div class="flex flex-col items-center px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%">
        <!-- Cards -->
        <div
            class="relative flex flex-col md:flex-row w-full -mt-16 sm:-mt-24 rounded-2xl  shadow  overflow-hidden bg-card  ease-in-out duration-150">
            <!-- FAQs card -->
            <div class="relative w-full flex flex-col">
                <img class="w-full h-[300px] object-cover" src="./assets/images/contact_img.jpeg" alt="Visual Marketing">
            </div>
            <!-- Guides card -->
            <div class="relative w-full flex flex-col">
                <div class="flex flex-col flex-auto p-8 justify-center w-full items-center md:items-start">
                    <h2 class="text-[#060266] text-center md:text-start  mb-1"
                        style="font-weight:700;font-size:23px;max-width:380px;">Are You Looking to Revamp Your
                        Visual
                        Marketing?</h2>
                    <p class="mt-2 mb-3 text-black text-center md:text-left" style="font-weight:400;font-size:14px;">
                        Reach out to find out more
                        about
                        our customization options and services. Our dedicated team of professionals value customer
                        service and
                        is here to support you at every step of the process. </p>
                </div>
            </div>
        </div>
        <!-- FAQs -->
        <div class="flex flex-col md:flex-row  w-full mt-12 gap-0 md:gap-8  justify-center">
            <div
                class="flex-initial flex flex-col justify-start items-center md:items-start md:w-3/5 mt-8 mb-8 md:mb-0">
                <h2 class="text-[#060266] text-start mb-2" style="font-size: 23px;font-weight: 700;"
                    *ngIf="user != null">
                    Need More Help?
                </h2>
                <p class="text-center md:text-start  text-black" style="font-size: 14px;font-weight:normal;"
                    *ngIf="user != null">
                    Please copy your support token and share
                    it with our support team only
                    when requested.
                    By doing this, you'll be temporarily sharing your account access with our support team
                    executive. This temporary access will get
                    revoked automatically after one hour.</p>
                <button mat-flat-button class="btn_normal w-full sm:w-auto flex justify-center mt-2 mb-6"
                    (click)="getSupportToken()" *ngIf="user != null">
                    Create Support Token</button>
                <!-- /column -->
                <div class="flex gap-6 mb-4 md:mb-4 flex-row contactData">
                    <a href="tel:8002823122">
                        <mat-icon svgIcon="mat_solid:phone_in_talk" class="w-6 h-6 text-[#060267]"
                            href="tel:8002823122"></mat-icon>
                    </a>
                    <div class="flex flex-row">
                        <a class="flex justify-content-start align-items-center" href="tel:8002823122">
                            <div class="text-[#3723dd] text-md md:text-lg font-bold"><b>800 282 3122</b></div>
                        </a>
                        <div class="flex"
                            style="border:1px solid rgba(0, 0, 0, 0.125);margin-left:15px;margin-right:15px;">
                        </div>
                        <a class="flex justify-start items-start" href="tel:4075191140">
                            <span class="text-[#3723dd] text-md md:text-lg font-bold">407 519
                                1140</span>
                        </a>
                    </div>
                </div>
                <div class="flex mb-4 md:mb-4">
                    <a class="flex gap-6 justify-center items-center" href="mailto:support@printingwarehouses.com">
                        <mat-icon svgIcon="mat_solid:email" class="w-6 h-6 text-[#060267]"></mat-icon>
                        <div class="text-sm md:text-md" style="color: #000;">
                            support&#64;printingwarehouses.com</div>
                    </a>
                </div>
                <div class="flex gap-6 flex-row">
                    <mat-icon svgIcon="mat_solid:av_timer" class="w-6 h-6 text-[#060267]"></mat-icon>
                    <div class="flex flex-col">
                        <div class="text-sm text-black md:text-md">
                            Hours of operation</div>
                        <div class="text-sm md:text-lmd text-black">
                            08:30 AM to 5.30 PM EST | Monday - Friday</div>
                    </div>
                </div>
            </div>
            <div class="flex-initial md:w-3/5">
                <form novalidate #contactUsForm="ngForm" class="contact-form mt-8 mb-8" [formGroup]="contactForm"
                    #contactNgForm="ngForm">
                    <div class="flex gap-4 sm:flex-row">
                        <mat-form-field class="w-full">
                            <mat-label class="text-black">First Name</mat-label>
                            <input matInput [formControlName]="'firstName'" class="text-black">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <mat-label class="text-black">Last Name</mat-label>
                            <input matInput [formControlName]="'lastName'" required class="text-black">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="w-full">
                        <mat-label class="text-black">Email</mat-label>
                        <input matInput [formControlName]="'email'" required class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label class="text-black">Subject</mat-label>
                        <input matInput [formControlName]="'subject'" required rows="5" class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label class="text-black">Message</mat-label>
                        <textarea matInput [formControlName]="'message'" required rows="5"
                            class="text-black"></textarea>
                    </mat-form-field>
                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                    <div class="flex justify-end w-full md:w-auto">
                        <button class="btn_normal mt-4 justify-center w-100 sm:w-auto" [disabled]="contactForm.disabled"
                            mat-flat-button [color]="'primary'" type="submit" (click)="submit()">
                            <span *ngIf="!contactForm.disabled">
                                Submit
                            </span>
                            <mat-progress-spinner *ngIf="contactForm.disabled" [diameter]="24" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                    </div>

                </form>
            </div>
        </div>
        <!--  -->
    </div>
    <!-- map -->
    <div class="card map-responsive w-full"
        style="margin-top:2rem;border-radius:0px;border:1px solid #e5e5e5;border-radius:20px">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3505.8505077999534!2d-81.4668161!3d28.5141451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77926a27744f7%3A0x886bd5b36ab314!2s6200%20Metrowest%20Blvd%20STE%20204%2C%20Orlando%2C%20FL%2032835%2C%20USA!5e0!3m2!1sen!2sin!4v1638890747339!5m2!1sen!2sin"
            width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <div class="flex flex-col justify-center" style="padding:22px;line-height: 22px;">
            <p class="text-black text-center mb-0" style="font-size:14px;">
                Printing Warehouses of
                America Inc.</p>
            <p class="text-black text-center mb-0" style="font-size:14px;">
                6200 Metrowest Blvd, Suite
                204, Orlando, FL 32835</p>
        </div>
    </div>
    <!-- /.map -->
</div>