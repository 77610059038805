import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { OrganizationService } from 'app/core/organization/organization.service';

export const employeeAccessDataResolver = (route: ActivatedRouteSnapshot) => {
    const _organizationService = inject(OrganizationService);
    const _productService = inject(ProductService);
    const requestArray: Observable<any>[] = [_organizationService.getEmployeesAllocated(route.params['id'], true, false),
    _productService.getProductVersionDetails(route.params['id'])]
    return forkJoin(requestArray);
};