import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AutofillManagerService } from '../autofill-manager.service';
import { AutofillContact, AutofillGroup, MappingData } from '../autofill-manager.types';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { AutofillContactInfo } from './autofill-contact.types';
import { GlobalService } from 'app/core/auth/global.service';
import { EDITOR_URL } from 'environments/environment';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { UserService } from 'app/core/user/user.service';
import { ReviewProductComponent } from './review-product/review-product.component';

@Component({
  selector: 'contact-update',
  templateUrl: './contact-update.component.html',
  styleUrls: ['./contact-update.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatRadioModule, FormsModule, ReactiveFormsModule,
    MatInputModule, MatSelectModule, MatProgressSpinnerModule, FuseAlertComponent,
    MatTabsModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule,
    MatProgressSpinnerModule]
})

export class AutofillContactComponent implements OnInit {

  @ViewChild("designMapTab", { static: false }) designMapTab: MatTabGroup;
  /**
   * Constructor
   */
  constructor(private _autofillManagerService: AutofillManagerService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef, private _imageViewer: ImageViewerService,
    private _toast: ToastrService, private broadcast: BroadcastService,
    private fb: UntypedFormBuilder, private _accountService: UserService,
    private route: ActivatedRoute, private _globalServices: GlobalService) {

  }

  @ViewChild('contactNgForm') contactNgForm: NgForm;
  contactForm: UntypedFormGroup;
  designations: string[]

  lockedFields = []

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  groupDetails: AutofillGroup

  contactData: AutofillContactInfo;
  ngOnInit(): void {
    this.contactData = this.route.snapshot.data['data'][1]

    this._autofillManagerService.group$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(group => {
        this.groupDetails = group
        this._changeDetectorRef.markForCheck()
      })

    this._autofillManagerService.contact$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contactData => {
        this.contactData = contactData
        this.lockedFields = contactData?.locked_fields?.split(',')
        this.initContactForm()
        this._changeDetectorRef.markForCheck()
      })

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()

    })
    this.getDesignationList()
  }

  getDesignationList() {
    this._accountService.getDesignationList().subscribe({
      next: res => {
        this.designations = res.designation_list
        this.designations.push("Custom")

        if (this.designations?.find((x => x == this.contactData?.contact_data?.designation)) == undefined) {
          this.showCustomDesignation = true
          this.contactForm.patchValue({ 'custom_designation': this.contactData?.contact_data.designation })
          this.contactForm.patchValue({ 'designation': "Custom" })
          // this.organizationForm
        }

      },
      error: error => {
        this._toast.error("Unable to get designation list. Please try again!", "Sorry")
      }
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initContactForm() {
    this.contactForm = this.fb.group({
      first_name: [this.contactData?.contact_data?.first_name ?? '', [Validators.required]],
      middle_name: [this.contactData?.contact_data?.middle_name ?? ''],
      last_name: [this.contactData?.contact_data?.last_name ?? '', [Validators.required]],
      email: [this.contactData?.contact_data?.email ?? '', [Validators.required]],
      phone_number: [this.contactData?.contact_data?.phone_number ?? '', [Validators.required]],
      tag: [this.contactData?.contact_data?.tag ?? ''],
      designation: [this.contactData?.contact_data?.designation ?? '', [Validators.required]],
      custom_designation: [this.contactData?.contact_data?.designation ?? ''],
      address_line_1: [this.contactData?.contact_data?.address_line_1 ?? '', [Validators.required]],
      address_line_2: [this.contactData?.contact_data?.address_line_2 ?? ''],
      city: [this.contactData?.contact_data?.city ?? ''],
      state: [this.contactData?.contact_data?.state ?? ''],
      zipcode: [this.contactData?.contact_data?.zipcode ?? ''],

      company_name: [this.contactData?.contact_data?.company_name ?? ''],
      company_email: [this.contactData?.contact_data?.company_email ?? '', [Validators.email]],
      company_phone: [this.contactData?.contact_data?.company_phone ?? ''],
      company_fax: [this.contactData?.contact_data?.company_fax ?? ''],
      company_website: [this.contactData?.contact_data?.company_website ?? '']
    });
  }

  showCustomDesignation = false
  onDesignationChanged() {
    this.showCustomDesignation = this.contactForm.get('designation').value == "Custom"
    this.contactForm.controls['custom_designation'].setValidators(this.showCustomDesignation ? [Validators.required] : []);
    this.contactForm.controls['custom_designation'].updateValueAndValidity();
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  contactImageSrc
  fileToUpload
  onImageChanged(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      this.fileToUpload = ev.target.files[0]
      const reader = new FileReader();
      reader.onload = e => {
        this.contactImageSrc = reader.result;
      }
      reader.readAsDataURL(file);
    }
  }

  isFormSubmitted = false;


  refresh() {
    this._spinner.show
    this._autofillManagerService.getContactDetails(this.groupDetails?.id, this.contactData.id).subscribe({
      complete: () => {
        this._spinner.hide()
      }
    })
  }

  review() {
    this._spinner.show
    this._autofillManagerService.getRefreshedContactDetails(this.groupDetails?.id, this.contactData.id).subscribe({
      complete: () => {
        this._spinner.hide()
        this.openReviewPopup()
      }
    })
  }

  openReviewPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { contact: this.contactData, groupId: this.groupDetails.id }
    this._matDialog.open(ReviewProductComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {

          this._toast.success(value ?? ("Product review submitted successfully"), "Success");
          this._router.navigate(['/', 'autofill-manager', this.groupDetails.id])
        }
      });
  }

  openImage(image) {
    this._imageViewer.open(image)
  }

  editDesign(designId) {
    this._globalServices.setAutofillCookie()
    window.open(`${EDITOR_URL}/?id=` + designId, '_blank');
  }

  updateContactInfo() {
    this.showAlert = false
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      return;
    }

    this.contactForm.disable()

    let contact = this.contactForm.value
    if (this.showCustomDesignation) {
      contact['designation'] = contact['custom_designation']
    }

    const formData = new FormData();
    if (this.fileToUpload != null) {
      formData.append('profile_image', this.fileToUpload)
    }
    formData.append('contact_data', JSON.stringify(contact));


    let request = this._autofillManagerService.updateContact(this.groupDetails.id, this.contactData.id, formData)
    request.subscribe({
      next: (response: any) => {
        this.contactForm.enable()
        this._toast.success(response.message ? response.message : `Contact has been  'updated'}`, "Success")
      },
      error: error => {
        this.contactForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

}