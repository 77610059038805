import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrganizationAdmin } from 'app/core/organization/organization.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ProductService } from 'app/core/product/product.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clone-history',
  templateUrl: './clone-history.component.html',
  styleUrls: ['./clone-history.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule, MatDatepickerModule,
    CommonModule, MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CloneHistoryComponent implements OnInit {

  @ViewChild('cloneNgForm') cloneNgForm: NgForm;
  cloneForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { versionId, historyId, name },
    private _productService: ProductService, private _router: Router,
    private _toast: ToastrService, private _matDialog: MatDialog,
    private fb: UntypedFormBuilder, private _confirmation: FuseConfirmationService,
    private _dialogRef: MatDialogRef<CloneHistoryComponent>) {
  }

  ngOnInit(): void {
    this.cloneForm = this.fb.group({
      title: [this.data?.name ?? '', [Validators.required]],
      history_id: [this.data?.historyId],
    });
  }

  title = "Clone Template";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.cloneForm.markAllAsTouched();
    if (this.cloneForm.invalid) {
      return;
    }

    this.cloneForm.disable()
    let request = this._productService.cloneHistoryToCustomProduct(this.data.versionId, this.cloneForm.value)
    request.subscribe({
      next: (response: any) => {
        this.cloneForm.enable()
        this._confirmation.open({
          title: "Success",
          message: response.message ? response.message : `Successfully created a custom template based on this template.`,
          actions: { cancel: { show: false }, confirm: { label: "Ok" } },
          icon: { show: false }
        }).afterClosed().subscribe((result) => {
          this._router.navigate(['/', 'organization', 'product', response.product_id, 'employee-access'])
          this._matDialog.closeAll()
        })

        this._toast.success(response.message ? response.message : `History has been transferred`, "Success")
        this.close()
      },
      error: error => {
        this.cloneForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }



}
