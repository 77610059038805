import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthService, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

declare var AppleID: any;
declare var google: any
@Component({
  selector: 'app-login',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule,
    MatFormFieldModule, MatInputModule, MatSelectModule, MatTabsModule,
    MatIconModule, MatSidenavModule, MatButtonModule, FuseAlertComponent, GoogleSigninButtonModule,
    NgxSpinnerModule, MatProgressSpinnerModule, SocialLoginModule, MatCheckboxModule,]
})
export class LoginComponent implements OnInit, AfterViewInit {

  // @ViewChild('gbutton') gbutton: ElementRef = new ElementRef({});
  @ViewChild('gButtonMobile') gButtonMobile: ElementRef = new ElementRef({});

  @ViewChild('otpNgForm') otpNgForm: NgForm;
  // @ViewChild('googleContainer') googleContainer: HTMLElement;
  @ViewChild('signUpNgForm') signUpNgForm: NgForm;
  @ViewChild('loginNgForm') loginNgForm: NgForm;
  @ViewChild('forgotPasswordNgForm') forgotPasswordNgForm: NgForm;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { checklistId: number },
    private deviceService: DeviceDetectorService,
    private _formBuilder: UntypedFormBuilder, private authService: SocialAuthService,
    private _authService: AuthService, private _spinner: NgxSpinnerService,
    private _toast: ToastrService,
    private _dialogRef: MatDialogRef<LoginComponent>) { }

  otpForm: UntypedFormGroup;
  signUpForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  loginData: any = {}

  showSignup = false

  ngOnInit(): void {
    this.loginData.device = this.deviceService.getDeviceInfo().deviceType
    this.otpForm = this._formBuilder.group({
      otp: ['', [Validators.required, Validators.min(6)]]
    });
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.signUpForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.min(3)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.min(6)]],
      referral_code: [''],
      agreeTnC: ['', [Validators.requiredTrue]],
    });

    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.authService.authState.subscribe((user) => {
      this.loginData.token = user.provider === 'GOOGLE' ? user.idToken : user.authToken
      this.login(user.provider)
      // this.user = user;
      // this.loggedIn = (user != null);
    });
    document.addEventListener('AppleIDSignInOnSuccess', this.appleLoginListener)
    document.addEventListener('AppleIDSignInOnFailure', this.appleLoginErrorListener)
  }

  ngAfterViewInit() {
    // this.renderGoogleLogin()
  }

  showLogin() {
    this.showForgotPassword = false;
    this.otpIdentifier = null;
    this.showAlert = false
    this.showSignup = false;
    this.loginForm.enable()
    setTimeout(() => this.renderGoogleLogin(), 100);
  }

  renderGoogleLogin() {
    // google.accounts.id.initialize({
    //   client_id: '376257940850-hre70m0erul7ttulu6ajvmd1tgb8du2c.apps.googleusercontent.com',
    //   itp_support: true,
    // });

    // google.accounts.id.renderButton(
    //   this.gButtonMobile.nativeElement,
    //   {
    //     type: "icon", theme: "outline", text: 'signin_with',
    //     size: "large", width: "50", shape: "square", ux_mode: "popup",
    //   }
    // )
    // google.accounts.id.renderButton(
    //   this.gbutton.nativeElement,
    //   {
    //     type: "standard", theme: "outline", text: 'signin_with', logo_alignment: "left",
    //     size: "large", width: "50", shape: "rectangular", ux_mode: "popup",
    //   }
    // )
  }

  onDestroy() {
    this.authService.signOut()
    document.removeEventListener('AppleIDSignInOnSuccess', this.appleLoginListener)
    document.removeEventListener('AppleIDSignInOnFailure', this.appleLoginErrorListener)
  }

  fbLoginOptions = {
    scope: 'email,public_profile,pages_show_list,pages_manage_posts'
  };

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
  }

  signOut(): void {
    this.authService.signOut();
  }

  onGoogleLoginComplete(response) {

  }


  otp_identifier = null
  login(type) {
    this.loginForm.markAsDirty()
    // Return if the form is invalid
    if (type == 'email' && this.loginForm.invalid) {
      return;
    }

    // Disable the form
    this.loginForm.disable();

    this._spinner.show()
    this.showAlert = false
    this.loginData.login_type = type
    if (type == "password") {
      this.loginData.password = this.loginForm.get('password').value
      this.loginData.email = this.loginForm.get('email').value
    }
    this._authService.signIn(this.loginData).subscribe((response) => {
      this._spinner.hide()
      if (response.need_otp_verify == true) {
        this.otpIdentifier = response.otp_identifier
        this.alert = {
          type: 'success',
          message: response.message ? response.message : 'OTP sent successfully'
        };
        this.showAlert = true;
        // Re-enable the form
        this.otpForm.enable();
        // Reset the form
        this.otpNgForm?.resetForm();
      }
      else {
        this.close()
      }
    }, (err) => {
      this._spinner.hide()
      this.alert.message = err ? err.error.message : "Something went wrong. Please try again!"
      this.alert.type = "error"
      this.showAlert = true
      this.loginForm.enable();
    })
  }


  // title = "Create Checklist Item";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }


  otpIdentifier = null
  resendOTP() {
    this._spinner.show()
    this.otpForm.disable();
    this.showAlert = false;
    this._authService.resendOTP(this.otpIdentifier)
      .subscribe(
        (response) => {
          this._spinner.hide()
          this.otpIdentifier = response.otp_identifier
          this.otpForm.enable();
          this.otpNgForm.resetForm();
          this.alert = {
            type: 'success',
            message: response.message ? response.message : 'OTP sent successfully'
          };
          this.showAlert = true;
        },
        (response) => {
          this._spinner.hide()
          this.otpForm.enable();
          this.otpNgForm.resetForm();
          this.alert = {
            type: 'error',
            message: response?.error?.message ? response?.error?.message : 'Something went wrong'
          };
          this.showAlert = true;
        }
      );
  }

  /**
  * Sign in
  */
  submitOTP(): void {
    this.otpForm.markAsDirty()
    // Return if the form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    // Disable the form
    this.otpForm.disable();
    // Hide the alert
    this.showAlert = false;
    // Sign in
    this._authService.submitOTP(this.otpIdentifier, this.otpForm.get('otp').value)
      .subscribe({
        next: (response) => {
          this.close()
        },
        error: (response) => {
          // Re-enable the form
          this.otpForm.enable();
          // Reset the form
          this.otpNgForm.resetForm();
          // Set the alert
          this.alert = {
            type: 'error',
            message: response?.error?.message ? response?.error?.message : 'Invalid OTP'
          };
          // Show the alert
          this.showAlert = true;
        }
      });
  }

  showForgotPassword = false
  submitForgotPasswordMail() {
    this.forgotPasswordForm.markAsDirty()
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this._spinner.show()
    // Disable the form
    this.forgotPasswordForm.disable();
    // Hide the alert
    this.showAlert = false;
    // Sign in
    this._authService.forgotPassword(this.forgotPasswordForm.get('email').value)
      .subscribe({
        next: (response) => {
          this._spinner.hide()
          this.showForgotPassword = false
          this.forgotPasswordForm.enable();
          this.forgotPasswordNgForm.resetForm();
          this.alert = {
            type: 'success',
            message: response.message ? response.message : 'We have sent an email with instructions to reset password.'
          };
          this.showAlert = true;
          setTimeout(() => this.renderGoogleLogin(), 100);
        },
        error: (response) => {
          this._spinner.hide()
          // Re-enable the form
          this.forgotPasswordForm.enable();
          // Reset the form
          this.forgotPasswordNgForm.resetForm();
          // Set the alert
          this.alert = {
            type: 'error',
            message: response?.error?.message ? response?.error?.message : 'Invalid email id'
          };
          // Show the alert
          this.showAlert = true;
        }
      });
  }

  appleLoginListener = (event) => {
    // Handle successful response.     
    this.loginData.token = event.detail.authorization.code
    this.loginData.login_type = "apple_web"
    this.login("apple_web")
  }
  appleLoginErrorListener = (event) => {
    // Handle error.
    this._toast.error("Something went wrong. Please try again!", "Sorry")
  }

  async signInWithApple() {
    try {
      const data = await AppleID.auth.signIn()
      // Handle successful response.
    } catch (error) {
      // Handle error.
      this._toast.error("Apple login not available", "Sorry")
    }
  }

  signUp() {
    this.signUpForm.markAsDirty()
    // Return if the form is invalid
    if (this.signUpForm.invalid) {
      if (this.signUpForm.get("agreeTnC").value == false) {
        this.showAlert = true;
        this.alert.type = "error"
        this.alert.message = "Please agree that you have relevant authorization to use all the media files that you will be uploading."
      }
      return;
    }

    // Disable the form
    this.signUpForm.disable();
    // Hide the alert
    this.showAlert = false;
    // Sign in
    this._authService.signUp(this.signUpForm.value)
      .subscribe({
        next: (response) => {
          this.close()
        },
        error: (response) => {
          // Re-enable the form
          this.signUpForm.enable();
          // Reset the form
          this.signUpNgForm.resetForm();
          // Set the alert
          this.alert = {
            type: 'error',
            message: response?.error?.message ? response?.error?.message : 'Something went wrong. Please try again.'
          };
          // Show the alert
          this.showAlert = true;
        }
      });
  }
}
