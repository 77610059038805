import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { Category, FilterConfiguration, FilterParams, Franchise, Product, Size } from 'app/core/product/product.types';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { MatExpansionModule } from '@angular/material/expansion';
import { Color } from 'app/core/configuration/configuration.types';
@Component({
    selector: 'pwa-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSelectModule,
        MatExpansionModule,
        MatButtonModule,
        RouterModule]

})
export class SearchFilterComponent implements OnInit {

    constructor() { }
    @Output() onFilterChange = new EventEmitter<FilterParams>();

    // close() {
    //   this.onClose.emit();
    // }

    filterParams: FilterParams = new FilterParams()

    @Input() set filter(params: FilterParams) {
        this.filterParams = params
    }

    searchConfig: FilterConfiguration = null

    @Input() set configurations(config: FilterConfiguration) {
        this.searchConfig = config

        this.selectedFranchiseCategory = this.filterParams.franchise_category ? this.filterParams.franchise_category : -1
        this.franchises = this.searchConfig?.businesses?.find(business => business.id == this.filterParams.franchise_category)?.franchises
        this. sizeOpen = this.filterParams?.category_slug != null
    }

    @Input() isGlobalSearch: boolean = false


    // accordianStates
    categoryOpen = true
    subCategoryOpen = false
    sizeOpen = false
    businessOpen = false
    colorOpen = false

    ngOnInit(): void {

    }


    onChangeCategory(category?: Category) {
        if (this.filterParams.category == category?.id)
            return
        this.filterParams.category = category?.id
        this.filterParams.page = 1
        this.filterParams.sub_category = null
        this.filterParams.size = null
        if (!this.isGlobalSearch) {
            // this.filterParams.franchise_category = null
            // this.filterParams.franchise = null
            this.filterParams.colors = []
        }

        this.onFilterChange.emit(this.filterParams)
    }

    onChangeSubCategory(category?: Category) {
        if (this.filterParams.sub_category == category?.id)
            return
        this.filterParams.sub_category = category?.id
        this.filterParams.size = null
        this.filterParams.page = 1
        if (!this.isGlobalSearch) {
            // this.filterParams.franchise_category = null
            // this.filterParams.franchise = null
            this.filterParams.colors = []
        }
        this.onFilterChange.emit(this.filterParams)
    }

    onChangeSize(size?: Size) {
        if (this.filterParams.size == size?.id)
            return
        this.filterParams.size = size?.id
        this.filterParams.page = 1
        if (!this.isGlobalSearch) {
            // this.filterParams.franchise_category = null
            // this.filterParams.franchise = null
            this.filterParams.colors = []
        }
        this.onFilterChange.emit(this.filterParams)
    }

    franchises: Franchise[]
    selectedFranchiseCategory = -1
    onBusinessCategoryChanged(category) {
        if (this.filterParams.franchise_category == category)
            return
        this.filterParams.franchise_category = category == -1 ? null : category
        this.filterParams.franchise = null
        this.filterParams.page = 1
        if (!this.isGlobalSearch) {
            this.filterParams.colors = []
        }
        this.franchises = this.searchConfig?.businesses?.find(business => business.id == category)?.franchises
        this.onFilterChange.emit(this.filterParams)
    }

    onChangeBusiness(franchise?: Franchise) {
        if (this.filterParams.franchise == franchise?.id)
            return
        this.filterParams.franchise = franchise?.id
        this.filterParams.page = 1
        if (!this.isGlobalSearch) {
            this.filterParams.colors = []
        }
        this.onFilterChange.emit(this.filterParams)
    }

    onChangeColor(color: Color) {
        if (this.filterParams.colors == null)
            this.filterParams.colors = []
        let colorIndex = this.filterParams.colors.indexOf(color.id)
        if (colorIndex >= 0) {
            this.filterParams.colors.splice(colorIndex, 1);
        } else {
            this.filterParams.colors.push(color.id)
        }

        this.filterParams.page = 1
        this.onFilterChange.emit(this.filterParams)
    }


}
