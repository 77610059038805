<section class="flex-auto px-4 md:px-8 sm:pt-4 mx-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">Social Media</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <!-- <div class="flex flex-col-reverse md:flex-row items-center gap-8 sm:gap-20  w-full bg-[#f5f2ff] pt-[30px] pl-[20px]  pr-[20px] sm:pr-[50px] sm:pb-0 sm:pl-[50px]"
        style="border-radius:20px;">
        <div>
            <img class="h-[170px] sm:h-[220px]" src="./assets/temp/ssm_service_banner.png"
                style="max-width:fit-content;height:220px;width: auto;">
        </div>
        <div>
            <h1 style="font-weight:700;font-size:23px;">Social Media</h1>
            <p class="mt-2 mb-2 text-black" style="font-weight:400;font-size:14px;">Sed ut perspiciatis unde omnis iste
                natus error sit voluptatem accusantium doloremque laudantium,
                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                sunt explicabo.</p>
        </div>
    </div> -->
    <div class="hidden mt-6 gap-4 justify-end" style="right:0px;top:-20px">
        <button mat-flat-button class="btn_white">
            <div class="flex gap-2 items-center">
                <mat-icon svgIcon="mat_outline:insert_link"></mat-icon>
                <p style="font-size: 12px;;">Connect Social Media</p>
            </div>
        </button>
        <button mat-flat-button (click)="openNewPostPopup()" class="mt-0 btn_normal">
            <div class="items-center justify-center flex gap-2">
                <span style="height:24px;"> <mat-icon svgIcon="heroicons_outline:plus"
                        class="text-white"></mat-icon></span>
                <p style="font-size: 12px;;">New Post</p>
            </div>
        </button>
    </div>
    <!-- tab section starts -->
    <div class="p-0 mt-10 h-auto flex flex-row relative" style="border-radius:8px;">
        <div class="relative w-full">
            <mat-tab-group #tabGroup (selectedTabChange)="onChangeTab($event)">
                <mat-tab *ngFor="let smm of smTabs">
                    <ng-template mat-tab-label>
                        <img [src]="getIcon(smm)" class="mr-2 min-h-3 min-w-3 h-4 w-4" alt="Social media icon">
                        <span class="hidden sm:flex">
                            {{smm | titlecase}}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="accounts.get(smm)?.accounts?.length > 0 ; else noData">

                        <mat-accordion multi="true" class="w-full rounded-none" displayMode="flat">
                            <mat-expansion-panel *ngFor="let account of accounts.get(smm).accounts;let last = last"
                                (opened)="account.open = true" (closed)="account.open = false" [expanded]="account.open"
                                hideToggle class="shadow-none pb-0 mb-0 border-b rounded-none"
                                style="box-shadow: none !important; border-radius: 0px !important;">
                                <mat-expansion-panel-header class="flex flex-row justify-between w-full">
                                    <div class="flex flex-row justify-between w-full">
                                        <mat-panel-title style="text-wrap: nowrap;" class="grow flex flex-row">
                                            <img class="flex radio-bg rounded-full shrink-0 h-10 w-10 mr-3"
                                                [src]="account?.image_url ?? account?.profile_image_url"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div class="truncate w-[100px] sm:w-[240px] md:w-[auto]">
                                                {{account?.name ?? account?.screen_name}}
                                            </div>
                                        </mat-panel-title>
                                        <div class="flex flex-row items-center sm:gap-3">
                                            <div>
                                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                                    aria-label="Page options" (click)="$event.stopPropagation()">
                                                    <mat-icon>more_horiz</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <a mat-menu-item (click)="connectSocialMedia()">Sync Accounts</a>
                                                    <a mat-menu-item (click)="getLinkedinPageSyncUrl()"
                                                        *ngIf="selectedTab == 'linkedin'">Sync Pages</a>
                                                    <a mat-menu-item
                                                        (click)="deleteAccount(smm.key,account)">Disconnect</a>
                                                </mat-menu>
                                            </div>
                                            <mat-icon *ngIf="!account?.open"
                                                svgIcon="mat_outline:keyboard_arrow_down"></mat-icon>
                                            <mat-icon *ngIf="account?.open"
                                                svgIcon="mat_outline:keyboard_arrow_up"></mat-icon>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent *ngIf="account?.pages?.length > 0">
                                    <div *ngFor="let page of account?.pages "
                                        class="flex flex-row items-center gap-3 cursor-pointer pt-1 pb-1">
                                        <img class="flex radio-bg rounded-full shrink-0 h-10 w-10"
                                            [src]="page.image_url"
                                            onError="this.src = 'assets/images/profile-icon.webp'" alt="Profile image">
                                        <div class="text-black mb-0 grow truncate w-[100px] sm:w-[240px] md:w-[auto]">
                                            {{page.title}}
                                        </div>
                                        <div class="mb-0 flex justify-end pr-8">
                                            <p class="text-[#2715bc]" style="font-weight:600;">{{page?.total_posts}}
                                                Post{{page?.total_posts?.length > 1 ? 's' : ''}}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="flex justify-end items-center mt-2">
        <button mat-flat-button class="btn_white" (click)="connectSocialMedia()">
            <div class="flex items-center gap-2">
                <img src="./assets/images/connect_icon.png" class="w-6 h-auto" alt="Connect icon">
                <p style="font-size: 12px;;">Connect Social Media</p>
            </div>
        </button>
    </div>

    <!-- / -->

</section>

<ng-template #noData>
    <div class="flex flex-col items-center w-full p-10 md:p-20">
        <p class="text-black mb-1 text-center" style="font-size:18px;font-weight:500;font-family:poppins">
            There are no accounts
        </p>
        <p class="text-black text-center" style="font-size:13px;font-weight:normal;font-family:poppins">
            Currently, there are no social media accounts connected to your profile. Connect your preferred platforms to
            streamline your social media management process.
        </p>
    </div>
</ng-template>