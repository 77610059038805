<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','autofill-manager']">Autofill Manager</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">{{groupDetails?.name}}</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div *ngIf="groupDetails?.added_to_cart" class="mb-10">
        Product Added to Cart
    </div>
    <div class="flex flex-col mt-10 gap-6 w-full bg-white card shadow-md border border-color-[#dedeff]"
        style="border-radius:20px;">
        <div class="flex flex-row gap-3 sm:gap-6 items-center p-6 pb-0">
            <div
                class="hidden md:flex w-10 h-10 shrink-0 rounded-full bg-[#5040dd] text-xl text-white justify-center items-center">
                {{groupDetails?.name.substring(0, 1)}}
            </div>
            <div class="flex flex-col grow">
                <div class="text-black text-lg font-bold">
                    {{groupDetails?.name}}
                </div>
                <div class="text-black text-md">
                    {{groupDetails?.added_at | date:'MMM dd, YYYY h:mm a'}}
                </div>
                <div class="text-black text-md sm:hidden">
                    {{groupDetails?.contacts}} Contact{{groupDetails?.contacts > 1 ? 's' : ''}}
                </div>
            </div>
            <div class="text-black text-md hidden sm:flex" *ngIf="groupDetails?.version_history">
                {{groupDetails?.contacts}} Contact{{groupDetails?.contacts > 1 ? 's' : ''}}
            </div>
            <button class="p-4 pb-2 pt-2 hidden sm:flex btn_normal" (click)="addContact()"
                *ngIf="groupDetails?.added_to_cart == false && groupDetails?.version_history">
                <div class="hidden md:flex items-center gap-1"><mat-icon svgIcon="add"
                        class="text-white hidden sm:flex"></mat-icon>Add Contact</div>
                <div class="flex md:hidden">Add Contact</div>
            </button>
        </div>
        <div class="pl-6 pr-6">
            {{groupDetails?.description}}
        </div>
        <div class="border-b"></div>

        <div class="flex flex-col sm:flex-row pl-6 pr-6 gap-6" *ngIf="groupDetails?.version_history">
            <div class="bg-[#f5f5f5] overflow-hidden rounded-lg cursor-zoom-in w-full h-50 sm:w-32 sm:h-20"
                (click)="openImage(groupDetails?.product_image)">
                <img [src]="groupDetails?.product_image" class="w-full h-full object-contain"
                    [alt]="groupDetails?.product_name" style="border: solid 1px #f7f9ff;">
            </div>
            <div class="flex flex-col gap-1 grow">
                <div class="text-lg font-bold text-black">
                    {{groupDetails?.product_name}}
                </div>
                <div>
                    {{groupDetails?.option_titles?.join(' | ')}}
                </div>
                <div class="text-[#3723dd] font-bold text-lg">Price ${{groupDetails?.total_cost}}
                </div>
            </div>
            <button class="p-4 pb-2 pt-2 h-fit self-center btn_normal"
                [routerLink]="['/','autofill-manager',groupDetails?.id,'autofill']"
                *ngIf="groupDetails?.is_completed == false && groupDetails?.autofill_mapping_completed == false">
                Autofill Mapping
            </button>
            <button class="p-4 pb-2 pt-2 h-fit self-center btn_normal" (click)="proceedOrder()"
                *ngIf="groupDetails?.added_to_cart == false && groupDetails?.is_completed == true">
                Proceed to Order
            </button>
        </div>

        <div class="border-b ml-6 mr-6" *ngIf="groupDetails?.version_history"></div>

        <button class="p-4 pb-2 pt-2 w-fit self-center sm:hidden btn_normal flex gap-1 items-center"
            (click)="addContact()" *ngIf="groupDetails?.added_to_cart == false && groupDetails?.version_history">
            <mat-icon svgIcon="add" class="text-white flex sm:hidden"></mat-icon> Add Contact
        </button>

        <ng-container *ngIf="contacts?.length > 0; else noData">
            <div *ngFor="let contact of contacts; let last = last" class="flex flex-col pl-6 pr-6">
                <div class="flex flex-row gap-3 sm:gap-6 items-center">
                    <!-- <div
                        class="w-10 h-10 shrink-0 rounded-full bg-[#5040dd] text-xl text-white flex justify-center items-center">
                        {{group.name.substring(0, 1)}}
                    </div> -->
                    <div class="flex flex-col grow gap-1 cursor-pointer" [routerLink]="['contact',contact?.id]">
                        <div class="text-black text-lg font-bold">
                            {{contact.name}}
                        </div>
                        <div class="text-black text-md">
                            {{contact.email}}
                        </div>
                        <div class="text-black text-md sm:hidden flex flex-row gap-1 items-center">
                            <div style="width:13px;height:13px;border-radius:50px;"
                                [style.background]="contact.is_completed ? '#059e75': '#ff6f6f'"></div>
                            <span style="font-size: 12px;margin:0px;" class="text-black">
                                {{contact.is_completed ? 'Completed' : 'Pending'}}</span>
                        </div>
                    </div>
                    <div class="text-black text-md hidden sm:flex flex-row gap-1  items-center cursor-pointer"
                        [routerLink]="['contact',contact?.id]">
                        <div style="width:13px;height:13px;border-radius:50px;"
                            [style.background]="contact.is_completed ? '#059e75': '#ff6f6f'"></div>
                        <span style="font-size: 12px;margin:0px;" class="text-black">
                            {{contact.is_completed ? 'Completed' : 'Pending'}}</span>
                    </div>
                    <button [matMenuTriggerFor]="menu" class="flex ml-6" *ngIf="groupDetails?.added_to_cart == false">
                        <mat-icon svgIcon="mat_outline:menu"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="contact.is_completed == true"
                            (click)="resetContact(contact)">Reset</button>
                        <button mat-menu-item (click)="deleteContact(contact)">Delete</button>
                    </mat-menu>
                </div>
                <div class="border-b mt-6" *ngIf="!last"></div>
            </div>
        </ng-container>

        <div class="flex justify-center w-full pb-6">
            <a *ngIf="!showLoading && hasMoreItems" class="flex flex-col p-1 rounded-2xl mt-5 cursor-pointer"
                (click)="loadNextPage()">
                <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                    <div class="truncate">Load More</div>
                </div>
            </a>
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" *ngIf="showLoading">
            </mat-progress-spinner>
        </div>
    </div>



</section>

<ng-template #noData>
    <div class="max-w-200 flex flex-col items-center self-center mt-6 mb-6 pl-4 pr-4">
        <p class="text-black text-center mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
            {{ groupDetails?.version_history != null ? 'You haven\'t saved any contacts yet' : 'You haven\'t chose any
            design' }}
        </p>
        <p class="text-black text-center" style="font-size:13px;font-weight:normal;font-family:poppins">
            <ng-container *ngIf=" groupDetails?.version_history != null; else newDeb">
                Oops! It seems there are no contacts saved yet. Start by adding your contacts here.
            </ng-container>
            <ng-template #newDeb>
                It appears there is no design selected yet. Start by adding your design here
            </ng-template>
        </p>
        <div class="flex mt-6">
            <button class="block btn_white m-auto" (click)="addContact()" *ngIf="groupDetails?.version_history">
                Add Contact
            </button>
            <button class="block btn_white m-auto" (click)="chooseDesign()" *ngIf="!groupDetails?.version_history">
                Choose Design
            </button>
        </div>
    </div>
    <!-- / -->
</ng-template>