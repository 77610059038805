import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { PWAConfiguration } from './configuration.types';
import { FranchiseCategory } from '../product/product.types';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

    constructor(private _httpClient: HttpClient) {
    }

    private _configuration: BehaviorSubject<PWAConfiguration> = new BehaviorSubject(null);
    get configuration$(): Observable<PWAConfiguration> {
        return this._configuration.asObservable();
    }

    getConfigurations(): Observable<PWAConfiguration> {
        return this._httpClient.get<PWAConfiguration>(`${BASE_URL}product/configurations/`).pipe(
            tap((config) => {
                this._configuration.next(config);
            }),
        );
    }

    private _franchises: BehaviorSubject<FranchiseCategory[]> = new BehaviorSubject(null);
    get franchises$(): Observable<FranchiseCategory[]> {
        return this._franchises.asObservable();
    }

    getFranchises(): Observable<FranchiseCategory[]> {
        return this._httpClient.get<FranchiseCategory[]>(`${BASE_URL}product/categories/franchises/`).pipe(
            tap((config) => {
                this._franchises.next(config);
            }),
        );
    }

    getCategoryFranchises(categoryId): Observable<FranchiseCategory[]> {
        return this._httpClient.get<FranchiseCategory[]>(`${BASE_URL}product/categories/${categoryId}/franchise-category/franchises/`).pipe(
            tap((config) => {
                this._franchises.next(config);
            }),
        );
    }


    search(keyword): Observable<any> {
        let categories = this._configuration.value.print_categories.concat(this._configuration.value.digital_categories)
        let result = []

        categories.forEach(category => {
            let resultCategory = {
                id: category.id,
                title: category.title,
                sub_title: category.sub_title,
                sub_categories: category.sub_categories,
                image: category.image
            }

            let subCategories = category?.sub_categories?.filter(subCat => subCat.title?.toLowerCase()?.includes(keyword?.toLowerCase()))
            if (subCategories != null && subCategories?.length > 0) {
                resultCategory.sub_categories = subCategories
                result.push(resultCategory)
            }
        })

        return of(result)
    }

}
