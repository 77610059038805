import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { DigitalPlan, PlanGroup, PlanGroupResponse, Subscription } from './subscription.types';
export type PaymentType = "monthly" | "yearly";
@Injectable({ providedIn: 'root' })
export class SubscriptionService {

    constructor(private _httpClient: HttpClient) {
    }

    private _plans: ReplaySubject<DigitalPlan[]> = new ReplaySubject<DigitalPlan[]>(1);
    get plans$(): Observable<DigitalPlan[]> {
        return this._plans.asObservable();
    }

    getSubscriptionPlans(paymentType: PaymentType): Observable<DigitalPlan[]> {
        return this._httpClient.get<DigitalPlan[]>(`${BASE_URL}subscription/digital/plans/?payment_type=${paymentType}`).pipe(
            tap((plans) => {
                this._plans.next(plans);
            }),
        );
    }
    
    private _planGroups: ReplaySubject<PlanGroup[]> = new ReplaySubject<PlanGroup[]>(1);
    get planGroups$(): Observable<PlanGroup[]> {
        return this._planGroups.asObservable();
    }

    getPlanGroups(): Observable<PlanGroupResponse> {
        return this._httpClient.get<PlanGroupResponse>(`${BASE_URL}subscription/digital/groups/?batch_size=100`).pipe(
            tap((plans) => {
                this._planGroups.next(plans?.data);
            }),
        );
    }

    private _subscriptions: ReplaySubject<Subscription[]> = new ReplaySubject<Subscription[]>(1);
    get subscriptions$(): Observable<Subscription[]> {
        return this._subscriptions.asObservable();
    }
    getSubscriptions(): Observable<Subscription[]> {
        return this._httpClient.get<Subscription[]>(`${BASE_URL}subscription/digital/`).pipe(
            tap((plans) => {
                this._subscriptions.next(plans);
            }),
        );
    }

    cancelSubscription(plan) {
        return this._httpClient.delete(`${BASE_URL}subscription/digital/${plan}/`)
    }

    subscribe(data) {
        return this._httpClient.post(`${BASE_URL}subscription/digital/`, data)
    }

    getManageSubscriptionUrl(planId) {
        return this._httpClient.post<any>(`${BASE_URL}subscription/customer-portal/`, {})
    }

    getPromoCodes() {
        return this._httpClient.get<any>(`${BASE_URL}subscription/coupon/`, {})
    }
}
