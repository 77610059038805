import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AutofillManagerService } from '../../autofill-manager/autofill-manager.service';
import { ProductService } from 'app/core/product/product.service';

export const lockFieldsDataResolver = (route: ActivatedRouteSnapshot) => {
    const _autofillManagerService = inject(AutofillManagerService);
    const _productService = inject(ProductService);
    const requestArray: Observable<any>[] = [_productService.getAutofillMapping(route.params['id']),
    _autofillManagerService.getMappingData(), _productService.getProductVersionDetails(route.params['id'])]
    return forkJoin(requestArray);
};
