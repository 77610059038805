<mat-dialog-actions class="flex justify-between pl-4 mb-1 w-full">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-0">

    <pwa-search-filter class="flex w-full" [filter]="filterParams" [configurations]="filterConfig"
        (onFilterChange)="onFilterChange($event)"></pwa-search-filter>


</mat-dialog-content>
<mat-dialog-actions class="p-4">
    <!-- Submit button -->
    <button class="btn_normal w-100 mt-3" mat-flat-button [color]="'primary'" type="submit"
        (click)="submit()">
        Submit
    </button>
</mat-dialog-actions>