<mat-accordion multi="true" class="w-full mt-1">
    <!-- Categories -->
    <mat-expansion-panel *ngIf="filterParams.category_slug == null" (opened)="categoryOpen = true" (closed)="categoryOpen = false" [expanded]="categoryOpen"
        hideToggle class="shadow-none mb-0" style="box-shadow: none !important;">
        <mat-expansion-panel-header class="p-0">
            <mat-panel-title style="text-wrap: nowrap;" class="grow">
                Category
            </mat-panel-title>
            <mat-panel-description style="position: absolute;right:0;">
                <mat-icon *ngIf="!categoryOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                <mat-icon *ngIf="categoryOpen" svgIcon="heroicons_outline:minus"></mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div (click)="onChangeCategory(null)" class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.category == null">
                    </div>
                </div>
                <div class="text-black mb-0">
                    All
                </div>
            </div>
            <div (click)="onChangeCategory(category)" *ngFor="let category of searchConfig?.categories "
                class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.category == category.id">
                    </div>
                </div>
                <div class="text-black mb-0">
                    {{category.title}}
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>


    <div class="border-b" *ngIf="searchConfig?.sub_categories?.length > 0 && filterParams.category_slug == null"></div>
    <!-- Sub Categories -->
    <mat-expansion-panel *ngIf="(filterParams.sub_category_slug == null) && (searchConfig?.sub_categories?.length > 0)" (opened)="subCategoryOpen = true" (closed)="subCategoryOpen = false"
        [expanded]="subCategoryOpen" hideToggle
        class="shadow-none mb-0" style="box-shadow: none !important;">
        <mat-expansion-panel-header class="p-0">
            <mat-panel-title style="text-wrap: nowrap;" class="grow">
                Sub Category
            </mat-panel-title>
            <mat-panel-description style="position: absolute;right:0;">
                <mat-icon *ngIf="!subCategoryOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                <mat-icon *ngIf="subCategoryOpen" svgIcon="heroicons_outline:minus"></mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div (click)="onChangeSubCategory(null)" class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.sub_category == null">
                    </div>
                </div>
                <div class="text-black mb-0">
                    All
                </div>
            </div>
            <div (click)="onChangeSubCategory(category)" *ngFor="let category of searchConfig?.sub_categories"
                class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.sub_category == category.id">
                    </div>
                </div>
                <div class="text-black mb-0">
                    {{category.title}}
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <hr  *ngIf="(filterParams.sub_category_slug == null) && (searchConfig?.sub_categories?.length > 0) && (searchConfig?.sizes?.length > 0)">

    <!-- Sizes -->
    <mat-expansion-panel (opened)="sizeOpen = true" (closed)="sizeOpen = false" *ngIf="searchConfig?.sizes?.length > 0"
        [expanded]="sizeOpen" hideToggle class="shadow-none mb-0" style="box-shadow: none !important;">
        <mat-expansion-panel-header class="p-0">
            <mat-panel-title style="text-wrap: nowrap;" class="grow">
                Sizes
            </mat-panel-title>
            <mat-panel-description style="position: absolute;right:0;">
                <mat-icon *ngIf="!sizeOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                <mat-icon *ngIf="sizeOpen" svgIcon="heroicons_outline:minus"></mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div (click)="onChangeSize(null)" class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.size == null">
                    </div>
                </div>
                <div class="text-black mb-0">
                    All
                </div>
            </div>
            <div (click)="onChangeSize(size)" *ngFor="let size of searchConfig?.sizes"
                class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.size == size.id">
                    </div>
                </div>
                <div class="text-black mb-0">
                    {{size.title}}
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <hr *ngIf="searchConfig?.businesses?.length > 0">

    <!-- Business -->
    <mat-expansion-panel (opened)="businessOpen = true" (closed)="businessOpen = false"
        *ngIf="searchConfig?.businesses?.length > 0" [expanded]="businessOpen" hideToggle class="shadow-none mb-0"
        style="box-shadow: none !important;">
        <mat-expansion-panel-header class="p-0">
            <mat-panel-title style="text-wrap: nowrap;" class="grow">
                Business
            </mat-panel-title>
            <mat-panel-description style="position: absolute;right:0;">
                <mat-icon *ngIf="!businessOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                <mat-icon *ngIf="businessOpen" svgIcon="heroicons_outline:minus"></mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <mat-form-field class="w-full">
                <mat-select placeholder="Business" [(ngModel)]="selectedFranchiseCategory"
                    (selectionChange)="onBusinessCategoryChanged($event.value)">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option *ngFor="let business of searchConfig?.businesses"
                        [value]="business.id">{{business.franchise_category}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div (click)="onChangeBusiness(franchise)" *ngFor="let franchise of franchises"
                class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.franchise == franchise.id">
                    </div>
                </div>
                <div class="text-black mb-0">
                    {{franchise.name}}
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <hr *ngIf="searchConfig?.colors?.length > 0">

    <!-- Colors -->
    <mat-expansion-panel (opened)="colorOpen = true" (closed)="colorOpen = false"
        *ngIf="searchConfig?.colors?.length > 0" [expanded]="colorOpen" hideToggle class="shadow-none mb-0"
        style="box-shadow: none !important;">
        <mat-expansion-panel-header class="p-0">
            <mat-panel-title style="text-wrap: nowrap;" class="grow">
                Colors
            </mat-panel-title>
            <mat-panel-description style="position: absolute;right:0;">
                <mat-icon *ngIf="!colorOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                <mat-icon *ngIf="colorOpen" svgIcon="heroicons_outline:minus"></mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="grid grid-cols-7 sm:grid-cols-6 gap-1">
                <div class="aspect-square border rounded-lg" (click)="onChangeColor(color)"
                    *ngFor="let color of searchConfig?.colors" [style.background-color]="color.code"
                    [style.border-color]="this.filterParams.colors?.includes(color.id) ? '#000' : '#f5f5f5'">

                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>