<mat-dialog-actions style="padding:0px 16px">
    <button class="justify-end" mat-button (click)="close()" style="width:-webkit-fill-available">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:18px;height:18px;object-fit: contain;">
    </button>
    <div  class="justify-self-start text-lg sm:text-[22px] font-extrabold mb-0 pb-0" style="width:-webkit-fill-available">{{title}}</div>

    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pb-2 pt-4">

    <div class="flex flex-col w-full gap-2">
        <ng-container *ngIf="paymentCards?.length > 0 ; else noCard">

            <div class="grid grid-cols-1 gap-2 "
                style="overflow: auto;">
                <div *ngFor="let card of paymentCards"
                    class="flex flex-row gap-6 cursor-pointer items-center bg-[#f8f7ff] p-6 pt-2 pb-2"
                    (click)="selectedBillingCard = card" style="border-radius:8px;">
                    <div class="flex radio-bg rounded-full shrink-0 ">
                        <div class="radio-selected rounded-full shrink-0" *ngIf="selectedBillingCard?.id == card.id">
                        </div>
                    </div>
                    <div class="flex items-center">
                        <img [src]="'./assets/images/card/'+card.brand+'.png'"
                            onerror="this.src='./assets/images/card/credit_card.png'"
                            style="width:40px;height:40px;object-fit: contain;" alt="Card image">
                        <span class="ml-4" style="font-size:12px;">xxxx xxxx xxxx
                            {{card.last4}}</span>
                    </div>
                </div>
            </div>


          
        </ng-container>
    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0 flex justify-center sm:justify-end gap-3 mt-3" *ngIf="paymentCards?.length > 0">
    <button class="btn_white w-full sm:w-50" mat-flat-button type="submit" (click)="addCard()">
        Add New Card
    </button>
    <button mat-flat-button class="mt-0 w-full sm:w-50 btn_normal ml-0" type="submit" (click)="submit()">
        Submit
    </button>
</mat-dialog-actions>

<ng-template #noCard>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any cards yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Looks like you haven't added any cards yet. Add a new card to continue.
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addCard()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add New Card
        </button>
    </div>
    <!-- / -->
</ng-template>