<mat-dialog-actions class="flex justify-between pl-0 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <!-- <div class="justify-self-start text-2xl font-extrabold">{{title}}</div> -->
    <div class="justify-self-start flex items-center">
        <h1 class="text-black" style="font-weight:700;font-size:20px;">Manage Tags </h1>
    </div>
    <button class="flex w-auto justify-end items-center mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<div class="mt-4 mb-4" style="border-bottom:1px solid #d5d5d5;"></div>
<mat-dialog-content class="p-0 w-full max-w-full">
    <div class="flex flex-row items-center gap-4">
        <!-- Content starts -->
        <form class="w-full " [formGroup]="tagForm" #tagNgForm="ngForm">
            <mat-form-field class="w-full">
                <mat-label>Create New Tag</mat-label>
                <input id="tagname" matInput type="text" [formControlName]="'tag'" class="text-black">
                <mat-error *ngIf="tagForm.get('tag').hasError('required')">
                    Tag name shouldn't be empty
                </mat-error>
            </mat-form-field>
        </form>
        <button class="btn_normal" type="submit" (click)="createTag()">Add</button>
        <!-- Content ends -->
    </div>
    <form class="w-full " [formGroup]="tagUpdateForm" #tagUpdateNgForm="ngForm" *ngIf="data?.versionId != null">
        <mat-form-field class="w-full">
            <mat-label>Select Tag</mat-label>
            <mat-select name="selectedTag" [formControlName]="'tag'">
                <mat-option *ngFor="let tag of tags" [value]="tag.id">
                    {{tag.tag}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="tagUpdateForm.get('tag').hasError('required')">
                Please choose a tag
            </mat-error>
        </mat-form-field>
    </form>
    <!-- Alert -->
    <fuse-alert class="col-span-1 md:col-span-2" class="mt-1" *ngIf="showAlert" [appearance]="'outline'"
        [showIcon]="false" [type]="alert.type" [dismissible]="true">
        {{alert.message}}
    </fuse-alert>
    <ng-container *ngIf="data?.versionId == null">
        <div class="flex items-center mt-4" *ngFor="let tag of tags">
            <p class="text-black grow">{{tag.tag}}</p>
            <div class="flex gap-4 justify-end">
                <mat-icon class="text-black cursor-pointer" svgIcon="heroicons_outline:pencil-square"></mat-icon>
                <mat-icon class="text-black cursor-pointer" svgIcon="heroicons_outline:trash"
                    (click)="deleteTag(tag)"></mat-icon>
            </div>
        </div>
    </ng-container>


    <p *ngIf="tags?.length == 0" style="padding: 50px;text-align: center;">
        There are no tags
    </p>

    <div class="flex justify-center">
        <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </div>

    <pwa-pagination class="flex justify-center w-full justify-self-center mt-10 mb-8"
        *ngIf="pagination?.total_pages > 1" (onPageChange)="getTags($event)" [pagination]="pagination"></pwa-pagination>

</mat-dialog-content>
<mat-dialog-actions class="pb-4 pr-0 pt-2 pl-2 justify-end mt-4" *ngIf="data?.versionId != null">
    <!-- Submit button -->
    <button class="btn_normal w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="tagUpdateForm.disabled" (click)="updateTag()" form="tagUpdateForm">
        <span *ngIf="!tagUpdateForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="tagUpdateForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>