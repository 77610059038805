import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrganizationAdmin } from 'app/core/organization/organization.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule, MatDatepickerModule,
    CommonModule, MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CreateEmployeeComponent implements OnInit {

  @ViewChild('employeeNgForm') employeeNgForm: NgForm;
  employeeForm: UntypedFormGroup;
  today = new Date()
  designations: string[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrganizationAdmin,
    private _organizationService: OrganizationService,
    private _toast: ToastrService,
    private fb: UntypedFormBuilder, private _userService: UserService,
    private _dialogRef: MatDialogRef<CreateEmployeeComponent>) {
    if (data == null)
      this.title = "Create Employee"
  }

  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      designation: ['', [Validators.required]],
      expiry: ['', []],
      custom_designation: ['', []]
    });
    this.getDesignationList()
  }

  getDesignationList() {
    this._userService.getDesignationList().subscribe({
      next: res => {
        this.designations = res.designation_list
        this.designations.push("Custom")
      },
      error: error => {
        this._toast.error("Unable to get designation list. Please try again!", "Sorry")
        this.close()
      }
    })
  }

  hasDateError = false
  onDateChanged(data) {
    let date: Date = new Date(this.employeeForm.value['expiry'])
    this.hasDateError = date.getTime() < this.today.getTime()
  }

  title = "Update Employee";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.employeeForm.markAllAsTouched();
    if (this.employeeForm.invalid) {
      return;
    }

    if (this.hasDateError) {

      return
    }

    this.employeeForm.get('expiry').hasError('matDatepicker')

    const formData = new FormData();
    const formValue = this.employeeForm.value;
    Object.keys(formValue).forEach((key) => {
      formData.append(key, formValue[key]);
    });

    if (this.showCustomDesignation) {
      formData.set('designation', formData.get('custom_designation'))
    }

    let date = new Date(this.employeeForm.value['expiry'])
    if (date.getTime() > this.today.getTime()) {
      formData.set('expiry', date.toISOString())
    }
    else {
      formData.delete('expiry')
    }

    this.employeeForm.disable()
    let request = (this.data == null) ? this._organizationService.addAdmin(formData) : this._organizationService.updateAdmin(this.data.id, formData)
    request.subscribe({
      next: (response: any) => {
        this.employeeForm.enable()
        this.responseMessage = response.message ? response.message : `Employee has been ${this.data == null ? 'created' : 'updated'}`
        this.close()
      },
      error: error => {
        this.employeeForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  showCustomDesignation = false
  onDesignationChanged() {
    this.showCustomDesignation = this.employeeForm.get('designation').value == "Custom"
    this.employeeForm.controls['custom_designation'].setValidators(this.showCustomDesignation ? [Validators.required] : []);
    this.employeeForm.controls['custom_designation'].updateValueAndValidity();
  }

}
