import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { Product } from 'app/core/product/product.types';
import { forIn } from 'lodash';
import { Pagination } from 'app/core/api/pagination.type';
@Component({
    selector: 'pwa-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule,
        RouterModule]

})
export class PWAPagination implements OnInit {

    constructor() { }
    // @Output() onClose = new EventEmitter<void>();

    // close() {
    //   this.onClose.emit();
    // }

    selectedPage = 0

    @Input() totalPages: number = 0
    @Input() totalCount: number = 0
    @Input() set pagination(pagination: Pagination) {
        this.selectedPage = pagination?.current_page - 1
        this.totalPages = pagination?.total_pages - 1
        this.calculateVisiblePages()
    }

    @Output() onPageChange = new EventEmitter<number>();

    displayLimit = 5
    hiddenIndex = 4

    showMoreRight = false
    showMoreLeft = false

    ngOnInit(): void {
        this.calculateVisiblePages()
    }

    visiblePages = []
    calculateVisiblePages() {

        if (this.totalPages < 5) {
            this.showMoreRight = false
            this.visiblePages = []
            for (let i = 0; i <= this.totalPages; i++) {
                this.visiblePages.push(i)
            }
            this.showMoreLeft = false
        } else if ((this.selectedPage + 3) > this.totalPages) {
            this.visiblePages = [this.totalPages - 4, this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages]
            this.showMoreRight = false
            this.showMoreLeft = this.totalPages > 4
        } else if (0 > (this.selectedPage - 3)) {
            this.visiblePages = [0, 1, 2, 3, 4]
            this.showMoreRight = this.totalPages > 4
            this.showMoreLeft = false
        } else {
            this.visiblePages = [this.selectedPage - 2, this.selectedPage - 1, this.selectedPage, this.selectedPage + 1, this.selectedPage + 2]

            this.showMoreRight = this.totalPages > 4
            this.showMoreLeft = this.totalPages > 4
        }
    }

    changePage(page: number) {
        if ((this.selectedPage + 1) == page)
            return
        this.onPageChange.emit(page)
        this.selectedPage = page - 1;
        this.calculateVisiblePages()
    }

}
