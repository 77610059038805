import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { VersionHistory } from 'app/core/product/product.types';
import { GlobalService } from 'app/core/auth/global.service';
import { EDITOR_URL } from 'environments/environment';
import { ProductService } from 'app/core/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CartService } from 'app/core/cart/cart.service';
import { MatMenuModule } from '@angular/material/menu';
import { AddToCartComponent } from '../add-to-cart/add-to-cart.component';
import { UserService } from 'app/core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { CloneHistoryComponent } from '../clone-history/clone-history.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TransferHistoryComponent } from '../transfer-history/transfer-history.component';
import { NewPostPopup } from '../../social-media/new-post/new-post.component';
import { UploadDesignPopup } from '../upload-design-popup/upload-design.component';
@Component({
    selector: 'app-version-history',
    templateUrl: './version-history.component.html',
    styleUrls: ['./version-history.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule,
        MatFormFieldModule, MatSelectModule, MatTabsModule, MatIconModule, MatMenuModule,
        MatSidenavModule, MatButtonModule, RouterModule, MatRadioModule, MatCheckboxModule, NgOptimizedImage]
})
export class VersionHistoryComponent implements OnInit {
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;

    needToAutofillDetails = false
    autofillType: ('contacts' | 'manager' | 'self') = 'contacts'

    histories: VersionHistory[]
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { versionId, histories: VersionHistory[], options, category, subCategory, isForDesignSelection: false },
        private _userService: UserService, private _globalService: GlobalService,
        private _productService: ProductService, private _toast: ToastrService, private _matDialog: MatDialog,
        private _spinner: NgxSpinnerService, private _confirmation: FuseConfirmationService,
        private _router: Router, private _localStorageService: LocalStorageService,
        private _dialogRef: MatDialogRef<VersionHistory>
    ) {
        this.histories = data?.histories
        if (this.histories?.length > 0) {
            this.selectedHistory = this.histories[0]

        }
    }

    selectedHistory: VersionHistory = null
    ngOnInit(): void {
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
                this.isOrganizationAccount = this._localStorageService.accountType == 'organization'

                this.isOrganizationAdmin = this._localStorageService.isOrganizationOwner
                this.isPWADesigner = this._localStorageService.isPWADesigner
            })
    }
    isOrganizationAccount = false
    isOrganizationAdmin = false
    isPWADesigner = false

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.stopCheckingDesignUpdates();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    //Close modal with a response message
    responseMessage = "";
    close() {
        // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInLeft")
        // document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
        // setTimeout(() => { this._dialogRef.close(); }, 1000);
        this._dialogRef.close();
    }

    openEditor() {
        this._globalService.setCookie()
        window.open(`${EDITOR_URL}/?id=` + this.selectedHistory?.designs[this.tab.selectedIndex].id, '_blank');
        this.startCheckingUpdates()
    }

    deleteHistory(historyId) {
        const dialogRef = this._confirmation.open({
            title: "Delete Version",
            message: `Are you sure you want to delete this version?`,

        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == 'confirmed')
                this.proceedDeleteHistory(historyId)
        });
    }

    proceedDeleteHistory(historyId) {
        this._spinner.show();
        this._productService.deleteHistory(historyId).subscribe({
            next: (res: any) => {
                this._spinner.hide();
                let isUserProduct = this.selectedHistory?.is_user_product
                this.histories = this.histories.filter(version => version.id !== historyId)
                if (this.histories.length == 0) {
                    {
                        if (isUserProduct) {
                            this._toast.success('All histories has been deleted', "Success")
                            this.close()
                        }
                        this.selectedHistory = null
                    }
                } else if (this.selectedHistory.id == historyId) {
                    this.selectedHistory = this.histories[0]
                }
                this._toast.success(res.message ? res.message : 'Version has been removed successfully', "Success")
            },
            error: (err) => {
                this._spinner.hide();
                this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
            }
        })
    }

    deleteDesign() {
        const dialogRef = this._confirmation.open({
            title: "Delete Design",
            message: `Are you sure you want to delete this design?`,

        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == 'confirmed')
                this.proceedDeleteDesign(this.selectedHistory?.designs[this.tab.selectedIndex].id)
        });
    }

    proceedDeleteDesign(designId) {
        this._spinner.show();
        this._productService.deleteDesign(designId).subscribe({
            next: (res: any) => {
                this._spinner.hide();
                this.selectedHistory.designs = this.selectedHistory.designs.filter(design => design.id !== designId)
                this._toast.success(res.message ? res.message : 'Design has been removed successfully', "Success")
            },
            error: (err) => {
                this._spinner.hide();
                this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
            }
        })
    }

    createVersionHistory() {
        const dialogRef = this._confirmation.open({
            title: "Create Version History",
            message: `Do you want to autofill your details?`,
            actions: { cancel: { label: 'No' }, confirm: { label: "Yes" } }
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.continueCreateVersionHistory(result == 'confirmed')
        });
    }

    continueCreateVersionHistory(autofillSelfData: boolean) {
        this._spinner.show()
        this._productService.createVersionHistory(this.data?.versionId, true).subscribe({
            next: (version: VersionHistory) => {
                this._spinner.hide()
                this.histories = [version].concat(this.histories)
                this.selectedHistory = version
            },
            error: error => {
                this._spinner.hide()
                this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
            }
        })
    }

    autofillMyDetails() {
        const dialogRef = this._confirmation.open({
            title: "Autofill My Details",
            message: `You need to create a version history to autofill your details. Would you like to create a new version history?`,
            actions: { cancel: { label: 'No' }, confirm: { label: "Yes" } }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result == 'confirmed') {
                this.continueCreateVersionHistory(true)
            }

        });
    }


    interval
    startCheckingUpdates() {
        this.stopCheckingDesignUpdates()
        this.interval = setInterval(() => {
            this.checkDesignUpdates()
        }, 3000)
    }


    checkDesignUpdates() {
        if (this.selectedHistory == null)
            return
        this._productService.getDesignUpdateStatus(this.selectedHistory.id, this.selectedHistory.last_edited_time).subscribe((res) => {
            if (res.update_available == true) {
                this.selectedHistory.last_edited_time = res.last_edited_time;
                this.selectedHistory.designs = res.designs;
            }
        })

    }

    stopCheckingDesignUpdates() {
        clearInterval(this.interval);
    }

    onClickEmployeeAccess() {
        if (this.selectedHistory?.is_user_product) {
            this._router.navigate(['/', 'organization', 'product', this.data?.versionId, 'employee-access'])
            this._matDialog.closeAll()
        }
        else {
            const dialogRef = this._confirmation.open({
                title: "Clone Design",
                message: "This template is publicly available. In order to share it with employees, you need to create a custom template by cloning this design. Do you wish to clone now?"
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result == 'confirmed') {
                    this.showCloneHistoryModal()
                }
            });
        }
    }

    showCloneHistoryModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { historyId: this.selectedHistory.id, name: "", versionId: this.data?.versionId };
        this._matDialog.open(CloneHistoryComponent, dialogConfig)
    }

    openPlaceYourOrder() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = 'calc(100%)'
        dialogConfig.maxWidth = '100%'
        dialogConfig.position = { right: '0px', top: '0px' }
        dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'lg:w-auto', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
        dialogConfig.data = {
            autofillType: this.needToAutofillDetails ? this.autofillType : null,
            historyId: this.selectedHistory.id,
            historyItem: this.selectedHistory,
            options: this.data?.options,
            versionId: this.data?.versionId,
            isForDesignSelection: this.data?.isForDesignSelection,
            autofillDetails: this.needToAutofillDetails && this.autofillType == 'self'
        };
        this._matDialog.open(AddToCartComponent, dialogConfig)
            .afterClosed().subscribe(value => {
                if (value != null) {
                    this._dialogRef.close(value)
                }
            });
    }

    openTransferHistory() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { historyId: this.selectedHistory.id, versionId: this.data?.versionId };
        this._matDialog.open(TransferHistoryComponent, dialogConfig)
    }

    openNewPostPopup() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { versionHistory: this.selectedHistory }
        dialogConfig.panelClass = ['w-full', 'max-w-200', 'h-full']
        this._matDialog.open(NewPostPopup, dialogConfig).afterClosed().subscribe(value => {
            if (value != null && value.length > 0) {
                this._toast.success(value)
            }
        });
    }

    openUploadDesign(isCreate) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { versionId: this.data?.versionId, versionHistory: this.selectedHistory?.id, category: this.data?.category, subCategory: this.data?.subCategory, createType: isCreate ? 'create' : 'upload' };
        this._matDialog.open(UploadDesignPopup, dialogConfig)
            .afterClosed().subscribe(value => {

                if (value != null && value.length > 0) {
                    this.histories = value
                    this.selectedHistory = this.histories[0]
                }
            });
    }
}
