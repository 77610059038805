import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { SearchService } from './search.service';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';

export const smyLibraryDataResolver = (route: ActivatedRouteSnapshot) => {
    const _searchService = inject(SearchService);
    const _productService = inject(ProductService);
    const filter = new FilterParams()
    filter.category = route.queryParams['category'] ?? null
    filter.sub_category = route.queryParams['sub_category'] ?? null
    filter.size = route.queryParams['size'] ?? null
    filter.search = route.queryParams['search'] ?? null
    filter.custom_templates = true
    filter.user_tag = route.queryParams['user_tag'] ? getInteger(route.queryParams['user_tag']) : null
    filter.page = route.queryParams['page'] ? getInteger(route.queryParams['page']) : 1
    filter.franchise_category = route.queryParams['franchise_category'] ?? null
    filter.franchise = route.queryParams['franchise'] ?? null
    if (route.queryParams['colors'] != null) {
        filter.colors = route.queryParams['colors']?.split(',')?.map(function (str) {
            if (getInteger(str) != null)
                return getInteger(str);
        });

    }
    filter.get_filter_configuration = true

    const requestArray: Observable<any>[] = [_searchService.search(filter, true), _productService.getUserTags('')]
    return forkJoin(requestArray);
};
function getInteger(value: string) {
    let result = Number(value)
    return Number.isNaN(result) ? null : result
}