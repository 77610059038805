<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>

    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pb-2">

    <div class="flex flex-col min-w-0">
        <div class="w-full max-w-120 sm:min-w-100 md:min-w-120 gap-2 grid grid-cols-1"
            [ngClass]="data.designs?.length == 1 ? ' sm:grid-cols-1' : ' sm:grid-cols-2'">
            <div *ngFor="let design of data.designs" class="flex flex-col w-full">
                <div>{{design.title}}</div>
                <img [src]="design.image" class="w-full h-50 object-contain" (click)="openImage(design.image) "
                    style="background-color: #f5f5f5;cursor: zoom-in;" alt="Design preview">
                <button class="btn_white mt-3 justify-center flex ml-auto" mat-flat-button type="submit"
                    (click)="modifyDesigns(design.id)"  style="padding:0.5rem 1.2rem !important;">
                    <span>
                        Modify
                    </span>
                </button>
            </div>
        </div>
        <mat-checkbox class="mt-2 -ml-3 flex justify-end" [(ngModel)]="proofConfirmed" style="margin-right:-12px;">
            I have downloaded and reviewed the design
        </mat-checkbox>

        <fuse-alert class="mt-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
            [dismissible]="true">
            {{alert.message}}
        </fuse-alert>
    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0 flex justify-center sm:justify-end gap-3 mt-3">
    <button class="btn_white w-full sm:w-50" [disabled]="showLoading" mat-flat-button type="submit"
        (click)="downloadDesigns()">
        <span *ngIf="!showLoading">
            Download
        </span>
        <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
    <button mat-flat-button class="mt-0 w-full sm:w-50 btn_normal ml-0" [disabled]="showLoading" type="submit"
        (click)="submit()">
        <span *ngIf="!showLoading">
            Submit
        </span>
        <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>