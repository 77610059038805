<div class="container w-full px-4 md:px-8 sm:pt-4 ml-auto mr-auto pb-12 pt-0" style="max-width:90%">
    <section class="breadcrumb flex mt-2">
        <ul class="flex">
            <li><a href="./home.component.html">Home / Images / Family Stock Images</a></li>
            <span class="ml-2 mr-2">/</span>
            <li class="font-bold">Search</li>
        </ul>
    </section>
    <div class="flex sm:justify-end justify-between flex-col sm:flex-row gap-4 items-center mt-6 mb-8">
        <div class="w-full h-full  flex items-center">
            <form class="flex w-full">
                <input class="w-full px-4 sm:px-18 text-black h-[36px]" [formControl]="searchControl"
                    [placeholder]="'Search'" #barSearchInput style="border:1px solid #343434;border-top-left-radius:6px;border-bottom-left-radius:6px;">
                <button class="shrink-0 flex gap-2 items-center px-6" style="border:1px solid #343434;border-top-right-radius:6px;border-bottom-right-radius:6px;"><img
                        src="./assets/images/search_image_icon.svg"
                        style="width:25px;height:25px;object-fit: contain;" alt="Search by image"><span class="hidden sm:flex text-sm">Search By Image</span></button>
            </form>
        </div>
        <label style="text-wrap: nowrap;">Sort by</label>
        <form class="w-auto">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-select placeholder="Popular">
                    <mat-option [value]="'-added_at'">Latest</mat-option>
                    <mat-option [value]="'added_at'">Oldest</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <div class="flex flex-col sm:flex-row mt-6 gap-8">
        <!-- search -->
        <div class="w-full sm:w-64 shrink-0 hidden sm:flex flex-col">
            <mat-accordion multi="true" class="w-full mt-1">
                <!-- Categories -->
                <mat-expansion-panel (opened)="categoryOpen = true" (closed)="categoryOpen = false"
                    [expanded]="categoryOpen" hideToggle class="shadow-none mb-0" style="box-shadow: none !important;">
                    <mat-expansion-panel-header class="p-0">
                        <mat-panel-title style="text-wrap: nowrap;" class="grow">
                            Category
                        </mat-panel-title>
                        <mat-panel-description style="position: absolute;right:0;">
                            <mat-icon *ngIf="!categoryOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                            <mat-icon *ngIf="categoryOpen" svgIcon="heroicons_outline:minus"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div (click)="onChangeCategory(null)"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-[4px] shrink-0 ">
                                <div class="radio-selected rounded-[4px] shrink-0"
                                    *ngIf="filterParams.category == null">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                All
                            </div>
                        </div>
                        <!-- <div (click)="onChangeCategory(category)" *ngFor="let category of searchConfig?.categories "
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-md shrink-0 ">
                                <div class="radio-selected rounded-md shrink-0"
                                    *ngIf="filterParams.category == category.id">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                {{category.title}}
                            </div>
                        </div> -->
                        <a class="text-black underline mt-4 flex font-semibold">and More</a>
                    </ng-template>
                </mat-expansion-panel>


                <div class="border-b" *ngIf="searchConfig?.sub_categories?.length > 0"></div>
                <!-- Sub Categories -->
                <mat-expansion-panel (opened)="subCategoryOpen = true" (closed)="subCategoryOpen = false"
                    *ngIf="searchConfig?.sub_categories?.length > 0" [expanded]="subCategoryOpen" hideToggle
                    class="shadow-none mb-0" style="box-shadow: none !important;">
                    <mat-expansion-panel-header class="p-0">
                        <mat-panel-title style="text-wrap: nowrap;" class="grow">
                            Sub Category
                        </mat-panel-title>
                        <mat-panel-description style="position: absolute;right:0;">
                            <mat-icon *ngIf="!subCategoryOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                            <mat-icon *ngIf="subCategoryOpen" svgIcon="heroicons_outline:minus"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div (click)="onChangeSubCategory(null)"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-full shrink-0 ">
                                <div class="radio-selected rounded-full shrink-0"
                                    *ngIf="filterParams.sub_category == null">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                All
                            </div>
                        </div>
                        <div (click)="onChangeSubCategory(category)"
                            *ngFor="let category of searchConfig?.sub_categories"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-full shrink-0 ">
                                <div class="radio-selected rounded-full shrink-0"
                                    *ngIf="filterParams.sub_category == category.id">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                {{category.title}}
                            </div>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
                <hr *ngIf="searchConfig?.sizes?.length > 0">

                <!-- Sizes -->
                <mat-expansion-panel (opened)="sizeOpen = true" (closed)="sizeOpen = false"
                    *ngIf="searchConfig?.sizes?.length > 0" [expanded]="sizeOpen" hideToggle class="shadow-none mb-0"
                    style="box-shadow: none !important;">
                    <mat-expansion-panel-header class="p-0">
                        <mat-panel-title style="text-wrap: nowrap;" class="grow">
                            Sizes
                        </mat-panel-title>
                        <mat-panel-description style="position: absolute;right:0;">
                            <mat-icon *ngIf="!sizeOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                            <mat-icon *ngIf="sizeOpen" svgIcon="heroicons_outline:minus"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div (click)="onChangeSize(null)"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-full shrink-0 ">
                                <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.size == null">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                All
                            </div>
                        </div>
                        <div (click)="onChangeSize(size)" *ngFor="let size of searchConfig?.sizes"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-full shrink-0 ">
                                <div class="radio-selected rounded-full shrink-0" *ngIf="filterParams.size == size.id">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                {{size.title}}
                            </div>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
                <hr *ngIf="searchConfig?.businesses?.length > 0">

                <!-- Business -->
                <mat-expansion-panel (opened)="businessOpen = true" (closed)="businessOpen = false"
                    *ngIf="searchConfig?.businesses?.length > 0" [expanded]="businessOpen" hideToggle
                    class="shadow-none mb-0" style="box-shadow: none !important;">
                    <mat-expansion-panel-header class="p-0">
                        <mat-panel-title style="text-wrap: nowrap;" class="grow">
                            Business
                        </mat-panel-title>
                        <mat-panel-description style="position: absolute;right:0;">
                            <mat-icon *ngIf="!businessOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                            <mat-icon *ngIf="businessOpen" svgIcon="heroicons_outline:minus"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <mat-form-field class="w-full">
                            <mat-select placeholder="Business" [(ngModel)]="selectedFranchiseCategory"
                                (selectionChange)="onBusinessCategoryChanged($event.value)">
                                <mat-option [value]="-1">All</mat-option>
                                <mat-option *ngFor="let business of searchConfig?.businesses"
                                    [value]="business.id">{{business.franchise_category}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div (click)="onChangeBusiness(franchise)" *ngFor="let franchise of franchises"
                            class="flex flex-row items-center gap-2 cursor-pointer pt-1 pb-1">
                            <div class="flex radio-bg rounded-full shrink-0 ">
                                <div class="radio-selected rounded-full shrink-0"
                                    *ngIf="filterParams.franchise == franchise.id">
                                </div>
                            </div>
                            <div class="text-black mb-0">
                                {{franchise.name}}
                            </div>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
                <hr *ngIf="searchConfig?.colors?.length > 0">

                <!-- Colors -->
                <mat-expansion-panel (opened)="colorOpen = true" (closed)="colorOpen = false"
                    *ngIf="searchConfig?.colors?.length > 0" [expanded]="colorOpen" hideToggle class="shadow-none mb-0"
                    style="box-shadow: none !important;">
                    <mat-expansion-panel-header class="p-0">
                        <mat-panel-title style="text-wrap: nowrap;" class="grow">
                            Colors
                        </mat-panel-title>
                        <mat-panel-description style="position: absolute;right:0;">
                            <mat-icon *ngIf="!colorOpen" svgIcon="heroicons_outline:plus"></mat-icon>
                            <mat-icon *ngIf="colorOpen" svgIcon="heroicons_outline:minus"></mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="grid grid-cols-7 sm:grid-cols-6 gap-1">
                            <div class="aspect-square border rounded-lg" (click)="onChangeColor(color)"
                                *ngFor="let color of searchConfig?.colors" [style.background-color]="color.code"
                                [style.border-color]="this.filterParams.colors?.includes(color.id) ? '#000' : '#f5f5f5'">

                            </div>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <!-- // -->
        <!-- image listing -->
        <div class="flex flex-col grow md:ml-6">
            <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-4">
                <div *ngFor="let item of imageList">
                    <img [src]="item.image" style="border-radius: 10px;" alt="Postcard preview">
                </div>
            </div>

        </div>
        <!-- // -->
    </div>
</div>