import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrganizationAdmin } from 'app/core/organization/organization.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { AutofillGroup } from '../autofill-manager.types';
import { AutofillManagerService } from '../autofill-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule, MatDatepickerModule,
    CommonModule, MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CreateGroupComponent implements OnInit {

  @ViewChild('groupNgForm') groupNgForm: NgForm;
  groupForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { group: AutofillGroup, versionHistory, quantity, options },
    private _autofillService: AutofillManagerService, private _router: Router,
    private _toast: ToastrService, private fb: UntypedFormBuilder, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<CreateGroupComponent>) {
    if (data == null)
      this.title = "Create Group"
  }

  ngOnInit(): void {
    this.groupForm = this.fb.group({
      name: [this.data?.group?.name ?? '', [Validators.required, Validators.minLength(3)]],
      description: [this.data?.group?.description ?? '', [Validators.required, Validators.minLength(3)]],
      version_history: { value: this.data?.versionHistory, disabled: this.data?.group != null },
      quantity_id: { value: this.data?.quantity, disabled: this.data?.group != null },
      options: { value: this.data?.options, disabled: this.data?.group != null },


    });
  }


  title = "Update Group";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.groupForm.markAllAsTouched();
    if (this.groupForm.invalid) {
      return;
    }

    this.groupForm.disable()
    let request = (this.data?.group == null) ? this._autofillService.createGroup(this.groupForm.value) : this._autofillService.updateGroup(this.data?.group?.id, this.groupForm.value)
    request.subscribe({
      next: (response: any) => {
        this.groupForm.enable()
        this.responseMessage = response.message ? response.message : `Group has been ${this.data?.group == null ? 'created' : 'updated'}`
        if (this.data?.group == null) {
          this._toast.success(response.message ? response.message : `Group has been created`, 'Success')
          this._router.navigate(['/', 'autofill-manager', response?.id])
          this._matDialog.closeAll()
        } else {
          this.close()
        }
      },
      error: error => {
        this.groupForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

}
