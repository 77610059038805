import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { LocalStorageService } from './core/localstorage/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private localStorageService: LocalStorageService) {
        // let lang = localStorageService.language
        // var domainName = window.location.hostname;
        // console.log(domainName);
        // console.log(lang);
        // ;
        
        // if (lang == 'en') {
        //     document.cookie = 'googtrans=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure; domain=.' + domainName;
        //     document.cookie = 'googtrans=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure; domain=' + domainName;
        // } else {
        //     // document.cookie = `googtrans=/en/${lang}; path=/; domain=.${domainName};`;
        //     document.cookie = `googtrans=/en/${lang}; path=/; secure; domain=.` + domainName;
        //     document.cookie = `googtrans=/en/${lang}; path=/; secure; domain=` + domainName;
        // }
    }

    ngOnInit(): void {
        initFlowbite();
    }
}
