import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})

export class TermsAndConditions implements OnInit {

  
  ngOnInit(): void {
  }

  /**
   * Constructor
   */
  constructor() {

  }
}