import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, NotificationList } from 'app/layout/common/notifications/notifications.types';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    private _hasMoreOrders: ReplaySubject<Boolean> = new ReplaySubject(null);
    get hasMoreOrders$(): Observable<Boolean> {
        return this._hasMoreOrders.asObservable();
    }

    private _notificationCount: ReplaySubject<number> = new ReplaySubject(0);
    get notificationCount$(): Observable<number> {
        return this._notificationCount.asObservable();
    }

    notificationsPage = 1

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(nextPage: Boolean = false): Observable<NotificationList> {
        if (nextPage)
            this.notificationsPage++
        else
            this.notificationsPage = 1
        return this._httpClient.get<NotificationList>(`${BASE_URL}notification/list/?batch=20&page=${this.notificationsPage}`).pipe(
            tap((notifications) => {
                this._notificationCount.next(notifications.unread_count)
                if (nextPage) {
                    let tempNotifications: Notification[] = this._notifications.value
                    tempNotifications = [...tempNotifications, ...notifications.notifications]
                    this._notifications.next(tempNotifications);
                } else {
                    this._notifications.next(notifications.notifications);
                }
                this._hasMoreOrders.next(notifications.current_page < notifications.total_pages)
                return notifications;
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(notificationId: any): Observable<any> {
        // return this.http.put(, {})
        return this._httpClient.put(`${BASE_URL}notification/${notificationId}/`, {})
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: any): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.put<boolean>(`${BASE_URL}notification/all/`, {}).pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].notification_read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return true;
                }),
            )),
        );
    }
}
