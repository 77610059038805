import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { ProductService } from 'app/core/product/product.service';
import { VersionHistory } from 'app/core/product/product.types';
import { VersionHistoryComponent } from '../version-history/version-history.component';


@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule, MatProgressSpinnerModule, MatDialogModule, CommonModule,
    MatInputModule, MatFormFieldModule, FormsModule, MatButtonModule,
    ReactiveFormsModule, FuseAlertComponent, StripeCardComponent
  ]
})
export class CreateProductComponent implements OnInit {

  @ViewChild('productNgForm') productNgForm: NgForm;
  productForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sub_category, size, category },
    private _productService: ProductService,
    private _toast: ToastrService, private _spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<CreateProductComponent>) {
  }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      title: ['', [Validators.required]],
      sub_title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      sub_category: [this.data?.sub_category ?? '', [Validators.required]],
      size: [this.data?.size ?? '', [Validators.required]],
      category: [this.data?.category ?? '', [Validators.required]],
    });
  }

  title = "Create Template";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.productForm.markAllAsTouched();
    if (this.productForm.invalid) {
      return;
    }

    this.productForm.disable()
    let request = this._productService.createCustomProduct(this.productForm.value)
    request.subscribe({
      next: (response: any) => {
        this.productForm.enable()
        this.getVersionHistory(response.id)
      },
      error: error => {
        this.productForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  getVersionHistory(product) {
    this._spinner.show()
    this._productService.getProductHistory(product).subscribe({
      next: versions => {
        this._spinner.hide()
        if (versions?.length > 0)
          this.openVersionHistory(versions, product)
        else
          this._toast.error("Something went wrong", "Sorry")
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openVersionHistory(histories: VersionHistory[], version) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = 'calc(100%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']

    dialogConfig.data = { versionId: version, histories: histories, category: this.data?.category, subCategory: this.data?.sub_category };
    this._matDialog.open(VersionHistoryComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
    this.close()


  }

}
