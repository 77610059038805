import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerService } from '../../../image-viewer/image-viewer.service';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@Component({
  selector: 'cart-design-map',
  templateUrl: './design-map.component.html',
  styleUrls: ['./design-map.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatRadioModule,ReactiveFormsModule, MatSlideToggleModule, MatFormFieldModule, FormsModule, MatSelectModule, MatInputModule, MatTabsModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule]
})

export class AutofillDesignMapComponent implements OnInit {

  /**
   * Constructor
   */
  constructor(private _imageViewer: ImageViewerService) {

  }

  @Input() data;
  @Input() mapData;

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFinalSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input() onClickNext = new EventEmitter<any>();

  values = {};
  ValuesArray = {
    design_id: '',
    fields: {},
    locked_fields: []
  }
  item_update_id = null;

  ngOnInit(): void {
    this.ValuesArray = {
      design_id: this.data.design_id,
      fields: {},
      locked_fields: []
    }

    this.onClickNext.subscribe(()=>{
      this.onSubmit() 
    })
  }

  onSubmit() {
    let lockedFields = []
    this.data.fields.forEach(field => {
      if (field.locked)
        lockedFields.push(field.id)
    })
    this.ValuesArray.locked_fields = lockedFields
    if (this.ValuesArray.fields == null)
      this.ValuesArray.fields = {}
    this.data.fields.forEach(field => {
      if (field.key_id) {
        this.ValuesArray.fields[field.key_id] = field.id
      }
    })
    this.onNext.emit(this.ValuesArray)
  }

  openImage(image_url) {
    this._imageViewer.open(image_url)
  }
}