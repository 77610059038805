import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';


@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CreateAddressComponent implements OnInit {

  @ViewChild('addressNgForm') addressNgForm: NgForm;
  addressForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Address,
    private _accountService: UserService,
    private _toast: ToastrService,
    private fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<CreateAddressComponent>) {
    
  }

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      address_line_1: [this.data?.address_line_1 ?? '', [Validators.required]],
      address_line_2: [this.data?.address_line_2 ?? ''],
      city: [this.data?.city ?? '', [Validators.required]],
      state: [this.data?.state ?? '', [Validators.required]],
      zipcode: [this.data?.zipcode ?? '', [Validators.required]],
      name: [this.data?.name ?? '', [Validators.required]],
      default_shipping: [this.data?.default_shipping ?? false, [Validators.required]],
      default_billing: [this.data?.default_billing ?? false, [Validators.required]],
      phone_number: [this.data?.phone_number ?? '', [Validators.required]],
    });
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.addressForm.markAllAsTouched();
    if (this.addressForm.invalid) {
      return;
    }

    this.addressForm.disable()
    let request = (this.data == null) ? this._accountService.createAddress(this.addressForm.value) : this._accountService.updateAddress(this.data.id, this.addressForm.value)
    request.subscribe({
      next: (response: any) => {
        this.addressForm.enable()
        this.responseMessage = response.message ? response.message : `Address has been ${this.data == null ? 'created' : 'updated'}`
        this.close()
      },
      error: error => {
        this.addressForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

}
