import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, Contact, UploadsResult, User } from 'app/core/user/user.types';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { LocalStorageService } from '../localstorage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _localStorage: LocalStorageService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }




    private _designations: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    set designations(value: string[]) {
        // Store the value
        this._designations.next(value);
    }

    get designations$(): Observable<string[]> {
        return this._designations.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>(`${BASE_URL}user/profile/`).pipe(
            tap((user) => {
                this._localStorage.organizationId = user.organization_id ? user.organization_id + '' : '0'
                this._localStorage.isOrganizationOwner = user.is_organization_owner
                this._localStorage.isPWADesigner = user.account_type == 'pwa_designer'
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.post<User>(`${BASE_URL}user/profile/`, user).pipe(
            map((response: any) => {
                this._user.next(response);
                if (response.token != null) {
                    this._localStorage.accessToken = response.token
                }
                return response
            }),
        );
    }

    getDesignationList() {
        return this._httpClient.get(`${BASE_URL}product/configurations/`).pipe(
            tap((response: any) => {
                this._designations.next(response.designation_list);
            }),
        );
    }

    verifyMobile() {
        return this._httpClient.get(`${BASE_URL}user/verify/mobile/`)

    }

    verifyEmail() {
        return this._httpClient.get(`${BASE_URL}user/verify/email/`)
    }

    verifyOTP(data) {
        return this._httpClient.post(`${BASE_URL}user/verify/otp/`, data)
    }

    reSendOTP(data) {
        return this._httpClient.post(`${BASE_URL}user/resend/otp/`, data)
    }


    getZipCodeSuggestions(search) {
        return this._httpClient.get<any>(`${BASE_URL}user/address/contact/?zipcode=${search}`)
    }

    //////////////Address/////////////

    private _addressList: BehaviorSubject<Address[]> = new BehaviorSubject(null);
    get addressList$(): Observable<Address[]> {
        return this._addressList.asObservable();
    }

    getAddresses(): Observable<Address[]> {
        return this._httpClient.get<Address[]>(`${BASE_URL}user/address/manage/`).pipe(
            map((addresses) => {
                this._addressList.next(addresses);
                return addresses;
            })
        );
    }

    createAddress(data): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}user/address/manage/`, data)
    }

    updateAddress(itemId, data) {
        return this._httpClient.put(`${BASE_URL}user/address/manage/${itemId}/`, data,)
    }

    deleteAddress(addressId) {
        return this._httpClient.delete(`${BASE_URL}user/address/manage/${addressId}/`).pipe(
            tap((res: any) => {
                this._addressList.next(this._addressList.value.filter(address => address.id !== addressId));
            })
        )
    }
    /////////////////////////////////

    ////////Contact//////////////////
    private _contactList: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
    get contactList$(): Observable<Contact[]> {
        return this._contactList.asObservable();
    }

    getContacts(): Observable<Contact[]> {
        return this._httpClient.get<Contact[]>(`${BASE_URL}user/contact/manage/`).pipe(
            map((contacts) => {
                this._contactList.next(contacts);
                return contacts;
            })
        );
    }

    getContactDetails(contactId): Observable<Contact> {
        return this._httpClient.get<Contact>(`${BASE_URL}user/contact/manage/${contactId}/`)
    }

    createContact(data): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}user/contact/manage/`, data)
    }

    updateContact(itemId, data) {
        return this._httpClient.put(`${BASE_URL}user/contact/manage/${itemId}/`, data,)
    }

    deleteContact(contactId) {
        return this._httpClient.delete(`${BASE_URL}user/contact/manage/${contactId}/`).pipe(
            tap((res: any) => {
                this._contactList.next(this._contactList.value.filter(contact => contact.id !== contactId));
            })
        )
    }


    /////////////////////////////////

    //////////////Address/////////////

    private _uploadsList: BehaviorSubject<UploadsResult> = new BehaviorSubject(null);
    get mediaList$(): Observable<UploadsResult> {
        return this._uploadsList.asObservable();
    }

    getUploadedMedias(): Observable<UploadsResult> {
        return this._httpClient.get<UploadsResult>(`${BASE_URL}user/media-library/`).pipe(
            map((medias) => {
                this._uploadsList.next(medias);
                return medias;
            })
        );
    }

    createMedia(data): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}user/media-library/`, data)
    }

    updateMedia(mediaId, data) {
        return this._httpClient.put(`${BASE_URL}user/media-library/${mediaId}/`, data).pipe(
            tap((res: any) => {
                let mediaList = this._uploadsList.value
                mediaList.images.find(media => media.id == mediaId).name = data.name
                this._uploadsList.next(mediaList);
            })
        )
    }

    deleteMedia(mediaId) {
        return this._httpClient.delete(`${BASE_URL}user/media-library/${mediaId}/`).pipe(
            tap((res: any) => {
                let data = this._uploadsList.value
                data.images = this._uploadsList.value.images.filter(media => media.id !== mediaId)
                this._uploadsList.next(data);
            })
        )
    }
    /////////////////////////////////

    getVIDProfiles() {
        return this._httpClient.get<any>(`${BASE_URL}vid/profile/`)
    }

    submitContactUsForm(data) {
        let request = { name: data.firstName + ' ' + data.lastName, email: data.email, message: data.message, subject: data.subject }
        return this._httpClient.post(`${BASE_URL}user/contact-us/submit/`, request)
    }
    
    getSupportToken() {
        return this._httpClient.put(`${BASE_URL}user/login/token/`, {"support_access":true})
    }
}
