<mat-dialog-actions class="flex justify-between pl-4 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <div class="justify-self-start text-2xl font-extrabold">{{createType == 'upload' ? 'Upload' : 'Create'}} Your Design
    </div>
    <button class="flex w-auto mb-0" mat-button (click)="close(null)">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="p-0 max-h-[70vh]">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-full sm:min-w-100 w-full">
        <div class="flex items-center  w-full h-auto p-4 pt-0 pb-2">
            <form class="w-full  mx-auto ">
                <mat-form-field class="w-full mt-4">
                    <mat-label>No. of Sides</mat-label>
                    <mat-select id="name" matInput [(ngModel)]="noOfSides" [ngModelOptions]="{standalone: true}">
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Content starts -->
                <!-- <h1 class="text-black" style="font-weight:700;font-size:20px;">Upload Your design</h1> -->
                <mat-form-field class="w-full">
                    <mat-label>Side One Name</mat-label>
                    <input id="name" matInput [(ngModel)]="sideOneName" [ngModelOptions]="{standalone: true}"
                        class="text-black">
                    <mat-hint class="mat-error" *ngIf="showError && sideOneName?.length ==0"> Name shouldn't be
                        empty</mat-hint>
                </mat-form-field>
                <!-- Create event form -->
                <div class="w-full h-[170px] relative mb-4" style="background-color:#f5f5f5;border-radius:8px;"
                    *ngIf="createType == 'upload'">

                    <img class="absolute w-full h-full object-contain" [src]="sideOneImage" alt="Preview"
                        *ngIf="sideOneImage != null">
                    <div class="flex w-full h-full items-center justify-center bg-grey-lighter">
                        <label class="w-auto flex flex-col items-center px-4 py-1 bg-white text-black text-sm" style="border-radius:8px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
                        border: solid 1px #ddd;cursor: pointer;">

                            <span class="mt-0" style="font-weight:500;font-size:12px;">Choose File</span>
                            <input accept="image/*" onclick="this.value = null" (change)="onImageChanged($event, true)"
                                hidden="true" type="file" class="hidden text-black">
                        </label>
                    </div>
                </div>

                <ng-container *ngIf="noOfSides > 1">
                    <mat-form-field class="w-full">
                        <mat-label>Side Two Name</mat-label>
                        <input id="name" matInput [(ngModel)]="sideTwoName" [ngModelOptions]="{standalone: true}"
                            class="text-black">
                        <mat-hint class="mat-error" *ngIf="showError && sideTwoName?.length ==0"> Name shouldn't be
                            empty</mat-hint>
                    </mat-form-field>
                    <!-- Create event form -->
                    <div class="w-full h-[170px] relative" style="background-color:#f5f5f5;border-radius:8px;"
                        *ngIf="createType == 'upload'">
                        <img class="absolute w-full h-full object-contain" [src]="sideTwoImage"
                            *ngIf="sideTwoImage != null" alt="Preview">
                        <div class="flex w-full h-full items-center justify-center bg-grey-lighter">
                            <label class="flex flex-col items-center px-4 py-1 bg-white text-black text-sm" style="border-radius:8px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
                        border: solid 1px #ddd;cursor: pointer;">

                                <span class="mt-0" style="font-weight:500;font-size:12px;">Choose File</span>
                                <input accept="image/*" onclick="this.value = null"
                                    (change)="onImageChanged($event, false)" hidden="true" type="file"
                                    class="hidden text-black">
                            </label>
                        </div>
                    </div>
                </ng-container>


                <!-- Alert -->
                <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                    [showIcon]="false" [type]="alert.type" [dismissible]="true">
                    {{alert.message}}
                </fuse-alert>
                <!-- Content ends -->

            </form>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-2">
    <!-- Submit button -->
    <!-- <button class="fuse-mat-button-large w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="createChecklistForm.disabled" (click)="submitForm()" form="createChecklistForm">
        <span *ngIf="!createChecklistForm.disabled">
            SUBMIT
        </span>
        <mat-progress-spinner *ngIf="createChecklistForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button> -->
    <button mat-flat-button class=" mt-0 w-full btn_normal" (click)="submit()">{{createType == 'upload' ? 'Upload' :
        'Create'}} Design</button>
</mat-dialog-actions>