<!-- referal toggle -->
<ng-container *ngIf="appearance == 'icon'">
    <button mat-icon-button (click)="openPopup()" #demoOrigin>
        <mat-icon [svgIcon]="'nav_schedule_demo'" style="padding: 1px;" class="text-black"></mat-icon>
    </button>
</ng-container>
<ng-container *ngIf="appearance == 'button'">
    <button mat-flat-button (click)="openPopup()" #demoOrigin
        style="padding:0.5rem 0.5rem 0.5rem 1rem;background-color:#2716bc;border-radius:8px !important;">
        <div class="flex flex-nowrap flex-row items-center">
            <p class="text-white" style="font-size: 14px;font-weight:700">Schedule a Demo</p>
        </div>
    </button>
</ng-container>

<!-- referal panel -->
<ng-template #demoPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Schedule Demo</div>

        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">

            <ng-container>
                <iframe src="https://api.leadconnectorhq.com/widget/booking/esrLHI0pNjFEO0OyKdnJ"
                    style="min-height: 400px; width: 100%;border:none;height: 100%;padding: 0px;" scrolling="auto"
                    id="esrLHI0pNjFEO0OyKdnJ_1692030551809"></iframe>
            </ng-container>

        </div>

    </div>

</ng-template>