import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Product, ProductVersion } from 'app/core/product/product.types';
import { MatMenuModule } from '@angular/material/menu';
@Component({
    selector: 'app-design-view',
    templateUrl: './design-view.component.html',
    styleUrls: ['./design-view.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule, MatMenuModule,
        MatFormFieldModule,
        NgOptimizedImage,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule,
        RouterModule]

})
export class DesignView implements OnInit {

    constructor() { }
    // @Output() onClose = new EventEmitter<void>();

    // close() {
    //   this.onClose.emit();
    // }

    _productVersion: ProductVersion
    @Input() set product(product: ProductVersion) {
        this._productVersion = product
        this.setInitialImage()
    }

    setInitialImage() {
        if (this._productVersion?.images?.length > 0)
            this.imgSrc = this._productVersion?.images[0]
        else {
            this.imgSrc = this._productVersion?.featured_image ?? this._productVersion?.thumb_image ?? this._productVersion.image
        }
        this.changeBackgroundCounter = 0
    }
    @Input() showMenu: boolean = false

    @Output() onDelete = new EventEmitter<void>();
    @Output() onAssignTag = new EventEmitter<void>();
    @Output() onUpdateTag = new EventEmitter<void>();
    @Output() onRemoveTag = new EventEmitter<void>();

    imgSrc = null
    interval = null

    ngOnInit(): void {

    }

    changeBackgroundCounter = 0
    onMouseEnter() {
        if (this._productVersion?.images?.length > 0) {
            this.changeImage()
            this.interval = setInterval(() => {
                this.changeImage()
            }, 2000)
        }
    }

    changeImage() {
        this.changeBackgroundCounter++;
        if (this.changeBackgroundCounter > this._productVersion?.images?.length - 1) {
            this.changeBackgroundCounter = 0;
        }
        this.imgSrc = this._productVersion?.images[this.changeBackgroundCounter];
    }

    onMouseLeave() {
        clearInterval(this.interval);
        this.setInitialImage()
    }


    ngOnDestroy() {
        clearInterval(this.interval);
    }

    onclickIndicator(index) {
        this.changeBackgroundCounter = index;
        this.imgSrc = this._productVersion?.images[this.changeBackgroundCounter]
    }

    onClickDelete() {
        this.onDelete.emit()
    }

    onClickRemoveTag() {
        this.onRemoveTag.emit()
    }

    onClickAssignTag() {
        this.onAssignTag.emit()
    }

    onClickUpdateTag() {
        this.onUpdateTag.emit()
    }
}
