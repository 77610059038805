import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AutofillManagerService } from '../autofill-manager.service';
import { ActivatedRouteSnapshot } from '@angular/router';

export const autofillGroupDataResolver = (route: ActivatedRouteSnapshot) => {
    const _autofillManagerService = inject(AutofillManagerService);
    const requestArray: Observable<any>[] = [_autofillManagerService.getGroupDetails(route.params['id'], false),
    _autofillManagerService.getContacts(route.params['id'], false)]
    return forkJoin(requestArray);
};
