import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { MatDialogConfig, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { Subject, takeUntil } from 'rxjs';
import { SMMPost, SMMPostsResponse } from 'app/core/social-media/social-media.types';

import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { Pagination } from 'app/core/api/pagination.type';
import { NewPostPopup } from '../new-post/new-post.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-social-media-post',
  templateUrl: './social-media-posts.component.html',
  styleUrls: ['./social-media-posts.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, PWAPagination, MatTabsModule, MatIconModule, NgFor, MatButtonModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, FuseCardComponent, NewPostPopup, MatDialogModule, MatMenuModule]
})

export class SocialMediaPostsComponent implements OnInit {
  @ViewChild("postsTab", { static: false }) postsTab: MatTabGroup;
  sortbyby = 0
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private broadcast: BroadcastService,
    private _fuseConfirmationService: FuseConfirmationService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
    private _spinner: NgxSpinnerService, private _smmService: SocialMediaService,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef,) {
    this.posts.set('published', null)
    this.posts.set('scheduled', null)
    this.posts.set('draft', null)

    this.sort.set('published', '-added_at')
    this.sort.set('scheduled', '-added_at')
    this.sort.set('draft', '-added_at')

    this.pagination.set('published', null)
    this.pagination.set('scheduled', null)
    this.pagination.set('draft', null)

    this.matIconRegistry.addSvgIcon(
      "smm_view_list",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/view_by_list.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "smm_view_grid",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/view_by_grid.svg")
    );

  }

  postTypes = ['published', 'scheduled', 'draft']

  listType: 'list' | 'grid' = 'grid'
  posts = new Map<string, SMMPostsResponse>();
  sort = new Map<string, string>();
  pagination = new Map<string, Pagination>();

  ngOnInit(): void {
    this._smmService.publishedPosts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((posts: SMMPostsResponse) => {
        this.posts.set('published', posts)
        this.pagination.set('published', posts)
        this._changeDetectorRef.markForCheck()
      })
    this._smmService.scheduledPosts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(posts => {
        this.posts.set('scheduled', posts)
        this.pagination.set('scheduled', posts)
        this._changeDetectorRef.markForCheck()
      })
    this._smmService.draftPosts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(posts => {
        this.posts.set('draft', posts)
        this.pagination.set('draft', posts)
        this._changeDetectorRef.markForCheck()
      })
    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      this.refreshAllPosts()
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getPageCount(post: SMMPost) {
    let count = post.targets?.length
    return count;
  }

  getPageCountText(post: SMMPost) {
    let count = post.targets?.length

    if (count == 1)
      return post.targets[0].title ?? post.targets[0].name;
    else
      return (post.targets[0].title ?? post.targets[0].name) + (count == 1 ? "" : ` + ${count - 1} more`);
  }

  openNewPostPopup(post: SMMPost) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { post: post }
    dialogConfig.panelClass = ['w-full', 'max-w-200']
    this._matDialog.open(NewPostPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this.refreshAllPosts()
        }
      });
  }

  refreshAllPosts() {
    this.refreshPostList('published')
    this.refreshPostList('draft')
    this.refreshPostList('scheduled')
  }

  asIsOrder(a, b) {
    return 1;
  }

  onSortChanged(type, sort) {
    this.sort.set(type, sort)
    this.refreshPostList(type)
  }

  refreshPostList(type) {
    this.getPosts(type, 1)
  }

  getPosts(type, page) {
    this._smmService.getPosts(type, page, this.sort.get(type) == 'added_at').subscribe()
  }

  deletePost(type, post: SMMPost) {

    const dialogRef = this._fuseConfirmationService.open({
      title: "Delete Post",
      message: `Are you sure you want to delete the post?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeletePost(type, post)
    });
  }

  proceedDeletePost(type, post: SMMPost) {
    this._spinner.show();
    this._smmService.deletePost(post.id + '').subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.getPosts(type, this.pagination.get(type).current_page)
        this._toast.success(res.message ? res.message : 'Post has been removed successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }
}