import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SMMAllAccountsResponse, SMMPost } from 'app/core/social-media/social-media.types';
import { ToastrService } from 'ngx-toastr';
import { MatInputModule } from '@angular/material/input';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-support-token',
  templateUrl: './support-token.component.html',
  styleUrls: ['./support-token.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule,
    FormsModule, MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule,
    FuseCardComponent]
})
export class SupportToken implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
    private _smmService: SocialMediaService, private _toast: ToastrService,
    private clipboard: Clipboard,
    private _dialogRef: MatDialogRef<SupportToken>) { 
    }

  ngOnInit(): void {
  }




  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  successAnimationRes = './assets/lottie/lottie_success1.json'
  showSuccessAnimation = false
  onClickCopy() {
    this.clipboard.copy(this.data)
    if (!this.showSuccessAnimation) {
      this.showSuccessAnimation = true
      this.successAnimationRes = this.successAnimationRes + '?a=' + Math.random()
    }
  }


}
