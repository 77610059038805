import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'lodash';

import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _toast: ToastrService) { }

  downloadFile(url: string, fileName: string): any {
    this._spinner.show()
    this.http.get(url, { responseType: 'blob' }).subscribe({
      next: res => {
        let blob: any = new Blob([res], { type: 'image/*; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, fileName);
        this._spinner.hide()
      },
      error: error => {
        this._spinner.hide()
        this._toast.error("Something went wrong. Please try again", "Sorry")
      }
    })
  }
}