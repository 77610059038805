import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AutofillManagerService } from './autofill-manager.service';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { AutofillGroup } from './autofill-manager.types';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CreateGroupGuideComponent } from './create-group-guide/create-group-guide.component';
import { SearchPopup } from '../search/search-popup/search-popup.component';


@Component({
  selector: 'autofill-manager',
  templateUrl: './autofill-manager.component.html',
  styleUrls: ['./autofill-manager.component.scss'],
  standalone: true,
  imports: [CommonModule, MatRadioModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule]
})

export class AutofillManagerComponent implements OnInit {

  /**
   * Constructor
   */
  constructor(private _autofillManagerService: AutofillManagerService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toast: ToastrService, private broadcast: BroadcastService) {

  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  groups: any[];
  showPrimaryAddButton = false
  hasMoreItems = false
  showLoading = false
  ngOnInit(): void {
    this._autofillManagerService.groups$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contacts => {
        this.groups = contacts
        this.showPrimaryAddButton = this.groups.length > 0
        this._changeDetectorRef.markForCheck()
      })
    this._autofillManagerService.hasMoreGroup$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((hasMore: boolean) => {
        this.hasMoreItems = hasMore;
        this._changeDetectorRef.markForCheck();
      });

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()

    })
  }

  loadNextPage() {
    this.showLoading = true
    this._autofillManagerService.getGroups(true).subscribe({
      complete: () => { this.showLoading = false }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openGroupCreateModal() {
    // const dialogConfig = new MatDialogConfig();
    // this._matDialog.open(CreateGroupGuideComponent, dialogConfig);

    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateGroupComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Group created successfully"), "Success");
          this._autofillManagerService.getGroups().subscribe()
        }
      });
  }

  editGroupDetails(group) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { group: group }
    this._matDialog.open(CreateGroupComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Group created successfully"), "Success");
          this._autofillManagerService.getGroups().subscribe()
        }
      });
  }

  deleteGroup(group: AutofillGroup) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Delete Group",
      message: `Are you sure you want to delete ${group.name}?`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteGroup(group)
    });
  }

  proceedDeleteGroup(group: AutofillGroup) {
    this._spinner.show();
    this._autofillManagerService.deleteGroup(group.id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._toast.success(res.message ? res.message : 'Group has been removed successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }
}