import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { MatDialogConfig, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { Subject, takeUntil } from 'rxjs';
import { SMMPost, SMMPostsResponse } from 'app/core/social-media/social-media.types';

import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { Pagination } from 'app/core/api/pagination.type';
import { NewPostPopup } from '../new-post/new-post.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PostHistoryModal } from '../post-history/post-history.component';

@Component({
  selector: 'app-social-media-post-details',
  templateUrl: './social-media-post-details.component.html',
  styleUrls: ['./social-media-post-details.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, MatTooltipModule, PWAPagination, MatTabsModule, MatIconModule, NgFor, MatButtonModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, FuseCardComponent, NewPostPopup, MatDialogModule]
})

export class SocialMediaPostDetailComponent implements OnInit {
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private broadcast: BroadcastService,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _spinner: NgxSpinnerService, private _smmService: SocialMediaService,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef,) {

  }

  openNewPostPopup(post: SMMPost) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { post: post }
    dialogConfig.panelClass = ['w-full', 'max-w-200']
    this._matDialog.open(NewPostPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          this._smmService.getPostDetails(this.postDetails.id).subscribe()
        }
      });
  }

  openViewHistory(target) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { postDetails: this.postDetails, target: target }
    dialogConfig.panelClass = ['w-full', 'max-w-200']
    this._matDialog.open(PostHistoryModal, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  postDetails: SMMPost
  ngOnInit(): void {
    this._smmService.postDetails$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((post: SMMPost) => {
        this.postDetails = post
        this._changeDetectorRef.markForCheck()
      })
    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      // this._userService.getAddresses().subscribe()
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  deletePost() {

    const dialogRef = this._fuseConfirmationService.open({
      title: "Delete Post",
      message: `Are you sure you want to delete the post?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeletePost()
    });
  }

  proceedDeletePost() {
    this._spinner.show();
    this._smmService.deletePost(this.postDetails?.id + '').subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._toast.success(res.message ? res.message : 'Post has been removed successfully', "Success")
        this._router.navigate(['/', 'social-media', 'posts'])
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  getPageCount() {
    let count = this.postDetails.targets?.length
    return count;
  }

  getPageCountText() {
    let count = this.postDetails.targets?.length

    if (count == 1)
      return this.postDetails.targets[0].title ?? this.postDetails.targets[0].name;
    else
      return (this.postDetails.targets[0].title ?? this.postDetails.targets[0].name) + (count == 1 ? "" : ` + ${count - 1} more`);
  }
}