<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','settings','organization']">Organization</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">Product</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">{{productDetails?.title}}</a>
        <span class="ml-2 mr-2">/</span>
        <p style="font-weight:600">Lock Fields</p>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <mat-tab-group class="mt-4 lg:mt-8" (selectedTabChange)="tabChanged($event)" id="designMapTab" #designMapTab>
        <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
            <design-map (onNext)="onClickNext($event)" [mapData]="mapData" [data]="tab">

            </design-map>
        </mat-tab>
    </mat-tab-group>


</section>