<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','autofill-manager']">Autofill Manager</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','autofill-manager',groupDetails?.id]">{{groupDetails?.name}}</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">{{contactData?.name}}</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="flex flex-col  mt-10 gap-6 w-full bg-white card shadow-md border border-color-[#dedeff]"
        style="border-radius:20px;">
        <div class="flex flex-col sm:flex-row gap-3 sm:gap-6 items-center p-6 md:p-10 pb-0 md:pb-0">
            <div class="flex flex-row gap-3 w-full grow">
                <div class="flex w-15 h-15 shrink-0 rounded-full bg-[#f5f5f5] ">
                    <img [src]="contactData?.contact_data?.profile_image">
                </div>
                <div class="flex flex-col grow">
                    <div class="text-black text-xl font-bold">
                        {{contactData?.name}}
                    </div>
                    <div class="text-black text-lg">
                        {{contactData?.email}}
                    </div>
                </div>
            </div>

            <div class="flex flex-row gap-3" *ngIf="!groupDetails?.added_to_cart">
                <button class="btn_white" (click)="refresh()">
                    Refresh
                </button>
                <button class="btn_normal" (click)="review()">
                    Review
                </button>
            </div>

        </div>

        <div class="border-b ml-6 mr-6 md:pl-10  md:pr-10"></div>

        <div class="grid grid-cols-1 sm:grid-cols-2 mb-6 gap-6 justify-items-center pl-6 pr-6  md:pl-10  md:pr-10">
            <div *ngFor="let design of contactData?.version_history?.designs"
                class="flex flex-col w-full max-w-100 gap-6">
                <div class="flex w-full h-56 shrink-0 bg-[#f5f5f5]" style="border:1px solid #f5f5f5">
                    <img [src]="design?.image" (click)="openImage(design.image)"
                        class="w-full h-full object-contain cursor-zoom-in" alt="Design preview">
                </div>
                <div class="flex flex-row w-full items-center justify-end gap-6">
                    <div>{{design.title}}</div>
                    <button class="btn_normal" (click)="editDesign(design.id)" *ngIf="!groupDetails?.added_to_cart">
                        Edit
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col p-6 md:p-10 mt-10 gap-6 w-full bg-white card shadow-md border border-color-[#dedeff]"
        style="border-radius:20px;">
        <div class="text-black text-xl font-bold">
            Personal Details
        </div>

        <div class="flex items-center  w-full  pt-0">
            <div class="w-full mx-auto ">
                <div class="relative w-fit mb-5" *ngIf="!lockedFields?.includes('profile_image')">
                    <img style="width:80px;height:80px;object-fit: cover;flex-shrink:0;background-color: #fff; border: 1px solid #ddd;"
                        [src]="contactImageSrc" onerror="this.src = './assets/images/business-icon.png'"
                        alt="profile-image" class="rounded-full">
                    <a *ngIf="!groupDetails?.added_to_cart" class="bg-[#5040dd] p-2 rounded-full absolute"
                        style="top:50px;right: 0px;cursor: pointer;">
                        <img src="./assets/images/edit_btn.svg" alt="profile-edit" (click)="selectProfileImage.click();"
                            style="width:12px;height:12px;object-fit: contain;">
                    </a>

                </div>
                <form [formGroup]="contactForm" #contactNgForm="ngForm"
                    class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('first_name')">
                        <mat-label>First Name</mat-label>
                        <input id="first_name" matInput type="text" [formControlName]="'first_name'" class="text-black">
                        <mat-error *ngIf="contactForm.get('first_name').hasError('required')">
                            First name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('middle_name')">
                        <mat-label>Middle Name</mat-label>
                        <input id="middle_name" matInput type="text" [formControlName]="'middle_name'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('last_name')">
                        <mat-label>Last Name</mat-label>
                        <input id="last_name" matInput type="text" [formControlName]="'last_name'" class="text-black">
                        <mat-error *ngIf="contactForm.get('last_name').hasError('required')">
                            Last name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('email')">
                        <mat-label>Email Address</mat-label>
                        <input id="email" matInput type="email" [formControlName]="'email'" class="text-black">
                        <mat-error *ngIf="contactForm.get('email').hasError('required')">
                            Email address shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('phone_number')">
                        <mat-label>Phone Number</mat-label>
                        <input id="phone_number" matInput type="tel" [formControlName]="'phone_number'"
                            class="text-black">
                        <mat-error *ngIf="contactForm.get('phone_number').hasError('required')">
                            Phone number shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('tag')">
                        <mat-label>Tag</mat-label>
                        <input id="tag" matInput type="text" [formControlName]="'tag'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full " *ngIf="!lockedFields?.includes('address_line_1')">
                        <mat-label>Address Line 1</mat-label>
                        <input id="address_line_1" matInput type="text" [formControlName]="'address_line_1'"
                            class="text-black">
                        <mat-error *ngIf="contactForm.get('address_line_1').hasError('required')">
                            Address line 1 shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full " *ngIf="!lockedFields?.includes('address_line_2')">
                        <mat-label>Address Line 2</mat-label>
                        <input id="address_line_2" matInput type="text" [formControlName]="'address_line_2'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('city')">
                        <mat-label>City</mat-label>
                        <input id="city" matInput type="text" [formControlName]="'city'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('state')">
                        <mat-label>State</mat-label>
                        <input id="state" matInput type="text" [formControlName]="'state'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('zipcode')">
                        <mat-label>Zipcode</mat-label>
                        <input id="zipcode" matInput type="text" [formControlName]="'zipcode'" class="text-black">
                        <mat-error *ngIf="contactForm.get('zipcode').hasError('required')">
                            Zipcode shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_name')">
                        <mat-label>Company Name</mat-label>
                        <input id="company_name" matInput type="text" [formControlName]="'company_name'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_email')">
                        <mat-label>Company Email</mat-label>
                        <input id="company_email" matInput type="email" [formControlName]="'company_email'"
                            class="text-black">
                        <mat-error *ngIf="contactForm.get('company_email').hasError('required')">
                            A valid email id is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_phone')">
                        <mat-label>Company Phone</mat-label>
                        <input id="company_phone" matInput type="text" [formControlName]="'company_phone'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_fax')">
                        <mat-label>Company Fax</mat-label>
                        <input id="company_fax" matInput type="text" [formControlName]="'company_fax'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_fax')">
                        <mat-label>Company Fax</mat-label>
                        <input id="company_fax" matInput type="text" [formControlName]="'company_fax'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('company_website')">
                        <mat-label>Company Website</mat-label>
                        <input id="company_website" matInput type="text" [formControlName]="'company_website'"
                            class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="!lockedFields?.includes('designation')">
                        <mat-label>Designation</mat-label>
                        <mat-select name="designation" [formControlName]="'designation'"
                            (selectionChange)="onDesignationChanged()">
                            <mat-option *ngFor="let designation of designations" [value]="designation">
                                {{designation}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="contactForm.get('designation').hasError('required')">
                            Please choose a designation
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full"
                        *ngIf="showCustomDesignation && !lockedFields?.includes('designation')">
                        <mat-label>Custom Designation</mat-label>
                        <input [formControlName]="'custom_designation'" matInput class="text-black">
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

        <button class="btn_normal self-end" (click)="updateContactInfo()" *ngIf="!groupDetails?.added_to_cart">
            Update
        </button>
    </div>


</section>


<input accept="image/*" onclick="this.value = null" (change)="onImageChanged($event)" #selectProfileImage hidden="true"
    type="file" class="form-control text-black">