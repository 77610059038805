<div class="flex flex-col h-full">
    <div style="border-radius:10px;width:100%;height: 100%;overflow: hidden;" class="card" (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()" [style.background-color]="bgColor ?? '#f5f5f5'">
        <img fetchpriority="low" [src]="this.currentImage" loading="lazy" alt="Product Image" class="w-full h-full cursor-zoom-in grow relative"
            style="object-fit: contain;" (click)="openImage(currentImage)"
            onerror="this.src='./assets/images/placeholder.png'" fill>

    </div>
    <div class="flex items-center justify-between mt-4" *ngIf="productImages?.length > 1">
        <div class="flex flex-row gap-1 items-center w-full overflow-scroll">
            <img fetchpriority="low" *ngFor="let indicator of productImages;let i=index;" [alt]="'Image '+ index" class="cursor-pointer object-contain w-auto relative" [ngSrc]="indicator"
                [ngStyle]="{ 'border' : changeBackgroundCounter==i ?  '1px solid #5040dd' :'1px solid #e9e6ff'}"
                style="height:60px;border-radius: 10px; aspect-ratio: 15/11;" (click)="onclickIndicator(i)" fill>

            <!-- <mat-icon svgIcon="mat_outline:menu"></mat-icon> -->
        </div>

    </div>
</div>