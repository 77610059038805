import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { SearchService } from './search.service';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { ConfigurationService } from 'app/core/configuration/configuration.service';

export const searchDataResolver = (route: ActivatedRouteSnapshot) => {
    const _searchService = inject(SearchService);
    const _configService = inject(ConfigurationService);
    const filter = new FilterParams()
    filter.category = route.queryParams['category'] ?? null
    filter.sub_category = route.queryParams['sub_category'] ?? null
    filter.size = route.queryParams['size'] ?? null
    filter.search = route.queryParams['search'] ?? null
    filter.page = route.queryParams['page'] ? getInteger(route.queryParams['page']) : 1

    filter.get_filter_configuration = null

    const requestArray: Observable<any>[] = [_searchService.search(filter)]

    // if (filter.category != null)
    //     requestArray.push(_configService.getCategoryFranchises(filter.category))
    // else
    //     requestArray.push(_configService.getFranchises())

    return forkJoin(requestArray);
};
function getInteger(value: string) {
    let result = Number(value)
    return Number.isNaN(result) ? null : result
}