<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">CCPA</a>
    </div>
    <!-- / -->

    <div class="container">
        <div>
            <p ALIGN=JUSTIFY><span>Printing Warehouses of America
                    Inc. is complying with the CCPA (as it
                    pertains to us) and considering its underlying principles in how we think about customer trust and
                    data
                    privacy as a core pillar of our business. We are providing this CCPA-specific privacy notice to
                    supplement the information and disclosures already contained in our Privacy Policy. This notice
                    applies
                    to individuals residing in California from whom we collect Personal Information. </span></p>
            <p ALIGN=JUSTIFY><span>In general, we do not sell your Personal Information.</span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:justify;"><span>The
                    chart below outlines the categories of Personal Information (as defined by the CCPA) that we have
                    collected and/or disclosed for a business purpose in the preceding twelve months. The examples of
                    Personal Information provided for each category are taken from the CCPA and are included to help you
                    understand what the categories mean. The examples are not meant to indicate what we actually collect
                    or disclose, and more information about our specific practices can be found in our Privacy Policy.
                </span>
            </p>
            <BR>
            <table cellspacing="0" cellpadding="0" style="width:100%; border-collapse:collapse">
                <thead>
                    <tr>
                        <td
                            style="border-bottom:0.75pt solid #3697d9; padding:6pt 11.25pt 5.62pt; vertical-align:bottom; background-color:#5b9bd5">
                            <p ALIGN=JUSTIFY style="margin-bottom:15pt; font-size:11pt"><span>Category</span></p>
                        </td>
                        <td
                            style="border-bottom:0.75pt solid #3697d9; padding:6pt 11.25pt 5.62pt; vertical-align:bottom; background-color:#5b9bd5">
                            <p ALIGN=JUSTIFY style="margin-bottom:15pt; text-align:center; font-size:11pt"><span>We
                                    Collect</span></p>
                        </td>
                        <td
                            style="border-bottom:0.75pt solid #3697d9; padding:6pt 11.25pt 5.62pt; vertical-align:bottom; background-color:#5b9bd5">
                            <p ALIGN=JUSTIFY style="margin-bottom:15pt; text-align:center; font-size:11pt"><span>We
                                    Disclose</span></p>
                        </td>
                        <td
                            style="border-bottom:0.75pt solid #3697d9; padding:6pt 11.25pt 5.62pt; vertical-align:bottom; background-color:#5b9bd5">
                            <p ALIGN=JUSTIFY style="margin-bottom:15pt; text-align:center; font-size:11pt"><span>We
                                    Sell</span></p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:6pt 11.25pt 6pt; vertical-align:middle; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-weight:bold">A. Identifiers</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:6pt 11.25pt 6pt; vertical-align:middle; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:6pt 11.25pt 6pt; vertical-align:middle; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:6pt 11.25pt 6pt; vertical-align:middle; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Name,
                                    alias, postal address, unique personal identifier, online identifier, internet
                                    protocol
                                    address, email address, account name, social security number, driver’s license
                                    number,
                                    passport number, or other similar identifiers.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">B. Categories of Personal
                                    Information in
                                    Cal. Civ. Code 1798.80(e)</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Name,
                                    signature, social security number, physical characteristics or description, address,
                                    telephone number, passport number, driver’s license or state identification card
                                    number,
                                    insurance policy number, education, employment, employment history, bank account
                                    number,
                                    credit card number, debit card number, or any other financial information, medical
                                    information, or health insurance information.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">C. Characteristics of Protected
                                    Classifications under California or Federal Law</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Race
                                    or color, ancestry or national origin, religion or creed, age (over 40), mental or
                                    physical disability, sex (including gender and pregnancy, childbirth, breastfeeding
                                    or
                                    related medical conditions), sexual orientation, gender identity or expression,
                                    medical
                                    condition, genetic information, marital status, military and veteran status.</span>
                            </p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">D. Commercial Information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt">
                                <span>Yes</span>
                            </p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt">
                                <span>No</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Records of personal property, products or services purchased, obtained, reviewed, or
                                    considered, or other purchasing or consuming histories or tendencies.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">E. Biometric Information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>N/A
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>N/A
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Physiological, biological, or behavioral characteristics, including DNA, that can be
                                    used, singly or in combination with each other or with other identifying data, to
                                    establish individual identity, such as imagery of the iris, retina, fingerprint,
                                    face,
                                    hand, palm, vein patterns, and voice recordings, from which an identifier template,
                                    such
                                    as a faceprint, a minutiae template, or a voiceprint, can be extracted, and
                                    keystroke
                                    patterns or rhythms, gait patterns or rhythms, and sleep, health, or exercise data
                                    that
                                    contain identifying information.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">F. Internet or Other Electronic
                                    Network
                                    Activity Information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Browsing history, search history, and information regarding a consumer’s interaction
                                    with an internet website, application or advertisement.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">G. Geolocation Data</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Example:
                                    Precise physical location.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">H. Sensory Information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>N/A
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Audio, electronic, visual, thermal, olfactory, or similar information.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">I. Professional or
                                    employment-related
                                    information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt">
                                <span>Yes</span>
                            </p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt">
                                <span>Yes</span>
                            </p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Job
                                    application or resume information, past and current job history, and job performance
                                    information.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">J. Non-Public Education Information
                                    (as
                                    defined in 20 U.S.C. 1232g; 34 C.F.R. Part 99)</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>N/A
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>N/A
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Records that are directly related to a student maintained by an educational agency
                                    or
                                    institution or by a party acting for the agency or institution.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:1rem"><span
                                    style="font-family:Helvetica; font-weight:bold">K. Inferences Drawn from Personal
                                    Information</span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>Yes
                                </span></p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt">
                                <span>Yes</span>
                            </p>
                        </td>
                        <td
                            style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top; background-color:#f5f5f5">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span>No
                                </span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span>Examples:
                                    Consumer profiles reflecting a consumer’s preferences, characteristics,
                                    psychological
                                    trends, preferences, predispositions, behavior, attitudes, intelligence, abilities,
                                    and
                                    aptitudes.</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                        <td style="border-top:0.75pt solid #e8e8e8; padding:5.62pt 11.25pt 6pt; vertical-align:top">
                            <p ALIGN=JUSTIFY style="margin-bottom:0pt; text-align:center; font-size:11pt"><span
                                    style="font-family:Helvetica; -aw-import:ignore">&#xa0;</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p ALIGN=JUSTIFY style="margin-top:15pt; margin-bottom:7.5pt;"><span class="heading"
                    style="font-family:Helvetica; font-weight:bold">Use
                    of Personal Information </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span>The CCPA defines
                    various business and commercial purposes for collecting, using, and disclosing Personal Information.
                    While we collect, use, and disclose Personal Information pursuant to our </span><a
                    href="https://www.leadspedia.com/privacy-policy.html" style="text-decoration:none"><span
                        style="font-family:Helvetica; color:#000000">Privacy Policy</span></a><span> as a whole, we
                    wanted to clarify that this includes Personal
                    Information
                    in accordance with the specific CCPA business and commercial purposes as follows:</span></p>
            <ol type="1" style="margin:0pt; padding-left:0pt">
                <li
                    style="margin-top:14pt; margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Auditing related to a current interaction with you and concurrent transactions, including, but
                        not
                        limited to auditing compliance with this specification and other standards.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal
                        activity, and prosecuting those responsible for that activity.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Debugging to identify and repair errors that impair existing intended functionality.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Short-term, transient use.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Contracting with service providers to perform services on our behalf, including maintaining or
                        servicing accounts, providing customer service, processing or fulfilling orders and
                        transactions,
                        verifying customer information, processing payments, providing advertising or marketing
                        services,
                        providing analytic services, or providing similar services on behalf of the business or service
                        provider.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Undertaking internal research for technological development and demonstration.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Undertaking activities to verify or maintain the quality or safety of our services, and to
                        improve, upgrade, or enhance our services.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Otherwise enabling or effecting, directly or indirectly, a commercial transaction.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:14pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>For other purposes for which we provide specific notice at the time the information is
                        collected.</span>
                </li>
            </ol>
            <p ALIGN=JUSTIFY style="margin-top:15pt; margin-bottom:7.5pt;"><span class="heading"
                    style="font-family:Helvetica; font-weight:bold">Collection and Disclosure of Personal Information
                </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span>In the preceding
                    twelve
                    months since this notice was last updated, we have collected Personal Information from general
                    sources
                    including you, your use of our services, your devices, our affiliates, our vendors, and our service
                    providers. More specific information about Personal Information we collect is laid out in this
                    notice
                    and in our </span><a href="https://www.leadspedia.com/privacy-policy.html"
                    style="text-decoration:none"><span style="font-family:Helvetica; color:#000000">Privacy
                        Policy</span></a><span>, which we update from time to time.</span>
            </p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span>As also explained
                    in
                    our Privacy Policy, we share your Personal Information with the following categories of CCPA third
                    parties: </span></p>
            <ol type="1" style="margin:0pt; padding-left:0pt">
                <li
                    style="margin-top:14pt; margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Affiliates.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Vendors and service providers, including for data analytics and marketing and advertising our
                        products and services to you.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Third parties integrated into our services.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Third parties as required by law and similar disclosures.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:0pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Third parties in connection with a merger, sale, or asset transfer.</span>
                </li>
                <li style="margin-left:32.17pt; margin-bottom:14pt;; padding-left:3.83pt; font-family:Helvetica">
                    <span>Other third parties for whom we have obtained your permission to disclose your Personal
                        Information. </span>
                </li>
            </ol>
            <p ALIGN=JUSTIFY style="margin-top:15pt; margin-bottom:7.5pt;"><span class="heading"
                    style="font-family:Helvetica; font-weight:bold">Your
                    California Privacy Rights</span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span>If you are a
                    California resident, you may exercise the following rights. </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span class="heading" style="font-family:Helvetica; font-weight:bold">Right
                    to Know and Access.</span><span> You may submit a verifiable request
                    for information regarding the: (1) categories of Personal Information collected or disclosed by us;
                    (2) purposes for which categories of Personal Information are collected by us; (3) categories of
                    sources from which we collect Personal Information; and (4) specific pieces of Personal Information
                    we have collected about you during the past twelve months. </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span class="heading" style="font-family:Helvetica; font-weight:bold">Right
                    to Delete</span><span>. Subject to certain exceptions, you have the
                    option to delete Personal Information about you that we have collected from you. </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span
                    style="font-family:Helvetica; font-weight:bold">Verification.</span><span>
                    Requests for access to or deletion of Personal Information are subject to our ability to reasonably
                    verify your identity in light of the information requested and pursuant to relevant CCPA
                    requirements, limitations, and regulations.</span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span class="heading" style="font-family:Helvetica; font-weight:bold">Right
                    to Equal Service and Price.</span><span> You have the right not to
                    receive discriminatory treatment for the exercise of your CCPA privacy rights, subject to certain
                    limitations.
                </span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span class="heading" style="font-family:Helvetica; font-weight:bold">Shine
                    the Light.</span><span> We do not rent, sell, or share your Personal
                    Information with nonaffiliated companies for their direct marketing purposes, unless we have your
                    permission.</span></p>
            <p ALIGN=JUSTIFY style="margin-top:15pt; margin-bottom:7.5pt;"><span class="heading"
                    style="font-family:Helvetica; font-weight:bold">Contact Information</span></p>
            <p ALIGN=JUSTIFY style="margin-bottom:11.25pt;"><span>If you have any
                    questions, or would like more information on our privacy practices, please contact us at
                </span><a href="mailto:info@printingwarehouses.com"> info&#64;printingwarehouses.com</a><span> or by mail
                    at:</span></p>
            <p> Printing Warehouses of America Inc. <BR>
                6200 Metrowest Blvd, Suite 204<BR>
                Orlando, FL 32835<BR>
            </p>
        </div>
    </div>
</section>