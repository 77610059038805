<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" href="/social-media">Social Media</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" href="/social-media/posts">Posts</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">{{postDetails?.id}}</a>
    </div>
    <!-- / -->
    <!-- Detail page -->


    <!-- tab section starts -->
    <div class="p-0 mt-10  flex flex-row">
        <div class="w-full flex flex-col md:flex-row gap-6 justify-between md:items-center">
            <div class="gap-6 flex items-center w-full">
                <div class="flex items-center cursor-pointer" [routerLink]="'/social-media/posts'">
                    <mat-icon class="min-w-1 min-h-1 w-5 h-5" svgIcon="arrow_back_ios"></mat-icon>
                    <a class="cursor-pointer"><span class="ml-2 text-[#292362] font-semibold"
                            style="font-size:14px;">Back</span></a>
                </div>
                <div class="text-start w-full">
                    <p class="truncate w-full text-black font-bold" style="font-size:1rem;contain: inline-size;">
                        Ref Id:<span style="font-weight:normal;" class="ml-2"
                            [matTooltip]="postDetails?.reference_id">{{postDetails?.reference_id}}</span></p>
                </div>
            </div>
            <div class="w-full flex items-start sm:items-center flex-col sm:flex-row gap-2">
                <div class="flex items-center gap-6 w-full">
                    <span class="text-sm text-black leading-none mt-1">
                        <span *ngIf="!postDetails?.published && !postDetails?.is_draft && !postDetails?.error">Scheduled
                            for
                            {{postDetails?.scheduled_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                        <span *ngIf="postDetails?.published">Published on
                            {{postDetails?.published_time ??
                            postDetails?.latest_publish_history[0].published_time
                            | date:'MMM dd yyyy | hh:mm aa'}}</span>
                        <span *ngIf="postDetails?.is_draft > 0">Updated on
                            {{postDetails?.updated_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                        <span *ngIf="postDetails?.error > 0">Tried to publish on
                            {{postDetails?.published_time ??
                            postDetails?.latest_publish_history[0].published_time
                            | date:'MMM dd yyyy | hh:mm aa'}}</span>
                    </span>
                </div>
                <div style="flex-grow:1" class="justify-end flex w-full">
                    <button mat-flat-button class="mt-0 btn_normal w-full sm:w-auto" (click)="openNewPostPopup(null)">
                        <div class="items-center justify-center flex gap-2 ">
                            <span style="height:24px;"> <mat-icon svgIcon="heroicons_outline:plus"
                                    class="text-white"></mat-icon></span>
                            <p style="font-size: 12px;">New Post</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-6 mb-8 border-b" style="border-color: #d5d5d5;"></div>
    <div class="grid md:grid-cols-3 gap-8">
        <div>
            <fuse-card class="flex flex-col w-full filter-post">
                <!-- Post header -->
                <div class="flex items-center mx-4 mt-6 mb-4">
                    <img class="w-10 h-10 rounded-full" onError="this.src = 'assets/images/profile-icon.webp'"
                        alt="Profile image"
                        [src]="postDetails?.targets.length > 0 ? (postDetails?.targets[0].image_url ?? postDetails?.targets[0].profile_image_url ?? postDetails?.targets[0].profile_picture_url)  :  'assets/images/smm/empty_image_bg.png'">
                    <img *ngIf="postDetails?.targets.length > 1" class="w-10 h-10 rounded-full" alt="Profile image"
                        onError="this.src = 'assets/images/profile-icon.webp'"
                        [src]="postDetails?.targets.length > 1 ?  (postDetails?.targets[1].image_url ?? postDetails?.targets[1].profile_image_url ?? postDetails?.targets[1].profile_picture_url) :  'assets/images/smm/empty_image_bg.png'"
                        style="margin-left:-20px;box-shadow: -3px 0px 5px #00000057;">

                    <div class="flex flex-col ml-4 grow">
                        <span class="font-semibold leading-none">
                            <span *ngIf="getPageCount() != 0">{{getPageCountText()}}
                            </span>
                            <span *ngIf="getPageCount() == 0">
                                <i>No targets</i>
                            </span>
                        </span>
                        <span class="text-xs text-secondary leading-none mt-1">
                            <span
                                *ngIf="!postDetails?.published && !postDetails?.is_draft && !postDetails?.error">Scheduled
                                for
                                {{postDetails?.scheduled_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                            <span *ngIf="postDetails?.published">Published on
                                {{postDetails?.published_time ??
                                postDetails?.latest_publish_history[0].published_time
                                | date:'MMM dd yyyy | hh:mm aa'}}</span>
                            <span *ngIf="postDetails?.is_draft > 0">Updated on
                                {{postDetails?.updated_time | date:'MMM dd yyyy | hh:mm aa'}}</span>
                            <span *ngIf="postDetails?.error > 0">Tried to publish on
                                {{postDetails?.published_time ??
                                postDetails?.latest_publish_history[0].published_time
                                | date:'MMM dd yyyy | hh:mm aa'}}</span>
                        </span>
                    </div>
                    <div *ngIf="postDetails?.recurring_frequency != null" class="flex flex-col items-center"
                        style="width: 50px;min-width: 40px; text-align: center;font-size: 8px;color:#000;">
                        <img [src]="'assets/images/smm_recurring_list.png'" alt="Recurring icon"
                            style="width: 32px;border-radius: 0px;margin-right: 0px;">
                        Recurring
                    </div>
                </div>
                <!-- Post details -->
                <div class="flex mx-4 mb-4">
                    <div class="flex h-60 pr-0 w-full relative"
                        style="border-radius:8px;background-color:#f5f5f5;overflow: hidden;">
                        <!-- Post Image  -->
                        <img *ngIf="postDetails?.image_url" [src]="postDetails?.image_url" class="w-full h-full"
                            alt="Post image" style="object-fit: contain;">

                        <!-- Post text -->
                        <p class="truncate h-full w-fll"
                            style="margin-bottom: 0px; white-space: pre-wrap; padding: 5px;"
                            *ngIf="!postDetails?.image_url && postDetails?.text">{{postDetails?.text}}</p>

                        <!-- Post Error -->
                        <div *ngIf="postDetails?.error"
                            class="flex flex-col items-center justify-center p-4 absolute opacity-70"
                            style="bottom: 0px;left: 0px;top: 0px;right: 0px;background-color: #000;">
                            <img src="assets/images/ic_smm_error.svg" style="height: 50px;width: 50px;"
                                alt="Social media error icon">
                            <p style="padding: 20px;color: white;padding: 0px;margin: 0px;">Error !
                            </p>
                            <p style="padding: 20px;color: white;text-align: center;padding: 0px;margin: 0px;">
                                Something went wrong. Please reschedule this post</p>
                        </div>
                    </div>
                </div>
                <!-- Post text -->
                <p class="mb-4 -mt-3 mx-4 h-full w-fll" style="margin-bottom: 0px; white-space: pre-wrap;"
                    *ngIf="postDetails?.image_url && postDetails?.text">{{postDetails?.text}}</p>
            </fuse-card>
        </div>
        <div class="md:col-span-2">
            <h1 style="font-weight:700;font-size:18px;">Connected Targets</h1>
            <div *ngFor="let target of postDetails?.targets;last as isLast;"
                class="flex flex-row mt-6 items-start md:items-center gap-4">
                <div class="flex w-full items-center">
                    <img class="w-8 h-8 rounded-full" alt="Profile image" [src]="target.image_url">
                    <span class="font-normal leading-none ml-4">
                        {{target.title ?? target?.name}}
                        <div class="flex md:hidden items-center mt-1">
                            <img class="w-5 h-5 rounded-full" alt="Profile image"
                                [src]="target.target_type =='facebook' ? './assets/images/fb_icon.png' : target.target_type =='twitter' ? './assets/images/twitter_icon.png' : target.target_type =='instagram' ? './assets/images/instagram_icon.png':'./assets/images/linkedin.png'">
                            <span class="font-normal leading-none ml-2 text-sm">
                                {{target.target_type | titlecase}}
                            </span>
                        </div>
                    </span>

                </div>
                <div class="flex md:w-auto md:justify-end items-center grow shrink-0">
                    <div class="grid md:grid-cols-2 gap-4 md:gap-16 w-full md:w-auto">
                        <div class="hidden md:flex items-center">
                            <img class="w-5 h-5 rounded-full" alt="Profile image"
                                [src]="target.target_type =='facebook' ? './assets/images/fb_icon.png' : target.target_type =='twitter' ? './assets/images/twitter_icon.png' : target.target_type =='instagram' ? './assets/images/instagram_icon.png':'./assets/images/linkedin.png'">
                            <span class="font-normal leading-none ml-2">
                                {{target.target_type | titlecase}}
                            </span>
                        </div>
                        <div class="flex justify-end">
                            <button mat-flat-button class="text-[#292362] btn_white font-[500] "
                                (click)="openViewHistory(target)">
                                <div class="flex">
                                    <span class="hidden sm:flex">View&nbsp;</span>
                                    History
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Post text -->
            </div>

        </div>
    </div>
    <!-- / -->

</section>