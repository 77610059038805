import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PaymentService } from 'app/core/payment/payment.service';
import { PaymentCard } from 'app/core/payment/payment.types';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CreateAddressComponent } from '../settings/address/create-address/create-address.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CreateCardComponent } from '../settings/saved-cards/create-card/create-card.component';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails, CartItem, ShippingMethod } from 'app/core/cart/cart.types';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { PaymentLinkModal } from './payment-link/payment-link.component';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTabsModule, MatCheckboxModule, FormsModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatIconModule]
})

export class PaymentComponent implements OnInit {
  addressList: any[];
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  addressList$ = new BehaviorSubject<Address[]>(null);
  cards$ = new BehaviorSubject<PaymentCard[]>(null);
  constructor(
    private clipboard: Clipboard,
    private _fuseConfirmationService: FuseConfirmationService, private _spinner: NgxSpinnerService, private _userService: UserService,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _cardsService: PaymentService, private _cartService: CartService, private _router: Router) {

  }

  selectedTab = 0

  selectedBillingAddress = null
  selectedShippingAddress = null
  selectedCard = null
  selectedShipping = null

  showAddressAddButton = false
  showCardAddButton = false

  sendProofForApproval = false
  additionalInstructions = ""

  cartDetails: CartDetails = null
  cartItems: CartItem[] = []
  ngOnInit(): void {
    this._userService.addressList$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(addresses => {
        this.addressList$.next(addresses)
        this.showAddressAddButton = this.addressList$.value.length > 0
        this._changeDetectorRef.markForCheck()
      })

    this._cardsService.savedCards$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cards => {
        this.cards$.next(cards)
        this.showCardAddButton = this.cards$.value.length > 0
        this._changeDetectorRef.markForCheck()
      })
    this._cartService.cartDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cartDetails => {
        if (cartDetails.items.length == 0) {
          this.showCartEmptyOrReviewError(true)
          return
        } else {
          let incompleteItem = cartDetails?.items?.find(item => !item.review_completed)
          if (incompleteItem != null) {
            this.showCartEmptyOrReviewError(false)
            return
          }
        }

        cartDetails.items.forEach(item => {
          item.selectedQuantity = item.quantity_options.find(qty => qty.quantity == item.quantity).id
        })
        this.cartDetails = cartDetails
        this.cartItems = cartDetails.items
        this._changeDetectorRef.markForCheck()
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateAddressComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Address added successfully"), "Success");
          this._userService.getAddresses().subscribe()
        }
      });
  }

  isShippingBillingSame = false

  showNextTab() {
    switch (this.selectedTab) {
      case 0: {
        if (this.selectedShippingAddress == null) {
          this._toast.error("Please select a shipping address")
        } else {
          if (this.isShippingBillingSame) {
            this.selectedBillingAddress = this.selectedShippingAddress
            this.selectedTab = 2
          }
          else
            this.selectedTab = 1
        }
        break;
      }
      case 1: {
        if (this.selectedBillingAddress == null) {
          this._toast.error("Please select a billing address")
        }
        else {
          this.selectedTab = 2
        }
        break;
      }
    }
  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
    if (clickedIndex == 1 && this.isShippingBillingSame) {
      this.selectedBillingAddress = this.selectedShippingAddress
    }
    if (clickedIndex == 2)
      this.updateCartAddress()
  }

  setSameAddressForBilling(isSame) {
    if (isSame)
      this.selectedBillingAddress = this.selectedShippingAddress
  }

  addCard() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateCardComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Card added successfully"), "Success");
          // Refresh the keyword list
          //   this.showLoading(true)
          this._cardsService.getCards().subscribe()
        }
      });
  }

  updateCartAddress() {
    this._spinner.show();
    this._cartService.updateCartAddress({ "shipping_address": this.selectedShippingAddress.id, "billing_address": this.selectedBillingAddress.id }).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._cartService.getCartDetails().subscribe()
        this.getShipingDetails()
      },
      error: (err) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Sorry",
          message: err ? err.error.message : `Unable to update cart details. Please try again.`,
          dismissible: false,
          actions: {
            confirm: { label: "Retry" }
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == 'confirmed')
            this.updateCartAddress()
          else
            this.selectedTab = 1
        });
      }
    })
  }

  shippingMethods: ShippingMethod[]
  getShipingDetails() {
    this._spinner.show();
    this._cartService.getShippingMethods().subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.shippingMethods = res
        this.selectedShipping = res.find(shipping => shipping.selected)?.id


      },
      error: (err) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Sorry",
          message: err ? err.error.message : `Unable to get shipping details. Please try again.`,
          dismissible: false,
          actions: {
            confirm: { label: "Retry" }
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == 'confirmed')
            this.getShipingDetails()
          else
            this.selectedTab = 1
        });
      }
    })
  }

  updateShippingMethos() {
    this._spinner.show();
    this._cartService.updateShippingMethods({ "shipping": this.selectedShipping }).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.cartDetails = res
      },
      error: (err) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Sorry",
          message: err ? err.error.message : `Unable to update cart details. Please try again.`,
          dismissible: false,
          actions: {
            confirm: { label: "Retry" }
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == 'confirmed')
            this.updateShippingMethos()
        });
      }
    })
  }

  confirmAndPay(shareLink = false) {
    if (this.selectedShipping == null) {
      this._toast.error("Please select shipping method", "Sorry")
      return
    }
    else if (this.selectedCard == null && !shareLink) {
      console.log("Check error");
      return
    }

    this._spinner.show();
    let data = {
      "proofs_skipped": !this.sendProofForApproval,
      "instructions": this.additionalInstructions
    }

    if (shareLink)
      data['payment_link_requested'] = true
    else
      data['card_id'] = this.selectedCard

    this._cartService.checkOut(data).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        if (shareLink) {
          this.showPaymentLinkShare(res)
        }
        else {
          this.showCheckoutSuccess(res)
        }
      },
      error: (err) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Sorry",
          message: err ? err.error.message : `Something went wrong. Please try again.`,
          dismissible: false,
          actions: {
            confirm: { label: "Retry" }
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == 'confirmed')
            this.confirmAndPay()
        });
      }
    })
  }

  showCartEmptyOrReviewError(isCartEmpty) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Sorry",
      message: isCartEmpty ? `Your cart is empty. Please add products to your cart to continue.` : "Please review all item(s) before checkout",
      dismissible: false,
      actions: {
        cancel: { show: false },
        confirm: { label: "Ok" }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnDestroy()
      if (isCartEmpty)
        this._router.navigate(['/'])
      else
        this._router.navigate(['/', 'cart'])
    });
  }

  showCheckoutSuccess(res) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Checkout",
      message: res ? res.message : `Order placed successfully!`,
      dismissible: false,
      icon: { show: false },
      actions: {
        cancel: { show: false },
        confirm: { label: "Ok" }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._router.navigate(['/', 'settings', 'order-history'])
    });
  }

  showPaymentLinkShare(res) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { link: res.payment_link }
    this._matDialog.open(PaymentLinkModal, dialogConfig)
      .afterClosed().subscribe(value => {
        this._toast.success("Navigating to order histories.")
        this._router.navigate(['/', 'settings', 'order-history'])
      });


    // const dialogRef = this._fuseConfirmationService.open({
    //   title: "Payment Link",
    //   message: (res ? res.message : `Order processed successfully!`) + ". Copy the payment link and share.",
    //   dismissible: false,
    //   icon: { show: false },
    //   actions: {
    //     cancel: { show: false },
    //     confirm: { label: "COPY LINK" }
    //   }
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.clipboard.copy(res.payment_link)
    //   this._toast.success("Payment link is copied to the clipboard. Navigating to order histories.")
    //   this._router.navigate(['/', 'settings', 'order-history'])
    // });
  }

}
