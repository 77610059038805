import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-schedule-demo-popup',
  templateUrl: './schedule-demo.component.html',
  styleUrls: ['./schedule-demo.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule, MatProgressSpinnerModule,
    RouterModule]
})
export class ScheduleDemoPopup implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<ScheduleDemoPopup>
  ) { }

  ngOnInit(): void {

  }
  isLoading = true
  responseMessage = "";
  close() {
    this._dialogRef.close();
  }

}
