<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content>

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form [formGroup]="contactForm" #contactNgForm="ngForm" class="grid grid-cols-1 gap-2">

                    <div class=" h-fit">
                        <mat-radio-group class="flex flex-row mt-2 mb-2 -ml-2" [color]="'primary'" name="method"
                            [formControlName]="'method'" (change)="onMetodChange($event)">
                            <mat-radio-button class="mr-2" [value]="'single'">
                                Single
                            </mat-radio-button>
                            <mat-radio-button [value]="'multiple'">
                                Multiple
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field class="w-full" *ngIf="isSingleUpload">
                        <mat-label>Email</mat-label>
                        <input [formControlName]="'email'" matInput class="text-black">
                        <mat-error *ngIf="contactForm.get('email').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('email').hasError('email')">
                            A valid email id is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full" [floatLabel]="'always'" *ngIf="!isSingleUpload">
                        <mat-label>CSV File</mat-label>
                        <input matInput readonly id="attachment" type="text" [formControlName]="'attachment'"
                            class="text-black" [placeholder]="'Please choose a file'" #guideDocumentField />
                        <label class="hoverable object-none object-center" for="guideDocument" style="cursor: pointer;">
                            <a style="color: royalblue;">
                                <mat-icon class="text-xl px-2">attach_file
                                </mat-icon>
                            </a>
                        </label>
                        <input class="hidden text-black" id="guideDocument" type="file" accept="text/csv"
                            (change)="onSelectDocument($event)" #guideDocument>
                        <mat-error>
                            Please choose a valid CSV file
                        </mat-error>
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <button class="btn_normal p-4 pb-2 pt-2 w-50 mt-3" [disabled]="contactForm.disabled" type="submit"
        (click)="submit()" mat-flat-button>
        <span *ngIf="!contactForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="contactForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>