<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600">Autofill Manager</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="flex flex-col-reverse md:flex-row items-center gap-8 md:gap-20  w-full bg-[#e2f1ff] pt-[30px] pl-[30px]  pr-[30px] md:pr-[50px] md:pb-0 md:pt-0  md:pl-[50px]"
        style="border-radius:20px;">
        <div class="mt-10">
            <img class="h-[170px] sm:h-[220px]" src="./assets/images/autofill-manager-header.png" alt="Autofill manager"
                style="max-width:fit-content;height:220px;width: auto;">
        </div>
        <div>
            <h1 class="text-center md:text-start" style="font-weight:700;font-size:23px;">Autofill Manager</h1>
            <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">The
                Autofill Manager is a tool
                designed to streamline data entry and form completion by automatically populating fields with relevant
                information. It saves time and reduces errors by storing and organizing commonly used data, such as
                contact details, addresses, and more, for easy access and retrieval.</p>
        </div>
    </div>

    <div class="flex flex-col mt-10 gap-6 w-full bg-white card shadow-md border border-color-[#dedeff]"
        style="border-radius:20px;">
        <div class="flex flex-row p-6 pb-0 items-center gap-6">
            <img src="./assets/images/autofill-groups-icon.png" class="w-10 h-10 object-contain" alt="Group icon">
            <div class="grow text-black font-bold text-xl">
                Groups
            </div>
            <button class="btn_normal flex gap-1 items-center" (click)="openGroupCreateModal()">
                <mat-icon svgIcon="add" class="text-white hidden sm:flex"></mat-icon><span class="hidden sm:flex">Create
                    Group</span>
                <span class="flex sm:hidden">Create</span>
            </button>
        </div>
        <div class="border-b"></div>

        <ng-container *ngIf="groups?.length > 0; else noData">
            <div *ngFor="let group of groups; let last = last" class="flex flex-col pl-6 pr-6">
                <div class="flex flex-row gap-3 sm:gap-6 items-center">
                    <div [routerLink]="[group.id]"
                        class="cursor-pointer w-10 h-10 shrink-0 rounded-full bg-[#5040dd] text-xl text-white flex justify-center items-center">
                        {{group.name.substring(0, 1)}}
                    </div>
                    <div [routerLink]="[group.id]" class="cursor-pointer flex flex-col grow">
                        <div class="text-black text-lg font-bold">
                            {{group.name}}
                        </div>
                        <div class="text-black text-md">
                            {{group.added_at | date:'MMM dd, YYYY h:mm a'}}
                        </div>
                        <div class="text-black text-md sm:hidden">
                            {{group.contacts}} Contact{{group.contacts > 1 ? 's' : ''}}
                        </div>
                    </div>
                    <div [routerLink]="[group.id]" class="cursor-pointer text-black text-md hidden sm:flex">
                        {{group.contacts}} Contact{{group.contacts > 1 ? 's' : ''}}
                    </div>
                    <button [matMenuTriggerFor]="menu" class="flex">
                        <mat-icon svgIcon="mat_outline:menu"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editGroupDetails(group)">Edit</button>
                        <button mat-menu-item [routerLink]="[group.id]">
                            Details
                        </button>
                        <button mat-menu-item (click)="deleteGroup(group)">Delete</button>
                    </mat-menu>
                </div>
                <div class="border-b mt-6" *ngIf="!last"></div>
            </div>
        </ng-container>

        <div class="flex justify-center w-full pb-6">
            <a *ngIf="!showLoading && hasMoreItems" class="flex flex-col p-1 rounded-2xl mt-5 cursor-pointer"
                (click)="loadNextPage()">
                <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                    <div class="truncate">Load More</div>
                </div>
            </a>
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" *ngIf="showLoading">
            </mat-progress-spinner>
        </div>
    </div>



</section>

<ng-template #noData>
    <div class="max-w-200 flex flex-col items-center self-center mt-6 mb-6">
        <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
            You don’t have any group yet
        </p>
        <p class="text-black text-center sm:text-start" style="font-size:13px;">
            It appears there are no groups created yet. Create your first group.
        </p>
        <div class="flex mt-8">
            <button class="block btn_white m-auto sm:ml-0" (click)="openGroupCreateModal()" mat-flat-button>
                Create Group
            </button>
        </div>
    </div>
    <!-- / -->
</ng-template>