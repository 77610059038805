import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { BASE_URL } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { User } from '../user/user.types';
import { OrganizationService } from '../organization/organization.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { CartService } from '../cart/cart.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    @Output() loginNotificationEmitter: EventEmitter<User> = new EventEmitter<User>();
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _localStorage: LocalStorageService,
        private _organizationService: OrganizationService,
        private _notificationService: NotificationsService,
        private _cartService: CartService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(`${BASE_URL}user/reset/password/`, { email: email });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${BASE_URL}user/login/`, credentials).pipe(
            switchMap((response: any) => {
                if (response.need_otp_verify == true) {
                    return of(response)
                }
                this.onSigninSuccess(response)

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
    * Resend OTP
    *
    * @param otp_identifire
    */
    resendOTP(otpIdentifier): Observable<any> {
        return this._httpClient.post(`${BASE_URL}user/resend/otp/`, { otp_identifier: otpIdentifier })
    }

    /**
     * Submit OTP
     *
     * @param otpIdentifier
     * @param otp
     */
    submitOTP(otpIdentifier, otp): Observable<any> {
        return this._httpClient.post(`${BASE_URL}user/verify/otp/`, { otp_identifier: otpIdentifier, otp: otp }).pipe(
            switchMap((response: any) => {
                this.onSigninSuccess(response)
                return of(response);
            })
        );
    }

    onSigninSuccess(user: User) {

        this._localStorage.accountType = user.last_used_profile ?? 'user'
        this._organizationService.organization = user.organization
        // Store the access token in the local storage
        this.accessToken = user.token;
        // Store the access token in the local storage
        this._localStorage.accessToken = user.token;
        this._localStorage.organizationId = user.organization_id ? user.organization_id + '' : '0'
        // Set the authenticated flag to true
        this._authenticated = true;
        // Store the user on the user service
        this._userService.user = user;
        // Return a new observable with the response
        this.loginNotificationEmitter.emit(user);

        this._notificationService.getAll().subscribe()
        this._cartService.getCartDetails().subscribe()
        this._userService.get().subscribe()
    }

    loginWithToken(token) {
        let data = { 'token': token }
        return this._httpClient.post<any>(`${BASE_URL}user/login/token/`, data).pipe(
            switchMap((response: any) => {
                this.onSigninSuccess(response)
                return of(response);
            })
        );
      }

    getOneTimeToken() {
        // let data = { 'support_access': true }
        return this._httpClient.put<any>(`${BASE_URL}user/login/token/`, null)
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.clear();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}user/register/`, user).pipe(
            switchMap((response: any) => {
                this.onSigninSuccess(response)
                return of(response);
            })
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (this._localStorage.accessToken) {
            return of(true);
        }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        return of(false);
    }
}
