<div class="flex flex-col w-full h-full">
    <!-- Main -->
    <div class="flex-auto px-4 md:px-8 pt-0 sm:pt-4 overflow-y-auto m-auto pb-12 w-full" style="max-width:90%;"
        cdkScrollable>

        <!-- CONTENT GOES HERE -->
        <section class="flex-auto m-auto">
            <!-- Bread crumb -->
            <div class="breadcrumb flex mt-4 mb-8">
                <a class="text-black" [routerLink]="['/','home']">Home</a>
                <span class="ml-2 mr-2">/</span>
                <a class="text-black">{{(selectedProduct?.is_printable || selectedProduct?.is_printing)? 'Print' :
                    'Digital'}} Products</a>
                <span class="ml-2 mr-2">/</span>
                <a class="text-black" *ngIf="subCategory"
                    [routerLink]="['/','category', category?.slug]">{{category?.title}}</a>
                <span class="ml-2 mr-2" *ngIf="subCategory">/</span>
                <p style="font-weight:600">{{subCategory ? subCategory?.title : selectedProduct?.title}}</p>
            </div>
            <!-- / -->
            <!-- Detail page -->
            <div class="flex flex-col md:flex-row gap-4 sm:gap-[45px] w-full">
                <div class="grow">
                    <div class="flex flex-col md:flex-row" style="gap:40px">
                        <div>
                            <div class="flex flex-col lg:flex-row" style="gap:40px">

                                <!-- Image Carousel -->
                                <pwa-image-carousel class="w-full md:w-90 shrink-0 h-60"
                                    [images]="subCategory ? [subCategory?.image] : isVersionDetails ? getImagesList(this.selectedProduct) : this.selectedProduct?.images"></pwa-image-carousel>

                                <!-- Product Details -->
                                <div>
                                    <h1 style="font-weight:700;font-size:23px;">{{subCategory ? subCategory?.title :
                                        this.selectedProduct?.title}}</h1>
                                    <!-- <p class="mt-1 text-black" style="font-weight:400;font-size:14px;">
                                        {{subCategory?.template_name}}
                                    </p> -->
                                    <h2 class="mt-1 text-black" style="font-weight:400;font-size:14px;">
                                        {{subCategory ? subCategory?.sub_title : this.selectedProduct?.sub_title}}
                                    </h2>
                                    <p class="mt-3 mb-3 text-black" style="font-weight:400;font-size:14px;">
                                        {{subCategory ? subCategory?.description : this.selectedProduct?.description}}
                                    </p>
                                    <ul class="max-w-md space-y-1 text-black list-disc list-inside"
                                        *ngIf="subCategory?.bullets?.length > 0">
                                        <li style="color:#5040dd"
                                            *ngFor="let bullet of subCategory?.bullets?.split('\n')">
                                            <span style="color:#000000">{{bullet}}</span>
                                        </li>
                                    </ul>

                                    <form class="mt-10"
                                        *ngIf="products?.length > 0 && (!isProductDetails && !isVersionDetails) && (user == null || !(selectedProduct?.is_printable || selectedProduct?.is_printing))">
                                        <mat-form-field class="w-full sm:w-80 lg:w-full" floatLabel="always">
                                            <mat-label>Product</mat-label>
                                            <mat-select [(ngModel)]="selectedProductId"
                                                (selectionChange)='getProductDetails($event.value)'
                                                [ngModelOptions]="{standalone: true}" class="text-black  w-full"
                                                placeholder="Please select a product">
                                                <mat-option *ngFor="let product of products;"
                                                    [value]="product.id">{{product.title}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>

                                    <!-- Pricing calculator -->
                                    <ng-container
                                        *ngIf="isScreenSmall && (selectedProduct?.is_printable || selectedProduct?.is_printing)">
                                    <div class="mt-8">
                                        <ng-container *ngTemplateOutlet="itemTemplate" class="mt-4 "></ng-container>
                                    </div>
                                        
                                    </ng-container>
                                    <p class="mt-4 " style="font-weight:400;font-size:16px;"
                                        *ngIf="selectedProduct?.is_printable || selectedProduct?.is_accessory || selectedProduct?.is_printing">
                                        Price starts
                                        at<span class="ml-2" style="font-weight:400;font-size:16px;font-weight:600;">
                                            ${{selectedProduct?.price}}</span></p>
                                    <div *ngIf="selectedProduct != null"
                                        class="mt-4 flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 sm:gap-4 lg:gap-2 items w-full sm:w-[fit-content]">
                                        <button mat-flat-button
                                            class="flex items-center w-auto sm:w-auto justify-center btn_normal"
                                            (click)="openDesignSelection()"
                                            *ngIf="selectedProduct?.is_accessory == false">
                                            <div class="justify-center flex gap-2 items-center">
                                                <span style="width: 15px !important;height: 15px !important;"><img
                                                        class="mr-2 object-contain w-full h-full" alt="Choose Template Icon"
                                                        src="./assets/images/startyour_design.svg"></span>
                                                {{selectedProduct?.start_button_title?.toLowerCase() ??
                                                'Choose Template'}}
                                            </div>
                                        </button>
                                        <button mat-flat-button
                                            class="items-center w-auto sm:w-auto justify-center btn_normal"
                                            (click)="openDesignSelection()"
                                            *ngIf="selectedProduct?.is_accessory == true">
                                            <!-- <span>
                                     <img class="mr-2" alt="Choose-icon"
                                         src="./assets/temp/choose_icon.svg"
                                         style="width: 20px;height:20px;object-fit: contain;">
                                 </span> -->
                                            Choose Your Accessory</button>
                                        <button mat-flat-button class="items-center w-auto sm:w-auto btn_white"
                                            (click)="openUploadDesign()"
                                            *ngIf="selectedProduct?.is_accessory == false && !isVersionDetails">
                                            <div class="justify-center flex gap-2 items-center">
                                                <span style="width: 15px !important;height: 15px !important;"><img
                                                        class="mr-2 object-contain w-full h-full" alt="Upload Design Icon"
                                                        src="./assets/images/upload_icon.svg"></span>
                                                Create Your Design
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- Featured Products -->
                            <ng-container *ngIf="featuredTemplates?.length > 0">
                                <div class="flex flex-row justify-between items-center mt-10">
                                    <h2 style="font-weight:600;font-size:20px;">Featured Templates</h2>
                                </div>
                                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-6">
                                    <ng-container *ngFor="let product of featuredTemplates;">
                                        <app-design-view [product]="product" class="cursor-pointer" [showMenu]="false"
                                            (click)="getVersionHistory(product)"></app-design-view>
                                    </ng-container>
                                </div>
                            </ng-container>


                            <!-- Similar Categories -->
                            <ng-container *ngIf="relatedCategories?.length > 0">
                                <div class="flex flex-row justify-between items-center mt-10">
                                    <h2 style="font-weight:600;font-size:20px;">Related Categories</h2>
                                </div>
                                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-6"
                                    *ngIf="relatedCategories?.length > 0">
                                    <ng-container *ngFor="let cat of relatedCategories;">
                                        <app-design-view [product]="cat" class="cursor-pointer" [showMenu]="false"
                                            [routerLink]="['/','category', category?.slug, cat.slug]"></app-design-view>
                                    </ng-container>
                                </div>
                            </ng-container>

                        </div>
                        <!-- /similar categories -->

                    </div>

                    <!-- Testimonials -->
                    <!-- <div class="flex flex-row justify-between mt-8 items-end">
                 <div>
                     <h2 class="text-black" style="font-weight:bold;font-size:20px;">Testimonials</h2>
                     <div class="flex flex-col sm:flex-row items-start sm:items-end">
                         <div class="flex items-center mt-4 w-[auto] sm:w-[200px]">
                             <div class="flex gap-1">
                                 <img alt="Rating" src="./assets/temp/rating.svg"
                                     style="width: 20px;height:20px;object-fit: contain;">
                                 <img alt="Rating" src="./assets/temp/rating.svg"
                                     style="width: 20px;height:20px;object-fit: contain;">
                                 <img alt="Rating" src="./assets/temp/rating.svg"
                                     style="width: 20px;height:20px;object-fit: contain;">
                                 <img alt="Rating" src="./assets/temp/rating.svg"
                                     style="width: 20px;height:20px;object-fit: contain;">
                                 <img alt="Rating" src="./assets/temp/rating.svg"
                                     style="width: 20px;height:20px;object-fit: contain;">
                             </div>
                             <p class="ml-2">4.5</p>
                         </div>
                         <a href="#" class="text-sm text-black underline">3245
                             reviews</a>
                     </div>
                 </div>
                 <button class="w-auto  mt-4 sm:mt-0 font-sm" (click)="openReviewPopup()"
                     style=" border-radius: 8px;border: solid 1px #ddd;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);padding:0.65rem 1.4rem;height:fit-content;">
                     <p style="color:#292362;font-weight:500;font-size:14px;">Write a Review</p>
                 </button>
             </div> -->

                    <!-- / -->
                </div>
                <div class="shrink-0 md:max-w-90">
                    <ng-container
                        *ngIf="!isScreenSmall && (selectedProduct?.is_printable || selectedProduct?.is_printing)">
                        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
                    </ng-container>
                    <div class="bg-[#f7f5ff] text-black text-start pl-10 pr-10 pt-10 pb-7"
                        style="border-radius:30px 30px 0px 0px;">
                        <p class="mb-1 text-start" style="font-weight:600;font-size:20px;">
                            Looking for <br> Designer Support?
                        </p>
                        <p class="mb-5" style="font-size:13px;">
                            Our expert designers are here to assist you every step of the way. Hire your
                            dedicated designer today and bring your vision to life with professional expertise
                            and personalized attention
                        </p>
                        <button mat-flat-button class="btn_white flex justify-center items-center"
                            (click)="openDesignerRequirementModal()">
                            <span class="flex gap-2 items-center">
                                <img alt="Add Designer Icon" src="./assets/images/designer_icon.svg"
                                    style="width: 20px;height:20px;object-fit: contain;">
                                Hire Your Designer
                            </span>
                        </button>
                    </div>
                    <div class="bg-[#e6e3ff] text-black text-start  pt-6 pb-10 pl-10 pr-10"
                        style="border-radius:0px 0px 30px 30px;">
                        <p style="font-size:13px;">Please dial in to inquire about any special
                            pricing that may be offered.</p>
                        <a href="tel:8002823122" class="items-center justify-start gap-3 flex mt-3">
                            <img class="flex" src="./assets/images/phone_icon.svg"
                                style="width:22px;height:22px;object-fit:contain" alt="Phone Icon">
                            <p class="text-black text-md"
                                style="font-family: 'Poppins', sans-serif;font-weight:600;margin-left:0px !important;font-size:16px !important">
                                800
                                282 3122</p>
                        </a>
                    </div>
                </div>
            </div>
            <!-- / -->
        </section>
    </div>
</div>

<ng-template #itemTemplate>
    <div class="mb-8"
        style="min-width:auto;height:fit-content;border-radius: 30px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); border: solid 2px #e4e3ff;padding:36px;">
        <p class="mb-4" style="font-weight:bold;font-size:23px;">Price Calculator</p>
        <form *ngIf="user != null">
            <mat-form-field class="w-full mt-2" floatLabel="always" [subscriptSizing]="'dynamic'"
                *ngIf="!isProductDetails && !isVersionDetails">
                <mat-label>Product</mat-label>
                <mat-select [(ngModel)]="selectedProductId" (selectionChange)='getProductDetails($event.value)'
                    [ngModelOptions]="{standalone: true}" class="text-black  w-full"
                    placeholder="Please select a product">
                    <mat-option *ngFor="let product of products;" [value]="product.id">{{product.title}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full mt-2" *ngFor="let config of configurations; let i = index" floatLabel="always"
                [subscriptSizing]="'dynamic'">
                <mat-label>{{config.title}}</mat-label>
                <mat-select [(ngModel)]="config.selected" (selectionChange)='onOptionsSelected($event.value, i)'
                    [ngModelOptions]="{standalone: true}" class="text-black  w-full"
                    placeholder="Please select an option">
                    <mat-option *ngFor="let option of config.options;let i=index;"
                        [value]="option.id">{{option.title}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full mt-2" *ngIf="quantityList?.length > 0" floatLabel="always"
                [subscriptSizing]="'dynamic'">
                <mat-label>Quantity</mat-label>
                <mat-select [(ngModel)]="selectedQuantity" (selectionChange)='onSelectQuantity($event.value)'
                    [ngModelOptions]="{standalone: true}" class="text-black  w-full"
                    placeholder="Please select an option">
                    <mat-option *ngFor="let quantity of quantityList; let i = index"
                        [value]="quantity">{{quantity.quantity +' ($'+quantity.price+')'}}</mat-option>
                </mat-select>
            </mat-form-field>

            <p class="mt-4" style="font-size:18px;">Price <span class="ml-2"
                    style="font-size:20px;font-weight:600;">${{selectedQuantity ? selectedQuantity?.price : '-'}}</span>
            </p>
            <button mat-flat-button class="block mt-4 w-full text-center cursor-pointer btn_red"
                (click)="priceCalculatorSubmit()" style="padding:0.67rem 2rem !important;">
                Place Your Order</button>
            <p class="text-start mt-4 m-auto" style="font-size:12px;font-weight:200;line-height: 1.2;">
                * Pricing does not include Taxes, Shipping and Handling</p>
        </form>

        <div class="flex flex-col h-full" *ngIf="user == null">
            <!-- Order history - Message -->
            <p class="text-black mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
                Unlock Pricing Insights.
            </p>
            <p class="text-black" style="font-size:13px;font-weight:normal;font-family:poppins">
                Effortlessly Estimate Expenses with Our Price Calculator Tool. Log in to Access
                Our Price Calculator!
            </p>
            <div class="flex mt-8">
                <button mat-flat-button class="block btn_white" (click)="openSignin()" style="align-self: flex-start;">
                    Login
                </button>
            </div>
            <!-- / -->
        </div>
    </div>
</ng-template>