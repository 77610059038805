<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1 pb-1">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <form [formGroup]="productForm" #productNgForm="ngForm" class="w-full grid grid-cols-1 gap-2">
            <mat-form-field class="w-full">
                <mat-label>Title</mat-label>
                <input id="title" matInput type="text" [formControlName]="'title'" class="text-black">
                <mat-error *ngIf="productForm.get('title').hasError('required')">
                    Title shouldn't be empty
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full -mt-[10px]">
                <mat-label>Subtitle</mat-label>
                <input id="sub_title" matInput type="text" [formControlName]="'sub_title'" class="text-black">
                <mat-error *ngIf="productForm.get('sub_title').hasError('required')">
                    Subtitle shouldn't be empty
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full -mt-[10px]">
                <mat-label>Description</mat-label>
                <textarea id="description" matInput type="text" [formControlName]="'description'"></textarea>
                <mat-error *ngIf="productForm.get('description').hasError('required')">
                    Description shouldn't be empty
                </mat-error>
            </mat-form-field>

            <!-- Alert -->
            <fuse-alert class="mt-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
                [dismissible]="true">
                {{alert.message}}
            </fuse-alert>
        </form>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button class="w-50 mt-3 btn_normal" [disabled]="productForm.disabled" mat-flat-button [color]="'primary'"
        type="submit" (click)="submit()">
        <span *ngIf="!productForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="productForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>