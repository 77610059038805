<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">Hire Designer</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1 pb-1">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full sm:min-w-100 max-w-120 mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form novalidate class="grid grid-cols-1 md:grid-cols-2 gap-2" [formGroup]="contactForm"
                    #contactNgForm="ngForm">
                    <mat-form-field class="w-full col-span-1">
                        <mat-label class="text-black">First Name</mat-label>
                        <input matInput [formControlName]="'firstName'" class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full col-span-1">
                        <mat-label class="text-black">Last Name</mat-label>
                        <input matInput [formControlName]="'lastName'" class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full col-span-1">
                        <mat-label class="text-black">Phone</mat-label>
                        <input matInput [formControlName]="'phoneNumber'" required class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full col-span-1">
                        <mat-label class="text-black">Email</mat-label>
                        <input matInput [formControlName]="'email'" required class="text-black">
                    </mat-form-field>
                    <mat-form-field class="w-full col-span-1 md:col-span-2">
                        <mat-label class="text-black">Requirement</mat-label>
                        <textarea matInput [formControlName]="'message'" required rows="5"></textarea>
                        <mat-hint>Please describe your requirement</mat-hint>
                    </mat-form-field>
                    <!-- Alert -->
                    <fuse-alert class="w-full col-span-1 md:col-span-2 mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>

                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button mat-flat-button class="w-50 mt-3 btn_normal" [disabled]="contactForm.disabled" mat-flat-button
        [color]="'primary'" type="submit" (click)="submit()">
        <span *ngIf="!contactForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="contactForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>