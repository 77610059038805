<div style="border-radius:8px;width:100%;height:150px;background-color:#f5f5f5;overflow: hidden;"
    class="card relative hover:shadow-xl border border-[#f5f5f5]" (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()">
    <img fetchpriority="low" [ngSrc]="imgSrc" fill onerror="this.src='./assets/images/placeholder.png'" loading="lazy"
        alt="{{_productVersion?.title}}" class="w-full h-full" style="object-fit: contain;">
    <div *ngIf="_productVersion?.user_tag"
        class="absolute pt-1 pb-1 pl-2 pr-2 rounded-md bg-[#4563d4] text-white text-sm" style="top: 10px; left: 10px;">
        {{_productVersion?.user_tag?.tag}}
    </div>

</div>
<div class="flex items-center mt-2 w-full">
    <div class="grow w-[80%] shrink">
        <p class="text-black truncate tooltip text-ellipsis mb-1 w-full" style="font-size:14px;font-weight:400;">
            {{_productVersion?.title}}</p>
        <p class=" text-[#848484] truncate text-ellipsis" style="font-size:12px;font-weight:400;">
            {{_productVersion?.template_name}}</p>
    </div>

    <div class="flex flex-row gap-1 items-center shrink-0">
        <ng-container *ngIf="_productVersion?.images?.length > 1">
            <div *ngFor="let indicator of _productVersion?.images;let i=index;"
                [ngStyle]="{'background-color' : changeBackgroundCounter==i ?  '#5040dd' :'#e9e6ff'}"
                style="width:16px;border-radius:50px;height:5px;"
                (click)="$event.stopPropagation();onclickIndicator(i)"></div>

        </ng-container>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Products menu" *ngIf="showMenu"
            (click)="$event.stopPropagation()">
            <mat-icon svgIcon="mat_outline:menu"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onClickDelete()">
                <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="!_productVersion?.user_tag" (click)="onClickAssignTag()">
                <span>Assign Tag</span>
            </button>
            <button mat-menu-item *ngIf="_productVersion?.user_tag" (click)="onClickUpdateTag()">
                <span>Update Tag</span>
            </button>
            <button mat-menu-item *ngIf="_productVersion?.user_tag" (click)="onClickRemoveTag()">
                <span>Remove Tag</span>
            </button>
        </mat-menu>
    </div>

</div>