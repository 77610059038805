import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { PWAConfiguration } from 'app/core/configuration/configuration.types';
import { ProductService } from 'app/core/product/product.service';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-upload-design',
  templateUrl: './upload-design.component.html',
  styleUrls: ['./upload-design.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule,
    MatFormFieldModule, MatSelectModule, MatTabsModule, MatInputModule, FuseAlertComponent,
    MatIconModule, MatSidenavModule, MatButtonModule, RouterModule]
})
export class UploadDesignPopup implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { versionId, versionHistory: number, category, subCategory, createType },
    private _configurationService: ConfigurationService, private _matDialog: MatDialog,
    private _productService: ProductService, private _spinner: NgxSpinnerService, private _toast: ToastrService,
    private _dialogRef: MatDialogRef<UploadDesignPopup>) {
    this.createType = this.data?.createType ?? 'create'
  }

  sideOneName = ''
  sideTwoName = ''
  showError
  createType = 'create'
  maxSides = 1
  noOfSides = 1
  ngOnInit(): void {

    this._configurationService.configuration$.subscribe((config: PWAConfiguration) => {
      let category = config.digital_categories.concat(config.print_categories).find(cat => cat.id == this.data?.category)
      this.maxSides = category?.sub_categories?.find(sub => sub.id == this.data?.subCategory)?.maximum_sides

    })
  }


  // title = "Create Checklist Item";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = "";
  close(versions) {
    this._dialogRef.close(versions);
  }

  sideOneImage
  sideTwoImage
  onImageChanged(ev, imageSelectionForSideOne) {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (imageSelectionForSideOne)
          this.sideOneImage = reader.result;
        else
          this.sideTwoImage = reader.result;
      }
      reader.readAsDataURL(file);
    }
  }

  submit() {
    this.showError = false
    this.showAlert = false
    if (this.checkSideOne() && this.checkSideTwo()) {
      this.createDesign(true)
    }

  }

  checkSideOne(): boolean {

    if (this.sideOneName?.length > 0) {
      if (this.sideOneImage != null || this.createType == 'create') {
        return true
      }
      else {
        this.showAlert = true
        this.alert.type = 'error'
        this.alert.message = "Please select design to upload"
      }
    }
    else
      this.showError = true

    return false
  }

  checkSideTwo(): boolean {
    if (this.noOfSides == 1)
      return true
    if (this.sideTwoName?.length > 0) {
      if (this.sideTwoImage != null || this.createType == 'create') {
        return true
      }
      else {
        this.showAlert = true
        this.alert.type = 'error'
        this.alert.message = "Please select design to upload"
      }
    }
    else
      this.showError = true

    return false
  }

  createDesign(isFirstDesign) {
    let formData = new FormData()

    formData.set('history_id', this.data?.versionHistory?.toString())
    formData.set('title', isFirstDesign ? this.sideOneName : this.sideTwoName)
    if (this.createType == 'upload')
      formData.set('image', isFirstDesign ? this.sideOneImage : this.sideOneImage)
    formData.set('has_design_source', this.createType == 'create' ? '1' : '0')

    this._spinner.show()
    this._productService.createCustomProductDesign(formData).subscribe({
      next: response => {
        this._spinner.hide()
        if (!isFirstDesign || this.noOfSides == 1) {
          this.getVersionHistory()
        } else if (this.noOfSides > 1) {
          this.createDesign(false)
        }
      },
      error: error => {
        this._spinner.hide()
        this.showAlert = true
        this.alert.type = 'error'
        this.alert.message = error?.error?.message ?? "Something went wrong. Please try again"
      }
    })
  }

  getVersionHistory() {
    this._spinner.show()
    this._productService.getProductHistory(this.data?.versionId).subscribe({
      next: versions => {
        this._spinner.hide()
        this.close(versions)
      },
      error: error => {
        this._spinner.hide()
        this._toast.success("Your designs has been created!", "Success")
      }
    })
  }

}
