import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { FAQ, FAQCategories, FAQCategory, FAQList, FAQVideo, FAQVideoList } from './faq.types';
export type PaymentType = "monthly" | "yearly";
@Injectable({ providedIn: 'root' })
export class FAQService {

    constructor(private _httpClient: HttpClient) {
    }

    private _faqCategories: BehaviorSubject<FAQCategory[]> = new BehaviorSubject(null);
    get faqCategories$(): Observable<FAQCategory[]> {
        return this._faqCategories.asObservable();
    }

    getCategories(): Observable<FAQCategories> {
        return this._httpClient.get<FAQCategories>(`${BASE_URL}help/category/?batch_size=100`).pipe(
            tap((plans: FAQCategories) => {
                this._faqCategories.next(plans.data);
            }),
        );
    }

    getFAQs(categoryId): Observable<FAQList> {
        return this._httpClient.get<FAQList>(`${BASE_URL}help/category/${categoryId}/faq/?batch_size=100`)
    }

    getVideos(categoryId): Observable<FAQVideoList> {
        return this._httpClient.get<FAQVideoList>(`${BASE_URL}help/help_video/?category=${categoryId}&batch_size=100`)
    }

}
