import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaymentCard } from 'app/core/payment/payment.types';
import { CreateCardComponent } from '../../settings/saved-cards/create-card/create-card.component';


@Component({
  selector: 'app-card-chooser',
  templateUrl: './card-chooser.component.html',
  styleUrls: ['./card-chooser.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class CardChooserComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentCard, private _cardsService: PaymentService, private _toast: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<CardChooserComponent>) {
    this.responseMessage = { card: data }
  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  paymentCards: PaymentCard[] = [];
  ngOnInit(): void {
    this._cardsService.savedCards$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cards => {
        this.paymentCards = cards
        this._changeDetectorRef.markForCheck()
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addCard() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateCardComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Card added successfully"), "Success");
          this._cardsService.getCards().subscribe()
        }
      });
  }

  title = "Saved Cards";

  submit() {
    if (this.selectedBillingCard == null) {
      this._toast.error("Please choose a card", "Sorry");
    } else
      this.responseMessage = { card: this.selectedBillingCard }
    this.close()
  }

  //Close modal with a response message
  responseMessage: { card: PaymentCard }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  selectedBillingCard = null

}
