import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule, MatDialogModule, CommonModule, RouterModule,
  ]
})
export class PaymentLinkModal implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { link: string },
    private _router: Router, private _dialogRef: MatDialogRef<PaymentLinkModal>,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private clipboard: Clipboard
  ) {
    this.paymentLink = data.link
    this.matIconRegistry.addSvgIcon(
      "nav_gift_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav_gift_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "link_share_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/link_share_icon.svg")
    );
  }

  ngOnInit(): void {

  }

  title = "Payment Link";
  paymentLink = ""

  close() {
    this._dialogRef.close();
  }

  successAnimationRes = './assets/lottie/lottie_success1.json'
  showSuccessAnimation = false
  onClickCopy(referralCode) {
    this.clipboard.copy(referralCode)
    if (!this.showSuccessAnimation) {
      this.showSuccessAnimation = true
      this.successAnimationRes = this.successAnimationRes + '?a=' + Math.random()
    }
  }

}
