import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CartService } from 'app/core/cart/cart.service';
import { PaymentService } from 'app/core/payment/payment.service';
import { UserService } from 'app/core/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';

export const paymentForOtherDataResolver = (route: ActivatedRouteSnapshot) => {
    const _cartService = inject(CartService);
    const _toastService = inject(ToastrService);
    const _router = inject(Router);
    const requestArray: Observable<any>[] = [
        _cartService.getPaymentLinkDetails(route.params['token']).pipe(
            catchError(error => {
                _toastService.error(error.error.message ?? "Something went wrong.")
                _router.navigate(['/'])
                return of(null);
            })
        )
    ]
    return forkJoin(requestArray);
};
