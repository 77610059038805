import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})

export class PrivacyPolicyComponent implements OnInit {

  
  ngOnInit(): void {
  }

  /**
   * Constructor
   */
  constructor() {

  }
}