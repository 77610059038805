import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AllocatedEmployee } from 'app/core/organization/organization.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { ProductService } from 'app/core/product/product.service';
import { Product } from 'app/core/product/product.types';
import { OrganizationService } from 'app/core/organization/organization.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateEmployeeComponent } from '../settings/organization-employee/create-employee/create-employee.component';


@Component({
  selector: 'employee-access',
  templateUrl: './employee-access.component.html',
  styleUrls: ['./employee-access.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, MatIconModule, MatSlideToggleModule, FormsModule]
})

export class EmployeeAccessComponent implements OnInit {

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  employees: AllocatedEmployee[]
  hasMoreItems = false

  product: Product
  /**
   * Constructor
   */
  constructor(private _organizationService: OrganizationService, private broadcast: BroadcastService,
    private _changeDetectorRef: ChangeDetectorRef, private _conformation: FuseConfirmationService,
    private _toast: ToastrService, private _spinner: NgxSpinnerService, private _router: Router,
    private _route: ActivatedRoute, private _productService: ProductService, private _matDialog: MatDialog) {

  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this._organizationService.allocatedEmployees$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(employees => {
        this.employees = employees
        this._changeDetectorRef.markForCheck();
      })

    this._productService.productDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(product => {
        this.product = product
        this._changeDetectorRef.markForCheck();
      })

    this._organizationService.hasMoreProducts$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((hasMore: boolean) => {
        this.hasMoreItems = hasMore
        this._changeDetectorRef.markForCheck();
      });

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      if (data.payload == 'user')
        this.showNoAccessPopup()
    })
  }

  showNoAccessPopup() {
    const dialogRef = this._conformation.open({
      title: "Restricted Access",
      message: `This page can be accessed only by an organization. we are taking you to profile information`,
      dismissible: false,
      actions: { cancel: { show: false }, confirm: { label: "Ok" } }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._router.navigate(['/', 'settings'])
    });
  }

  showLoading = false
  loadNextPage() {
    this.showLoading = true
    this._organizationService.getEmployeesAllocated(this.product?.id, false, true).subscribe({
      complete: () => { this.showLoading = false }
    });
  }

  addEmployee() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateEmployeeComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Employee added successfully"), "Success");
          this._organizationService.getAdmins().subscribe()
        }
      });
  }

  updateEmployeeAccess(employee, isEnabled) {
    employee.is_enabled = isEnabled
    if (employee.id == null)
      this.addProductAccess(employee)
    else {
      this._organizationService.toggleProductAccess(employee?.id ?? employee?.organization_admin, isEnabled).subscribe()
    }
  }

  addProductAccess(employee) {
    this._organizationService.addProductAccess(employee.organization_admin, this.product?.id).subscribe({
      complete: () => {
        this._organizationService.getEmployeesAllocated(this.product?.id, false, false)
      }
    });
  }

}