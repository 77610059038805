<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1 pb-1 overflow-auto">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-200 sm:min-w-100 md:min-w-160 lg:min-w-200 mx-auto ">
                <div class="relative w-fit mb-5">
                    <img style="width:80px;height:80px;object-fit: cover;flex-shrink:0;background-color: #fff; border: 1px solid #ddd;"
                        [src]="contactImageSrc" onerror="this.src = './assets/images/profile-icon.png'"
                        alt="profile-image" class="rounded-full">
                    <a class="bg-[#5040dd] p-2 rounded-full absolute" style="top:50px;right: 0px;cursor: pointer;">
                        <img src="./assets/images/edit_btn.svg" alt="profile-edit"
                            (click)="selectProfileImage.click();"
                            style="width:12px;height:12px;object-fit: contain;">
                    </a>

                </div>
                <form [formGroup]="contactForm" #contactNgForm="ngForm"
                    class="w-full grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-2">
                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>First Name</mat-label>
                        <input id="first_name" matInput type="text" [formControlName]="'first_name'" class="text-black">
                        <mat-error *ngIf="contactForm.get('first_name').hasError('required')">
                            First name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Middle Name</mat-label>
                        <input id="middle_name" matInput type="text" [formControlName]="'middle_name'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Last Name</mat-label>
                        <input id="last_name" matInput type="text" [formControlName]="'last_name'" class="text-black">
                        <mat-error *ngIf="contactForm.get('last_name').hasError('required')">
                            Last name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Email Address</mat-label>
                        <input id="email" matInput type="email" [formControlName]="'email'" class="text-black">
                        <mat-error *ngIf="contactForm.get('email').hasError('required')">
                            Email address shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Phone Number</mat-label>
                        <input id="phone_number" matInput type="tel" [formControlName]="'phone_number'" class="text-black">
                        <mat-error *ngIf="contactForm.get('phone_number').hasError('required')">
                            Phone number shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Tag</mat-label>
                        <input id="tag" matInput type="text" [formControlName]="'tag'" class="text-black">
                    </mat-form-field>


                    <mat-form-field class="w-full col-span-1 sm:col-span-4 md:col-span-6">
                        <mat-label>Address Line 1</mat-label>
                        <input id="address_line_1" matInput type="text" [formControlName]="'address_line_1'" class="text-black">
                        <mat-error *ngIf="contactForm.get('address_line_1').hasError('required')">
                            Address line 1 shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-4 md:col-span-6">
                        <mat-label>Address Line 2</mat-label>
                        <input id="address_line_2" matInput type="text" [formControlName]="'address_line_2'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>City</mat-label>
                        <input id="city" matInput type="text" [formControlName]="'city'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>State</mat-label>
                        <input id="state" matInput type="text" [formControlName]="'state'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Zipcode</mat-label>
                        <input id="zipcode" matInput type="text" [formControlName]="'zipcode'" class="text-black">
                        <mat-error *ngIf="contactForm.get('zipcode').hasError('required')">
                            Zipcode shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Name</mat-label>
                        <input id="company_name" matInput type="text" [formControlName]="'company_name'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Email</mat-label>
                        <input id="company_email" matInput type="email" [formControlName]="'company_email'" class="text-black">
                        <mat-error *ngIf="contactForm.get('company_email').hasError('required')">
                            A valid email id is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Phone</mat-label>
                        <input id="company_phone" matInput type="text" [formControlName]="'company_phone'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Fax</mat-label>
                        <input id="company_fax" matInput type="text" [formControlName]="'company_fax'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Fax</mat-label>
                        <input id="company_fax" matInput type="text" [formControlName]="'company_fax'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Company Website</mat-label>
                        <input id="company_website" matInput type="text" [formControlName]="'company_website'" class="text-black">
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3">
                        <mat-label>Designation</mat-label>
                        <mat-select name="designation" [formControlName]="'designation'"
                            (selectionChange)="onDesignationChanged()">
                            <mat-option *ngFor="let designation of designations" [value]="designation">
                                {{designation}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="contactForm.get('designation').hasError('required')">
                            Please choose a designation
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full col-span-1 sm:col-span-2 md:col-span-3" *ngIf="showCustomDesignation" >
                        <mat-label>Custom Designation</mat-label>
                        <input [formControlName]="'custom_designation'" matInput class="text-black">
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="w-full col-span-1 sm:col-span-4 md:col-span-6" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button mat-flat-button class="w-50 mt-3 btn_normal" [disabled]="contactForm.disabled" mat-flat-button
        [color]="'primary'" type="submit" (click)="submit()">
        <span *ngIf="!contactForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="contactForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>

<input accept="image/*" onclick="this.value = null" (change)="onImageChanged($event)" #selectProfileImage hidden="true"
    type="file" class="form-control text-black">