<mat-dialog-actions style="padding:0px 16px">
    <button class="justify-end" mat-button (click)="close()" style="width:-webkit-fill-available">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:18px;height:18px;object-fit: contain;">
    </button>
    <div class="justify-self-start text-lg sm:text-[22px] font-extrabold mb-0 pb-0" style="width:-webkit-fill-available">{{title}}</div>

    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pb-2 pt-4">

    <div class="flex flex-col w-full gap-2">
        <ng-container *ngIf="addressList?.length > 0 ; else noAddress">

            <div class="bg-[#f8f7ff] p-6 flex items-center w-full gap-3 cursor-pointer"
                (click)="selectedBillingAddress = address;" style="border-radius:8px;"
                *ngFor="let address of addressList">

                <div class="flex radio-bg rounded-full shrink-0 ">
                    <div class="radio-selected rounded-full shrink-0" *ngIf="selectedBillingAddress?.id == address.id">
                    </div>
                </div>
                <label class="flex flex-col justify-between items-start w-full cursor-pointer">
                    <div class="text-black mb-0" style="font-weight: 600;font-size:15px;">
                        {{address.name}}
                    </div>
                    <div class="text-black"
                        style="font-size:13px;font-weight:400;text-wrap: balance; word-break: break-word;">
                        <span> {{address.address_line_1}},</span>
                        <span *ngIf="address.address_line_2?.length > 0">
                            {{address.address_line_2}},</span>
                        <span> {{address.state}}, </span>
                        <span> {{address.city}}, </span>
                        <span> {{address.zipcode}}</span>
                    </div>
                </label>
            </div>
        </ng-container>
    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0 flex justify-center sm:justify-end gap-3 mt-3" *ngIf="addressList?.length > 0">
    <button class="btn_white w-full sm:w-50" mat-flat-button type="submit" (click)="addAddress()">
        Add New Address
    </button>
    <button mat-flat-button class="mt-0 w-full sm:w-50 btn_normal ml-0" type="submit" (click)="submit()">
        Submit
    </button>
</mat-dialog-actions>

<ng-template #noAddress>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any addresses yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Looks like you haven't added any addresses yet. Add a new address to continue.
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addAddress()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add New Address
        </button>
    </div>
    <!-- / -->
</ng-template>