<mat-dialog-actions class="flex justify-end pl-4 mb-1 p-0" style="min-height:fit-content !important">
    <button class="flex mb-2 sm:mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
</mat-dialog-actions>

<mat-dialog-content class="p-0 sm:p-8 sm:pt-0">

    <div class="w-full  mx-auto ">
        <!-- Content starts -->

        <form class="w-full mt-2 gap-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5"
            [formGroup]="filterForm" #filterNgForm="ngForm" id="filterForm">

            <!-- Franchise Category -->
            <mat-form-field class="w-full" floatLabel="always">
                <mat-label>Franchise Category</mat-label>
                <mat-select name="franchise_category" placeholder="Select" [formControlName]="'franchise_category'"
                    (selectionChange)="onChangeCategory();doSearch()">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="category?.id"
                        *ngFor="let category of data?.product?.franchise_categories">{{category?.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Franchise -->
            <mat-form-field class="w-full" floatLabel="always">
                <mat-label>Franchise</mat-label>
                <mat-select name="franchise" placeholder="Select" [formControlName]="'franchise'"
                    (selectionChange)="doSearch()">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="franchise?.id"
                        *ngFor="let franchise of franchises">{{franchise?.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Tags -->
            <mat-form-field class="w-full" floatLabel="always">
                <mat-label>Tag</mat-label>
                <mat-select name="tag" placeholder="Select" [formControlName]="'tag'" (selectionChange)="doSearch()">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="tag?.id" *ngFor="let tag of data?.product?.tags">{{tag?.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Keyword -->
            <mat-form-field class="w-full" floatLabel="always">
                <mat-label>Keyword</mat-label>
                <mat-select name="keyword" placeholder="Select" [formControlName]="'keyword'"
                    (selectionChange)="doSearch()">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="keyword?.id"
                        *ngFor="let keyword of data?.product?.keywords">{{keyword?.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- / -->
            <!-- colors -->
            <mat-form-field class="w-full" floatLabel="always">
                <mat-label>Colors</mat-label>
                <mat-select name="color" placeholder="select" [formControlName]="'color'"
                    (selectionChange)="doSearch()">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="color.id" *ngFor="let color of data?.product?.colors">
                        <div class="flex flex-row items-center gap-2">
                            <div class="h-4 w-4 border border-[#f5f5f5] rounded-md"
                                [style.background-color]="color.color_code"></div>
                            {{color.color}}
                        </div>

                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- / -->
        </form>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-3 mb-4">
            <div *ngFor="let version of versions; let i = index">
                <app-design-view [product]="version" class="cursor-pointer"
                    (click)="getProductVersionHistory(version.id)"></app-design-view>
            </div>
        </div>
        <p *ngIf="versions?.length == 0" style="padding: 50px;text-align: center;">
            There are no designs for this product
        </p>

        <div class="flex justify-center">
            <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>

        <pwa-pagination class="flex justify-center w-full justify-self-center mt-10 mb-8"
            *ngIf="pagination?.total_pages > 1" (onPageChange)="getVersions($event)"
            [pagination]="pagination"></pwa-pagination>

        <!-- Content ends -->

    </div>
</mat-dialog-content>