import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html',
  styleUrls: ['./create-card.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CreateCardComponent implements OnInit {

  @ViewChild('createCardNgForm') createCardNgForm: NgForm;
  createCardForm: UntypedFormGroup;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  constructor(
    private _paymentService: PaymentService,
    private _toast: ToastrService, private _spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder, private stripeService: StripeService,
    private _dialogRef: MatDialogRef<CreateCardComponent>) { }

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '1rem',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  get validForm() {
    return this.createCardForm.valid && this.stripeCardValid;
  }


  ngOnInit(): void {
    this.createCardForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.getClientSecret()
  }

  onChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }

  title = "Add Card";

  clientSecret = null
  getClientSecret() {
    this._paymentService.getClientSecret().subscribe({
      next: (res: any) => {
        this.clientSecret = res.client_secret
      },
      error: error => {
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
        this.close()
      }
    })
  }


  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  isFormSubmitted = false;
  createToken() {
    this.showAlert = false
    this.createCardForm.markAllAsTouched();
    this.createCardForm.markAsTouched()
    if (this.createCardForm.invalid) {
      return;
    }

    this._spinner.show()

    this.card.element
    this.stripeService.confirmCardSetup(this.clientSecret, {
      payment_method: {
        card: this.card.element,
        billing_details: {
          name: this.createCardForm.get('name').value,
        },
      },
    }).subscribe({
      next: response => {
        this._spinner.hide()
        if (response.error != null) {
          this.alert.type = "error"
          this.alert.message = response.error.message ?? "Something went wrong. Please try again"
          this.showAlert = true
        } else {
          this.responseMessage = "Your card has been added successfully."
          this.close()
        }

      },
      error: error => {
        this._spinner.hide()
        this.alert.type = "error"
        this.alert.message = "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }





}
