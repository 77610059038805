import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { PaymentCard } from './payment.types';
export type PaymentType = "monthly" | "yearly";
@Injectable({ providedIn: 'root' })
export class PaymentService {

    constructor(private _httpClient: HttpClient) {
    }

    private _savedCards: BehaviorSubject<PaymentCard[]> = new BehaviorSubject(null);
    get savedCards$(): Observable<PaymentCard[]> {
        return this._savedCards.asObservable();
    }

    getCards(): Observable<PaymentCard[]> {
        return this._httpClient.get<PaymentCard[]>(`${BASE_URL}user/card/`).pipe(
            tap((plans) => {
                this._savedCards.next(plans);
            }),
        );
    }

    deleteCard(cardId) {
        return this._httpClient.delete(`${BASE_URL}user/card/`, {body: { id: cardId}}).pipe(
            tap((res: any) => {               
                this._savedCards.next(this._savedCards.value.filter(card => card.id !== cardId));
            })
        )
    }

    getClientSecret(){
        return this._httpClient.post(`${BASE_URL}user/card/`, "")
    }
    addCard(data) {
        return this._httpClient.post(`${BASE_URL}user/card/`, data)
    }

}
