<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1">

    <div class="w-full max-w-240 sm:min-w-100 md:min-w-120 gap-2">
        <div *ngFor="let promocode of promocodes"
            class="w-full flex flex-col sm:flex-row items-center gap-2 rounded-2xl p-2"
            style="border:1px dashed #ccd8ea;">
            <div class="flex flex-row grow items-center gap-2">
                <div class="flex w-20 h-16 shrink-0 rounded-xl justify-center items-center"
                    style="background-color: #496ec1;">
                    <div class="font-bold text-white" style="font-size: 18px;" *ngIf="data?.isFromCart">
                        {{promocode.percentage_deduction ? promocode.percentage_deduction+"%" :
                        "$"+promocode.fixed_price_deduction}}
                    </div>
                    <div class="font-bold text-white" style="font-size: 18px;" *ngIf="!data?.isFromCart">
                        {{promocode.percent_off ? promocode.percent_off+"%" :
                        "$"+promocode.amount_off}}
                    </div>
                </div>
                <div class="grow flex flex-col">
                    <div class="text-xl font-bold text-black">
                        {{promocode.code}}
                    </div>
                    <div>
                        {{data?.isFromCart ? promocode.description : promocode.name}}
                    </div>
                </div>
            </div>

            <button mat-flat-button class="btn_white w-full sm:w-auto self-center" (click)="applyPromocode(promocode)"
                style="align-self: flex-start;line-height: normal;">
                Apply
            </button>
        </div>
    </div>

</mat-dialog-content>