import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { Product, ProductVersion, SearchResult, VersionHistory } from 'app/core/product/product.types';
import { ImageCarouselComponent } from 'app/shared/image-carousel/image-carousel.component';
import { ToastrService } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CartService } from 'app/core/cart/cart.service';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { LoginComponent } from 'app/shared/login/signin.component';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Category, PWAConfiguration } from 'app/core/configuration/configuration.types';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { HttpParams } from '@angular/common/http';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { DesignView } from '../design-view/design-view.component';
import { AddToCartComponent } from '../add-to-cart/add-to-cart.component';
import { VersionHistoryComponent } from '../version-history/version-history.component';
import { SearchService } from '../../search/search.service';
import { ProductVersionPopup } from '../product-version/product-version.component';
import { ReviewPopup } from '../review_popup/review-popup.component';
import { CreateProductComponent } from '../create-product/create-product.component';
import { HireDesignerPopup } from '../hire-designer/hire-designer.component';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'category-details',
  templateUrl: './category-details.component.html',
  standalone: true,
  styleUrls: ['./category-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, DesignView,
    MatFormFieldModule, MatSelectModule, MatTabsModule, MatIconModule, MatTooltipModule,
    MatButtonModule, ImageCarouselComponent, RouterModule, MatMenuModule,NgOptimizedImage]
})

export class CategoryDetailsComponent implements OnInit {
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  categorySlug
  subCategorySlug
  category: Category
  subCategory: Category
  categories: Category[]
  subCategories: Category[]
  products: Product[]
  selectedProductId
  selectedProduct

  isScreenSmall: boolean;
  isProductDetails = false
  isVersionDetails = false

  showProductSelection = false

  ngOnInit(): void {
    // this.reInitVariables()
    this.featuredTemplates = []
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
        this.user = user
      })
    this._route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      params => {
        this.resetPricingData()
        this.categorySlug = this._route.snapshot.params['id']
        this.subCategorySlug = this._route.snapshot.params['subCategoryId']
        this.updateDetails()
      }
    );

    // this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(
    //   params => {
    //     this.resetPricingData()
    //     this.categorySlug = this._route.snapshot.params['id']
    //     this.subCategorySlug = params['sub_category']
    //     this.updateDetails()
    //   }
    // );

    this._configurationService.configuration$
      .pipe(takeUntil(this._unsubscribeAll)).subscribe((config: PWAConfiguration) => {
        this.categories = config.digital_categories.concat(config.print_categories)
        this.updateDetails()
      })

    this._searchService.searchResult$.pipe(takeUntil(this._unsubscribeAll)).subscribe(products => {

      // console.log("Search Results : " + products);
      this.products = products?.data
      if (this.products?.length > 0) {
        this.selectedProductId = this.products[0].id
        this.getProductDetails(this.products[0].id)
        this.featuredTemplates = []
      }

    })

    this._productService.productDetail$.pipe(takeUntil(this._unsubscribeAll)).subscribe(product => {
      // console.log("Product details : " + product?.id);
      this.selectedProduct = product

      this.showProductSelection = this.selectedProduct?.is_printable || this.selectedProduct?.is_printing
      if (product != null) {

        this.selectedProductId = product.id
        this.getFeaturedTemplates()
        this.getPricing(-1)
      }
      this._changeDetectorRef.markForCheck()
    })

    this._broadcast.subscribe("product_details_resolver_started_running", this._unsubscribeAll, data => {
      // console.log("ON resolver started");
      this.ngOnDestroy()

    })

    // console.log("ON CREATE");


  }

  getProducts() {
    this._searchService.search({
      sub_category_slug: this.subCategorySlug, category_slug: this.categorySlug, batch_size: 100, get_results: true
    }, false).subscribe()
  }

  getProductDetails(productId) {
    this._productService.getProductDetails(productId).subscribe()
    // .subscribe(product => {
    //   this.selectedProduct = product
    //   if (this.selectedProduct != null) {
    //     this.getFeaturedTemplates()
    //     this.getPricing(-1)
    //   }
    //   this._changeDetectorRef.markForCheck()
    // })
  }

  updateDetails() {
    this.featuredTemplates = []
    if (this.categorySlug != null && this.categories) {
      this.category = this.categories.find(cat => cat.slug == this.categorySlug)
      this.subCategories = this.category.sub_categories
      this.subCategory = this.subCategories.find(cat => cat.slug == this.subCategorySlug)

      if (this.subCategory == null)
        this.subCategory = this.subCategories[0]

      if (!this.subCategorySlug) {
        this.subCategorySlug = this.subCategory.slug

        this.getProducts()
      }

    }
  }

  // switchCategory(category) {
  //   const urlTree = this._router.createUrlTree([], {
  //     queryParams: { sub_category: category.slug },
  //     queryParamsHandling: "merge",
  //     preserveFragment: false
  //   });

  //   this._router.navigateByUrl(urlTree);
  // }

  getVersionHistory(product) {
    if (this._localStorage.accessToken?.length == 0) {
      this.openSignin()
      return
    }
    if (this.selectedProduct?.is_accessory) {
      this.openPlaceYourOrder(product)
      return
    }
    this._spinner.show()
    this._productService.getProductHistory(product.id).subscribe({
      next: versions => {
        this._spinner.hide()
        if (versions?.length > 0)
          this.openVersionHistory(versions, product)
        else
          this.createVersionHistory(product)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openPlaceYourOrder(product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-auto', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    dialogConfig.data = {
      options: this.getOptionsArray(),
      category: this.category.title,
      // subCategory: this.subCategory.title,
      isAccessory: true,
      versionId: product?.id,
      isForDesignSelection: false
    };
    this._matDialog.open(AddToCartComponent, dialogConfig)
  }

  createVersionHistory(product) {
    this._spinner.show()
    this._productService.createVersionHistory(product.id, false).subscribe({
      next: (version: VersionHistory) => {
        this._spinner.hide()
        this.openVersionHistory([version], product)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openVersionHistory(histories: VersionHistory[], version: ProductVersion) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']

    dialogConfig.data = { options: this.getOptionsArray(), versionId: version?.id, histories: histories, category: version?.category_id, subCategory: version?.sub_category_id };
    this._matDialog.open(VersionHistoryComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  featuredTemplates: any[]
  getFeaturedTemplates() {
    // this._searchService.globalSearch({ batch_size: 12, get_results: true, category: this.categoryId, sub_category: this.subCategoryId }, false, false).subscribe({
    //   next: (res: SearchResult) => {
    //     this.featuredTemplates = res.products
    //   }
    // })

    let queryParams = new HttpParams();
    queryParams = queryParams.append("batch_size", 12);
    queryParams = queryParams.append("page", 1);

    this._productService.getProductVersions(this.selectedProduct?.id, queryParams).subscribe({
      next: (res: any) => {
        this.featuredTemplates = res.data
      },
      error: (err) => {
        this.featuredTemplates = []
      }
    })
  }


  user: User
  ngOnDestroy(): void {
    // console.log("ONDESTROY");
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    // this._productService.clearData()
  }

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private _productService: ProductService, private _cartService: CartService,
    private _fuseMediaWatcherService: FuseMediaWatcherService, private _toast: ToastrService, private _spinner: NgxSpinnerService,
    private _changeDetectorRef: ChangeDetectorRef, private _route: ActivatedRoute, private _router: Router,
    private _userService: UserService, private _configurationService: ConfigurationService, private _searchService: SearchService,
    private _confirmation: FuseConfirmationService, private _localStorage: LocalStorageService, private _broadcast: BroadcastService) {

  }

  resetPricingData() {
    this.configurations = []
    this.quantityList = []
    this.selectedQuantity = null
    this.optionsMap.clear()
  }
  configurations: any[];
  selectedQuantity
  getPricing(index) {
    if (this.selectedProduct == null)
      return

    if (!(this.selectedProduct?.is_printable || this.selectedProduct?.is_printing) && !this.selectedProduct?.is_accessory) {
      return
    }

    if (this._localStorage.accessToken?.length == 0) {
      return
    }

    if (index == -1)
      this.resetPricingData()

    let parameters = {
      "quantityID": this.selectedQuantity?.id,
      "options": this.getOptionsArray()
    }

    parameters[this.isVersionDetails ? 'version' : 'product'] = this.selectedProduct?.id

    this._productService.getPriceCalculation(parameters).subscribe({
      next: (res: any) => {
        if (res.type == 'configuration') {
          if (this.configurations == null)
            this.configurations = []

          this.configurations.push(res)
          res.selected = null
          if (res.options.length == 1) {
            res.selected = res.options[0].id
            this.onOptionsSelected(res.selected, index + 1)
          }
        } else if (res.type == 'quantity') {
          this.quantityList = res.options
          this.onSelectQuantity(this.quantityList[0])
        }
      },
      error: (err) => {
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  quantityList: any = []

  optionsMap = new Map<number, number>();
  onSelectQuantity(event) {
    this.selectedQuantity = event
  }

  getOptionsArray() {
    let optionsArray: string[] = [];
    this.optionsMap.forEach((value: number, key: number) => {
      optionsArray.push(value.toString());
    });

    return optionsArray;
  }

  onOptionsSelected(option, index) {
    for (let i = index; i < this.configurations.length; i++) {
      this.optionsMap.delete(i + 1)
    }
    this.selectedQuantity = null
    this.quantityList = null
    this.configurations = this.configurations.slice(0, index + 1)
    if (option != null) {
      this.optionsMap.set(index, option)
      this.getPricing(index)
    }
  }


  priceCalculatorSubmit() {
    if (this._localStorage.accessToken?.length == 0) {
      this.openSignin()
      return
    }
    if (this.selectedQuantity == null) {
      this._toast.error("Please choose the configurations", "Error")
    } else {
      this.openDesignSelection()
    }
  }


  openSignin() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(LoginComponent, dialogConfig)
  }

  openDesignSelection() {
    if (this.isVersionDetails) {
      this.getVersionHistory(this.selectedProduct)
      return
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    dialogConfig.data = { product: this.selectedProduct, options: this.getOptionsArray() };
    this._matDialog.open(ProductVersionPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  openUploadDesign() {
    if (this._localStorage.accessToken?.length == 0) {
      this.openSignin()
      return
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { sub_category: this.selectedProduct?.sub_category, size: this.selectedProduct?.size, category: this.selectedProduct?.category };
    dialogConfig.panelClass = ['w-[90vw]', 'max-w-md']
    this._matDialog.open(CreateProductComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  openReviewPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { checklistId: 0 };
    dialogConfig.panelClass = ['w-full', 'max-w-md']
    this._matDialog.open(ReviewPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  openDesignerRequirementModal() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(HireDesignerPopup, dialogConfig)
  }

  getImagesList(product: any) {
    if (product?.images)
      return product?.images.map(imgObject => imgObject.url)
    else
      return [product?.image]
  }

  saveConfigurations() {
    sessionStorage.setItem("configurations", this.getOptionsArray().toString())
  }
}

