import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { AutofillDesignMapComponent } from './design-map/design-map.component';
import { CartService } from 'app/core/cart/cart.service';
import { CloneDesignDetails } from 'app/core/cart/cart.types';
import { Design, MappingData } from '../../autofill-manager/autofill-manager.types';
import { UserService } from 'app/core/user/user.service';
import { Contact } from 'app/core/user/user.types';
import { CreateContactComponent } from '../../settings/contacts/create-contact/create-contact.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'cart-clone',
  templateUrl: './cart-clone.component.html',
  styleUrls: ['./cart-clone.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatRadioModule, AutofillDesignMapComponent,
    MatTabsModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule,
    MatProgressSpinnerModule, MatCheckboxModule, FormsModule, ReactiveFormsModule]
})

export class CartCloneComponent implements OnInit {

  @ViewChild("designMapTab", { static: false }) designMapTab: MatTabGroup;
  /**
   * Constructor
   */
  constructor(private _cartService: CartService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef, private _imageViewer: ImageViewerService,
    private _toast: ToastrService, private broadcast: BroadcastService,
    private _userService: UserService,
    private route: ActivatedRoute) {
    this.cartItemId = this.route.snapshot.paramMap.get('cartItemId');
  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  cartItemDetails: CloneDesignDetails
  contactList: Contact[];
  mapData: MappingData;
  tabs: Design[] = [];
  autofillMappedData = {
    designs: []
  }


  cartItemId

  ngOnInit(): void {
    this.mapData = this.route.snapshot.data['data'][1]
    this._cartService.cloneItemDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(details => {
        this.cartItemDetails = details
        this.configureTabs()
        this._changeDetectorRef.markForCheck()
      })

    this._userService.contactList$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contacts => {
        this.contactList = contacts
        this._changeDetectorRef.markForCheck()
      })

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      this._router.navigate(['/', 'cart'])
    })
  }

  configureTabs() {
    this.cartItemDetails?.designs.forEach((element, index) => {
      if (index === 0) {
        element.active = true
      }
      element.onNext = new EventEmitter<any>();

      this.tabs.push(element)
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onNext(){
    this.tabs[this.designMapTab.selectedIndex].onNext?.emit()
  }

  onClickNext(data) {
    let index = this.designMapTab.selectedIndex

    if (this.autofillMappedData.designs.length > index) {
      this.autofillMappedData.designs[index] = data
    }
    else {
      this.autofillMappedData.designs.push(data)
    }

    // if ((index + 1) == this.tabs.length) {
    //   this.submitAutofillMappingData()
    // } else
    this.designMapTab.selectedIndex += 1

  }

  showCloneButton = false
  tabChanged(e: MatTabChangeEvent) {
    // this.tabs = this.tabs.filter((item, index) => {
    //   if (index == e.index) {
    //     item.active = true
    //   } else {
    //     item.active = false
    //   }
    //   return item
    // })
    this.showCloneButton = this.designMapTab.selectedIndex >= this.tabs?.length

  }

  addContact() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateContactComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Contact added successfully"), "Success");
          this._userService.getContacts().subscribe()
        }
      });
  }

  submitAutofillMappingData() {
    this._spinner.show();

    let data = { 'design_autofill_data': this.autofillMappedData.designs }
    this._cartService.postAutofilData(this.cartItemId, data).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.cloneProduct()
      },
      error: err => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : "Something went wrong", "Sorry")
      }
    })
  }

  cloneProduct() {
    this._spinner.show();

    let selectedContacts = this.contactList.filter(contact => contact.selected).map(contact => {
      return contact.id
    })

    this._cartService.cloneProduct(this.cartItemId, { 'contacts': selectedContacts, 'use_for_autofill': this.route.snapshot.queryParams['action'] == 'autofill' }).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        sessionStorage.setItem("from_product_details", "false")
        let dialog = this._fuseConfirmationService.open({
          title: "Success",
          message: res?.message ?? "Successfully submitted autofill mapping",
          dismissible: false,
          icon: { show: false },
          actions: {
            cancel: { show: false },
            confirm: { label: "Ok" }
          },
        })
        dialog.afterClosed().subscribe(result => {
          this._router.navigate(['/cart'])
        })
      },
      error: err => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : "Something went wrong", "Sorry")
      }
    })
  }
}