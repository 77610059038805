import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { Contact } from 'app/core/user/user.types';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule, MatProgressSpinnerModule, MatDialogModule, CommonModule, MatInputModule, MatSelectModule, MatFormFieldModule, FormsModule,
    ReactiveFormsModule, FuseAlertComponent, StripeCardComponent
  ]
})
export class CreateContactComponent implements OnInit {

  @ViewChild('contactNgForm') contactNgForm: NgForm;
  contactForm: UntypedFormGroup;
  designations: string[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Contact,
    private _accountService: UserService,
    private _toast: ToastrService,
    private fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<CreateContactComponent>) {
    if (data == null)
      this.title = "Create Contact"
  }

  ngOnInit(): void {
    this.contactImageSrc = this.data?.profile_image
    this.contactForm = this.fb.group({
      first_name: [this.data?.first_name ?? '', [Validators.required]],
      middle_name: [this.data?.middle_name ?? ''],
      last_name: [this.data?.last_name ?? '', [Validators.required]],
      email: [this.data?.email ?? '', [Validators.required]],
      phone_number: [this.data?.phone_number ?? '', [Validators.required]],
      tag: [this.data?.tag ?? ''],
      designation: [this.data?.designation ?? '', [Validators.required]],
      custom_designation: [this.data?.designation ?? ''],
      address_line_1: [this.data?.address_line_1 ?? '', [Validators.required]],
      address_line_2: [this.data?.address_line_2 ?? ''],
      city: [this.data?.city ?? ''],
      state: [this.data?.state ?? ''],
      zipcode: [this.data?.zipcode ?? ''],

      company_name: [this.data?.company_name ?? ''],
      company_email: [this.data?.company_email ?? '', [Validators.email]],
      company_phone: [this.data?.company_phone ?? ''],
      company_fax: [this.data?.company_fax ?? ''],
      company_website: [this.data?.company_website ?? '']
    });

    this.getDesignationList()
  }

  getDesignationList() {
    this._accountService.getDesignationList().subscribe({
      next: res => {
        this.designations = res.designation_list
        this.designations.push("Custom")

        if (this.data != null || this.designations?.find((x => x == this.data.designation)) == undefined) {
          this.showCustomDesignation = true
          this.contactForm.patchValue({ 'custom_designation': this.data.designation })
          this.contactForm.patchValue({ 'designation': "Custom" })
          // this.organizationForm
        }

      },
      error: error => {
        this._toast.error("Unable to get designation list. Please try again!", "Sorry")
        this.close()
      }
    })
  }
  title = "Update Contact";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  contactImageSrc
  fileToUpload
  onImageChanged(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      this.fileToUpload = ev.target.files[0]
      const reader = new FileReader();
      reader.onload = e => {
        this.contactImageSrc = reader.result;
      }
      reader.readAsDataURL(file);
    }
  }

  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      return;
    }

    this.contactForm.disable()

    const formData = new FormData();
    const formValue = this.contactForm.value;
    Object.keys(formValue).forEach((key) => {
      formData.append(key, formValue[key]);
    });

    if (this.showCustomDesignation) {
      formData.set('designation', formData.get('custom_designation'))
    }

    if (this.fileToUpload != null) {
      formData.append('profile_image', this.fileToUpload);
    }

    let request = (this.data == null) ? this._accountService.createContact(formData) : this._accountService.updateContact(this.data.id, formData)
    request.subscribe({
      next: (response: any) => {
        this.contactForm.enable()
        this.responseMessage = response.message ? response.message : `Contact has been ${this.data == null ? 'created' : 'updated'}`
        this.close()
      },
      error: error => {
        this.contactForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  showCustomDesignation = false
  onDesignationChanged() {
    this.showCustomDesignation = this.contactForm.get('designation').value == "Custom"
    this.contactForm.controls['custom_designation'].setValidators(this.showCustomDesignation ? [Validators.required] : []);
    this.contactForm.controls['custom_designation'].updateValueAndValidity();
  }

}
