import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { LocalStorageService } from '../localstorage/local-storage.service';
import { AllocatedEmployee, AllocatedProduct, AllocatedProductsList, Organization, OrganizationAdmin } from './organization.types';
@Injectable({ providedIn: 'root' })
export class OrganizationService {

    constructor(private _httpClient: HttpClient, private _localStorage: LocalStorageService) {
    }

    private _organization: ReplaySubject<Organization> = new ReplaySubject<Organization>(1);
    set organization(value: Organization) {
        this._organization.next(value);
    }
    get organization$(): Observable<Organization> {
        return this._organization.asObservable();
    }

    getOrganizationDetails(): Observable<Organization> {
        return this._httpClient.get<Organization>(`${BASE_URL}user/organization/manage/`).pipe(
            tap((organization) => {
                this._organization.next(organization);
            }),
        );
    }

    updateOrganization(data) {
        return this._httpClient.post<Organization>(`${BASE_URL}user/organization/manage/`, data).pipe(
            tap((organization) => {
                this._organization.next(organization);
            }),
        );
    }

    createOrganization(data) {
        return this._httpClient.post<Organization>(`${BASE_URL}user/organization/manage/`, data).pipe(
            tap((organization) => {
                this._localStorage.organizationId = organization.id + ''
                this._localStorage.organizationName = organization.name
                this._organization.next(organization);
            }),
        );
    }

    //////////////////Admins//////////
    private _admins: BehaviorSubject<OrganizationAdmin[]> = new BehaviorSubject<OrganizationAdmin[]>([]);
    get admins$(): Observable<OrganizationAdmin[]> {
        return this._admins.asObservable();
    }

    getAdmins(): Observable<OrganizationAdmin[]> {
        return this._httpClient.get<OrganizationAdmin[]>(`${BASE_URL}user/organization/admins/`).pipe(
            tap((organization) => {
                this._admins.next(organization);
            }),
        );
    }

    addAdmin(data) {
        return this._httpClient.post(`${BASE_URL}user/organization/admins/`, data)
    }

    updateAdmin(data, adminId) {
        return this._httpClient.put(`${BASE_URL}user/organization/admins/${adminId}/`, data)
    }

    deleteAdmin(adminId) {
        return this._httpClient.delete(`${BASE_URL}user/organization/admins/${adminId}/`).pipe(
            tap((res: any) => {
                this._admins.next(this._admins.value.filter(contact => contact.id !== adminId));
            })
        )
    }

    private _allocatedProducts: BehaviorSubject<AllocatedProduct[]> = new BehaviorSubject<AllocatedProduct[]>([]);
    get allocatedProducts$(): Observable<AllocatedProduct[]> {
        return this._allocatedProducts.asObservable();
    }
    private _allocatedEmployees: BehaviorSubject<AllocatedEmployee[]> = new BehaviorSubject<AllocatedEmployee[]>([]);
    get allocatedEmployees$(): Observable<AllocatedEmployee[]> {
        return this._allocatedEmployees.asObservable();
    }

    private _hasMoreProducts: BehaviorSubject<Boolean> = new BehaviorSubject(null);
    get hasMoreProducts$(): Observable<Boolean> {
        return this._hasMoreProducts.asObservable();
    }

    productsPage = 1
    getProductsAllocated(adminId, nextPage: Boolean = false): Observable<any> {
        if (nextPage)
            this.productsPage++
        else
            this.productsPage = 1

        let params = new HttpParams()
        if (adminId)
            params = params.append('organization_admin', adminId)
        params = params.append('page', this.productsPage)
        params = params.append('batch_size', 20)
        params = params.append('require_all_admins', "0")
        return this._httpClient.get<AllocatedProductsList>(`${BASE_URL}product/version-access/`, { params: params }).pipe(
            map((products) => {
                if (nextPage) {
                    let tempOrders: AllocatedProduct[] = this._allocatedProducts.value
                    tempOrders = [...tempOrders, ...products.data]
                    this._allocatedProducts.next(tempOrders);
                } else {
                    this._allocatedProducts.next(products.data);
                }
                this._hasMoreProducts.next(products.current_page < products.total_pages)
                return products;
            })
        );
    }


    employeesPage = 1
    getEmployeesAllocated(product, getAdmins: boolean = false, nextPage: Boolean = false): Observable<any> {
        if (nextPage)
            this.employeesPage++
        else
            this.employeesPage = 1

        let params = new HttpParams()
        if (product)
            params = params.append('product_version', product)
        params = params.append('page', this.employeesPage)
        params = params.append('batch_size', 20)
        params = params.append('require_all_admins', getAdmins ? "1" : "0")
        return this._httpClient.get<any>(`${BASE_URL}product/version-access/`, { params: params }).pipe(
            map((response) => {
                if (nextPage) {
                    let tempOrders: AllocatedEmployee[] = this._allocatedEmployees.value
                    tempOrders = [...tempOrders, ...response.data]
                    this._allocatedEmployees.next(tempOrders);

                } else {
                    this._allocatedEmployees.next(response.data);
                }
                this._hasMoreProducts.next(response.current_page < response.total_pages)
                return response;
            })
        );
    }

    toggleProductAccess(id, isEnabled: boolean) {
        return this._httpClient.put(`${BASE_URL}product/version-access/${id}/`, { "is_enabled": isEnabled })
    }

    updateProductAccess(enabled, adminId) {
        return this._httpClient.put(`${BASE_URL}product/version-access/${adminId}/`, { "is_enabled": enabled })
    }

    addProductAccess(admin, product) {
        let data = { 'organization_admin': admin, 'product_version': product }
        return this._httpClient.post(`${BASE_URL}product/version-access/`, data)
    }

    //////////////////////////////////
}
