<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">

    <!-- //New billing address ui -->
    <div class="p-8 sm:p-10 mt-8" style="border:solid 2px #dedeff;border-radius:30px; max-width: 800px;margin:auto">
        <!-- Billing Address -->
        <p class="mb-4 font-bold" style="font-size:18px;">Billing Address</p>
        <div class="bg-[#f8f7ff] p-6 sm:pt-7 sm:pb-7 sm:pl-8 sm:pr-8 flex flex-col sm:flex-row justify-between w-full gap-3 items-center mb-6"
            style="border-radius:12px;">
            <div class="flex flex-col flex-grow" *ngIf="selectedBillingAddress">
                <p class="text-black mb-0" style="font-weight: bold;font-size:16px;">
                    {{selectedBillingAddress?.name}}
                </p>
                <p style="font-size:16px;">
                    <span> {{selectedBillingAddress?.address_line_1}},</span>
                    <span *ngIf="selectedBillingAddress?.address_line_2?.length > 0">
                        {{selectedBillingAddress?.address_line_2}},
                        <br></span>
                    <span> {{selectedBillingAddress?.state}}, </span>
                    <span> {{selectedBillingAddress?.city}}, </span>
                    <span> {{selectedBillingAddress?.zipcode}}</span>
                </p>
            </div>
            <button class="flex shrink-0 btn_white" (click)="chooseAddress()" mat-flat-button
                style="line-height: normal;">
                Change Address
            </button>

        </div>
        <!-- // -->
        <!-- Payment card -->
        <p class="mb-4 font-bold" style="font-size:18px;">Payment Card</p>
        <div class="p-6 sm:pt-7 sm:pb-7 sm:pl-8 sm:pr-8 flex flex-col sm:flex-row justify-between w-full gap-3 items-center mb-6"
            style="border-radius:12px;border:1px solid #d5d5d5">
            <div class="flex items-center" *ngIf="selectedCard">
                <!-- <ng-container *ngIf="showCardAddButton ; else noCards"> -->
                <div class="flex flex-grow">
                    <div class="flex items-center">
                        <img [src]="'./assets/images/card/'+selectedCard.brand+'.png'"
                            onerror="this.src='./assets/images/card/credit_card.png'"
                            style="width:70px;height:auto;object-fit: contain;" alt="Card image">
                        <span class="ml-4" style="font-size:16px;">xxxx xxxx xxxx
                            {{selectedCard.last4}}</span>
                    </div>
                </div>
                <!-- </ng-container> -->
            </div>
            <button class="flex btn_white shrink-0" (click)="chooseCard()" mat-flat-button style="line-height: normal;">
                Change Card
            </button>
        </div>
        <!-- // -->
        <!-- Selected plans -->
        <p class="mb-6 font-bold" style="font-size:18px;">Selected Plans</p>
        <div *ngFor="let plan of selectedPlans;last as isLast">
            <ng-container *ngTemplateOutlet="pricingTemplate; context: {$implicit: plan}"></ng-container>
            <div *ngIf="!isLast" style="border-top:1px solid #d5d5d5 !important;border:0;margin:20px 0px"></div>
        </div>
        <div class="flex justify-end mt-10">
            <button mat-flat-button class="btn_normal w-auto text-center cursor-pointer"
                (click)="completeSubscription()" style="min-width:230px !important;padding:1.4rem 1rem !important">
                Pay & Subscribe</button>
        </div>
        <div class="w-fit justify-self-center mt-8">
            Having difficulty while payment? Please <a [routerLink]="['/','contact-us']" class="text-primary"><u>Contact
                    Us</u></a>
        </div>

    </div>
    <!--  -->
</section>

<ng-template #noAddress>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any addresses yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Looks like you haven't added any addresses yet. Add a new address to ensure smooth and timely delivery of your
        orders.
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addAddress()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add New Address
        </button>
    </div>
    <!-- / -->
</ng-template>
<ng-template #noCards>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any cards yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Oops! It seems you haven't saved any cards yet. Add a new card to make payments hassle-free and secure
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addCard()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add New Card
        </button>
    </div>
    <!-- / -->
</ng-template>
<ng-template #pricingTemplate let-plan>
    <div class="flex flex-col sm:flex-row justify-between gap-4">
        <div class="flex justify-between items-center">
            <p class="font-bold flex-grow text-[#060267]" style="font-size:16px;">{{plan.title}}</p>
            <div class="cursor-pointer flex sm:hidden">
                <img src="./assets/images/close-red.svg" style="width:16px;height:16px;margin-left:17px;"
                    (click)="removePlan(plan)" *ngIf="!plan.completed && !plan.isSubscribing" alt="Close icon">

                <div class="w-full sm:w-auto h-fit" style="font-size:12px;font-weight:600;"
                    *ngIf="!plan.completed && plan.isSubscribing">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div>

                <div class="w-full sm:w-auto h-fit" *ngIf="plan.completed">
                    <img src="./assets/images/tick-green.png" class="h-6 w-6">
                </div>
            </div>
        </div>
        <div class="flex flex-row shrink-0 justify-start sm:justify-end gap-3 sm:gap-6 items-center">
            <div class="flex flex-col-reverse md:flex-row gap-1 md:gap-6">
                <p class="text-[#060266] font-bold line-through content-center"
                    *ngIf="!plan.custom && plan.cost !='Free' && plan.offer_percentage > 0">
                    <span *ngIf="(!plan.custom && plan.cost !='Free')">
                        $</span>{{plan.original_price}}
                    <span *ngIf="(!plan.custom && plan.cost !='Free' && plan.payment_type != '')">
                        /{{(plan.payment_type=='monthly' ? 'mo' : plan.payment_type)}}
                    </span>
                </p>
                <p class="text-[#3723dd] plantype_text" style="font-size:22px;font-weight:bold;line-height: 1;">
                    <span *ngIf="(!plan.custom && plan.cost !='Free')">
                        $</span>{{plan.cost}}
                    <span *ngIf="(!plan.custom && plan.cost !='Free' && plan.payment_type != '')"
                        style="font-size: 18px;">
                        /{{(plan.payment_type=='monthly' ? 'mo' : plan.payment_type)}}
                    </span>
                </p>
            </div>


            <button mat-flat-button class="w-full hidden sm:block sm:w-auto btn_normal" (click)="onConfirmAndPay(plan)"
                *ngIf="plan.error">
                <span style="font-size:12px;font-weight:600;">
                    Retry
                </span>
            </button>

            <div class="w-full sm:w-auto h-fit hidden sm:block" style="font-size:12px;font-weight:600;"
                *ngIf="!plan.completed && plan.isSubscribing">
                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
                </mat-progress-spinner>
            </div>

            <div class="w-full sm:w-auto h-fit" *ngIf="plan.completed">
                <img src="./assets/images/tick-green.png" class="h-6 w-6">
            </div>

            <div class="cursor-pointer hidden sm:flex" *ngIf="!plan.completed && !plan.isSubscribing">
                <img src="./assets/images/close-red.svg" style="width:16px;height:16px;margin-left:17px;"
                    (click)="removePlan(plan)" alt="Close icon">
            </div>

        </div>

    </div>
    <div class="text-warn">
        {{plan.error}}
    </div>


    <button mat-flat-button class="w-full block sm:hidden sm:w-auto btn_normal" (click)="onConfirmAndPay(plan)"
        *ngIf="plan.error">
        <span style="font-size:12px;font-weight:600;">
            Retry
        </span>
    </button>
</ng-template>