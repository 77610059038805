<mat-dialog-actions class="flex justify-end pl-4 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <!-- <div class="justify-self-start text-2xl font-extrabold">{{title}}</div> -->
    <button class="flex w-auto justify-end items-start mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="p-0 w-full max-w-full">


    <div class="flex flex-col items-center justify-center flex-auto min-w-0 max-w-full w-full">
        <!-- Content starts -->
        <h1 class="text-black" style="font-weight:700;font-size:20px;">Support Token</h1>
        <div class="flex flex-col justify-center mt-4" style="text-align:center;padding:0 20px;padding-bottom:17px;">
            <div class="text-black text-left" style="font-size: 14px">
                Please find your support token below
            </div>
            <div class="mt-2 text-justify" style="font-size: 14px;">
                You may share it only with our support team/designer via the chat option on this
                website if requested by them.
                This support token can be sued to access your Printing Warehouses account to help
                you with account-related issues.
            </div>
            <div class="mt-2 flex flex-row items-center gap-1">

                <div class="flex items-center bg-white grow"
                    style="border: 1px solid #34343434;border-radius: 10px; height: 40px !important;">
                    <p class="truncate grow text-black px-2" style="contain:inline-size;font-size:12px;">
                        {{data}}
                    </p>
                </div>

                <button mat-flat-button
                    class="bg-[#3723dd] h-full p-4 pl-6 pr-6 text-white flex items-center justify-center"
                    style="border-radius:8px !important;height:40px;" (click)="onClickCopy()">
                    <div class="flex flex-row justify-content-center items-center ">
                        <lottie-player *ngIf="showSuccessAnimation" [src]="successAnimationRes" background="transparent"
                            speed="2" style="margin-right: 10px; width: 20px; height: 20px;" autoplay></lottie-player>
                        <div class="text-md">
                            {{showSuccessAnimation ? 'Copied' : 'Click to Copy'}}
                        </div>
                    </div>
                </button>
            </div>

            <div class="mt-4 text-[#5040dd] font-bold" style="font-size: 14px;">
                This token will expire after 1 hour.
            </div>
        </div>

        <!-- Content ends -->
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions class="pb-0 pr-0 pt-2 pl-2 justify-end mt-4"> -->
<!-- Submit button -->
<!-- <button class="fuse-mat-button-large w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="createChecklistForm.disabled" (click)="submitForm()" form="createChecklistForm">
        <span *ngIf="!createChecklistForm.disabled">
            SUBMIT
        </span>
        <mat-progress-spinner *ngIf="createChecklistForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button> -->
<!-- </mat-dialog-actions> -->