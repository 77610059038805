import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SMMAllAccountsResponse, SMMPost } from 'app/core/social-media/social-media.types';
import { ToastrService } from 'ngx-toastr';
import { MatInputModule } from '@angular/material/input';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { formatDate } from '@angular/common';
import { ProductVersionHistory } from 'app/core/user/user.types';
import { VersionHistory } from 'app/core/product/product.types';
import { LibraryChooserComponent } from '../library-chooser/library-chooser.component';
import { ImageCarouselComponent } from 'app/shared/image-carousel/image-carousel.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchPopup } from '../../search/search-popup/search-popup.component';

@Component({
  selector: 'app-newpost-popup',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FormsModule, MatInputModule,
    ReactiveFormsModule, MatDialogModule, MatDatepickerModule,
    MatNativeDateModule, MatCheckboxModule, MatFormFieldModule,
    MatSelectModule, MatTabsModule, MatIconModule,
    MatSidenavModule, ImageCarouselComponent, MatButtonModule,
    RouterModule, FuseCardComponent]
})
export class NewPostPopup implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { post: SMMPost, versionHistory: VersionHistory }, private _confirmation: FuseConfirmationService,
    private _smmService: SocialMediaService, private _toast: ToastrService, private _matDialog: MatDialog, private _spinner: NgxSpinnerService,
    private _dialogRef: MatDialogRef<NewPostPopup>) {
    if (this.data?.post != null) {
      this.postContent = data?.post?.text
      this.imageRes = data?.post?.image_url
      if (data?.post?.image_url)
        this.versionImages = [data?.post?.image_url]
      this.postFrequency = data?.post?.recurring_frequency ?? ''
      this.schedulePost = data?.post?.scheduled_time != null
      this.scheduledTime = formatDate(new Date(data?.post?.scheduled_time), 'yyyy-MM-ddTHH:mm', 'en')
    }
    if (this.data?.versionHistory != null) {
      this.processVersionHistories([this.data.versionHistory])
    }
  }

  // linkedin: 1
  // recurring_frequency: monthly
  // scheduled_time: 1711664640000
  // text: kjhgfd
  // facebook_pages: 256,242
  // instagram_profiles: 
  // linkedin_pages: 
  date = new Date().toISOString().split('T')[0]

  imageRes = null
  postFrequency: 'daily' | 'monthly' | 'yearly' | '' = ''
  schedulePost: boolean = false
  scheduledTime = formatDate(new Date(this.date), 'yyyy-MM-ddTHH:mm', 'en')
  versionImages = []
  hasTimeError = false
  ngOnInit(): void {

    this.getSMMAccounts()
  }

  smmAccounts: SMMAllAccountsResponse
  getSMMAccounts() {
    this._smmService.getAllAccounts(['facebook', 'twitter', 'instagram', 'linkedin']).subscribe({
      next: (res: SMMAllAccountsResponse) => {
        this.smmAccounts = res
        this.displayData()
      },
      error: error => {
        this._toast.error("Something went wrong. Please try again later.", "Sorry")
        this.close()
      }
    })
  }

  displayData() {
    this.smmAccounts.facebook.forEach(facebook => {
      if (this.data?.post?.targets?.find(target => target.id == facebook.id))
        this.selectedAccounts.push('facebook_profile' + facebook.id)
      facebook.pages?.forEach(page => {
        if (this.data?.post?.targets?.find(target => target.id == page.id))
          this.selectedAccounts.push('facebook_page' + page.id)
      })
    })

    this.smmAccounts.linkedin.forEach(linkedin => {
      if (this.data?.post?.targets?.find(target => target.id == linkedin.linkedin_id))
        this.selectedAccounts.push('linkedin_profile' + linkedin.linkedin_id)
      linkedin.pages?.forEach(page => {
        if (this.data?.post?.targets?.find(target => target.id == page.id))
          this.selectedAccounts.push('linkedin_page' + page.id)
      })
    })

    this.smmAccounts.twitter.forEach(twitter => {
      if (this.data?.post?.targets?.find(target => target.id == twitter.twitter_id))
        this.selectedAccounts.push('twitter_profile' + twitter.twitter_id)
    })

    this.smmAccounts.instagram.forEach(instagram => {
      if (this.data?.post?.targets?.find(target => target.id == instagram.id))
        this.selectedAccounts.push('instagram_profile' + instagram.id)
      instagram.pages?.forEach(page => {
        if (this.data?.post?.targets?.find(target => target.id == page.id))
          this.selectedAccounts.push('instagram_page' + page.id)
      })
    })
  }

  selectedAccounts = []
  postContent = ''
  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  selectedVersions: VersionHistory[]
  //Close modal with a response message
  responseMessage = "";
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  fileToUpload = null;
  onImageChanged(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      this.fileToUpload = ev.target.files[0]
      const reader = new FileReader();
      reader.onload = e => {
        this.versionImages = [reader.result]
        this.imageRes = reader.result;
      }
      reader.readAsDataURL(file);
    }
  }

  submitPost(isDraft: boolean) {
    if (this.postContent?.length == 0 && this.fileToUpload == null && this.data?.versionHistory == null && (this.selectedVersions?.length == null || this.selectedVersions?.length == 0)) {
      this.showError('Please choose an image or enter your message to post')
      return
    }
    if (this.selectedAccounts?.length == 0) {
      this.showError('Please choose your social media accounts to share the post')
      return
    }

    this._spinner.show()
    var formData = new FormData()
    if (this.fileToUpload != null)
      formData.set('image', this.fileToUpload);
    if (this.data?.post != null && this.fileToUpload == null) {
      console.log(this.data);
      console.log(this.fileToUpload);
      
      
      formData.append('image_url', this.imageRes);
    }

    if (this.postFrequency != null && this.postFrequency?.length > 0)
      formData.set('recurring_frequency', this.postFrequency);

    formData.set('text', this.postContent)

    if (!this.schedulePost && !isDraft)
      formData.set('publish_now', this.schedulePost ? '0' : '1')

    if (this.schedulePost) {
      formData.set('scheduled_time', new Date(this.scheduledTime).getTime().toString())
    }
    
    if (this.data.versionHistory != null) {
      formData.append('history_id', this.data?.versionHistory?.id?.toString());
    }
    else {
      this.selectedVersions?.forEach(element => {
        formData.append('history_id', element.id?.toString());
      });
    }

    let facebookPages = []
    let instagramProfiles = []
    let linkedInPages = []
    this.selectedAccounts?.forEach(account => {

      if (account.includes('facebook_profile')) {
        facebookPages.push(account.replace('facebook_profile', ''))
      }
      if (account.includes('facebook_page')) {
        facebookPages.push(account.replace('facebook_page', ''))
      }

      if (account.includes('linkedin_profile')) {
        formData.append('linkedin', '1');
        // linkedInPages.push(account.replace('linkedin_profile', ''))
      }
      if (account.includes('linkedin_page')) {
        formData.append('linkedin', '1');
        linkedInPages.push(account.replace('linkedin_page', ''))
      }
      if (account.includes('twitter_profile')) {
        formData.append('twitter', '1');
      }
      if (account.includes('instagram_profile')) {
        instagramProfiles.push(account.replace('instagram_profile', ''))
      }
      if (account.includes('instagram_page')) {
        instagramProfiles.push(account.replace('instagram_page', ''))
      }
    })

    if (instagramProfiles?.length > 0)
      formData.set('instagram_profiles', instagramProfiles.toString())
    if (facebookPages?.length > 0)
      formData.set('facebook_pages', facebookPages.toString())
    if (linkedInPages?.length > 0)
      formData.set('linkedin_pages', linkedInPages.toString())

    let request = this.data?.post ? this._smmService.updatePost(formData, this.data?.post?.id) : this._smmService.createPost(formData)
    request.subscribe({
      next: response => {
        this._spinner.hide()
        this.responseMessage = response?.message ?? `Post ${this.data?.post ? 'updated' : 'created'} successfully`
        this.close()
      },
      error: error => {
        this._spinner.hide()
        this.showError(error?.error?.message ?? 'Something went wrong. Please try again.')
      }
    })
  }

  showError(errorMessage) {
    this._confirmation.open({
      title: 'Error',
      message: errorMessage,
      actions: { cancel: { show: false }, confirm: { label: "Ok" } }
    })
  }

  // openLibrary() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = { histories: this.selectedVersions }
  //   dialogConfig.panelClass = ['w-full', 'max-w-200']
  //   this._matDialog.open(LibraryChooserComponent, dialogConfig)
  //     .afterClosed().subscribe(value => {
  //       if (value != null && value.length > 0) {
  //         this.processVersionHistories(value)
  //       }
  //     });
  // }

  openLibrary() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { histories: this.selectedVersions, fromSocialMediaPost: true }
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    this._matDialog.open(SearchPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          console.log(value);

          this.processVersionHistories(value)
        }
      });
  }

  processVersionHistories(histories: VersionHistory[]) {
    this.selectedVersions = histories
    this.versionImages = this.selectedVersions?.map(version => version?.image)
    console.log(this.versionImages);

  }
}
