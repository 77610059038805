import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';
import { OrganizationService } from 'app/core/organization/organization.service';


@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    StripeCardComponent
  ]
})
export class CreateOrganizationComponent implements OnInit {

  @ViewChild('organizationNgForm') organizationNgForm: NgForm;
  organizationForm: UntypedFormGroup;

  constructor(
    private _organizationService: OrganizationService,
    private _toast: ToastrService,
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<CreateOrganizationComponent>) {
  }

  ngOnInit(): void {
    this.organizationForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      company_tagline: [''],
      company_email: ['', [Validators.required, Validators.email]],
      company_website: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      company_fax: ['', [Validators.min(100000)]],
      phone_number: ['', [Validators.required, Validators.min(100000)]],
      company_address_line_1: ['', [Validators.required]],
      company_address_line_2: [''],
      company_zipcode: ['', [Validators.required, Validators.min(1000)]],
      company_city: ['', [Validators.required, Validators.minLength(3)]],
      company_state: ['', [Validators.required, Validators.minLength(3)]],
    })
  }

  title = "Create Organization";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  isFormSubmitted = false;
  submit() {
    this.showAlert = false
    this.organizationForm.markAllAsTouched();
    if (this.organizationForm.invalid) {
      return;
    }

    this.organizationForm.disable()
    let request = this._organizationService.createOrganization(this.organizationForm.value)
    request.subscribe({
      next: (response: any) => {
        this.organizationForm.enable()
        this.responseMessage = {
          message: response.message ? response.message : `Organization has been created`,
          organizationId: response.id
        }
        this.close()
      },
      error: error => {
        this.organizationForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  //Close modal with a response message
  responseMessage: { message: string, organizationId: number }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

}
