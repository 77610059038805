import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FilterParams, Product, SearchResult, VersionHistory } from 'app/core/product/product.types';
import { ProductService } from 'app/core/product/product.service';
import { MatMenuModule } from '@angular/material/menu';
import { UserService } from 'app/core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { SearchService } from '../../search/search.service';
import { Pagination } from 'app/core/api/pagination.type';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { DesignView } from '../../product-detail/design-view/design-view.component';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
    selector: 'app-library-chooser',
    templateUrl: './library-chooser.component.html',
    styleUrls: ['./library-chooser.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatIconModule,
        MatMenuModule, MatButtonModule, RouterModule, PWAPagination, DesignView, MatProgressSpinnerModule]
})
export class LibraryChooserComponent implements OnInit {
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    versionHistories: VersionHistory[]
    products: Product[]
    pagination: Pagination = { current_page: 1, batch_size: 10, total_count: 100, total_pages: 1 }
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { selectedHistories: VersionHistory[], versionHistories: VersionHistory[] },
        private _toast: ToastrService,
        private _productService: ProductService, private _changeDetectorRef: ChangeDetectorRef,
        private _dialogRef: MatDialogRef<VersionHistory>
    ) {
        this.versionHistories = data?.versionHistories
        data?.selectedHistories?.forEach(history => {
            this.selectedVersions.set(history.id, history)
        })
    }

    ngOnInit(): void {
    }

    filterParams = new FilterParams()
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    //Close modal with a response message
    response: VersionHistory[];
    close(submitResult: boolean = false) {
        this._dialogRef.close(submitResult ? Array.from(this.selectedVersions.values()) : null);
    }


    selectedVersions = new Map();
    addOrRemoveVersion(version) {
        if (this.selectedVersions.has(version.id)) {
            this.selectedVersions.delete(version.id)
        } else {
            this.selectedVersions.set(version.id, version)
        }
    }

    submit() {
        if (this.selectedVersions.size == 0) {
            this._toast.error("You did not choose any designs", "Sorry")
        } else {
            this.close(true)
        }
    }
}
