<mat-dialog-actions class="flex justify-between pl-0 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <!-- <div class="justify-self-start text-2xl font-extrabold">{{title}}</div> -->
    <div class="justify-self-start flex items-center">
        <img class="w-10 h-10 sm:w-8 sm:h-8 rounded-full" alt="Profile image" [src]="target?.image_url"
            onError="this.src = 'assets/images/profile-icon.webp'">
        <span class="text-md font-bold leading-none ml-4">
            {{(target ? (target.title ?? target.name ) : '') | titlecase}}
        </span>
        <span class="ml-4 mr-4"> | </span>
        <span class="text-sm"> {{target?.target_type | titlecase}}</span>
    </div>
    <button class="flex w-auto justify-end items-center mb-0" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<hr class="mt-4 mb-4" style="border:1px solid #d5d5d5;">
<mat-dialog-content class="p-0 w-full max-w-full flex flex-col gap-3">
    <div class="flex items-center" *ngFor="let history of histories">
        <div class="flex flex-col items-start grow">
            <p class="text-truncate align-self-center" style="font-size: 14px;">
                {{history.time | date:'dd-MMM-yyyy'}}</p>
            <p style="font-size: 14px;">
                {{history.time | date:'EEEE hh:mm aa'}}</p>
        </div>
        <span class="flex"
            style="color: #3c2d8a;font-size: 13px;font-weight:bold;margin-right:30px;text-align: center;">
            <img [src]="target.target_type =='facebook' ? './assets/images/fb_icon.png' : target.target_type =='twitter' ? './assets/images/twitter_icon.png' : './assets/images/linkedin_small.png' "
                style="width:24px;height:24px;object-fit: contain;margin-right:10px;" alt="Social media icon">
            <a *ngIf="history.post_url?.length > 0" [href]="history?.post_url" target="_blank"
                rel="noopener noreferrer">View Post</a>
        </span>
        <img [src]="history?.error == true ? 'assets/images/warning.webp1' : 'assets/images/smm_sharing.png'" [alt]="history?.error == true ? 'Error icon' : 'Successfull icon' "
            style="width:19px;height:19px;object-fit: contain;margin-right:10px;" [matTooltip]="history?.error_message"
            (click)="showError(history)" [style.cursor]="history?.error == false ? 'default' : 'pointer'">
        <p class="hidden md:block" *ngIf="history?.error == false" style="font-size: 14px;">
            Shared Successfully!
        </p>
        <p class="hidden md:block" *ngIf="history?.error == true" (click)="showError(history)"
            [matTooltip]="history?.error_message" style="cursor: pointer; font-size: 14px;">
            Could not share!
        </p>

    </div>
    <p *ngIf="histories?.length == 0" style="padding: 50px;text-align: center;">
        There is no history for this page
    </p>

    <div class="flex justify-center">
        <mat-progress-spinner *ngIf="showLoading" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </div>

    <pwa-pagination class="flex justify-center w-full justify-self-center mt-10 mb-8" *ngIf="pagination?.total_pages > 1"
        (onPageChange)="getPostHistories($event)" [pagination]="pagination"></pwa-pagination>
</mat-dialog-content>