<mat-dialog-content class="p-0 flex flex-col max-h-[90vh]">
    <div class="flex justify-between mb-4 sm:mb-0 p-0">
        <div class="flex items-center">
            <span class="text-black mb-2" style="font-size:20px;font-weight:700;">Choose Designs</span>
        </div>
        <button class="flex ml-8 mb-0" mat-button (click)="close()">
            <img alt="Close icon" src="./assets/images/close_icon.svg"
                style="width:17px;height:17px;object-fit: contain;">
        </button>
    </div>
    <ng-container *ngIf="versionHistories?.length > 0 ; else noHistory">
        <div class="flex flex-col gap-2">
            <ng-container *ngFor="let version of versionHistories">
                <div class="w-full flex flex-row grow items-center gap-2 hover:shadow-lg"
                    (click)="addOrRemoveVersion(version)">
                    <div class="w-20 h-20 shrink-0 rounded-sm" style="background-color: #f5f5f5;">
                        <img [src]="version.image" class=" h-full w-full object-contain" alt="Version image">
                    </div>
                    <div class="grow flex flex-col">
                        <div>
                            Created on {{version.last_edited_time | date:'MMM dd, YYYY'}}
                        </div>
                        <div>
                            {{version.last_edited_time | date:'h:mm a'}}
                        </div>
                    </div>
                    <div class="flex radio-bg rounded-full shrink-0 ">
                        <div class="radio-selected rounded-full shrink-0" *ngIf="selectedVersions.has(version.id)">
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="btn_normal flex items-center justify-center  w-full sm:w-auto" (click)="submit()">
        Submit
    </button>
</mat-dialog-actions>

<ng-template #noHistory>
    <div class="flex flex-col items-center w-full p-10 md:p-20  col-span-3" *ngIf="!showHistoryLoading">
        <p class="text-black mb-1 text-center" style="font-size:18px;font-weight:500;font-family:poppins">
            There are no histories for this product
        </p>
        <p class="text-black text-center" style="font-size:13px;font-weight:normal;font-family:poppins">
            There are currently no histories in your library. Explore our collection and add posters to enhance your
            social media presence
        </p>
    </div>
    <div class="flex flex-col items-center w-full p-10 md:p-20 col-span-3" *ngIf="showHistoryLoading">
        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </div>
</ng-template>