import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { SearchService } from './search.service';
import { FilterParams } from 'app/core/product/product.types';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from 'app/core/product/product.service';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';

export const searchGlobalDataResolver = (route: ActivatedRouteSnapshot) => {
    const _searchService = inject(SearchService);
    const _productService = inject(ProductService);
    const _configService = inject(ConfigurationService);
    const _broadcast = inject(BroadcastService);
    const filter = new FilterParams()
    filter.category_slug = route.params['id'] ?? null
    filter.sub_category_slug = route.params['subId'] ?? null
    filter.product = getInteger(route.params['productId']) ?? null
    filter.category = route.queryParams['category'] ?? null
    filter.sub_category = route.queryParams['sub_category'] ?? null
    filter.size = route.queryParams['size'] ?? null
    filter.search = route.queryParams['search'] ?? null
    filter.page = route.queryParams['page'] ? getInteger(route.queryParams['page']) : 1

    filter.franchise_category = route.queryParams['franchise_category'] ?? null
    filter.franchise = route.queryParams['franchise'] ?? null


    filter.get_filter_configuration = true

    const requestArray: Observable<any>[] = [_searchService.globalSearch(filter)]

    if (filter.product != null) {
        requestArray.push(_productService.getProductDetails(filter.product))
        _broadcast.broadcast("product_details_resolver_started_running", {})
    }
    // else
    requestArray.push(_configService.getFranchises())


    return forkJoin(requestArray);
};
function getInteger(value: string) {
    let result = Number(value)
    return Number.isNaN(result) ? null : result
}