<!-- <mat-dialog-actions class="flex justify-end pl-4 mb-1 p-0" style="min-height:fit-content !important">
   
</mat-dialog-actions> -->

<mat-dialog-content class="relative p-0 overflow-hidden">
    <img class="top-[30px] lg:top-[40px] right-[20px] absolute cursor-pointer" alt="Close icon"
        src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;" (click)="close()">
    <div class="absolute w-full h-full flex justify-center items-center" *ngIf="isLoading">
        <mat-progress-spinner  [diameter]="24" [mode]="'indeterminate'" >
        </mat-progress-spinner>
    </div>
    <iframe src="https://api.leadconnectorhq.com/widget/booking/esrLHI0pNjFEO0OyKdnJ" (load)="isLoading=false"
        style="display:flex; min-height: 400px; width: 100%;border:none;height: 100%;padding: 0px;background-color: white;"
        scrolling="auto" id="esrLHI0pNjFEO0OyKdnJ_1692030551809"></iframe>


</mat-dialog-content>