<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <div class="justify-self-start text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content  class="pt-1 pb-1">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full h-auto pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div *ngIf="elementsOptions.clientSecret == null">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div> -->

                <!-- Create keyword form -->
                <form [formGroup]="createCardForm" #createCardNgForm="ngForm">
                    <!-- Name field -->
                    <mat-form-field class="w-full mb-2">
                        <mat-label>Name</mat-label>
                        <input id="name" matInput type="text" [formControlName]="'name'" #nameField class="text-black">
                        <mat-error *ngIf="createCardForm.get('name').hasError('required')">
                            Name shouldn't be empty
                        </mat-error>
                    </mat-form-field>

                    <ngx-stripe-card class="mt-2" [options]="cardOptions" (on)="onChange($event)"
                        [elementsOptions]="elementsOptions"></ngx-stripe-card>
                    <div id="card-errors"></div>
                    <!-- Alert -->
                    <fuse-alert class="mt-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                        [type]="alert.type">
                        {{alert.message}}
                    </fuse-alert>
                </form>

                <!-- <div>
                    <div class="col-md-12" [formGroup]="createCardForm">
                        <div class="form-group">
                            <label>Name</label>
                            <input class="form-control" type="text" formControlName="name">
                        </div>
                        <div class="form-group">
                            <ngx-stripe-card [options]="cardOptions" (on)="onChange($event)"
                                [elementsOptions]="elementsOptions"></ngx-stripe-card>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0 mt-[20px]">
    <!-- Submit button -->
    <button mat-flat-button class="w-50 mt-3 btn_normal" mat-flat-button [color]="'primary'"
        type="submit" (click)="createToken()">
        <span>
            Submit
        </span>
        <!-- <mat-progress-spinner *ngIf="createKeywordForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner> -->
    </button>
</mat-dialog-actions>