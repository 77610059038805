import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'cart',
  templateUrl: './socialmedia-services.component.html',
  styleUrls: ['./socialmedia-services.component.scss'],
  standalone: true,
  imports: [CommonModule, MatRadioModule, RouterModule]
})

export class SocialMediaServicesComponent implements OnInit {

  services: SocialmediaServices

  socialMedia = [];
  blogs = [];
  postingHostingAndPromotion = [];
  ConversionMarketing = [];
  seo = [];
  ngOnInit(): void {
    this._socialMediaService.sociamMediaServices$.subscribe(services => {
      this.services = services

      this.services.plans.forEach(element => {
        if (element.category === "Social Media") {
          this.socialMedia.push(element)
        } else if (element.category === "Blogs") {
          this.blogs.push(element)
        } else if (element.category === "Posting + Paid Promotions") {
          this.postingHostingAndPromotion.push(element)
        } else if (element.category === "Conversion Marketing - Delivers Leads & Traffic") {
          this.ConversionMarketing.push(element)
        } else if (element.category === "SEO") {
          this.seo.push(element)
        }
      });
    })
  }

  /**
   * Constructor
   */
  constructor(private _socialMediaService: SocialMediaService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService, private _fuseConfirmationService: FuseConfirmationService,
    private _toast: ToastrService) {

  }

  onChoosePlan(planId) {
    if (this._localStorage.accessToken.length > 0) {
      this.submitPlanRequest(planId)
    }
    else {
      this.openSignin()
    }
  }

  openSignin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { checklistId: 0 };
    this._matDialog.open(LoginComponent, dialogConfig)
  }

  submitPlanRequest(planId) {
    this._spinner.show();
    this._socialMediaService.subscribe(planId).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        const dialogRef = this._fuseConfirmationService.open({
          title: "Success",
          message: res ? res.message : `Your request has been submitted.`,
          dismissible: false,
          icon: { show: false },
          actions: {
            cancel: { show: false },
            confirm: { label: "Ok" }
          }
        });
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : `Something went wrong. Please try again.`, "Sorry")
      }
    })
  }
}