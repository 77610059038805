import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, CanActivateFn, CanActivateChildFn } from '@angular/router';

import { catchError, finalize, lastValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../auth.service';
import { GlobalService } from '../global.service';
// @Injectable({
//     providedIn: 'root'
// })
// export class AutoLoginServiceGuard implements CanActivate {
//     constructor(public router: Router, private authService: AuthService, private route1: ActivatedRoute) { }

//     canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//         return new Promise(res => {



//         });
//     }

// }


export const AutoLoginServiceGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    if (route.queryParams['token'] == null)
        return of(true)
    else {
        const urlTree = router.parseUrl(``);
        return authService.loginWithToken(route.queryParams['token']).pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    return of(urlTree);
                }
                return of(urlTree);
            }),

            catchError(error => of(urlTree))
        );
    }
};