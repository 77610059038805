<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0 mt-12" style="max-width:90%;">
    <div class="breadcrumb flex mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','settings','organization']">Organization</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">Product</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">{{product?.title}}</a>
        <span class="ml-2 mr-2">/</span>
        <p style="font-weight:600">Employee Access</p>
    </div>

    <div class="flex flex-col p-6 gap-2"
        style="border: solid 1px #dedeff; box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16); border-radius: 20px;">
        <div class="flex justify-between items-center">
            <div class="flex items-center gap-6">
                <img class="w-[100px] h-[80px] bg-[#f5f5f5]" [src]="product?.featured_image ?? product?.thumb_image"
                    alt="Featured image" style="border-radius:10px;border:1px solid #dedeff;object-fit: contain;">

                <div>
                    <p class="font-semibold text-black text-lg sm:text-[20px] truncate w-[100px] sm:w-[200px] md:w-[auto]"
                        style="font-size:20px;">{{product?.title}}</p>
                    <p style="font-size:14px;">{{product?.template_name}}</p>
                    <p class="text-[#3723dd]" style="font-size: 14px;">{{product?.sub_title}}</p>
                </div>
            </div>
            <div class="hidden sm:block">
                <button class="flex btn_normal gap-2 items-center" (click)="addEmployee()" mat-flat-button>
                    <mat-icon class="text-white" svgIcon="heroicons_outline:plus"></mat-icon>
                    Add Employee
                </button>
            </div>
        </div>
        <button class="flex sm:hidden btn_normal gap-2 items-center mt-2" (click)="addEmployee()" mat-flat-button>
            <mat-icon class="text-white" svgIcon="heroicons_outline:plus"></mat-icon>
            Add Employee
        </button>

        <hr style="margin:20px 0px;">
        <ng-container *ngIf="employees?.length > 0 ; else noData">
            <div class="flex justify-between" *ngFor="let employee of employees">
                <div class="flex gap-4 items-center">
                    <img class="w-12 h-12 rounded-full border border-[#dedeff]" [src]="employee?.admin_image" alt="profile image">
                    <div>
                        <p class="font-semibold text-[060267]" style="font-size:17px;">{{employee?.admin_name}}</p>
                        <p class="text-black" style="font-size:14px">{{employee?.designation}}</p>
                    </div>
                </div>
                <div style="width:70px;">
                    <mat-slide-toggle [checked]="employee.is_enabled"
                        (change)="updateEmployeeAccess(employee , $event.checked )"></mat-slide-toggle>
                </div>
            </div>
        </ng-container>



    </div>


</section>

<ng-template #noData>
    <div class="flex flex-col max-w-120 text-center m-auto">
        <p class="text-black mb-5 mt-5" style="font-size:18px;font-weight:500;font-family:poppins">
            There are no employees in your organization
        </p>
        <p class="text-black mb-5" style="font-size:13px;font-weight:normal;font-family:poppins">
            Looks like there are no employees in your organization yet. Add a new employee to get started</p>
        <!-- <div class="flex mt-8">
        <button class="block bg-white" [routerLink]="'/'"
            style="color:#292362;padding:0.7rem 1.6rem;align-self: flex-start;border-radius: 8px;line-height: normal;font-weight:500;font-size:12px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #ddd;font-weight:600">
            CONTINUE SHOPPING
        </button>
    </div> -->
    </div>

</ng-template>