import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { DesignView } from '../product-detail/design-view/design-view.component';
import { MatSelectModule } from '@angular/material/select';
import { SearchService } from './search.service';
import { Subject, debounceTime, switchMap, takeUntil } from 'rxjs';
import { FilterConfiguration, FilterParams, Franchise, FranchiseCategory, Product, ProductVersion, SearchResult, Size, Tag, VersionHistory } from 'app/core/product/product.types';
import { MatRadioModule } from '@angular/material/radio';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { Pagination } from 'app/core/api/pagination.type';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SearchFilterDialog } from './search-filter-dialog/search-filter-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'app/core/product/product.service';
import { DeleteProductHistoryComponent } from './delete-product-history/delete-product-history.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ManageTags } from './manage-tags/manage-tags.component';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { Color, Category, PWAConfiguration, SubCategory } from 'app/core/configuration/configuration.types';
import { VersionHistoryComponent } from '../product-detail/version-history/version-history.component';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { AddToCartComponent } from '../product-detail/add-to-cart/add-to-cart.component';
import { LoginComponent } from 'app/shared/login/signin.component';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf,
    MatCheckboxModule, MatExpansionModule, MatIconModule, NgFor,
    DesignView, CommonModule, RouterModule, MatButtonModule,
    CommonModule, MatFormFieldModule, MatInputModule, FormsModule, SearchFilterComponent,
    ReactiveFormsModule, MatSelectModule, MatRadioModule, PWAPagination],
})
export class SearchPageComponent implements OnInit {
  isScreenSmall: boolean;

  filterParams = new FilterParams()

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(private _searchService: SearchService, private _router: Router, private _matDialog: MatDialog, private broadcast: BroadcastService,
    private _toast: ToastrService, private _productService: ProductService, private _confirmation: FuseConfirmationService,
    private _changeDetectorRef: ChangeDetectorRef, private _route: ActivatedRoute, private _spinner: NgxSpinnerService,
    private _localStorage: LocalStorageService,
    private _configurationService: ConfigurationService) {

    this.isCategoryDetails = this._route.snapshot.params['id'] != null
    this.categorySlug = this._route.snapshot.params['id']
    this.subCategorySlug = this._route.snapshot.params['subId']
    this.productId = this._route.snapshot.params['productId']

    this._route.queryParams.subscribe({
      next: (params: Params) => {
        this.filterParams = new FilterParams()
        this.filterParams.category = params['category'] ? this.getInteger(params['category']) : null
        this.filterParams.sub_category = params['sub_category'] ? this.getInteger(params['sub_category']) : null
        if (this.isCategoryDetails) {
          this.filterParams.category_slug = this.categorySlug
          this.filterParams.sub_category_slug = this.subCategorySlug
          this.filterParams.category = this.selectedCategory?.id
          this.filterParams.sub_category = this.selectedSubCategory?.id
          this.filterParams.product = this.getInteger(this.productId)
        }
        this.filterParams.size = params['size'] ? this.getInteger(params['size']) : null
        this.filterParams.page = params['page'] ? this.getInteger(params['page']) : 1
        this.filterParams.search = params['search'] ?? null

        ////update search input
        if (params['search']) {
          this.searchFormControl.patchValue(this.filterParams.search)
          this.updateSearch = false
        }

        if (this.isMyLibrary)
          this.filterParams.custom_templates = true
        this.filterParams.user_tag = params['user_tag'] ? this.getInteger(params['user_tag']) : null
        this.filterParams.colors = params['colors']?.split(',')?.map(function (str) {
          return parseInt(str);
        });
        this.filterParams.franchise_category = params['franchise_category'] ? this.getInteger(params['franchise_category']) : null
        this.filterParams.franchise = params['franchise'] ? this.getInteger(params['franchise']) : null

        this._changeDetectorRef.markForCheck()
        if (this.isFirstTimeUpdate)
          this.isFirstTimeUpdate = false
        else {
          if (this.isGlobalSearch)
            this._searchService.globalSearch(this.filterParams).subscribe()
          else
            this._searchService.search(this.filterParams, this.isMyLibrary).subscribe()
        }

      }
    })

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      this.onPageChange(1)
    })
  }

  private getInteger(value: string) {
    let result = Number(value)
    return Number.isNaN(result) ? null : result
  }

  isMyLibrary = false
  isGlobalSearch = false

  searchFormControl = new FormControl();

  products: Product[]
  userTags: Tag[]
  searchConfig: FilterConfiguration = {}
  isFirstTimeUpdate = true
  pagination: Pagination

  isCategoryDetails = false
  ngOnInit(): void {
    this.updateSearch = true

    this.isMyLibrary = this._router.url.includes('my-library')
    this.isGlobalSearch = this._router.url.includes('global-search') || this.isCategoryDetails

    if (!this.isMyLibrary) {
      this._configurationService.configuration$
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((config: PWAConfiguration) => {
          this.categories = config.digital_categories.concat(config.print_categories)
          this.colors = config.colors

        })
    }
    if (this.isGlobalSearch) {
      this._configurationService.franchises$
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((franchises: FranchiseCategory[]) => {
          this.franchises = franchises
        })
    }
    this.searchFormControl.patchValue(this.filterParams.search)
    this._searchService.searchResult$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: SearchResult) => {
        this._changeDetectorRef.markForCheck()
        this.products = result?.products ?? result?.data
        if (this.isMyLibrary)
          this.searchConfig = result.filter_configuration
        else {
          this.selectedCategory = this.categories?.find(category => category?.id == this.filterParams.category || category.slug == this.categorySlug)
          this.selectedSubCategory = this.selectedCategory?.sub_categories?.find(category => category?.id == this.filterParams.sub_category || category.slug == this.subCategorySlug)
          console.log(this.selectedCategory);
          console.log(this.selectedSubCategory);

          this.filterParams.category = this.selectedCategory?.id
          this.filterParams.sub_category = this.selectedSubCategory?.id

          this.searchConfig = {
            categories: this.categories,
            sub_categories: this.selectedCategory?.sub_categories,
            sizes: this.selectedSubCategory?.sizes,
            businesses: this.franchises,
            colors: result.filter_configuration?.colors
          }

        }
        this.pagination = result
        this._changeDetectorRef.markForCheck()
      })

    this._productService.userTags$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tags: Tag[]) => {
        this.userTags = tags
        this._changeDetectorRef.markForCheck()
      })

    this._productService.productDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((productDetails: Product) => {
        this.productDetails = productDetails
        this._changeDetectorRef.markForCheck()
      })

    this.searchFormControl.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(changedValue => {
      if (!this.updateSearch) {
        this.updateSearch = true
        return
      }
      this.filterParams = new FilterParams()
      this.filterParams.search = changedValue
      this.updateUrlForSearch()
    });
  }

  updateSearch = true

  categories: Category[]
  franchises: FranchiseCategory[]
  colors: Color[]
  selectedCategory: Category
  selectedSubCategory: SubCategory

  categorySlug = null
  subCategorySlug = null
  productId = null
  productDetails = null

  updateUrlForSearch() {
    let params = {}
    if (!this.isCategoryDetails) {
      params['category'] = this.filterParams.category
      params['sub_category'] = this.filterParams.sub_category
    }

    params['size'] = this.filterParams.size
    params['franchise_category'] = this.filterParams.franchise_category
    params['franchise'] = this.filterParams.franchise
    params['page'] = this.filterParams.page ?? 1
    params['search'] = this.filterParams.search ?? null
    params['user_tag'] = this.filterParams.user_tag ?? null
    if (this.filterParams?.colors?.length > 0)
      params['colors'] = this.filterParams.colors.toString()
    else
      params['colors'] = null
    const urlTree = this._router.createUrlTree([], {
      queryParams: params,
      queryParamsHandling: "merge",
      preserveFragment: false
    });

    this._router.navigateByUrl(urlTree);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onPageChange(page) {
    this.filterParams.page = page
    this.updateUrlForSearch()
  }

  onFilterChange(filter: FilterParams) {
    this.filterParams = filter
    this.updateUrlForSearch()
  }

  openFilterModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      filterParams: this.filterParams,
      filterConfig: this.searchConfig
    }
    // dialogConfig.height = "calc(100% - 30px)"
    dialogConfig.width = "calc(100% - 30px)"
    dialogConfig.maxWidth = "100%"
    dialogConfig.maxHeight = "100%"
    this._matDialog.open(SearchFilterDialog, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          value.search = this.filterParams.search
          this.filterParams = value
          this.updateUrlForSearch()
        }
      });
  }

  compareById(f1: Franchise, f2: Franchise): boolean {
    return f1 && f2 && f1.id === f2.id;
  }


  onClickDelete(product: Product) {
    if (product?.is_user_product) {
      this.deleteDesign(product)
    }
    else {
      this.getVersionHistory(product, true)
    }
  }

  deleteDesign(product) {
    const dialogRef = this._confirmation.open({
      title: "Delete Design",
      message: `Are you sure you want to delete this design?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteDesign(product.id)
    });
  }

  proceedDeleteDesign(productId) {
    this._spinner.show();
    this._productService.deleteProduct(productId).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._searchService.search(this.filterParams, this.isMyLibrary).subscribe()
        this._toast.success(res.message ? res.message : 'Product has been removed successfully', "Success")

      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  getVersionHistory(product, isFromMyLibrary) {
    if (!this.checkLoginStatus()) {
      return;
    }
    if (product?.is_accessory) {
      this.openPlaceYourOrder(product)
      return
    }
    this._spinner.show()
    this._productService.getProductHistory(product.id).subscribe({
      next: versions => {
        this._spinner.hide()
        if (versions?.length > 0)
          this.openVersionHistory(versions, product)
        else {
          if (!isFromMyLibrary)
            this.createVersionHistory(product)
          else
            this._toast.error("There are no version history to delete", "Sorry")
        }
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openPlaceYourOrder(product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-auto', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    dialogConfig.data = {
      category: product?.category_name,
      subCategory: product?.sub_category_name,
      isAccessory: true,
      versionId: product?.id,
      isForDesignSelection: false
    };
    this._matDialog.open(AddToCartComponent, dialogConfig)
  }

  createVersionHistory(product) {
    this._spinner.show()
    this._productService.createVersionHistory(product.id, false).subscribe({
      next: (version: VersionHistory) => {
        this._spinner.hide()
        this.openVersionHistory([version], product)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openVersionHistory(histories: VersionHistory[], version: ProductVersion) {

    const dialogConfig = new MatDialogConfig();
    // dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    // dialogConfig.maxWidth = '100%'
    // dialogConfig.position = { right: '0px', top: '0px' }

    // dialogConfig.data = { productId: productId, history: histories };
    // this._matDialog.open(DeleteProductHistoryComponent, dialogConfig)
    //   .afterClosed().subscribe(value => {
    //     if (value != null) {
    //       this._searchService.search(this.filterParams, this.isMyLibrary).subscribe()
    //     }
    //   });

    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'lg:w-5/6','xl:w-4/6', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    let configOptions = sessionStorage.getItem("configurations")?.split(',')
    dialogConfig.data = { versionId: version?.id, histories: histories, category: version?.category_id, subCategory: version?.sub_category_id, options: configOptions };
    this._matDialog.open(VersionHistoryComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });



  }

  onClickRemoveTag(product: Product) {
    this._spinner.show()
    let data = {
      "remove_product_versions": [product.id]
    }
    let request = this._productService.updateUserTag(data, product.user_tag.id)
    request.subscribe({
      next: (res: any) => {
        this._spinner.hide()
        product.user_tag = null
      },
      error: (err) => {
        this._spinner.hide()
        this._toast.error(err ?? err.error.message, "Sorry")
      }
    });
  }

  openManageTags(product: Product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { versionId: product?.id, selectedTag: product?.user_tag?.id }
    dialogConfig.panelClass = ['w-full', 'max-w-200']
    this._matDialog.open(ManageTags, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value?.needToUpdate) {
          this._searchService.search(this.filterParams, this.isMyLibrary).subscribe()
        }
      });
  }

  checkLoginStatus() {
    if (this._localStorage.accessToken?.length > 0)
      return true
    else
      this.openLoginModal()
  }

  openLoginModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { checklistId: 0 };
    this._matDialog.open(LoginComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }
}
