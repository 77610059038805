import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
@Component({
    selector: 'app-business-categories',
    templateUrl: './business-categories.component.html',
    styleUrls: ['./business-categories.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule, MatTooltipModule,
        RouterModule]
})
export class BusinessCategories implements OnInit {

    constructor(
        private _dialogRef: MatDialogRef<BusinessCategories>) { }

    ngOnInit(): void {
    }

    close() {
        this._dialogRef.close();
    }

    categories = [

        {
            name: 'Real Estate',
            "id": 3,
            image: './assets/images/realEstate.svg'
        },
        {
            name: 'Education',
            "id": 17,
            image: './assets/images/education.svg'
        },
        {
            name: 'Beauty & Cosmetics',
            "id": 25,
            image: './assets/images/beautycare.svg'
        },
        {
            name: 'Restaurants',
            "id": 13,
            image: './assets/images/restaurants.svg'
        },
        {
            name: 'Sports & Fitness',
            "id": 22,
            image: './assets/images/fitness.svg'
        },
        {
            name: 'Hotel',
            "id": 15,
            image: './assets/images/hotel.svg'
        },
        {
            name: 'Insurance',
            "id": 5,
            image: './assets/images/insurance.svg'
        },
        {
            name: 'Health & beauty',
            "id": 38,
            image: './assets/images/health.svg'
        },
        {
            name: 'Day Care Services',
            "id": 27,
            image: './assets/images/daycare.svg'
        },
        {
            name: 'Business',
            "id": 24,
            image: './assets/images/business.png'
        },
        {
            name: 'Attorney',
            "id": 2,
            image: './assets/images/attorney.svg'
        },
        {
            name: 'Retail and Sales',
            "id": 33,
            image: './assets/images/retail_Sales.svg'
        },
        {
            name: 'Janitorial',
            "id": 35,
            image: './assets/images/janitorial.svg'
        },
        {
            name: 'Flooring',
            "id": 42,
            image: './assets/images/flooring.svg'
        },
        {
            name: 'Mortgage',
            "id": 4,
            image: './assets/images/mortgage.svg'
        },
        {
            name: 'Pharmacy',
            "id": 43,
            image: './assets/images/pharmacy.svg'
        },
        {
            name: 'Accounting',
            "id": 1,
            image: './assets/images/accounting.svg'
        },
        {
            name: 'Finance',
            "id": 11,
            image: './assets/images/finance.svg'
        },
        {
            name: 'Services',
            "id": 12,
            image: './assets/images/services.svg'
        },
        {
            name: 'Title Company ',
            "id": 16,
            image: './assets/images/title_company.svg'
        },
        {
            name: 'Political',
            "id": 18,
            image: './assets/images/political.svg'
        },
        {
            name: 'Marketing',
            "id": 19,
            image: './assets/images/marketing.svg'
        },
        {
            name: 'Hospital',
            "id": 20,
            image: './assets/images/hospital.svg'
        },
        {
            name: 'Sale',
            "id": 21,
            image: './assets/images/sale.svg'
        },
        {
            name: 'Tours & Travels',
            "id": 23,
            image: './assets/images/tours_travels.svg'
        },
        {
            name: 'Construction',
            "id": 26,
            image: './assets/images/construction.svg'
        },
        {
            name: 'Banking',
            "id": 28,
            image: './assets/images/bank.svg'
        },
        {
            name: 'Public Safety',
            "id": 30,
            image: './assets/images/public_safety.svg'
        },
        {
            name: 'Tax',
            "id": 31,
            image: './assets/images/tax.svg'
        },
        {
            name: 'Religious',
            "id": 32,
            image: './assets/images/religious.svg'
        },
        {
            name: 'Food & Beverage',
            "id": 34,
            image: './assets/images/food_beverage.svg'
        },
        {
            name: 'Book Keeping',
            "id": 36,
            image: './assets/images/book_keeping.svg'
        },
        {
            name: 'Home Improvement ',
            "id": 37,
            image: './assets/images/home_improvement.svg'
        },
        {
            name: 'Media',
            "id": 44,
            image: './assets/images/media.svg'
        },
        {
            name: 'Financial Planning',
            "id": 39,
            image: './assets/images/financial_planning.svg'
        },
        {
            name: 'Veterinary',
            "id": 40,
            image: './assets/images/veterinary.svg'
        },
        {
            name: 'Jewelry',
            "id": 45,
            image: './assets/images/jewelry.svg'
        }
    ]

}
