import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PaymentService } from 'app/core/payment/payment.service';
import { PaymentCard } from 'app/core/payment/payment.types';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails, CartItem, Promocode, ShippingMethod } from 'app/core/cart/cart.types';
import { Router, RouterModule } from '@angular/router';
import { CreateAddressComponent } from '../../settings/address/create-address/create-address.component';
import { CreateCardComponent } from '../../settings/saved-cards/create-card/create-card.component';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { PromocodesComponent } from '../../cart/promocodes/promocodes.component';
import { AddressChooserComponent } from '../address-chooser/address-chooser.component';
import { CardChooserComponent } from '../card-chooser/card-chooser.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTabsModule, RouterModule, MatCheckboxModule, FormsModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatIconModule, MatProgressSpinnerModule]
})

export class SubscriptionPaymentComponent implements OnInit {
  addressList: any[];
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  addressList$ = new BehaviorSubject<Address[]>(null);
  cards$ = new BehaviorSubject<PaymentCard[]>(null);
  constructor(
    private _confirmation: FuseConfirmationService, private _spinner: NgxSpinnerService, private _userService: UserService,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _cardsService: PaymentService, private _router: Router,
    private _subscriptionService: SubscriptionService) {

  }


  selectedBillingAddress = null
  selectedCard = null

  selectedPlans = []
  ngOnInit(): void {
    if (sessionStorage.getItem('selectedPlans') == null) {
      this._router.navigate(['/', 'pricing'])
      return
    }

    this._userService.addressList$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(addresses => {
        this.addressList$.next(addresses)
        if (this.selectedBillingAddress == null)
          this.selectedBillingAddress = addresses[0]
        this._changeDetectorRef.markForCheck()
      })

    this._cardsService.savedCards$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cards => {
        this.cards$.next(cards)
        if (this.selectedCard == null)
          this.selectedCard = cards[0]
        this._changeDetectorRef.markForCheck()
      })

    this.selectedPlans = Array.from(JSON.parse(sessionStorage.getItem('selectedPlans')));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateAddressComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Address added successfully"), "Success");
          this._userService.getAddresses().subscribe()
        }
      });
  }

  removePlan(plan) {
    const dialogRef = this._confirmation.open({
      title: "Delete Plan",
      message: `Are you sure you want to remove '${plan.title}'?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.selectedPlans = this.selectedPlans.filter(item => plan.id != item.id)
    });

  }

  addCard() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateCardComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Card added successfully"), "Success");
          // Refresh the keyword list
          //   this.showLoading(true)
          this._cardsService.getCards().subscribe()
        }
      });
  }

  onConfirmAndPay(plan) {
    plan.isSubscribing = true
    plan.error = null
    if (this.selectedBillingAddress && this.selectedCard) {
      let data = {
        "billing_address": this.selectedBillingAddress?.id,
        "card_id": this.selectedCard.id,
        "plan_id": plan.id
      }

      this._spinner.show()
      this._subscriptionService.subscribe(data).subscribe({
        next: (res: any) => {
          plan.completed = true
          plan.isSubscribing = false
          plan.can_cancel = true

          this.hideSpinner()
          if (this.selectedPlans.filter(item => !item.completed).length == 0) {
            sessionStorage.removeItem('selectedPlans')
            sessionStorage.removeItem('planFrequency')
            this._confirmation.open({
              title: "Success",
              message: "Your subscription was successful!",
              icon: { show: false },
              actions: { confirm: { label: "Ok" }, cancel: { show: false } }
            }).afterClosed().subscribe(result => {
              this._router.navigate(['/'])
            })
          }
        },
        error: (err) => {
          plan.isSubscribing = false
          plan.error = err ? err.error.message : "Something went wrong. Please try again!"
          this.hideSpinner()
        }
      });
    }

  }

  hideSpinner() {
    let loadingPlans = this.selectedPlans.filter(plan => plan.isSubscribing)
    if (loadingPlans?.length == 0) {
      this._spinner.hide()
    }
  }


  completeSubscription() {
    if (this.selectedPlans.filter(item => !item.completed).length == 0) {
      sessionStorage.removeItem('selectedPlans')
      sessionStorage.removeItem('planFrequency')
      this._confirmation.open({
        title: "Success",
        message: "You have subscribed all selected plans!",
        icon: { show: false },
        actions: { confirm: { label: "Ok" }, cancel: { show: false } }
      }).afterClosed().subscribe(result => {
        this._router.navigate(['/'])
      })
      return
    }

    this.selectedPlans.forEach((element, index) => {
      if (!element.completed)
        this.onConfirmAndPay(element);
    });
  }

  chooseAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.selectedBillingAddress
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-2/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    this._matDialog.open(AddressChooserComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          if (value.address) {
            this.selectedBillingAddress = value.address
          }
        }
      });
  }

  chooseCard() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.selectedCard
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-2/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    this._matDialog.open(CardChooserComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          if (value.card) {
            this.selectedCard = value.card
          }
        }
      });
  }


}
