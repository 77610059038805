import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseHorizontalNavigationComponent, FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { PWANavigation } from 'app/shared/navigation';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../../../../shared/login/signin.component';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { OrganizationService } from 'app/core/organization/organization.service';
import { Organization } from 'app/core/organization/organization.types';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails } from 'app/core/cart/cart.types';
import { DomSanitizer } from '@angular/platform-browser';
import { ReferralComponent } from 'app/layout/common/referral/referral.component';
import { ConfigurationService } from 'app/core/configuration/configuration.service';
import { Category, PWAConfiguration } from 'app/core/configuration/configuration.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { VID_URL } from 'environments/environment';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HomeService } from 'app/modules/admin/home/home.service';
import { MatInputModule } from '@angular/material/input';
import { CreateOrganizationComponent } from 'app/shared/create-organization/create-organization.component';
import { AuthService } from 'app/core/auth/auth.service';
import { ScheduleDemoComponent } from 'app/layout/common/schedule-demo/schedule-demo.component';

@Component({
    selector: 'enterprise-layout',
    templateUrl: './enterprise.component.html',
    styleUrls: ['./enterprise.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, ScheduleDemoComponent, CommonModule, NgIf, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, NotificationsComponent, ReferralComponent, UserComponent, FuseHorizontalNavigationComponent, RouterOutlet, RouterModule, QuickChatComponent, MatExpansionModule, MatButtonModule, MatMenuModule, NgxSpinnerModule, FormsModule, ReactiveFormsModule, MatInputModule],
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];
    panelOpenState = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('verticalNavigation') verticalNavigation: FuseVerticalNavigationComponent;
    configuration: PWAConfiguration
    newsletterForm: UntypedFormGroup;
    hideRequiredMarker = "true"
    @ViewChild('newsletterNgForm') newsletterNgForm: NgForm;
    /**
     * Constructor
     */

    constructor(
        public _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _matDialog: MatDialog, private _toast: ToastrService,
        private _userService: UserService, private _cartService: CartService,
        private _organizationService: OrganizationService,
        private _localStorageService: LocalStorageService,
        private _configurationService: ConfigurationService,
        private broadcastService: BroadcastService,
        private _changeDetectorRef: ChangeDetectorRef,
        private matIconRegistry: MatIconRegistry, private _authService: AuthService,
        private domSanitizer: DomSanitizer, private _fuseConfirmationService: FuseConfirmationService,
        private _localStorage: LocalStorageService, private _homeService: HomeService,
        private _spinner: NgxSpinnerService, private fb: UntypedFormBuilder,
        private _confirmationService: FuseConfirmationService
    ) {
        this.navigation = new PWANavigation(_localStorage).navigation
        this.matIconRegistry.addSvgIcon(
            "nav_cart_icon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav_cart_icon.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "nav_gift_icon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav_gift_icon.svg")
        );
    }

    close() {
        this.verticalNavigation.close()
    }

    logOut() {
        this._confirmationService.open({
            title: "Logout",
            message: "Are you sure, you want to logout?",
            actions: { confirm: { label: "Logout" } }
        }).afterClosed().subscribe(result => {
            if (result == 'confirmed') {
                this._authService.signOut()
                window.location.reload();
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    isOrganizationAccount = false
    isPaymentPage = false
    ngOnInit(): void {
        this.currentUrl = this._router.url

        this.isPaymentPage = (this.currentUrl == '/checkout' || this.currentUrl?.includes('/pay/') || this.currentUrl == '/pricing/payment')

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                let menu = this.navigation.find(navItem => navItem.id == 'digital-products')
                let serviceMenu = menu?.children?.find(navItem => navItem.id == 'digital-products.services')
                if (serviceMenu != null)
                    serviceMenu.type = this.isScreenSmall ? "collapsable" : "group"
            });

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url
                this.isPaymentPage = (this.currentUrl == '/checkout' || this.currentUrl?.includes('/pay/') || this.currentUrl == '/pricing/payment')
            }
        });

        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
                this.user = user
                let smmMenu = this.navigation.find(navItem => navItem.id == 'social-media')
                smmMenu.disabled = false
                this.refreshNavigation()

                this.isOrganizationAccount = this._localStorageService.accountType == 'organization'
            })

        this._configurationService.configuration$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((config: PWAConfiguration) => {
                this.configuration = config
                this.configureNavBar('print-products', this.configuration.print_categories)
                this.configureNavBar('digital-products', this.configuration.digital_categories)
                this.addDigitalServices()
            })

        this._organizationService.organization$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((organization: Organization) => {
                this.organization = organization
                this.isOrganizationAccount = this._localStorageService.accountType == 'organization'
            })

        this._cartService.cartDetail$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((cart: CartDetails) => {
                this.cartCount = cart?.items?.length
            })

        this.newsletterForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.broadcastService.subscribe("action-switch-to-organization", this._unsubscribeAll, data => {

            this.switchAccount(data?.payload?.redirect)
        })

    }

    refreshNavigation() {
        const navigation = this._fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        const hNavigation = this._fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('hNavigation');
        navigation?.refresh()
        hNavigation?.refresh()
    }

    subscribeNewsletter() {
        this.newsletterForm.markAllAsTouched();
        if (this.newsletterForm.invalid) {
            return;
        }
        this._spinner.show()
        let request = this._homeService.subscribeNewsLetter(this.newsletterForm.value)
        request.subscribe({
            next: (response: any) => {
                this._spinner.hide()
                this.newsletterForm.enable()
                this._fuseConfirmationService.open({
                    title: "Success",
                    message: response.message ? response.message : "Thank you for subscribing to our newsletter",
                    dismissible: false,
                    actions: {
                        cancel: { show: false },
                        confirm: { label: "Ok" }
                    },
                    icon: { show: false }
                });
            },
            error: error => {
                this._spinner.hide()
                this.newsletterForm.enable()
                this._toast.error(error ? error.error.message : "Something went wrong. Please try again")
            }
        })
    }

    addDigitalServices() {
        let menu = this.navigation.find(navItem => navItem.id == 'digital-products')
        if (menu != null) {
            menu.children.push({
                id: `digital-products.services`,
                title: "Digital Services",
                type: this.isScreenSmall ? "collapsable" : "group",
                children: [
                    // {
                    //     id: `digital-products.services`,
                    //     title: "Services",
                    //     type: 'basic',
                    //     link: `/socialmedia-services`,
                    //     function: (item: FuseNavigationItem) => {
                    //         this.toggleNavigation('mainNavigation')
                    //     }
                    // },
                    {
                        id: `digital-products.vid`,
                        title: "Virtual Biz Cards",
                        type: 'basic',
                        function: (item: FuseNavigationItem) => {
                            this.changeNavigationStatus(false)
                            this.onClickVID(false)
                        }
                    },
                    {
                        id: `digital-products.email-signature`,
                        title: "Email Signatures",
                        type: 'basic',
                        function: (item: FuseNavigationItem) => {
                            this.changeNavigationStatus(false)
                            this.onClickVID(true)
                        }
                    },
                    {
                        id: `digital-products.crmLevelX`,
                        title: "CRM Level X",
                        type: 'basic',
                        externalLink: true,
                        target: '_blank',
                        link: `https://crmlevel10.com/`,
                        function: (item: FuseNavigationItem) => {
                            this.changeNavigationStatus(false)
                        }
                    },
                ]
            })
        }
    }
    // 
    onClickVID(isEmailSignature) {
        if (!this.checkLoginStatus()) {
            return
        }
        this._userService.getVIDProfiles().subscribe({
            next: (res) => {
                this._spinner.hide()
                sessionStorage.removeItem('isFromVid')
                if (isEmailSignature)
                    window.open(VID_URL + 'email-signatures')
                else
                    window.open(VID_URL)
            },
            error: (err) => {
                this._spinner.hide();
                const dialogRef = this._confirmationService.open({
                    title: "Sorry",
                    message: err ? err.error.message : `Something went wrong. Please try again.`,
                    dismissible: false,
                    actions: {
                        confirm: { label: "Ok" }
                    }
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result == 'confirmed')
                        if (err.error.url != null) {
                            window.location = err.error.url
                        }
                });
            }
        })
    }

    checkLoginStatus() {
        if (this._localStorage.accessToken?.length > 0)
            return true
        else
            this.openLoginModal()
    }

    openLoginModal() {
        this.changeNavigationStatus(false)
        const dialogConfig = new MatDialogConfig();
        this._matDialog.open(LoginComponent, dialogConfig)
            .afterClosed().subscribe(value => {
                if (value != null && value.length > 1) {

                }
            });
    }

    configureNavBar(menuId, categories: Category[]) {
        let menu = this.navigation.find(navItem => navItem.id == menuId)
        if (menu != null) {
            menu.children = []
            categories.forEach(category => {
                let categoryItem: FuseNavigationItem = {
                    id: `${menuId}.${category.id}`,
                    title: category.title,
                    type: 'basic',
                    link: `/category/${category.slug}`,
                    children: []
                }
                // category.sub_categories.forEach(subCategory => {
                //     let subCategoryItem: FuseNavigationItem = {
                //         id: `${menuId}.${subCategory.id}`,
                //         title: subCategory.title,
                //         type: 'basic',
                //         link: `/search`,
                //         queryParams: { category: category.id, sub_category: subCategory.id }
                //     }
                //     categoryItem.children.push(subCategoryItem)
                // })
                menu.children.push(categoryItem)
            })
        }

    }

    cartCount = 0

    currentUrl = null

    user: User = null
    organization: Organization = null

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    switchAccount(redirect: any = null) {
        this.changeNavigationStatus(false)
        if (this._localStorageService.organizationId == null || this._localStorageService.organizationId == '0') {
            const dialogRef = this._fuseConfirmationService.open({
                title: "Create Organization",
                message: `It seems like you do not have any organization to switch to. Would you like to create a new organization?`,
                actions: {
                    confirm: { label: "CREATE" }
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result == 'confirmed')
                    this.openOrganizationCreateModal()
            });
            return
        }

        if (this.isOrganizationAccount) {
            this._localStorageService.accountType = 'user'
            this.isOrganizationAccount = false
            this._userService.get().subscribe()
            this.broadcastService.broadcast('account_switch_notification', 'user')
            this._toast.success("You are successfully switched to personal profile")
        } else {
            this._localStorageService.accountType = 'organization'
            this.isOrganizationAccount = true
            this._organizationService.getOrganizationDetails().subscribe()
            this.broadcastService.broadcast('account_switch_notification', 'organization')
            this._toast.success("You are successfully switched to organization profile")
        }

        if (redirect != null)
            this._router.navigate(redirect)
    }

    openOrganizationCreateModal() {
        const dialogConfig = new MatDialogConfig();
        this._matDialog.open(CreateOrganizationComponent, dialogConfig)
            .afterClosed().subscribe(value => {
                if (value != null) {
                    this.switchAccount()
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    changeNavigationStatus(open: boolean) {
        const navigation = this._fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if (navigation) {
            try {
                // Toggle the opened status
                if (open) {
                    navigation?.open()
                }
                else
                    navigation?.close();
            }
            catch (e) { }
        }
    }
}
