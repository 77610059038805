import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { Category, FilterConfiguration, FilterParams, Franchise, Product, Size } from 'app/core/product/product.types';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { MatExpansionModule } from '@angular/material/expansion';
import { Color } from 'app/core/configuration/configuration.types';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { FuseMasonryComponent } from '@fuse/components/masonry';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
@Component({
    selector: 'pwa-search-filter',
    templateUrl: './stock-images.component.html',
    styleUrls: ['./stock-images.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatSelectModule,
        MatExpansionModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        FuseMasonryComponent,
        RouterModule]

})
export class StockImagescomponent implements OnInit {
    private _matAutocomplete: MatAutocomplete;
    searchControl: UntypedFormControl = new UntypedFormControl();
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService, private _changeDetectorRef: ChangeDetectorRef) { }
    @Output() onFilterChange = new EventEmitter<FilterParams>();
    @ViewChild('widgetsQuantitySCroll', { read: ElementRef }) public widgetsQuantitySCroll: ElementRef<any>;
    @ViewChild('cardSCroll', { read: ElementRef }) public cardSCroll: ElementRef<any>;
    @ViewChild('franchiseLogoSCroll', { read: ElementRef }) public franchiseLogoSCroll: ElementRef<any>;
    @ViewChild('widgetsContent') widgetsContent: ElementRef;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    // close() {
    //   this.onClose.emit();
    // }

    filterParams: FilterParams = new FilterParams()

    @Input() set filter(params: FilterParams) {
        this.filterParams = params
    }

    searchConfig: FilterConfiguration = null

    @Input() set configurations(config: FilterConfiguration) {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the masonry columns
                //
                // This if block structured in a way so that only the
                // biggest matching alias will be used to set the column
                // count.
                if (matchingAliases.includes('xl')) {
                    this.masonryColumns = 5;
                }
                else if (matchingAliases.includes('lg')) {
                    this.masonryColumns = 4;
                }
                else if (matchingAliases.includes('md')) {
                    this.masonryColumns = 3;
                }
                else if (matchingAliases.includes('sm')) {
                    this.masonryColumns = 2;
                }
                else {
                    this.masonryColumns = 1;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
    isSafariBrowser = false
    public scrollRight(target): void {
        let element = null
        switch (target) {
            case 'vid': {
                element = this.cardSCroll.nativeElement
                break;
            }
            case 'business': {
                element = this.widgetsQuantitySCroll.nativeElement
                break;
            }
            case 'franchise': {
                element = this.franchiseLogoSCroll.nativeElement
                break;
            }
        }
        if (this.isSafariBrowser)
            element.scrollLeft += 300
        else
            element.scrollTo({ left: (element.scrollLeft + 300), behavior: 'smooth' });
    }
    public scrollLeft(target): void {
        let element = null
        switch (target) {
            case 'vid': {
                element = this.cardSCroll.nativeElement
                break;
            }
            case 'business': {
                element = this.widgetsQuantitySCroll.nativeElement
                break;
            }
            case 'franchise': {
                element = this.franchiseLogoSCroll.nativeElement
                break;
            }
        }

        if (this.isSafariBrowser)
            element.scrollLeft -= 300
        else
            element.scrollTo({ left: (element.scrollLeft - 300), behavior: 'smooth' });
    }
    buttonGroup = [
        {
            text: "Happy Family"
        },
        {
            text: "Friends"
        },
        {
            text: "Childrens"
        },
        {
            text: "Mother"
        },
        {
            text: "Father"
        },
        {
            text: "Marriage"
        },
        {
            text: "Family Home"
        },
        {
            text: "Family Tree"
        },
        {
            text: "Parenting"
        },
        {
            text: "Parenting"
        },
        {
            text: "Parenting"
        },


    ];

    masonryColumns: number = 5;

    imageList = [
        {
            image: './assets/images/postcard.webp'
        },
        {
            image: './assets/images/contact_img.jpeg'
        },
        {
            image: './assets/images/customizeplan_banner.png'
        },
        {
            image: './assets/images/ebook_img.png'
        },
        {
            image: './assets/images/fb_posts.webp'
        },
        {
            image: './assets/images/getstarted_banner.png'
        },
        {
            image: './assets/images/footer_logo.png'
        },
        {
            image: './assets/images/insta_icon.png'
        },
        {
            image: './assets/images/product3.png'
        },
    ];
    imageArray = [
        {
            image: "./assets/images/postcard.webp"
        },
        {
            image: "./assets/images/postcard.webp"
        },
        {
            image: "./assets/images/postcard.webp"
        }
    ];
    subImages = [
        {
            image: "./assets/images/postcard.webp"
        },
        {
            image: "./assets/images/postcard.webp"
        },
        {
            image: "./assets/images/postcard.webp"
        },
        {
            image: "./assets/images/postcard.webp"
        },
      
    ];


}
