import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';


@Component({
  selector: 'app-hire-designer',
  templateUrl: './hire-designer.component.html',
  styleUrls: ['./hire-designer.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
  ]
})
export class HireDesignerPopup implements OnInit {

  @ViewChild('contactNgForm') contactNgForm: NgForm;
  contactForm: UntypedFormGroup;

  constructor(
    private _accountService: UserService,
    private _toast: ToastrService,
    private fb: UntypedFormBuilder, private _confirmation: FuseConfirmationService,
    private _dialogRef: MatDialogRef<HireDesignerPopup>) {

  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      subject: ['Hire Designer'],
      phoneNumber: [''],
      message: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
    this._accountService.user$.subscribe(user => {
      this.user = user
      this.reInitForm()
    })
  }

  user: User

  reInitForm() {
    this.contactForm.patchValue({
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      email: this.user.email,
      phoneNumber: this.user.phone_number
    })
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  submit() {
    this.showAlert = false
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      return;
    }
    this.contactForm.disable()

    let data = this.contactForm.value
    data['message'] = `Contact No : ' + ${this.contactForm.get('phoneNumber').value}\n\n'` + data['message']

    let request = this._accountService.submitContactUsForm(this.contactForm.value)
    request.subscribe({
      next: (response: any) => {
        this.close()
        this.contactForm.enable()
        this.contactForm.reset()
        this._confirmation.open({
          title: "Success",
          icon: { show: false },
          actions: { confirm: { label: "Ok" }, cancel: { show: false } },
          message: response?.message ?? "Thank you for showing your interest in us. We will get back to you soon."
        })
      },
      error: error => {
        this.contactForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }


  //Close modal with a response message
  responseMessage: { message: string, enable2FA: boolean }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

}
