import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Address, Contact, UploadsResult, User } from 'app/core/user/user.types';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { ImageViewerComponent } from './image-viewer.component';

@Injectable({ providedIn: 'root' })
export class ImageViewerService {

    constructor(
        private _matDialog: MatDialog,

    ) { }

    open(url: String) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = url
        this._matDialog.open(ImageViewerComponent, dialogConfig)
    }

}
