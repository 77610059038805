import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AutofillManagerService } from '../autofill-manager.service';
import { ActivatedRouteSnapshot } from '@angular/router';

export const autofillMapDataResolver = (route: ActivatedRouteSnapshot) => {
    const _autofillManagerService = inject(AutofillManagerService);
    const requestArray: Observable<any>[] = [_autofillManagerService.getGroupDetails(route.params['id'], true),
    _autofillManagerService.getMappingData()]
    return forkJoin(requestArray);
};
