import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FilterConfiguration, FilterParams, Franchise, FranchiseCategory, Product, ProductVersion, VersionHistory } from 'app/core/product/product.types';
import { Pagination } from 'app/core/api/pagination.type';
import { ProductService } from 'app/core/product/product.service';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from 'app/shared/login/signin.component';
import { HttpParams } from '@angular/common/http';
import { DesignView } from '../../product-detail/design-view/design-view.component';
import { VersionHistoryComponent } from '../../product-detail/version-history/version-history.component';
import { SearchService } from '../search.service';
import { LibraryChooserComponent } from '../../social-media/library-chooser/library-chooser.component';
@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule, PWAPagination, MatProgressSpinnerModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule, DesignView,
    RouterModule]
})
export class SearchPopup implements OnInit {

  @ViewChild('filterNgForm') createChecklistNgForm: NgForm;
  isForSocialMedia = false
  constructor(@Inject(MAT_DIALOG_DATA) public data: { histories: VersionHistory[], fromSocialMediaPost: false },
    private _formBuilder: FormBuilder, private _productService: ProductService, private _searchService: SearchService,
    private _localStorage: LocalStorageService, private _spinner: NgxSpinnerService,
    private _toast: ToastrService, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<SearchPopup>
  ) {
    this.isForSocialMedia = data?.fromSocialMediaPost
    this.selectedHistories = data?.histories
  }

  // @Output() onClose = new EventEmitter<void>();

  // close() {
  //   this.onClose.emit();
  // }

  filter: FilterParams = new FilterParams()
  ngOnInit(): void {
    this.filter.printable_only = true
    this.getVersions(1)
  }

  versions: ProductVersion[]
  showLoading = false
  pagination: Pagination = { current_page: 1, batch_size: 10, total_count: 1, total_pages: 1 }

  searchConfig: FilterConfiguration = {}

  getVersions(pageNumber) {
    this.showLoading = true

    this.filter.page = pageNumber ?? 1
    this.filter.get_filter_configuration = true
    this.filter.cloneable_only = true

    this._searchService.globalSearch(this.filter, this.isForSocialMedia).subscribe({
      next: (res: any) => {
        this.versions = res.products
        this.pagination = res
        this.showLoading = false
        this.searchConfig = res.filter_configuration
      },
      error: (err) => {
        this.showLoading = false
      }
    })
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = "";
  close() {
    // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInLeft")
    // document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    // setTimeout(() => { this._dialogRef.close(); }, 1000);
    this._dialogRef.close();
  }

  openSignIn() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(LoginComponent, dialogConfig)
  }


  getProductVersionHistory(versionId) {
    if (this._localStorage.accessToken?.length == 0) {
      this.openSignIn()
      return
    }
    this._spinner.show()
    this._productService.getProductHistory(versionId).subscribe({
      next: versions => {
        this._spinner.hide()
        if (versions?.length > 0)
          this.openVersionHistory(versions, versionId)
        else
          this.createVersionHistory(versionId)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  createVersionHistory(versionId) {
    this._spinner.show()
    this._productService.createVersionHistory(versionId, false).subscribe({
      next: version => {
        this._spinner.hide()
        this.openVersionHistory([version], versionId)
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openVersionHistory(histories, versionId) {
    if (this.isForSocialMedia) {
      this.openHistorySelectionPopup(histories, versionId)
      return
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']

    dialogConfig.data = { versionId: versionId, histories: histories, isForDesignSelection: true };
    this._matDialog.open(VersionHistoryComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          this._dialogRef.close(value)
        }
      });
  }

  selectedHistories: VersionHistory[]
  openHistorySelectionPopup(histories, versionId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-auto', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']

    dialogConfig.data = { selectedHistories: this.selectedHistories, versionHistories: histories };
    this._matDialog.open(LibraryChooserComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          console.log(value);
          this.selectedHistories = value
        }
      });
  }

  doSearch() {
    this.getVersions(1)
  }

  franchises: Franchise[]
  onChangeFranchiseCategory() {
    this.filter.franchise = null
    this.franchises = this.searchConfig.businesses?.find(business => business.id == this.filter.franchise_category)?.franchises
  }

  onChangeCategory() {
    this.filter.sub_category = null
    this.filter.size = null
    this.filter.franchise_category = null
    this.filter.franchise = null
    this.filter.colors = []
  }

  onChangeSubCategory() {
    this.filter.size = null
    this.filter.franchise_category = null
    this.filter.franchise = null
    this.filter.colors = []
  }

  onChangeSize() {
    this.filter.franchise_category = null
    this.filter.franchise = null
    this.filter.colors = []
  }

  submit() {
    this._dialogRef.close(this.selectedHistories)
  }

}
