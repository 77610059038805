import { Pagination } from "../api/pagination.type"
import { Color } from "../configuration/configuration.types"

export class Product {
    id?: number
    slug?: string
    title?: string
    sub_title?: string
    description?: string
    bullets?: string
    size?: number
    category?: number
    sub_category?: number
    category_id?: number
    sub_category_id?: number
    featured_image?: string
    images?: string[]
    is_printable?: boolean
    is_printing?:boolean
    is_accessory?: boolean
    franchises?: Franchise[]
    franchise_categories?: FranchiseCategory[]
    colors?: Color[]
    tags?: Tag[]
    keywords?: Keyword[]

    user_tag?: Tag
    is_user_product?: boolean
    template_name?
    price?: string
    accessories?: any[]
    thumb_image?: string

    similar_products?: Product[]

    //for version details
    image: string
}

export class ProductVersion extends Product {
    is_featured: boolean
    franchise_name: string
    franchise_category: string
}

export interface Keyword {
    id: number
    title: string
}
export interface Tag {
    id: number
    tag: string
    title: string
}

export interface TagList {
    data: Tag[]
}

export interface VersionsList extends Pagination {
    data: Product[]
}

export interface SearchResult extends Pagination {
    filter_configuration: FilterConfiguration
    products: Product[]
    data: Product[]
}

export interface FilterConfiguration {
    categories?: Category[]
    sub_categories?: Category[]
    sizes?: Size[]
    businesses?: FranchiseCategory[]
    colors?: Color[]
}

export interface Category {
    id: number
    title: string
    printable: boolean
    user_templates_allowed: boolean
}

export interface Size {
    id: number
    height: number
    width: number
    title: string
}

// export interface Color {
//     id: number
//     name: string
//     code: string

//     color: string
//     color_code: string
// }

export interface FranchiseCategory {
    id: number
    name: string
    franchise_category: string
    franchises: Franchise[]
}

export interface Franchise {
    name: string
    id: number
    category: number
}

export class FilterParams {
    custom_templates?: boolean
    category?: number
    sub_category?: number
    category_slug?:string
    sub_category_slug?:string
    keywords?: number[]
    colors? = []
    search?: string
    user_tag?: number
    page?: number = 1
    cloneable_only?: boolean
    printable_only?: boolean
    digital_only?:boolean
    franchise?: number
    franchise_category?: number = null
    size? = 0
    batch_size?= 24
    get_filter_configuration?: boolean = true
    get_results?: boolean = true
    product?: number =  null
}


export interface VersionHistory {
    id: number
    last_edited_time: number
    is_user_product: boolean
    image: string
    thumb: string
    has_design_source: boolean
    is_premium: boolean
    is_custom_design_revision: boolean
    designs: Design[]
    is_printable : boolean
    is_accessory: boolean
}

export interface Design {
    id: number
    title: string
    image: string
    thumb: string
    review?: string
}