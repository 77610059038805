import { Clipboard } from '@angular/cdk/clipboard';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScheduleDemoPopup } from 'app/modules/admin/schedule-demo/schedule-demo.component';

@Component({
    selector: 'schedule-demo',
    templateUrl: './schedule-demo.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'schedule-demo',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe, ShareButtonsModule],
})
export class ScheduleDemoComponent implements OnInit, OnDestroy {
    @ViewChild('demoOrigin') private _demoOrigin: MatButton;
    @ViewChild('demoPanel') private _demoPanel: TemplateRef<any>;

    @Input() appearance: 'icon' | 'button' = 'icon';

    private _overlayRef: OverlayRef;

    user: User

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay, private _matDialog: MatDialog,
        private _viewContainerRef: ViewContainerRef,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry.addSvgIcon(
            "nav_schedule_demo",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav-schedule-demo.svg")
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the demo panel
     */
    openPanel(): void {
        // Return if the demo panel or its origin is not defined
        if (!this._demoPanel || !this._demoOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._demoPanel, this._viewContainerRef));
    }

    /**
     * Close the demo panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._demoOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
    

    openPopup() {
      
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = 'calc(100%)'
        dialogConfig.maxWidth = '100%'
        dialogConfig.position = { right: '0px', top: '0px' }
        dialogConfig.panelClass = ['w-full', 'sm:w-3/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
       this._matDialog.open(ScheduleDemoPopup, dialogConfig)
          .afterClosed().subscribe(value => {
            if (value != null && value.length > 1) {
    
            }
          });
        // this.sidebarContent = "designSelection";
        // this.matDrawer.open()
    
      }
}
