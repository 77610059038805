import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrderService } from 'app/core/order/order.service';
import { OrderItem } from 'app/core/order/order.types';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CartItem } from 'app/core/cart/cart.types';
import { CartService } from 'app/core/cart/cart.service';
import { FileService } from 'app/core/file-services/file.service';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { Address } from 'app/core/user/user.types';
import { BehaviorSubject, Subject } from 'rxjs';
import { CreateAddressComponent } from '../../settings/address/create-address/create-address.component';


@Component({
  selector: 'app-address-chooser',
  templateUrl: './address-chooser.component.html',
  styleUrls: ['./address-chooser.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class AddressChooserComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Address, private _userService: UserService, private _toast: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<AddressChooserComponent>) {
    this.responseMessage = { address: data }
  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  addressList: Address[] = [];
  ngOnInit(): void {
    this._userService.addressList$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(addresses => {
        this.addressList = addresses
        this._changeDetectorRef.markForCheck()
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateAddressComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Address added successfully"), "Success");
          this._userService.getAddresses().subscribe()
        }
      });
  }

  title = "Saved Addresses";

  submit() {
    if (this.selectedBillingAddress == null) {
      this._toast.error("Please choose an address", "Sorry");
    } else
      this.responseMessage = { address: this.selectedBillingAddress }
    this.close()
  }

  //Close modal with a response message
  responseMessage: { address: Address }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  selectedBillingAddress = null

}
