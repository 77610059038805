import { inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CartService } from 'app/core/cart/cart.service';
import { UserService } from 'app/core/user/user.service';

export const cartCloneDataResolver = (route: ActivatedRouteSnapshot) => {
    const _cartService = inject(CartService);
    const _contactService = inject(UserService);
    const requestArray: Observable<any>[] = [_cartService.getCloneDesignDetails(route.params['cartItemId']),
    _cartService.getMappingData(), _contactService.getContacts()]
    return forkJoin(requestArray);
};
