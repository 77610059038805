import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { FilterParams, SearchResult } from 'app/core/product/product.types';

@Injectable({ providedIn: 'root' })
export class SearchService {



    constructor(private _httpClient: HttpClient) {
    }

    private _searchResult: BehaviorSubject<SearchResult> = new BehaviorSubject(null);
    get searchResult$(): Observable<SearchResult> {
        return this._searchResult.asObservable();
    }

    search(filter: FilterParams, isMyLibrary: boolean = false): Observable<SearchResult> {
        
        let request
        if (isMyLibrary)
            request = this._httpClient.post<SearchResult>(`${BASE_URL}user/products/`, filter)
        else {
            let queryParams = new HttpParams();
            if (filter?.category)
                queryParams = queryParams.append("category", filter.category);
            if (filter?.sub_category)
                queryParams = queryParams.append("sub_category", filter.sub_category);
            if (filter?.category_slug)
                queryParams = queryParams.append("category_slug", filter.category_slug);
            if (filter?.sub_category_slug)
                queryParams = queryParams.append("sub_category_slug", filter.sub_category_slug);
            if (filter?.size)
                queryParams = queryParams.append("size", filter.size);
            if (filter?.search)
                queryParams = queryParams.append("search", filter.search);

            queryParams = queryParams.append("batch_size", filter.batch_size ?? 24);

            request = this._httpClient.get<SearchResult>(`${BASE_URL}products/`, { params: queryParams })
        }
        return request.pipe(
            tap((result: SearchResult) => {
                if (filter.get_results)
                    this._searchResult.next(result);

                return result;
            }),
        );
    }

    globalSearch(filter: FilterParams, isMyLibrary: boolean = false, saveResults: boolean = true): Observable<SearchResult> {
        let request

        if (filter.batch_size == null)
            filter.batch_size = 24
        // let queryParams = new HttpParams();
        // if (filter?.category)
        //     queryParams = queryParams.append("category", filter.category);
        // if (filter?.sub_category)
        //     queryParams = queryParams.append("sub_category", filter.sub_category);
        // if (filter?.size)
        //     queryParams = queryParams.append("size", filter.size);
        // if (filter?.search)
        //     queryParams = queryParams.append("search", filter.search);

        if (isMyLibrary)
            request = this._httpClient.post<SearchResult>(`${BASE_URL}user/products/`, filter)
        else
            request = this._httpClient.post<SearchResult>(`${BASE_URL}product/list/`, filter)

        return request.pipe(
            tap((result: SearchResult) => {
                if (filter.get_results && saveResults)
                    this._searchResult.next(result);

                return result;
            }),
        );
    }

    clearData() {
        this._searchResult.next(null);
    }

}
