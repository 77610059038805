import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { subscriptionPlanDataResolver } from './modules/admin/customize-your-plan/plan.resolvers';
import { CartComponent } from './modules/admin/cart/cart.component';
import { HelpComponent } from './modules/admin/help/help.component';
import { faqDataResolver } from './modules/admin/help/help.resolvers';
import { cartDataResolver } from './modules/admin/cart/cart.resolvers';
import { PaymentComponent } from './modules/admin/payment/payment.component';
import { paymentDataResolver } from './modules/admin/payment/payment.resolvers';
import { SocialMediaServicesComponent } from './modules/admin/socialmedia-services/socialmedia-services.component';
import { socialMediaServiceDataResolver } from './modules/admin/socialmedia-services/social-media-services.resolvers';
import { homeDataResolver } from './modules/admin/home/home.resolvers';
import { HomeComponent } from './modules/admin/home/home.component';
import { AutofillManagerComponent } from './modules/admin/autofill-manager/autofill-manager.component';
import { autofillManagerDataResolver } from './modules/admin/autofill-manager/autofill-manager.resolvers';
import { autofillGroupDataResolver } from './modules/admin/autofill-manager/group-details/group-details.resolvers';
import { AutofillGroupDetailsComponent } from './modules/admin/autofill-manager/group-details/group-details.component';
import { autofillMapDataResolver } from './modules/admin/autofill-manager/autofill-map/autofill-map.resolvers';
import { AutofillMapComponent } from './modules/admin/autofill-manager/autofill-map/autofill-map.component';
import { autofillContactDataResolver } from './modules/admin/autofill-manager/contact-update/contact-update.resolvers';
import { AutofillContactComponent } from './modules/admin/autofill-manager/contact-update/contact-update.component';
import { cartCloneDataResolver } from './modules/admin/cart/cart-clone/cart-clone.resolvers';
import { CartCloneComponent } from './modules/admin/cart/cart-clone/cart-clone.component';
import { SearchPageComponent } from './modules/admin/search/search.component';
import { searchDataResolver } from './modules/admin/search/search.resolvers';
import { CcpaComponent } from './modules/admin/ccpa/ccpa.component';
import { ProductDetailComponent } from './modules/admin/product-detail/product-detail.component';
import { categoryDetailDataResolver, productDetailDataResolver } from './modules/admin/product-detail/product-detail.resolvers';
import { PrivacyPolicyComponent } from './modules/admin/privacy-policy/privacy-policy.component';
import { smmPostsDataResolver } from './modules/admin/social-media/social-media-posts/social-media-posts.resolvers';
import { SocialMediaPostsComponent } from './modules/admin/social-media/social-media-posts/social-media-posts.component';
import { smmConnectionDataResolver } from './modules/admin/social-media/social-media-management/social-media-management.resolvers';
import { SocialMediaManagementComponent } from './modules/admin/social-media/social-media-management/social-media-management.component';
import { TermsAndConditions } from './terms-conditions/terms-conditions.component';
import { smmPostsDetailsDataResolver } from './modules/admin/social-media/social-media-post-details/social-media-post-details.resolvers';
import { SocialMediaPostDetailComponent } from './modules/admin/social-media/social-media-post-details/social-media-post-details.component';
import { smyLibraryDataResolver } from './modules/admin/search/my-library.resolvers';
import { EmployeeAccessComponent } from './modules/admin/employee-access/employee-access.component';
import { ContactUsComponent } from './modules/admin/help/contact-us/contact-us.component';
import { employeeAccessDataResolver } from './modules/admin/employee-access/employee-access.resolvers';
import { lockFieldsDataResolver } from './modules/admin/product-detail/lock-fields/lock-fields.resolvers';
import { LockFieldsComponent } from './modules/admin/product-detail/lock-fields/lock-fields.component';
import { SubscriptionPaymentComponent } from './modules/admin/customize-your-plan/subscription-payment/subscription-payment.component';
import { subscriptionPaymentDataResolver } from './modules/admin/customize-your-plan/subscription-payment/subacription-payment.resolvers';
import { searchGlobalDataResolver } from './modules/admin/search/search-global.resolvers';
import { productVersionDetailDataResolver } from './modules/admin/product-detail/product-version-detail.resolvers';
import { AboutUsComponent } from './modules/admin/about-us/about-us.component';
import { ImageSearchComponent } from './modules/admin/image-search/image-search.component';
import { StockImagescomponent } from './modules/admin/stock-images/stock-images.component';
import { PayForOtherComponent } from './modules/admin/pay-for-others/pay-for-others.component';
import { paymentForOtherDataResolver } from './modules/admin/pay-for-others/pay-for-others.resolvers';
import { AutoLoginServiceGuard } from './core/auth/guards/auto-login.guard';
import { CategoryDetailsComponent } from './modules/admin/product-detail/category-details/category-details.component';
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'cart', resolve: { data: cartDataResolver }, component: CartComponent },
            { path: 'cart/clone/:cartItemId', resolve: { data: cartCloneDataResolver }, component: CartCloneComponent },
            { path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.routes') },
            { path: 'checkout', resolve: { data: paymentDataResolver }, component: PaymentComponent },
            { path: 'autofill-manager', resolve: { data: autofillManagerDataResolver }, component: AutofillManagerComponent },
            { path: 'autofill-manager/:id', resolve: { data: autofillGroupDataResolver }, component: AutofillGroupDetailsComponent },
            { path: 'autofill-manager/:id/autofill', resolve: { data: autofillMapDataResolver }, component: AutofillMapComponent },
            { path: 'autofill-manager/:groupId/contact/:contactId', resolve: { data: autofillContactDataResolver }, component: AutofillContactComponent },
            { path: 'social-media/posts', resolve: { data: smmPostsDataResolver }, component: SocialMediaPostsComponent },
            { path: 'social-media/posts/:id', resolve: { data: smmPostsDetailsDataResolver }, component: SocialMediaPostDetailComponent },
            { path: 'social-media', resolve: { data: smmConnectionDataResolver }, component: SocialMediaManagementComponent },
            { path: 'my-library', component: SearchPageComponent, resolve: { result: smyLibraryDataResolver } },
            { path: 'organization/product/:id/employee-access', resolve: { data: employeeAccessDataResolver }, component: EmployeeAccessComponent },
            { path: 'organization/product/:id/lock-fields', resolve: { data: lockFieldsDataResolver }, component: LockFieldsComponent },
            { path: 'pricing/payment', component: SubscriptionPaymentComponent, resolve: { data: subscriptionPaymentDataResolver } },
        ]
    },

    // Public routes
    {
        path: '',
        canActivate: [NoAuthGuard, AutoLoginServiceGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'home', resolve: { data: homeDataResolver }, component: HomeComponent },
            { path: 'product/:productId', resolve: { details: productDetailDataResolver }, component: ProductDetailComponent },
            { path: 'product-version/:versionId', resolve: { details: productVersionDetailDataResolver }, component: ProductDetailComponent },
            { path: 'socialmedia-services', component: SocialMediaServicesComponent, resolve: { data: socialMediaServiceDataResolver } },
            { path: 'pricing', resolve: { plans: subscriptionPlanDataResolver }, loadChildren: () => import('app/modules/admin/customize-your-plan/customize-your-plan.module').then(m => m.CustomizePlanModule) },
            { path: 'pricing/:category', resolve: { plans: subscriptionPlanDataResolver }, loadChildren: () => import('app/modules/admin/customize-your-plan/customize-your-plan.module').then(m => m.CustomizePlanModule) },
            { path: 'pricing/:category/:group', resolve: { plans: subscriptionPlanDataResolver }, loadChildren: () => import('app/modules/admin/customize-your-plan/customize-your-plan.module').then(m => m.CustomizePlanModule) },
            { path: 'help/faq', component: HelpComponent, resolve: { categories: faqDataResolver } },
            { path: 'search', component: SearchPageComponent, resolve: { result: searchDataResolver } },
            { path: 'global-search', component: SearchPageComponent, resolve: { result: searchGlobalDataResolver } },
            { path: 'ccpa', component: CcpaComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'terms-conditions', component: TermsAndConditions },
            {
                path: 'about-us', component: AboutUsComponent, data: {
                    title: 'About Us',
                    description: 'Description Meta Tag Content'
                },
            },
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'category/:id/:subId/:productId', component: SearchPageComponent, resolve: { result: searchGlobalDataResolver } },
            { path: 'category/:id/:subCategoryId', resolve: { details: categoryDetailDataResolver }, component: CategoryDetailsComponent },
            { path: 'category/:id', resolve: { details: categoryDetailDataResolver }, component: CategoryDetailsComponent },
            { path: 'image-search', component: ImageSearchComponent },
            { path: 'stock-images', component: StockImagescomponent },
            { path: 'pay/:token', resolve: { data: paymentForOtherDataResolver }, component: PayForOtherComponent },
        ]
    },
    { path: '**', redirectTo: 'home' },
];
