import { Injectable } from '@angular/core';
import { BroadcastMessage } from './broadcast.type';
import { Subscription, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BroadcastService {

    constructor() { }
    private _handler: Subject<BroadcastMessage> = new Subject<BroadcastMessage>();

    broadcast(type: string, payload: any = null) {
        this._handler.next({ type, payload });
    }

    subscribe(type: string, subscribeUntil, callback: (payload: any) => void): Subscription {
        return this._handler.pipe(filter((message: BroadcastMessage) => message.type === type)).pipe(takeUntil(subscribeUntil))
            .subscribe(callback);
    }
}