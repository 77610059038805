import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AutofillManagerService } from '../autofill-manager.service';
import { AutofillContact, AutofillGroup, MappingData } from '../autofill-manager.types';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { AutofillDesignMapComponent } from './design-map/design-map.component';


@Component({
  selector: 'autofill-map',
  templateUrl: './autofill-map.component.html',
  styleUrls: ['./autofill-map.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatRadioModule, AutofillDesignMapComponent,
    MatTabsModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule,
    MatProgressSpinnerModule]
})

export class AutofillMapComponent implements OnInit {

  @ViewChild("designMapTab", { static: false }) designMapTab: MatTabGroup;
  /**
   * Constructor
   */
  constructor(private _autofillManagerService: AutofillManagerService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef, private _imageViewer: ImageViewerService,
    private _toast: ToastrService, private broadcast: BroadcastService,
    private route: ActivatedRoute) {

  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  groupDetails: AutofillGroup

  mapData: MappingData;
  tabs: any[] = [];
  autofillMappedData = {
    designs: []
  }

  ngOnInit(): void {
    this.mapData = this.route.snapshot.data['data'][1]

    this._autofillManagerService.group$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(group => {
        this.groupDetails = group
        this.configureTabs()
        this._changeDetectorRef.markForCheck()
      })

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()

    })
  }

  configureTabs() {
    this.groupDetails?.design_autofill_data?.designs.forEach((element, index) => {
      if (index === 0) {
        element.active = true
      }
      element.onNext = new EventEmitter<any>();

      this.tabs.push(element)
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onClickNext(data) {
    let index = this.designMapTab.selectedIndex

    if (this.autofillMappedData.designs.length > index) {
      this.autofillMappedData.designs[index] = data
    }
    else {
      this.autofillMappedData.designs.push(data)
    }

    if ((index + 1) == this.tabs.length) {
      this.submitAutofillMappingData()
    } else
      this.designMapTab.selectedIndex += 1

    // console.log("GotNext");
    // this.autofillMappedData.designs.push(data)
    // this.tabs.forEach((item, index) => {
    //   if (item.design_id === data.design_id) {
    //     if (index < (this.tabs.length - 1)) {
    //       this.tabs[index + 1]['active'] = true
    //       this.tabs[index].active = false
    //     }
    //     else {
    //       this.submitAutofillMappingData()
    //     }
    //   }
    // })
  }

  tabChanged(e: MatTabChangeEvent) {
    // this.tabs = this.tabs.filter((item, index) => {
    //   if (index == e.index) {
    //     item.active = true
    //   } else {
    //     item.active = false
    //   }
    //   return item
    // })
  }

  onNext(){
    this.tabs[this.designMapTab.selectedIndex].onNext?.emit()
  }

  submitAutofillMappingData() {

    this._spinner.show();

    let data = { 'design_autofill_data': this.autofillMappedData.designs }
    this._autofillManagerService.updateGroup(this.groupDetails?.id, data).subscribe({
      next: res => {
        this._spinner.hide();
        sessionStorage.setItem("from_product_details", "false")
        let dialog = this._fuseConfirmationService.open({
          title: "Success",
          message: res?.message ?? "Successfully submitted autofill mapping",
          dismissible: false,
          icon: { show: false },
          actions: {
            cancel: { show: false },
            confirm: { label: "Ok" }
          },
        })
        dialog.afterClosed().subscribe(result => {
          this._router.navigate(['autofill-manager/', this.groupDetails?.id])
        })
      },
      error: err => {
        this._spinner.hide()
        this._toast.error(err ? err.error.message : "Something went wrong", "Sorry")
      }
    })
  }
}