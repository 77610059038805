export const environment = {
  production: false,
  envName: "Development",
  firebase: {
    apiKey: "AIzaSyBBGcDo-MBalzrUzF8SMA5JhYD-ib-liPE",
    authDomain: "printing-warehouses.firebaseapp.com",
    databaseURL: "https://printing-warehouses.firebaseio.com",
    projectId: "printing-warehouses",
    storageBucket: "printing-warehouses.appspot.com",
    messagingSenderId: "376257940850",
    appId: "1:376257940850:web:ac7fe32313f622aed7a1eb",
    measurementId: "G-PYMVB18NME"
  }
};

export const BASE_URL = 'https://api.printingw.xyz/v1/';
export const EDITOR_URL = 'https://editor.printingw.xyz';
export const VID_URL = 'https://vid.printingw.xyz/';
export const MARKETING_URL = 'https://marketing.printingw.xyz/';

export const LINKEDIN_REDIRECT_URL = 'https://printingw.xyz/social-media';


export const stripeKey = "pk_test_51HBgnCA1Vot71LN2ozasENLhSsFYESG7V8xCUczjAXYtoHwAJKJTE2nZKBIzB0Zp4CB7I3vX2OSgZwm4cSIRYs6F004Ai1fvbf"

export const ANLYTICS_ID = "GA-223885157"

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
