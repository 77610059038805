import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { PaymentService } from 'app/core/payment/payment.service';
import { finalize } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stripeKey } from 'environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/core/user/user.service';
import { OrderService } from 'app/core/order/order.service';
import { OrderItem } from 'app/core/order/order.types';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Contact, ProductVersionHistory } from 'app/core/user/user.types';
import { Promocode } from 'app/core/cart/cart.types';


@Component({
  selector: 'app-promocodes',
  templateUrl: './promocodes.component.html',
  styleUrls: ['./promocodes.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule, MatProgressSpinnerModule, MatDialogModule, CommonModule,
    MatInputModule, MatCheckboxModule, MatFormFieldModule, FormsModule,
    ReactiveFormsModule, FuseAlertComponent, StripeCardComponent
  ]
})
export class PromocodesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { codes: Promocode[], isFromCart: boolean },
    private _dialogRef: MatDialogRef<PromocodesComponent>) {
    this.promocodes = this.data?.codes
  }

  promocodes

  ngOnInit(): void {

  }

  title = "Promocodes";

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  showLoading = false

  //Close modal with a response message
  responseMessage: { message: string, promocode: Promocode }
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  applyPromocode(promocode: Promocode) {
    this.responseMessage = { message: null, promocode: promocode }
    this.close()
  }

}
