<mat-dialog-actions class="flex justify-between pl-4 mb-1">
    <div class="justify-self-start text-lg sm:text-2xl font-extrabold">{{title}}</div>
    <button class="justify-end ml-2 sm:ml-8" mat-button (click)="close()">
        <img alt="Close icon" src="./assets/images/close_icon.svg" style="width:17px;height:17px;object-fit: contain;">
    </button>
    <!-- </div> -->
</mat-dialog-actions>
<mat-dialog-content class="pt-1 pb-1">

    <div class="flex flex-col sm:flex-row items-center justify-center flex-auto min-w-0">
        <div class="flex items-center  w-full  pt-0">
            <div class="w-full max-w-120 sm:min-w-100  mx-auto ">
                <!-- <div>
                    {{data.message}}
                </div> -->
                <!-- Create keyword form -->
                <form [formGroup]="employeeForm" #employeeNgForm="ngForm" class="grid grid-cols-1 md:grid-cols-1 gap-2">

                    <mat-form-field class="w-full">
                        <mat-label>Email Address</mat-label>
                        <input [formControlName]="'email'" matInput class="text-black notranslate">
                        <mat-error *ngIf="employeeForm.get('email').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="employeeForm.get('email').hasError('email')">
                            A valid email is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]" floatLabel="always">
                        <mat-label>Designation</mat-label>
                        <mat-select name="designation" [formControlName]="'designation'" class=" notranslate"
                            (selectionChange)="onDesignationChanged()">
                            <mat-option *ngFor="let designation of designations" [value]="designation" class=" notranslate">
                                {{designation}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="employeeForm.get('designation').hasError('required')">
                            Designation is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]" *ngIf="showCustomDesignation">
                        <mat-label>Custom Designation</mat-label>
                        <input [formControlName]="'custom_designation'" matInput class="text-black notranslate">
                        <mat-error *ngIf="employeeForm.get('custom_designation').hasError('required')">
                            Please mention employee designation
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full -mt-[10px]" [floatLabel]="'always'">
                        <mat-label>Expiry</mat-label>
                        <input matInput [formControlName]="'expiry'" [matDatepicker]="picker"
                            placeholder="Choose expiry date" [min]="today" (dateChange)="onDateChanged( $event)" class="text-black notranslate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                        <mat-error *ngIf="hasDateError">
                            Invalid date
                        </mat-error>
                    </mat-form-field>

                    <!-- Alert -->
                    <fuse-alert class="col-span-1 md:col-span-2" class="mt-4" *ngIf="showAlert" [appearance]="'outline'"
                        [showIcon]="false" [type]="alert.type" [dismissible]="true">
                        {{alert.message}}
                    </fuse-alert>
                </form>
            </div>
        </div>

    </div>

</mat-dialog-content>
<mat-dialog-actions class="p-4 pt-0">
    <!-- Submit button -->
    <button class="btn_normal w-50 mt-3" [disabled]="employeeForm.disabled" mat-flat-button
        [color]="'primary'" type="submit" (click)="submit()">
        <span *ngIf="!employeeForm.disabled">
            Submit
        </span>
        <mat-progress-spinner *ngIf="employeeForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button>
</mat-dialog-actions>