import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';
declare function googleTranslateElementInit()

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _localStorageService: LocalStorageService,
        private _cookieService: CookieService,
    ) {
        this.activeLang = this._localStorageService.language
        // this.saveLanguageSelection(this.activeLang)

        // let translateLanguage = this._cookieService.get('googtrans')
        // if (translateLanguage?.length == 0) {
        //     this.activeLang = 'en';
        // }
        // else {
        //     this.activeLang = translateLanguage.replace('/en/', '');
        // }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        // this._translocoService.langChanges$.subscribe((activeLang) => {
        //     console.log(activeLang);

        //     // Get the active lang
        //     this.activeLang = activeLang;

        //     // Update the navigation
        //     this._updateNavigation(activeLang);
        // });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'tr': 'tr',
            'es': 'es',
            'vi': 'vn',
            'pt': 'pt',
            'fr': 'fr',
            'zh-CN': 'cn'
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {

        if (this.activeLang == lang)
            return;
        this.activeLang = lang
        this._localStorageService.language = lang
        // Set the active lang
        this._translocoService.setActiveLang(lang);
        this.saveLanguageSelection(lang)
        googleTranslateElementInit()
        window.location.reload();
    }

    saveLanguageSelection(lang: string) {
        this._cookieService.deleteAll('/')
        let domain = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
        document.cookie = `googtrans=/en/${lang}; path=/; domain=.${domain}`;
        document.cookie = `googtrans=/en/${lang}; path=/; domain=${domain}`;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        if (projectDashboardItem) {
            this._translocoService.selectTranslate('Project').pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        if (analyticsDashboardItem) {
            this._translocoService.selectTranslate('Analytics').pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
