<mat-dialog-actions class="flex justify-end pl-4 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <div class="flex justify-between items-center w-full">
        <h1 class="text-black text-left text-xl sm:text-2xl" style="font-weight:700;">Business Categories</h1>
        <button class="flex w-auto justify-end items-center mb-0" mat-button (click)="close()">
            <img alt="Close icon" src="./assets/images/close_icon.svg"
                style="width:17px;height:17px;object-fit: contain;">
        </button>
    </div>
</mat-dialog-actions>
<mat-dialog-content class="p-0 w-full max-w-full max-h-[80vh]">

    <!-- Content starts -->
    <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 pt-5 pb-5 overflow-scroll">
        <li *ngFor="let category of categories;last as isLast">
            <a (click)="close()" style="cursor:pointer" [routerLink]="['/','global-search']"
                [queryParams]="{'franchise_category':category.id}" [matTooltip]="category.name">
                <div class="w-full category_btn pl-4 pt-2 pb-2 flex flex-row gap-4 items-center">
                    <img src="{{category.image}}" alt="And more" style="width:20px;height:20px;object-fit: contain;">
                    <span class="text-black truncate">
                        {{category.name}}</span>
                </div>

            </a>
        </li>
    </ul>
</mat-dialog-content>

<!-- <mat-dialog-actions class="pb-4 pr-0 pt-2 pl-2 justify-end mt-4"> -->
<!-- Submit button -->
<!-- <button class="fuse-mat-button-large w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="createChecklistForm.disabled" (click)="submitForm()" form="createChecklistForm">
        <span *ngIf="!createChecklistForm.disabled">
            SUBMIT
        </span>
        <mat-progress-spinner *ngIf="createChecklistForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button> -->

<!-- </mat-dialog-actions> -->