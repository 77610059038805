import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SMMAllAccountsResponse, SMMPost } from 'app/core/social-media/social-media.types';
import { ToastrService } from 'ngx-toastr';
import { MatInputModule } from '@angular/material/input';
import { ProductService } from 'app/core/product/product.service';
import { Tag } from 'app/core/product/product.types';
import { Pagination } from 'app/core/api/pagination.type';
import { PWAPagination } from 'app/shared/pagination/pagination.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-newpost-popup',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FormsModule, MatInputModule,
    ReactiveFormsModule, MatDialogModule,
    MatFormFieldModule, MatSelectModule,
    MatTabsModule, PWAPagination, MatProgressSpinnerModule,
    MatIconModule, MatSidenavModule, FuseAlertComponent,
    MatButtonModule, RouterModule, FuseCardComponent]
})
export class ManageTags implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: { versionId: any, selectedTag: any },
    private _productService: ProductService, private _toast: ToastrService,
    private fb: UntypedFormBuilder, private _confirmation: FuseConfirmationService,
    private _dialogRef: MatDialogRef<ManageTags>, private _spinner: NgxSpinnerService,) {

  }

  @ViewChild('tagNgForm') tagNgForm: NgForm;
  tagForm: UntypedFormGroup;
  tagUpdateForm: UntypedFormGroup;
  selectedTag
  ngOnInit(): void {
    this.tagForm = this.fb.group({
      tag: ['', [Validators.required]],
      add_product_versions: [this.data?.versionId ? [this.data?.versionId] : []]
    });
    this.tagUpdateForm = this.fb.group({
      tag: [this.data?.selectedTag ?? '', [Validators.required]]
    });
    this.getTags(1)
  }

  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };


  //Close modal with a response message
  responseMessage = { needToUpdate: false };
  close() {
    this._dialogRef.close(this.responseMessage);
  }

  showLoading = false
  tags: Tag[]
  pagination: Pagination = { current_page: 1, batch_size: 10, total_count: 1, total_pages: 1 }

  getTags(pageNumber) {
    this.showLoading = true
    this._productService.getUserTags('').subscribe({
      next: (res: any) => {
        this.tags = res.data
        this.pagination = res
        this.showLoading = false
      },
      error: (err) => {
        this.showLoading = false
      }
    })
  }

  createTag() {
    this.showAlert = false
    this.tagForm.markAllAsTouched();
    if (this.tagForm.invalid) {
      return;
    }

    this.tagForm.disable()

    this._productService.createUserTag(this.tagForm.value).subscribe({
      next: (res: any) => {
        this.getTags(1)
        this.tagForm.enable()
        this.tagForm.patchValue({ 'tag': '' })
        this.tagForm.markAsUntouched()
        if (this.data?.versionId != null) {
          this._toast.success("Tag assigned to the template", "Success")
          this.responseMessage.needToUpdate = true
          this.close()
        }
      },
      error: (error) => {
        this.tagForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  updateTag() {
    this.showAlert = false
    this.tagUpdateForm.markAllAsTouched();
    if (this.tagUpdateForm.invalid) {
      return;
    }

    this.tagUpdateForm.disable()

    this._productService.updateUserTag({ add_product_versions: [this.data?.versionId] }, this.tagUpdateForm.get('tag').value).subscribe({
      next: (res: any) => {
        this.getTags(1)
        this.tagUpdateForm.enable()
        this.tagUpdateForm.markAsUntouched()
        if (this.data?.versionId != null) {
          this._toast.success("Tag assigned to the template", "Success")
          this.responseMessage.needToUpdate = true
          this.close()
        }
      },
      error: (error) => {
        this.tagUpdateForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }

  deleteTag(tag) {
    const dialogRef = this._confirmation.open({
      title: "Delete Tag",
      message: `Are you sure you want to delete '${tag.tag}'? This action will remove the tag from all product versions associated with it`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteTag(tag.id)
    });
  }

  proceedDeleteTag(id) {
    this._spinner.show();
    this._productService.deleteUserTag(id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.alert.type = "success"
        this.alert.message = res.message ? res.message : "Tag deleted"
        this.showAlert = true
        this.tags = this.tags.filter(tag => tag.id != id)
        this.responseMessage.needToUpdate = true
      },
      error: (error) => {
        this._spinner.hide();
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }
}
