import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { EmailSignatureResponse, FeaturedTemplate, HomeBanner } from './home.types';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class HomeService {

    constructor(private _httpClient: HttpClient) {
    }

    private _banners: BehaviorSubject<HomeBanner[]> = new BehaviorSubject(null);
    get banners$(): Observable<HomeBanner[]> {
        return this._banners.asObservable();
    }

    getBanners(): Observable<HomeBanner[]> {
        return this._httpClient.get<HomeBanner[]>(`${BASE_URL}web/banners/`).pipe(
            tap((config) => {
                this._banners.next(config);
            }),
        );
    }

    downloadEBook(data) {
        return this._httpClient.post<any>(`${BASE_URL}marketing/ebook/download/`, data)
    }

    subscribeNewsLetter(data) {
        return this._httpClient.post<any>(`${BASE_URL}marketing/newsletter/`, data)
    }

    getEmailSignatures() {
        return this._httpClient.get<EmailSignatureResponse>(`${BASE_URL}email_signature/`)
    }

    getFeaturedTemplates() {
        return this._httpClient.get<FeaturedTemplate[]>(`${BASE_URL}web/featured-products/`)
    }

    getFeaturedCategories() {
        return this._httpClient.get<FeaturedTemplate[]>(`${BASE_URL}web/categories/`)
    }

}
