import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatButtonModule } from '@angular/material/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportToken } from './support-token/support-token.component';
import { faL } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule, FuseAlertComponent, MatSlideToggleModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, SupportToken, MatDialogModule]
})

export class ContactUsComponent implements OnInit {
  @ViewChild('contactNgForm') contactNgForm: NgForm;
  contactForm: UntypedFormGroup;
  user: User
  ngOnInit(): void {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      subject: [''],
      message: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
    this._userService.user$.subscribe(user => {
      this.user = user
      this.reInitForm()
    })
  }

  reInitForm() {
    this.contactForm.patchValue({
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      email: this.user.email,
    })
  }

  openSupportToken(token) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = token;
    dialogConfig.panelClass = ['w-full', 'max-w-md']
    this._matDialog.open(SupportToken, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {

        }
      });
  }

  getSupportToken() {
    this._spinner.show()
    let request = this._userService.getSupportToken()
    request.subscribe({
      next: (response: any) => {
        this._spinner.hide()
        this.openSupportToken(response.token)
      },
      error: error => {
        this._spinner.hide()
        this._confirmation.open({
          title: "Sorry",
          message: error ? error.error.message : "Something went wrong. Please try again",
          actions: { cancel: { show: false }, confirm: { label: "Ok" } }
        })
      }
    })
  }

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private _userService: UserService, private _spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder, private _confirmation: FuseConfirmationService) {

  }
  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  submit() {
    this.showAlert = false
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      return;
    }
    this.contactForm.disable()

    let request = this._userService.submitContactUsForm(this.contactForm.value)
    request.subscribe({
      next: (response: any) => {
        this.contactForm.enable()
        this.contactForm.reset()
        this._confirmation.open({
          title: "Success",
          icon: { show: false },
          actions: { confirm: { label: "Ok" }, cancel: { show: false } },
          message: response?.message ?? "Thank you for showing your interest in us. We will get back to you soon."
        })
      },
      error: error => {
        this.contactForm.enable()
        this.alert.type = "error"
        this.alert.message = error ? error.error.message : "Something went wrong. Please try again"
        this.showAlert = true
      }
    })
  }
}