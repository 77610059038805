<!-- referal toggle -->
<button mat-icon-button (click)="openPanel()" #referralOrigin>
    <mat-icon [svgIcon]="'nav_gift_icon'" style="padding: 3px;" class="text-black"></mat-icon>
</button>

<!-- referal panel -->
<ng-template #referralPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Share the Referral Link</div>

        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <ng-container>
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-26 h-26">
                        <mat-icon class="text-primary-700 dark:text-primary-50 w-26 h-26"
                            [svgIcon]="'link_share_icon'"></mat-icon>
                    </div>
                    <!-- <div class="mt-5 text-2xl font-semibold tracking-tight">
                        Share the Referral Link
                    </div> -->
                    <div class="w-full max-w-60 text-md text-center text-secondary mt-5">
                        {{user.referral_display_message}}
                    </div>

                    <div class="flex gap-3 justify-between w-full mt-6"
                        style="background-color:white;border-radius:6px;padding:8px 8px 8px 20px;box-shadow: 0 3px 6px 0 #e9eff8;border: solid 1px #e1e8f4;justify-content: space-between;">
                        <p style="margin-bottom:0px;font-size:12px;color:#000000;align-self: center;">
                            {{user.referral_code}}
                        </p>
                        <button class="btn_normal p-4 pl-6 pr-6 flex items-center justify-center"
                            style="border-radius:8px;height:40px;" (click)="onClickCopy(user.referral_code)">
                            <div class="flex flex-row justify-content-center align-items-center ">
                                <lottie-player *ngIf="showSuccessAnimation" [src]="successAnimationRes"
                                    background="transparent" speed="2"
                                    style="margin-right: 10px; width: 20px; height: 20px;" autoplay></lottie-player>
                                <div class="text-md">
                                    {{showSuccessAnimation ? 'Copied' : 'Click to Copy'}}
                                </div>
                            </div>
                        </button>
                    </div>

                    <div class="flex justify-center mt-8 gap-2">
                        <button shareButton="facebook" [url]="user.referral_url"
                            style="border:0px;background-color:transparent" [description]="user.referral_share_message">
                            <img src="./assets/images/fb_icon.png" style="width:30px;height:30px;" alt="Facebook">
                        </button>
                        <button shareButton="twitter" [url]="user.referral_url"
                            style="border:0px;background-color:transparent" [description]="user.referral_share_message">
                            <img src="./assets/images/twitter_icon.png" style="width:30px;height:30px;" alt="Twitter">
                        </button>
                        <button shareButton="linkedin" [url]="user.referral_url"
                            style="border:0px;background-color:transparent" [description]="user.referral_share_message">
                            <img src="./assets/images/linkedin_icon.png"
                                style="border:0px;background-color:transparent;width:30px;height:30px;" alt="Linkedin">
                        </button>
                        <button shareButton="email" [url]="user.referral_url"
                            style="border:0px;background-color:transparent" [description]="user.referral_share_message">
                            <img src="./assets/images/em_sharebtn.svg"
                                style="border:0px;background-color:transparent;width:30px;height:30px;" alt="Email">
                        </button>
                    </div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>