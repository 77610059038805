import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { VersionHistory } from 'app/core/product/product.types';
import { GlobalService } from 'app/core/auth/global.service';
import { EDITOR_URL } from 'environments/environment';
import { ProductService } from 'app/core/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CartService } from 'app/core/cart/cart.service';
import { MatMenuModule } from '@angular/material/menu';
import { CreateGroupComponent } from '../../autofill-manager/create-group/create-group.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
    selector: 'app-add-to-cart',
    templateUrl: './add-to-cart.component.html',
    styleUrls: ['./add-to-cart.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule,
        MatFormFieldModule, MatSelectModule, MatTabsModule, MatIconModule, MatMenuModule,
        MatSidenavModule, MatButtonModule, RouterModule, MatProgressSpinnerModule, NgOptimizedImage]
})
export class AddToCartComponent implements OnInit {
    @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
    histories: VersionHistory[];
    isScreenSmall: boolean;
    selectedHistory: any
    previousOptions = []
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { historyItem: any, isAccessory: boolean, historyId, options, quantity, versionId, autofillType, isForDesignSelection: false, autofillDetails: false },
        private _formBuilder: FormBuilder, private _globalService: GlobalService,
        private _productService: ProductService, private _toast: ToastrService,
        private _spinner: NgxSpinnerService, private _confirmation: FuseConfirmationService,
        private _cartService: CartService, private _router: Router,
        private _dialogRef: MatDialogRef<AddToCartComponent>,
        private _globalDialog: MatDialog
    ) {
        this.previousOptions = data?.options
        this.selectedHistory = data?.historyItem
    }

    ngOnInit() {
        this.getPricing(-1)
    }

    configurations: any[];
    selectedQuantity
    isGettingPrice = false
    getPricing(index) {
        this.isGettingPrice = true
        let requestData = {}
        if (this.data?.isAccessory == true) {
            requestData['version'] = this.data?.versionId
            requestData['options'] = this.getOptionsArray()
        }
        else {
            requestData['quantityID'] = this.selectedQuantity?.id
            requestData['options'] = this.getOptionsArray()
            requestData['version'] = this.data?.versionId
        }

        this._productService.getPriceCalculation(requestData).subscribe({
            next: (res: any) => {
                this.isGettingPrice = false
                if (res.type == 'configuration') {
                    if (this.configurations == null)
                        this.configurations = []

                    this.configurations.push(res)
                    res.selected = null
                    console.log(this.previousOptions);

                    let selectedOption = res.options.find(option => this.previousOptions?.includes(option.id + ''))

                    if (selectedOption != null) {
                        res.selected = selectedOption.id
                        this.onOptionsSelected(res.selected, index + 1)
                    } else if (res.options.length == 1) {
                        res.selected = res.options[0].id
                        this.onOptionsSelected(res.selected, index + 1)
                    }
                } else if (res.type == 'quantity') {
                    this.quantityList = res.options
                    this.onSelectQuantity(this.quantityList[0])
                }
            },
            error: (err) => {
                this.isGettingPrice = false
                this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
            }
        })
    }

    quantityList: any = []

    optionsMap = new Map<number, number>();
    onSelectQuantity(event) {
        this.selectedQuantity = event
    }

    getOptionsArray() {
        let optionsArray: string[] = [];
        this.optionsMap.forEach((value: number, key: number) => {
            optionsArray.push(value.toString());
        });

        return optionsArray;
    }

    onOptionsSelected(option, index) {
        for (let i = index; i < this.configurations.length; i++) {
            this.optionsMap.delete(i + 1)
        }
        this.selectedQuantity = null
        this.quantityList = null
        this.configurations = this.configurations.slice(0, index + 1)
        if (option != null) {
            this.optionsMap.set(index, option)
            this.getPricing(index)
        }
    }

    openAutofillGroupCreateModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            "versionHistory": this.data?.historyId,
            "quantity": this.selectedQuantity?.id,
            "options": this.getOptionsArray(),
        }
        this._globalDialog.open(CreateGroupComponent, dialogConfig);
    }

    addToCart() {
        if (this.selectedQuantity == null) {
            this._toast.error("Please select configurations and quantity before adding to cart", "Sorry")
            return
        }
        if (this.data?.autofillType == 'manager') {
            this.openAutofillGroupCreateModal()
            return
        }

        let requestData = {
            "history_id": this.data?.historyId,
            "version_id": this.data?.versionId,
            "quantity": this.selectedQuantity?.id,
            "options": this.getOptionsArray(),
            'use_for_autofill': this.data?.autofillDetails ?? false
        }

        if (this.data?.isForDesignSelection) {
            this._dialogRef.close(requestData)
            return
        }

        this._spinner.show()
        this._cartService.addToCart(requestData).subscribe({
            next: (response: any) => {
                this._spinner.hide()
                this._confirmation.open({
                    icon: { show: false },
                    title: "Cart",
                    message: response.message ? response.message : "Product added to cart",
                    actions: {
                        cancel: { show: this.data?.autofillType == null },
                        confirm: {
                            label: this.data?.autofillType == 'contacts' ? 'Choose Contacts' : "Go to Cart",
                        }
                    }
                }).afterClosed().subscribe((result) => {
                    if (this.data?.autofillType == 'contacts') {
                        this._router.navigate(['/', 'cart', 'clone', response?.id], { queryParams: { action: 'autofill' } })
                    }
                    else {
                        if (result == 'confirmed')
                            this._router.navigate(['/', 'cart'])
                    }
                    this._globalDialog.closeAll()
                });
            },
            error: error => {
                this._spinner.hide()
                this._toast.error(error ? error.error.message : "Something went wrong. Please try again!", "Sorry")
            }
        })
    }




    //Close modal with a response message
    responseMessage = "";
    close() {
        this._dialogRef.close();
    }


}
