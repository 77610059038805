import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {


    constructor() {
    }

    //Access Token
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    //Account Type
    set accountType(type: string) {
        localStorage.setItem('account_type', type);
    }

    get accountType(): string {
        return localStorage.getItem('account_type') ?? null;
    }

    //Organization Name
    set organizationName(type: string) {
        localStorage.setItem('organization_name', type);
    }

    get organizationName(): string {
        return localStorage.getItem('organization_name') ?? null;
    }


    //Organization Id
    set organizationId(type: string) {
        localStorage.setItem('organization_id', type);
    }

    get organizationId(): string {
        return localStorage.getItem('organization_id') ?? null;
    }

    //is Organization owner
    set isOrganizationOwner(isOwner: boolean) {
        localStorage.setItem('organization_owner', isOwner ? '1' : '0');
    }

    get isOrganizationOwner(): boolean {
        return localStorage.getItem('organization_owner') == '1';
    }

    //is designer 
    set isPWADesigner(isDesigner: boolean) {
        localStorage.setItem('pwa_designer', isDesigner ? '1' : '0');
    }

    get isPWADesigner(): boolean {
        return localStorage.getItem('pwa_designer') == '1';
    }

    //Customer Details
    set transferAccountDetails(transferAccountDetails: string) {
        localStorage.setItem('transferAccountDetails', transferAccountDetails);
    }

    get transferAccountDetails(): string {
        return localStorage.getItem('transferAccountDetails') ?? '';
    }

    //Language
    set language(language: string) {
        localStorage.setItem('app-language', language);
    }

    get language(): string {
        return localStorage.getItem('app-language') ?? 'en';
    }
}
